import styled from "styled-components";
import Wrap from "src/components/Wrap";
import Image from "src/components/Common/ImageFluid";
import { Paragraph } from "src/components/Text";

export const StyledWrap = styled(Wrap)`
  &.variant_A {
    display: none;
  }
  padding: 4.5rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${({ theme }) => theme.device.tablet} {
    padding: 9.3rem 8.1rem 9.3rem;
  }
`;

export const ImagesRowContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledImage = styled(Image)`
  @media ${({ theme }) => theme.device.tablet} {
  }
`;

export const ImageContainer = styled.div`
  @media ${({ theme }) => theme.device.mobile} {
    &:first-child {
      margin: 0 2rem 0 0;
    }

    &:last-child {
      margin: 0 0rem 0 0;
    }
  }

  @media ${({ theme }) => theme.device.tablet} {
    &:first-child {
      margin: 0 2rem 0 4rem;
    }

    &:last-child {
      margin: 0 4rem 0 2rem;
    }
  }

  @media ${({ theme }) => theme.device.laptop} {
    &:first-child {
      margin: 0 2rem 0 6rem;
    }

    &:last-child {
      margin: 0 6rem 0 2rem;
    }
  }
`;

export const TextBox = styled.div`
  @media ${({ theme }) => theme.device.mobile} {
    display: flex;
    flex-direction: column;
  }

  @media ${({ theme }) => theme.device.tablet} {
    display: none;
  }

  @media ${({ theme }) => theme.device.laptop} {
    display: none;
  }
`;

export const Heading = styled(Paragraph)`
  font-size: 2.8rem;
  line-height: 3rem;
  margin-bottom: 2rem;
  font-family: 'Effra-Regular';
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: left;
`;

export const SubHeading = styled(Paragraph)`
  font-size: 1.4rem;
  font-family: 'Effra-Regular';
  font-style: normal;
  font-weight: 300;
  line-height: 1.7rem;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 3rem;
`;
