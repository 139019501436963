import styled, { css } from "styled-components";
import { SectionHeading } from "src/components/Text";
import BlockContent from "src/components/BlockContent";
import Icon from "src/components/Common/Icon";

interface OpenProps {
  open: boolean;
}

export const Title = styled(SectionHeading)`
  font-size: 2.4rem;
  line-height: 3.2rem;
  margin-bottom: 1rem;

  @media ${({ theme }) => theme.device.tablet} {
    font-size: 4.4rem;
    line-height: 5.4rem;
    margin-bottom: 0.4rem;
  }
`;

export const Type = styled(SectionHeading)`
  font-size: 1.4rem;
  line-height: 2rem;

  @media ${({ theme }) => theme.device.tablet} {
    font-size: 1.6rem;
    line-height: 2rem;
  }
`;

export const Arrow = styled(Icon)`
  position: absolute;
  width: 3.2rem;
  height: 3.2rem;
  right: 2rem;
  bottom: 1.5rem;

  @media ${({ theme }) => theme.device.tablet} {
    right: 5.5rem;
    top: 7rem;
    bottom: unset;
  }
`;

export const Row = styled.div<OpenProps>`
  background-color: ${({ open, theme }) =>
    open ? theme.colors.bg_blue : theme.colors.pale_blue};
  padding: 1.7rem 3.5rem 1.7rem 2rem;
  min-height: 13.7rem;
  position: relative;

  @media ${({ theme }) => theme.device.tablet} {
    padding: 3.5rem 6rem;
    min-height: 16.2rem;
  }

  ${Title}, ${Type} {
    transition: 0.2s ease-out color;

    ${({ open }) =>
      open &&
      css`
        color: ${({ theme }) => theme.colors.font_white};
      `}
  }

  ${Arrow} {
    transition: 0.2s ease-out transform;

    path {
      transition: 0.2s ease-out stroke;
    }

    ${({ open }) =>
      open &&
      css`
        transform: rotate(90deg);

        path {
          stroke: ${({ theme }) => theme.colors.font_white};
        }
      `}
  }
`;

export const Description = styled(BlockContent)`
  font-size: 1.4rem;
  line-height: 2.2rem;
  font-family: 'Effra-Regular';
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.font_black};
  margin-bottom: 4.2rem;

  @media ${({ theme }) => theme.device.tablet} {
    font-size: 1.6rem;
    line-height: 3rem;
  }
`;

export const OpenContainer = styled.div`
  padding: 0.5rem 0 7rem;

  @media ${({ theme }) => theme.device.tablet} {
    padding: 0 6rem 8rem 6rem;
  }
`;
