import React, { useState, useCallback } from "react";

export default function useToggle(
  initialValue = false
): [boolean, (val?: boolean) => void] {
  const [value, setValue] = useState<boolean>(initialValue);

  const toggle = useCallback((val) => {
    if (typeof val !== "undefined" && val.type !== "click") {
      setValue(val);
      return;
    }

    setValue((v) => !v);
  }, []);

  return [value, toggle];
}
