import styled, { css } from "styled-components";
import Image from "src/components/Common/ImageFluid";
import Wrap from "src/components/Wrap";
import { SectionHeading } from "src/components/Text";
import BlockContent from "src/components/BlockContent";


export const StyledSection = styled.section`
  background-color: #F6F6FA;
  scroll-margin-top: ${({ notificationBar }) =>
    notificationBar ? "9rem" : "6.3rem"};

  @media ${({ theme }) => theme.device.tablet} {
    padding: 0 0 3rem 0;

    scroll-margin-top: ${({ notificationBar }) =>
      notificationBar ? "11.7rem" : "6.9rem"};
  }
`;

export const StyledWrap = styled(Wrap)`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin: 8rem auto 2rem auto;

  .blog-group-title {
    flex-direction: column;
    font-size: 2.75rem;
    line-height: 1.975;
    margin-bottom: 40px;
    width: 30%;
  }

  @media ${({ theme }) => theme.device.tablet} {
    padding: 6.2rem 8.1rem 6.6rem;
  }

  .blog-groups-container {
    width: 100%;

    .main-div {
      width: 95%;
      background-color: #fff;
      border: unset;
      border-radius: unset;
      box-shadow: 0 3px 6px #00000029;
      display: flex;
      flex-direction: row;
      margin: 0 10px 60px 10px;
      padding: 23px;
      flex-direction: column;

      .bg-content {
        width: 100%;

        .name {
          font-size: 2.2rem;
          margin: 2rem 0 .6rem 0;
          font-family: 'Effra-Regular';
        }

        .description {
          font-size: 1.5rem;
          margin: 0 0 2rem;
          font-family: 'Effra-Regular';

        }

        .learn-more-container {
          font-size: 16px;
          font-family: 'Effra-Regular';
        }
      }
    }
    .slider-arrows {
      background-color: white;
      float: right;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 2px;
      opacity: 1;
      text-align: right;
      width: 145px;
      height: 55px;

      button {
      }
  
      span{
        color: #0F0923;
      }
    }
  }
`;



export const StyledTextContainer = styled.div`
display: flex;
width: 37%;

`;

export const StyledBlogContainer = styled.div`
display: flex;
flex-direction: row;
width: 100%;
`;

export const StyledBlogItems  = styled.div`
display: flex;
width: 63%;

.single-blog-item {
    width: 25%;
}
`;

export const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;

`;


export const StyledButton = styled.button`
height: 100%;
width: 50%;
border: unset;
background: transparent;
cursor: pointer;
`;