import React from 'react';
import {
  BgContainer,
  StyledWrap,
  LeftPart,
  RightPart,
  StyledHeading,
  LeftPartTitle,
  ListLefPart,
  ListItemLeftPart,
  ListRightPart,
  ListItemRightPart,
  StyledContainer,
  RightPartTitle

} from './styles';
import BlockContent from '@sanity/block-content-to-react';
import { Serializer } from 'src/utils/serializer';
import RedX from "src/images/red_x.svg";
import GreenTick from "src/images/green_tick.svg";
import WhiteArrow from "src/images/new_white_arrow.svg";

interface Props {
  data: {
    heading?: object;
    textSupportive?: string;
    textUnsupportive?: string;
    supportiveListItems?: Array;
    unsupportiveListItems?: Array;

  }
}

const ComparisonSection = ({ data }: Props) => {
  const {
    heading,
    textSupportive,
    textUnsupportive,
    supportiveListItems,
    unsupportiveListItems } = data;

  return (
    <BgContainer>
      <StyledWrap>
        {heading &&
          <StyledHeading>
            <BlockContent blocks={heading} serializers={Serializer} />
          </StyledHeading>}
        <StyledContainer>
          <img src={WhiteArrow} className="white-arrow" />
          <LeftPart>
            <LeftPartTitle>{textUnsupportive}</LeftPartTitle>
            <ListLefPart>
              {unsupportiveListItems?.map((item, i) => {

                return (
                  <ListItemLeftPart key={i}><img src={RedX} /><span>{item}</span></ListItemLeftPart>
                )
              })}
            </ListLefPart>

          </LeftPart>
          <RightPart>
            <RightPartTitle>{textSupportive}</RightPartTitle>
            <ListRightPart>
              {supportiveListItems?.map((item, i) => {
                return (
                  <ListItemRightPart key={i}><img src={GreenTick} /><span>{item}</span></ListItemRightPart>
                )
              })}
            </ListRightPart>

          </RightPart>

        </StyledContainer>

      </StyledWrap>
    </BgContainer>
  )
}


export default ComparisonSection;