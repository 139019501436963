import React, { useEffect, useState } from "react";
import { StyledHeading, StyledWrap } from "./styles";
import { SectionSettings } from "../../interfaces/sanity";
import QuestionList from "src/components/QuestionList";
import { useLocation } from "@reach/router";

interface Props {
  data: {
    sectionSettings: SectionSettings;
    title?: string;
    replaceIcon?:boolean;
    list: Array<{
      _key: string;
      id?: string;
      question: string;
      answer: string;
    }>;
    experiment: {
      variantA?: boolean;
      variantB?: boolean;
    };
  };
  context?: any;
  plusSign?: boolean;
  isSection?: boolean;
}

const QuestionAnswer = ({ data, context, plusSign, isSection = true }: Props) => {
  const { title, list, sectionSettings, replaceIcon, experiment } = data;
 
  const location = useLocation();
  const [activeIndex, setActiveIndex] = useState<number>(-1);

  useEffect(() => {
    if (!sectionSettings?.id) return;
    const hash = location.hash;

    const index = list.findIndex((item) => `#${item.id}` === hash);

    setActiveIndex(index);
  }, [location]);

  return (
    <StyledWrap isSection={isSection} className={experiment?.variantA ? 'variant_A' : experiment?.variantB ? 'variant_B': ''}>
      <StyledHeading>{title}</StyledHeading>
      <QuestionList plusSign={plusSign} list={list} activeIndex={activeIndex} context={context} replaceIcon={replaceIcon} />
    </StyledWrap>
  );
};

export default QuestionAnswer;
