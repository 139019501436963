
const getUrlParams = () => new URL(window.location.href).searchParams;
const getDiscountCode = () => {
  if (typeof window !== "undefined") {
    return getUrlParams().get("DISCOUNT");
  }
}

// add discount if url contains DISCOUNT query parameter ?DISCOUNT=discountCode & cart has any item
const applyDiscount = async (id: string, addDiscount: (discountCode: string) => Promise<void>, cartItemsLength: number) => {
  const discountCode = getDiscountCode();
  if (id && discountCode && cartItemsLength) {
    try {
      await addDiscount(discountCode);
      window.localStorage.removeItem("applyDiscount");
    } catch (err) {
      console.log({ err });
    }
  }
  else if (discountCode) {
    window.localStorage.setItem("applyDiscount", discountCode);
  }
}

export default applyDiscount;
