import React, { FC } from "react";
import ReactModal from "react-modal";
import { CloseIcon } from "./styles";
import { useTheme } from "styled-components";

interface Props {
  className?: string;
  modalClassName?: string;
  onRequestClose?: () => void;
  isOpen: boolean;
  closeIcon?: boolean;
}

ReactModal.setAppElement("#___gatsby");

const Modal: FC<Props> = ({
  children,
  className,
  modalClassName,
  onRequestClose,
  closeIcon = true,
  ...props
}) => {
  const theme = useTheme();

  return (
    <ReactModal
      className={modalClassName}
      portalClassName={className}
      shouldCloseOnOverlayClick={true}
      onRequestClose={onRequestClose}
      // preventScroll={true}
      overlayElement={(overlayProps, contentElement) => (
        <div {...overlayProps}>
          {closeIcon && (
            <CloseIcon
              name="close"
              onClick={onRequestClose}
              color={theme.colors.black}
            />
          )}

          {contentElement}
        </div>
      )}
      {...props}
    >
      {children}
    </ReactModal>
  );
};

export default Modal;
