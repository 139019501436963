import React, { useState, useEffect, useRef } from "react";
import TestimonialCardsSlider from "src/components/TestimonialCardsSlider";

import {
    StyledWrap,
    StyledHeading,
    TextContainer,
    SliderContainer,
    TopIconList,
} from "./styles";

interface Props {
    data: {
        title: string,
        iconListTop: any,
        list: any,
        experiment: {
            variantA?: boolean;
            variantB?: boolean;
        };
    };
}

const TestimonialCards = ({ data }: Props) => {
    const sliderRef = useRef(null);
    const [currentSlide, setCurrentSlide] = useState<number>(0);
    const {
        title,
        iconListTop,
        list
      } = data;

    return (
        <StyledWrap className={data?.experiment?.variantB ? 'variant_B' : data?.experiment?.variantA ? 'variant_A': ''}>
            <TextContainer>
                <StyledHeading>{title}</StyledHeading>
            </TextContainer>
            <SliderContainer>
                <TestimonialCardsSlider
                    list={list}
                    setCurrent={setCurrentSlide}
                    ref={sliderRef}
                />
            </SliderContainer>
            {data.iconListTop && (
                <TopIconList
                    direction={iconListTop.direction}
                    list={iconListTop.list}
                    bigIcons={iconListTop.bigIcons}
                />
            )}
        </StyledWrap>
    );
};

export default TestimonialCards;
