import React from "react";
import { StyledWrap, StyledHeading, Container, StyledImage } from "./styles";
import Image from "src/components/Common/ImageFluid";
import { SanityImage } from "src/interfaces/sanity";
// import { SmallDesktop } from "src/components/Responsive";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

interface Props {
  data: {
    title: string;
    image: any;
    list: [];
    mobileImage?: SanityImage;
    experiment: {
      variantA?: boolean;
      variantB?: boolean;
    };
  };
}

const WhatYouGet = ({ data }: Props) => {
  const breakpoints = useBreakpoint();

  return (
    <Container className={data?.experiment?.variantA ? 'variant_A' : data?.experiment?.variantB ? 'variant_B': ''}>
      {!breakpoints.tablet && <Image imageRef={data.mobileImage} />}

      <StyledWrap>
        <StyledHeading>{data.title}</StyledHeading>
        <StyledImage imageRef={data.bottomIcon} />
      </StyledWrap>
    </Container>
  );
};

export default WhatYouGet;
