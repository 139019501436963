import styled from "styled-components";
import Wrap from "../../components/Wrap";
import Button from "src/components/Common/Button";
import { SectionHeading } from "../../components/Text";

export const StyledHeading = styled(SectionHeading)`
  text-align: center;
  margin-bottom: 4rem;

  @media ${({ theme }) => theme.device.tablet} {
    margin-bottom: 5.9rem;
  }
`;

export const StyledButton = styled(Button)`
  display: block;
  margin: 2.4rem auto 0 auto;

  @media ${({ theme }) => theme.device.tablet} {
    margin: 4.3rem auto 0 auto;
  }
`;

export const StyledWrap = styled(Wrap)`
  padding: 5.3rem 2rem;
  width: 100%;

  @media ${({ theme }) => theme.device.tablet} {
    padding: 7.5rem 8.1rem 6.9rem;
    width: 80%;
  }
`;
