import React, { useState, useEffect } from "react";
import Image from "../Common/ImageFluid";
import { SanityImage } from "../../interfaces/sanity";
import { encode, decode } from "shopify-gid";
import { client, useAddItemToCart, useCart } from "src/context/siteContext";
import { useMediaQuery } from "react-responsive";
import Ribbon from "src/components/Ribbon";
import { size } from "../Carousel";
import {
  ContainerLink,
  TextContainer,
  Heading,
  InfoContainer,
  DescriptionHeading,
  Description,
  PriceHeading,
  StyledRating,
  CartLink,
  StyledImage,
  ImageContainer,
  DescriptionParagraph,
  PriceContainer,
  ComparePrice,
  Footer,
  MobileTextContainer
} from "./styles";
import { useTheme } from "styled-components";
import trackKlavyioAddedToCart from "src/utils/mapKlaviyoPayload";

interface Props {
  id: number;
  slug: string;
  title: string;
  image: SanityImage;
  price: string;
  comparePrice: string;
  className?: string;
  description?: string;
  ribbon?: string;
  mobileSmall?: boolean;
  addToCart?: boolean;
  mobilePhoto?: SanityImage;
  ribbonSmall?: boolean;
  onClick?: () => void;
  large?: boolean;
}

const ProductStyled = ({
  id,
  slug,
  title,
  image,
  price,
  className,
  description,
  ribbon,
  mobileSmall,
  addToCart = false,
  mobilePhoto,
  ribbonSmall = false,
  large,
  onClick,
}: Props) => {
  const theme = useTheme();
  const { checkout } = useCart();
  const { addItemToCart } = useAddItemToCart();
  const [available, setAvailable] = useState(false);
  const [variants, setVariants] = useState([]);
  const [check, setCheck] = useState(true);
  const [activeVariantId, setActiveVariantId] = useState("" as string);
  const [compareAtPrice, setCompareAtPrice] = useState(
    undefined as string | undefined
  );
  const [adding, setAdding] = useState<boolean>(false);
  const isMobile = useMediaQuery({ maxWidth: size.phone });

  useEffect(() => {
    if (check) {
      const shopifyId = encode("Product", id, {
        accessToken: process.env.GATSBY_SHOPIFY_STOREFRONT_TOKEN,
      });

      client.product.fetch(shopifyId).then((product: any) => {
        const decodedVariants = [] as any;

        if (!product) return;

        product.variants.forEach((variant: any) => {
          decodedVariants.push({
            ...variant,
            cleanId: parseInt((variant.id).id, 0),
          });
        });

        setVariants(decodedVariants);
        setActiveVariantId(decodedVariants[0].id as string);
        setAvailable(decodedVariants[0].available);

        if (decodedVariants[0].compareAtPrice)
          setCompareAtPrice(decodedVariants[0].compareAtPrice);

        setCheck(false);
      });
    }
  }, [check]);

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (available) {
      setAdding(true);

      const attributes = [];

      const item = {
        name: title,
        id: variants[0].cleanId,
        sku: variants[0]?.sku,
        image: variants[0]?.image?.src,
        product_id: id,
        price: parseFloat(price),
        brand: 'GroovePillows',
        variant: variants[0]?.title,
        quantity: 1,
      };

      addItemToCart(activeVariantId, 1, attributes).then(() => {
        setAdding(false);
      }).then(() => {
        trackKlavyioAddedToCart(checkout, item);
        // TriplePixel('AddToCart', { item: parseFloat(variants[0]?.id?.replace("gid://shopify/ProductVariant/", "")), q: 1 });
      });
    }
  };

  return (
    <ContainerLink
      className={className}
      to={`/products/${slug}`}
      mobileSmall={mobileSmall}
      onClick={onClick}
    >
      <ImageContainer>
        {image && <StyledImage imageRef={mobilePhoto ? mobilePhoto : image} />}
      </ImageContainer>
      {ribbon && <Ribbon small={ribbonSmall}>{ribbon}</Ribbon>}
      <InfoContainer bigPadding={description ? true : false}>
        {
          isMobile ? (
            <TextContainer>
              <MobileTextContainer>
                <DescriptionHeading>{title}</DescriptionHeading>
                <PriceContainer>
                  <PriceHeading>
                    {process.env.GATSBY_CURRENCY_SYMBOL + price}
                  </PriceHeading>
                  {compareAtPrice && 
                  <ComparePrice>
                    {process.env.GATSBY_CURRENCY_SYMBOL + comparePrice}
                  </ComparePrice>}
                </PriceContainer>
              </MobileTextContainer>
              {description && <Description blocks={description} />}
            </TextContainer>
          ) : (
            <TextContainer addToCart={addToCart}>
              <DescriptionHeading>{title}</DescriptionHeading>
              {description && <Description blocks={description} />}
            </TextContainer>
          )
        }
        <Footer>
          {
            !isMobile && (
              <PriceContainer> 
                <PriceHeading> 
                  {process.env.GATSBY_CURRENCY_SYMBOL + price}
                </PriceHeading>
                {compareAtPrice && 
                <ComparePrice>
                  {process.env.GATSBY_CURRENCY_SYMBOL + comparePrice}
                </ComparePrice>}
              </PriceContainer>
            )
          }
          <CartLink onClick={handleClick} disabled={!available}>
            {adding ? "Adding..." : "Add to cart"}
          </CartLink>
        </Footer>
      </InfoContainer>
    </ContainerLink>
  );
};

export default ProductStyled;
