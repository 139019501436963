import React from "react";
import { SanityImage } from "src/interfaces/sanity";
import {
  Title,
  TextBlock,
  Description,
  StyledWrap,
  StyledImage,
} from "./styles";

interface Props {
  data: {
    heading: string;
    text: string;
    imagePosition: {
      image: SanityImage;
      position: "left" | "right";
    };
  };
}

const ImageText = ({ data }: Props) => {
  const { imagePosition, heading, text } = data;

  return (
    <StyledWrap noPadding={true} position={imagePosition?.position}>
      <StyledImage imageRef={imagePosition?.image} />
      <TextBlock position={imagePosition?.position}>
        {heading && <Title>{heading}</Title>}
        {text && <Description>{text}</Description>}
      </TextBlock>
    </StyledWrap>
  );
};

export default ImageText;
