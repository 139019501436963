import React from "react";
import { SanityImage } from "src/interfaces/sanity";
import { size } from "../../components/Responsive";
import { useMediaQuery } from "react-responsive";
import {
  Title,
  TextBlock,
  Description,
  StyledWrap,
  StyledImage,
} from "./styles";

interface Props {
  data: {
    heading: string;
    text: string;
    image: SanityImage;
    imageMobile: SanityImage;
    experiment: {
      variantA?: boolean;
      variantB?: boolean;
    };
  };
}

const NewImageText = ({ data }: Props) => {
  const { image, imageMobile, heading, text } = data;
  const isMobile = useMediaQuery({ maxWidth: size.phone });

  return (
    <StyledWrap noPadding={true} className={data?.experiment?.variantB ? 'variant_B' : data?.experiment?.variantA ? 'variant_A': ''}>
      <TextBlock>
        {heading && <Title>{heading}</Title>}
        {text && <Description>{text}</Description>}
      </TextBlock>
      <StyledImage imageRef={isMobile ? imageMobile : image} />
    </StyledWrap>
  );
};

export default NewImageText;
