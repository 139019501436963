import React, { useState, useEffect } from "react";
import { duration } from "src/utils/helpers";
import {
  CountdownTimerBox,
  TimerContainer,
  TimerTextBox,
  DicountStyle,
  TimerStyle
} from "./styles";
import Clock from "src/images/clock.svg";
import Discount from "src/images/discount.svg";


interface Props {
  date: any;
  showCountdown: boolean;
}

const CountdownTimer = ({ date, showCountdown }: Props) => {
  let interval = null;

  const [timeLeft, setTimeLeft] = useState(duration(date));

  useEffect(() => {
    interval = setInterval(() => {
      setTimeLeft(duration(date));

    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const { days, hours, minutes, seconds } = timeLeft;

  if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
    clearInterval(interval);
  }


  return (
    <div id="countdown-timer">
      {showCountdown &&
        <>
          {(days !== 0 && hours !== 0 && minutes !== 0 && seconds !== 0) &&
            <TimerContainer>
              <img src={Discount} alt="Discount icon" width="48px" height="48px" />
              <TimerTextBox>

                Save 10%! Use the code <DicountStyle>COSTOFLIVING</DicountStyle> to get 10% off your order above {process.env.GATSBY_CURRENCY !== "$" ? "£" : "$"}40.
                <p><TimerStyle>
                  Ending in:  {days > 0 && (` ${days.toFixed(0).padStart(2, "0")} D :`)} {hours.toFixed(0).padStart(2, "0")} Hr : {minutes.toFixed(0).padStart(2, "0")} Min : {seconds.toFixed(0).padStart(2, "0")} Sec
                </TimerStyle></p>
              </TimerTextBox>
            </TimerContainer>
          }</>
      }

    </div>
  )
}

export default CountdownTimer;