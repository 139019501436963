import React, { useEffect, useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { ProductUpsells } from "src/interfaces/sanity";
import { encode } from "shopify-gid";
import { client, useAddItemToCart, useUpsells } from "src/context/siteContext";

import {
  StyledBundleContainer,
  StyledHeader,
  StyledDescription,
  StyledBundleWrap,
  StyledBundleItem,
  StyledButton,
  BundleHeader,
  Badge,
} from "./styles";

import PillowImg from "src/images/pillow-icon.png";

interface Props {
  data: {
    bundles: any;
    description: string;
    title: string;
    backgroundColor: {
      title: string;
      value: string;
    };
  };
  upsells: Array<ProductUpsells>;
}

const BundleSection = ({ data, upsells }: Props) => {
  const { description, title, backgroundColor } = data;

  const { addItemToCart } = useAddItemToCart();
  const { openUpsells } = useUpsells();

  const [bundles, setBundles] = useState(data.bundles);
  const [adding, setAdding] = useState(0);

  useEffect(() => {
    const promises = data.bundles.map(
      (bundle: any) =>
        new Promise(async (resolve, reject) => {
          const isBundleAvailable = await checkBundle(bundle.shopifyId);
          bundle.isActive = isBundleAvailable;
          resolve(bundle);
        })
    );
    Promise.all(promises).then((result) => {
      setBundles(result);
    });
  }, [data.bundles]);

  const checkBundle = async (productId: any) => {
    const shopifyId = encode("Product", productId, {
      accessToken: process.env.GATSBY_SHOPIFY_STOREFRONT_TOKEN,
    });

    return await client.product.fetch(shopifyId).then((product: any) => {
      const availableVariant = product?.variants?.find(
        (variant: any) => variant
      );
      return availableVariant?.available;
    });
  };

  const handleSubmit = (shopifyId: number) => {
    setAdding(shopifyId);

    const shopifyPordId = encode("Product", shopifyId, {
      accessToken: process.env.GATSBY_SHOPIFY_STOREFRONT_TOKEN,
    });

    const upsellEnabled = upsells?.enabled && upsells?.productList.length > 0;

    client.product.fetch(shopifyPordId).then(async (product: any) => {
      const availableVariant = product.variants.find((variant: any) => variant);
      await addItemToCart(availableVariant?.id, 1, [], upsellEnabled);
      setAdding(0);

      if (!upsellEnabled) return;

      openUpsells({
        boughtItem: { id, title, quantity, price, imageRef: mainImage },
        productList: upsells?.productList,
      });
    });
  };


  return (
    <StyledBundleContainer id="Bundle">
      <StyledHeader>{title}</StyledHeader>
      <StyledDescription>{description}</StyledDescription>
      <BundleHeader>
        <StaticImage src="../../images/gift.png" alt="gift img" />
        <div className="mobileHeader">
          <span>{title}</span>
          {/* available now */}
        </div>
      </BundleHeader>
      <StyledBundleWrap>
        {bundles?.length &&
          bundles.map((bundle: any) => {
            const btnText =
              adding === bundle.shopifyId
                ? "You got it!"
                : bundle?.isActive
                ? "Get Offer"
                : "Out of Stock";
            return (
              <StyledBundleItem isPopular={bundle.isPopular} key={bundle._key}>
                <div className="itemWrap">
                  <div className="discount">{bundle.discount}% OFF</div>
                  {bundle.isPopular && (
                    <Badge backgroundColor={bundle?.backgroundColor?.value}>
                      Popular
                    </Badge>
                  )}
                  <p className="title">{bundle.title}</p>
                  <div className="items">
                    {bundle?.bundles?.map((item: string, idx: number) => {
                      return (
                        <div key={idx}>
                          {item}
                          {idx !== bundle.bundles.length - 1 ? <p>+</p> : ""}
                        </div>
                      );
                    })}
                  </div>
                  <div className="seprateLine" />
                  <div className="originPrice">
                    {process.env.GATSBY_CURRENCY_SYMBOL}
                    {parseFloat(bundle.price).toFixed(2)}
                  </div>
                  <div className="discountedPrice">
                    {process.env.GATSBY_CURRENCY_SYMBOL}
                    {parseFloat(bundle.discountedPrice).toFixed(2)}
                  </div>
                  {bundle.saveMoney && (
                    <>
                      <div className="seprateLine" />
                      <div className="saveMoney">
                        You save {process.env.GATSBY_CURRENCY_SYMBOL}
                        {parseFloat(bundle.saveMoneyPrice).toFixed(2)}
                      </div>
                    </>
                  )}
                  <StyledButton
                    onClick={() => handleSubmit(bundle.shopifyId)}
                    disabled={!bundle?.isActive}
                  >
                    {btnText}
                  </StyledButton>
                  {bundle.moneyBack && (
                    <div className="saveMoney">{bundle.moneyBackText}</div>
                  )}
                </div>
                {bundle.isPopular && (
                  <img
                    src={PillowImg}
                    alt="pillow icon"
                    className="pillowImage"
                  />
                )}
              </StyledBundleItem>
            );
          })}
      </StyledBundleWrap>
    </StyledBundleContainer>
  );
};

export default BundleSection;
