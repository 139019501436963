import styled from "styled-components";
import Icon from "../Common/Icon";
import { Paragraph } from "../../components/Text";

interface ListProps {
  options: "column" | "columnTwo";
}

const handleOptions = (option: "column" | "columnTwo") => {
  switch (option) {
    case "column":
      return "1fr";
    case "columnTwo":
      return "1fr 1fr";
    default:
      return "1fr";
  }
};

export const StyledParagraph = styled(Paragraph)`
  /* word-break: break-all; */
  font-size: 16px;
  line-height: 19px;
  font-family: 'Effra-Regular';

  /* @media ${({ theme }) => theme.device.largeDesktop} {
    font-size: 18px;
  } */

  @media ${({ theme }) => theme.device.tablet} {
    font-size: 18px;
    line-height: 22px;
  }
  
`;

export const StyledList = styled.ul<ListProps>`
  display: grid;
  grid-template-columns: ${({ options }) => handleOptions(options)};
`;

export const StyledListItem = styled.li`
  list-style-type: none;
  margin: 0 0 0.8rem;
  padding: 0;
  display: flex;
  align-items: flex-start;

  &:last-child {
    margin: 0;
  }
`;

export const StyledIcon = styled(Icon)`
  /* margin-right: 1.2rem; */
  /* margin: 0.5rem 1.2rem 0 0;
  min-width: 2rem; */
  width: 2rem;
  height: 2rem;
  flex-shrink: 0;
  margin: 0.1rem 1.2rem 0 0;

  @media ${({ theme }) => theme.device.tablet} {
    margin: 0.5rem 1.2rem 0 0;
  }
`;
