import styled from "styled-components";
import Wrap from "../../components/Wrap";
import { SectionHeading } from "../../components/Text";
import Image from "../../components/Common/ImageFluid";
import BlockContent from "../../components/BlockContent";
import Icon from "src/components/Common/Icon";

export const StyledWrap = styled(Wrap)`
  padding: 4rem 0 4rem 2rem;

  @media ${({ theme }) => theme.device.tablet} {
    padding: 6.8rem 8.1rem 8.3rem;
  }
`;

export const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.9rem;

  @media ${({ theme }) => theme.device.tablet} {
    margin-bottom: 6.2rem;
  }
`;

export const Heading = styled(SectionHeading)``;

export const ImageGallery = styled.div`
  display: flex;
  overflow-y: hidden;
  margin-bottom: 3.7rem;

  img {
    width: 15vw;
    height: 15vw;
  }
`;

export const StyledImage = styled(Image)`
  max-width: 19.7rem;

  @media ${({ theme }) => theme.device.tablet} {
    width: 100%;
  }
`;

export const StyledAnchor = styled.a`
  margin-right: 2rem;
  flex-shrink: 0;

  &:last-child {
    margin-right: 0;
    /* padding-right: 2rem; */
    box-sizing: content-box;
  }

  @media ${({ theme }) => theme.device.tablet} {
    flex-shrink: 1;
  }
`;

export const StyledBlocks = styled(BlockContent)`
  color: ${({ theme }) => theme.colors.blue};
  font-size: ${({ theme }) => theme.fontSize.xs.mobile};
  line-height: ${({ theme }) => theme.lineHeight.xs.mobile};
  font-family: 'Effra-Regular';
  font-weight: 400;

  @media ${({ theme }) => theme.device.tablet} {
    font-size: ${({ theme }) => theme.fontSize.xs.desktop};
    line-height: ${({ theme }) => theme.lineHeight.xs.desktop};
  }
`;

export const StyledIcon = styled(Icon)`
  max-width: 2.4rem;
  max-height: 2.4rem;
  margin-right: 0.7rem;

  @media ${({ theme }) => theme.device.tablet} {
    max-width: 3.2rem;
    max-height: 3.2rem;
    margin-right: 0.8rem;
  }
`;

export const CaptionContainer = styled.div`
  display: flex;
  align-items: center;
`;
