import styled, { css } from "styled-components";

export const StyledRow = styled.div`
  padding: 0 0 2rem;
  display: flex;
  flex-direction: row;

  & > div {
    width: 50%;
  }

  &:first-child {
    width: 100%;
    overflow: hidden;
  }

  @media ${({ theme }) => theme.device.phone} {
    flex-direction: column;
    & > div {
      width: 100%;
    }
  }

  @media ${({ theme }) => theme.device.tablet} {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 8.3rem;

    &:last-child {
      margin-bottom: 0;
    }

    &:first-child {
      padding-top: 1.8rem;
    }
  }

  @media ${({ theme }) => theme.device.laptop} {
    margin-bottom: 4rem;
  }

  @media ${({ theme }) => theme.device.largeDesktop} {
    margin-bottom: 8.3rem;
  }
`;
