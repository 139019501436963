
import Wrap from "src/components/Wrap";
import styled, { css } from "styled-components";
import { Heading, Paragraph, SectionHeading } from "src/components/Text";
import Icon from "src/components/Common/Icon";
import BlockContent from "src/components/BlockContent";

interface StyledWrapProps {
	isSection?: boolean;
}

export const StyledWrap = styled(Wrap) <StyledWrapProps>`
  padding: ${({ isSection }) =>
		isSection ? "4.1rem 0 3.3rem" : "4.1rem 0 0 0"};
		display: flex;
		flex-direction: column-reverse;
		justify-content: space-between;


  @media ${({ theme }) => theme.device.tablet} {
    padding: ${({ isSection }) =>
		isSection ? "4.5rem 0" : "2.4rem 0rem 0rem 4.5rem"};
    max-width: 144rem;
    box-sizing: content-box;
		flex-direction: row;
  }

  @media ${({ theme }) => theme.device.phone} {
    padding: ${({ isSection }) => (isSection ? "4rem 0" : "0")};
    margin: ${({ isSection }) => (isSection ? "0" : "0")};

  }
`;

export const StyledHeading = styled(SectionHeading)`
  padding: 0 2rem;
	text-align: center;
	display: block;
	margin: 0 auto 2.8rem auto;
  font-weight: 700;
  font-size: 30px;
	margin: 0 0 0 10px;
	line-height: 40px;
	text-align: center;
  font-family: 'Effra-Bold';
  color: ${({ theme }) => theme.colors.dark_blue};

  @media ${({ theme }) => theme.device.tablet} {
    margin-bottom: 2rem;
    padding: 0;
		width: 70%;
    line-height: 68px;
		margin: 0 auto 1rem auto;
    font-style: normal;
    font-weight: 700;
    font-size: 58px;
  }
`;


interface ListItemProps {
	plusSign?: boolean;
	notificationBar: boolean;
}


export const ListItem = styled.li<ListItemProps>`
  position: relative;
  cursor: pointer;
  list-style-type: none;
  padding: 1.5rem 2.2rem 1.9rem;
  margin: 0;
  scroll-margin-top: ${({ notificationBar }) =>
		notificationBar ? "9rem" : "6.3rem"};

  @media ${({ theme }) => theme.device.tablet} {
    padding: 1.6rem 0;
    scroll-margin-top: ${({ notificationBar }) =>
		notificationBar ? "17.5rem" : "12.7rem"};
  }
`;

export const StyledHeadingg = styled(Heading)`
  font-size: 1.3rem;
  line-height: 2.4rem;
	margin: 0 0 0 10px;
  font-family: 'Effra-Bold';
  color: ${({ theme }) => theme.colors.font_black};

  @media ${({ theme }) => theme.device.tablet} { 
    font-size: 1.8rem;
  }
`;

export const StyledIcon = styled(Icon)`
  margin-right: 1.5rem;
  max-width: 4.6rem;
  width: 100%;
  height: 4.6rem;
  align-self: flex-start;
`;

export const ItemHeader = styled.div<ListItemProps>`
  display: flex;
  flex-direction: ${({ plusSign }) => plusSign ? 'row-reverse' : 'row'};
  justify-content: ${({ plusSign }) => plusSign ? 'space-between' : 'left'};
  align-items: center;
`;

export const ItemContent = styled.div`
  padding: ${({ open }) => (open ? "3.1rem 0 0.5rem 3rem" : "0")};
  overflow: hidden;
	width: 100%;
  max-height: ${({ open }) => (open ? "initial" : "0")};

  @media ${({ theme }) => theme.device.tablet} {
    padding: ${({ open }) => (open ? "2.2rem 0 1.9rem 3.2rem" : "0")};
    width: 80%;
  }
`;

export const Content = styled(BlockContent)`
  line-height: 2.2rem;
  font-family: 'Effra-Regular';
  color: ${({ theme }) => theme.colors.font_dark_gray};
  font-weight: 400;
  max-width: 100%;
  font-family: 'Effra-Regular';

  p {
    font-size: 1rem;
  }

  @media ${({ theme }) => theme.device.tablet} {

    p {
      font-size: 1.4rem;
    }
  }
`;

export const ContentImage = styled.img`
  max-width: 5rem;
  margin-bottom: 1rem;
`;

export const QuestionList = styled.ul``;

export const LeftPart = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;

	@media ${({ theme }) => theme.device.tablet} { 
		width: 48%;
	}
`;

export const RightPart = styled.div`
	width: 100%;

	@media ${({ theme }) => theme.device.tablet} { 
		width: 48%;
	}
`;