import React from "react";
import { MoneyBackBanner } from "./styles";
import { useReturnMoneyDrawer } from "src/context/siteContext";
import { SanityColor } from "src/interfaces/sanity";
import { StyledImage } from "./styles";

interface Props {
  data: {
    firstColumnImage: any;
    firstColumnText: string;
    firstColumnLink: string;
    returnMoneyBack: Object[],
    secondColumnImage: any;
    secondColumnText: string;
    secondColumnLink: string;
    sectionSettings: {
      backgroundColor: SanityColor;
    };
    experiment: {
      variantA?: boolean;
      variantB?: boolean;
    };
  };
  context: {};
  className: string;
  bgColor: string;
  firstSVG: any;
  firstText: string;
  firstLink: string;
  secondSVG: any;
  secondText: string;
  secondLink?: string;
  props?: any;
}

const AnnouncementBar = ({
  data,
  context,
  bgColor,
  firstSVG,
  firstText,
  firstLink,
  secondSVG,
  secondText,
  secondLink,
  ...props
}: Props) => {
  const { openReturnMoney } = useReturnMoneyDrawer();


  return (
    <>
      <MoneyBackBanner {...props} color="white" className="showInDesktop" id={data?.experiment?.variantB ? 'variant_B' : data?.experiment?.variantA ? 'variant_A': ''}>
        <div>
          {data.firstColumnImage && (
            <StyledImage imageRef={data.firstColumnImage} />
          )}
          <span>
            &nbsp;{data?.firstColumnText}&nbsp;
            <span onClick={openReturnMoney}>{data?.firstColumnLink}</span>
          </span>
        </div>
        <div>
          {data.secondColumnImage && (
            <StyledImage className="phone" imageRef={data.secondColumnImage} />
          )}
          <span>
            {data?.secondColumnText}&nbsp;
            <span>
              {data?.secondColumnLink && (
                <a href={`tel:${data?.secondColumnLink}`}>
                  {data?.secondColumnLink}
                </a>
              )}
            </span>
          </span>
        </div>
      </MoneyBackBanner>
      <MoneyBackBanner {...props} color="white" className="showInMobile" id={data?.experiment?.variantB ? 'variant_B_Mobile' : data?.experiment?.variantA ? 'variant_A_Mobile': ''}>
        <div>
          {data.firstColumnImage && (
            <StyledImage imageRef={data.firstColumnImage} />
          )}
          <span>
            {data?.firstColumnText}
            <span onClick={openReturnMoney}>{data?.firstColumnLink}</span>
          </span>
        </div>
        <div>
          {data.secondColumnImage && (
            <StyledImage className="phone" imageRef={data.secondColumnImage} />
          )}
          <span>
            {data?.secondColumnText}&nbsp;
            <span>
              {data?.secondColumnLink && (
                <a href={`tel:${data?.secondColumnLink}`}>
                  {data?.secondColumnLink}
                </a>
              )}
            </span>
          </span>
        </div>
      </MoneyBackBanner>
    </>
  );
};

export default AnnouncementBar;
