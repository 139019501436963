import styled from "styled-components";

export const CallUs = styled.div`
  display: flex;
  font-size: 21px;
  padding: 2rem 10rem 0rem 5rem;

  justify-content: space-around;
  a {
    text-decoration: underline;
  }
  img {
    margin-right: 1rem;
  }

  @media ${({ theme }) => theme.device.phone} {
    font-size: 15px;
    padding: 2rem;
    display: none;
  }

  @media ${({ theme }) => theme.device.tablet} {
    font-size: 18px;
  }
`;

export const CallUsMobile = styled.div`
  display: none;

  @media ${({ theme }) => theme.device.phone} {
    display: flex;
    font-size: 21px;
    padding: 1rem;
    margin: 0;
    justify-content: center;
    background-color: #0F0923;
    color: white;
    font-size: 13.6px;
    
    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    a {
      text-decoration: underline;
    }
    img {
      margin-right: 1rem;
    }
  }

`;

export const RowStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media ${({ theme }) => theme.device.phone} {
    width: 100%;
    flex-direction: column;
    padding: 10px;
    margin: 0;
 
    ${CallUsMobile} {
      padding: 1rem 0;
      margin: 0;
    }
  }
`;