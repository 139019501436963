import React from "react";
import { List, ListItem, Text, Icon } from "./styles";

interface Props {
  direction: any;
  list: any;
  className?: string;
  bigIcons: boolean;
}

const IconList = ({ direction, list, className, bigIcons }: Props) => {
  const returnDate = () => {
    const date = new Date();

    const todayPlusTwo = date.setDate(date.getDate() + 2);
    const todayPlusFour = date.setDate(date.getDate() + 4);

    const firstDate = new Date(todayPlusTwo);
    const secondDate = new Date(todayPlusFour);

    const firstMonthShort = new Intl.DateTimeFormat("en-US", {
      month: "short",
    }).format(todayPlusTwo);
    const secondMonthShort = new Intl.DateTimeFormat("en-US", {
      month: "short",
    }).format(todayPlusTwo);

    if (firstDate.getMonth() !== secondDate.getMonth()) {
      return `${firstMonthShort} ${firstDate.getDate()} - ${secondMonthShort} ${secondDate.getDate()}`;
    }

    return `${firstMonthShort} ${firstDate.getDate()} - ${secondDate.getDate()}`;
  };

  return (
    <List className={className}>
      {list?.map((item, index) => {
        return (
          <ListItem key={index} direction={direction}>
            <Icon imageRef={item.icon} direction={direction} big={bigIcons} alt={item?.icon?.alt}/>
            <Text direction={direction}>{`${item.text} ${
              item.dateInterval ? returnDate() : ""
            }`}</Text>
          </ListItem>
        );
      })}
    </List>
  );
};

export default IconList;
