import styled from "styled-components";
import BackgroundImage from "../Common/BackgroundImage";

interface BgProps {
  backgroundColor: string | undefined;
  textColor: string | undefined;
  position?: "top" | "center" | "bottom";
  productPage?: boolean;
  notificationBar: boolean;
}

export const StyledBg = styled(BackgroundImage)<BgProps>`
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "initial"};
  color: ${(props) => props.textColor ? props.textColor: "#000000"};
  background-position-y: ${({ position }) => (position ? position : "initial")};
  position: relative;
  scroll-margin-top: ${({ notificationBar }) =>
    notificationBar ? "9rem" : "6.3rem"};

  @media ${({ theme }) => theme.device.tablet} {
    scroll-margin-top: ${({ notificationBar, productPage }) =>
      productPage
        ? notificationBar
          ? "17.5rem"
          : "12.7rem"
        : notificationBar
        ? "11.7rem"
        : "6.9rem"};
  }
`;

export const StyledSection = styled.section<BgProps>`
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "initial"};
  color: ${(props) => props.textColor ? props.textColor: "#000000"};
  position: relative;
  scroll-margin-top: ${({ notificationBar }) =>
    notificationBar ? "9rem" : "6.3rem"};

  @media ${({ theme }) => theme.device.tablet} {
    scroll-margin-top: ${({ notificationBar, productPage }) =>
      productPage
        ? notificationBar
          ? "17.5rem"
          : "12.7rem"
        : notificationBar
        ? "11.7rem"
        : "6.9rem"};
  }
  ${({ context, theme }) => 
    context?.modules[0]?._type === "imageRowSection" && context.slug === "home" && `
      &:first-of-type {
        padding-top: 11rem;
        @media ${theme.device.laptop} {
          padding-top: 8rem;
        }
      }
    `
  };
`;
