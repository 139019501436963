import React from "react";
import {
  StyledWrap,
  Heading,
  CarouselContainer,
  LeadText,
  InnerText,
  TextContainer,
  StyledCarousel,
} from "./styles";
import {
  SectionSettings,
  SanityImage,
  SanityColor,
} from "../../interfaces/sanity";
import Icon from "src/components/Common/Icon";
import { SanityButton } from "src/interfaces/common";

interface Props {
  data: {
    _type: "carouselList";
    title?: string;
    carousel: [
      {
        stars?: SanityImage;
        reviewTitle?: string;
        reviewQuote?: string;
        reviewerName?: string;
      }
    ];

    innerText?: any;
    leadText?: string;
    button: SanityButton;

    sectionSettings: {
      backgroundColor: SanityColor;
      backgroundImage: SanityImage;
    };
  };
}

const CarouselList = ({ data }: Props) => {
  const { title, carousel, leadText, innerText } = data;

  return (
    <StyledWrap>
      {title && <Heading>{title}</Heading>}

      <CarouselContainer>
        {carousel && <StyledCarousel carousel={carousel} />}

        {(leadText || innerText) && (
          <TextContainer>
            {leadText && <LeadText>{leadText}</LeadText>}
            {innerText && <InnerText blocks={innerText} />}
          </TextContainer>
        )}
      </CarouselContainer>
      <div className="trustpilot-se">
        <a href="https://uk.trustpilot.com/review/www.sleepeasycommunity.co.uk">
          Click here to view all reviews on Trustpilot.
        </a>
      </div>
    </StyledWrap>
  );
};

export default CarouselList;
