import React from "react";
import {
  StyledWrap,
  Heading,
  IconList,
  IconTitle,
  IconImage,
  Icon,
  StyledButton,
  StyledImage,
  IconText,
  AdditionalTextStyle, 
  StyledSubheading
} from "./styles";
import {
  SectionSettings,
  SanityImage,
  SanityColor,
} from "../../interfaces/sanity";
import GeneralSection from "src/components/GeneralSection";
import BlockContent from '@sanity/block-content-to-react';
import { Serializer } from 'src/utils/serializer';
import { useMediaQuery } from "react-responsive";

export const size = {
  phone: 480,
};
interface Props {
  data: {
    heading?: object;
    subheading?: string;
    button?: {
      enabled: boolean;
      link: string;
      text: string;
    };
    image?: {
      _type: "textIconImage";
      enabled: boolean;
      image: SanityImage;
    };
    icons?: {
      enabled: boolean;
      bigIcons: boolean;
      mobileDir?: "row" | "column";
      mobileDirection?: "row" | "column";
      containerWidth?: "80%" | "100%";
      textMobile?: "Top" | "Center";
      list: [
        {
          _key: string;
          _type: "icon";
          image: SanityImage;
          text: string;
        }
      ];
      text: string;
      minHeight?: number;
      textTop?: boolean;
    };
    sectionSettings: SectionSettings;
    generalFooter: object;
  };
  context: {
    reviews: any;
  };
}

const NewTextIconSection = ({ data, context }: Props) => {
  const { icons, button, heading, image, minHeight, textTop, additionalText, subheading, sectionSettings } = data;

  const isMobile = useMediaQuery({ maxWidth: size.phone });

  const renderIcons = (
    <IconList mobileDir={icons?.mobileDir} containerWidth={icons?.containerWidth}>
      {icons?.list.map((icon) => (
        <Icon key={icon?._key} mobileDirection={icons?.mobileDirection} textMobile={icons?.textMobile}>
          <IconImage imageRef={icon?.image} big={icons?.bigIcons} />
          {icon?.title &&
            <IconTitle style={{color: sectionSettings?.textColor?.value}}>{icon?.title}</IconTitle>}
          {icon?.text &&
            <IconText  style={{color: sectionSettings?.textColor?.value}} mobileDirection={icons?.mobileDirection}>
              <BlockContent blocks={icon?.text} serializers={Serializer} />
            </IconText>
          }
        </Icon>
      ))}
    </IconList>
  );

  const renderButton = (
    <StyledButton to={button?.link}>{button?.text}</StyledButton>
  );

  return (
    <StyledWrap
      headingOnly={!button?.enabled && !icons?.enabled}
      minHeight={minHeight}
      textTop={textTop}
    >
      {heading &&
        <Heading>
          <BlockContent blocks={heading} serializers={Serializer} />
        </Heading>}

        {(!isMobile && subheading) &&
        <StyledSubheading> {subheading}
        </StyledSubheading>}

      {image?.enabled && <StyledImage imageRef={image.image} />}
      {icons?.enabled && renderIcons}
      {button?.enabled && renderButton}
      {additionalText &&
        <AdditionalTextStyle>
          {additionalText}
        </AdditionalTextStyle>

      }
              {(isMobile && subheading) &&
        <StyledSubheading> {subheading}
        </StyledSubheading>}
      <GeneralSection reviews={context?.reviews} content={data?.generalFooter} />
    </StyledWrap>
  );
};

export default NewTextIconSection;
