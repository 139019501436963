import styled from "styled-components";
import Slider from "react-slick";
import { Paragraph, Heading } from "src/components/Text";
import Image from "src/components/Common/ImageFluid";
import ArrowIcon from "src/images/newArrow.svg";

export const StyledSlider = styled(Slider)`
  .slick-track {
    display: flex;
    /* align-items: space-between; */
  }

  .slick-slide > div:first-child {
    /*  */
    /* display: flex;
    justify-content: center; */
  } 
   
  .hOdFpH::before {
    background-color:white;
  }

  .slick-arrow,
  .slick-next {
    opacity: 1;
  }

  .slick-list {
    text-align: -webkit-center;
    height: 900px;

    @media ${({ theme }) => theme.device.tablet} { 
      height: unset;
    }
    @media ${({ theme }) => theme.device.phone} { 
      height: 860px;
    }
  }

  .slick-arrow {
    width: 3rem;
    height: 3rem;
    background-image: url(${ArrowIcon});
    background-size: cover;
    background-size: unset;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    z-index: 1;

    &:before {
      content: none;
    }

    &.slick-next {
      transform: translateY(0) rotate(180deg);
      right: 15%;
      top: 100%;
      background-color: #F58E8A;
      width: 49px;
      height: 49px;
      border-radius: 4px;
      margin-top: 15%;
      display: block !important;
    }

    &.slick-prev {
        left: 15%;
        top: 100%;
        transform: translateX(0);
        background-color: #F58E8A;
        width: 49px;
        height: 49px;
        border-radius: 4px;
        margin-top: 15%;
        display: block !important;
    }
    
    @media ${({ theme }) => theme.device.laptop} {
      &.slick-next {
        transform: translateY(0) rotate(180deg);
        right: -6% !important;
        top: 38%;
        background-color: #F58E8A;
        width: 49px;
        height: 49px;
        border-radius: 4px;
        margin-top: 4%;
        display: block !important;
      }
  
      &.slick-prev {
          left: -6% !important;
          top: 38%;
          transform: translateX(0);
          background-color: #F58E8A;
          width: 49px;
          height: 49px;
          border-radius: 4px;
          margin-top: 4%;
          display: block !important;
      }
    }
    
    @media ${({ theme }) => theme.device.tablet} {
      &.slick-next {
        transform: translateY(0) rotate(180deg);
        right: -8%;
        top: 38%;
        background-color: #F58E8A;
        width: 49px;
        height: 49px;
        border-radius: 4px;
        margin-top: 4%;
      }
  
      &.slick-prev {
          left: -8%;
          top: 38%;
          transform: translateX(0);
          background-color: #F58E8A;
          width: 49px;
          height: 49px;
          border-radius: 4px;
          margin-top: 4%;
      }
    }
  }
   
  ul.slick-dots {
      top: 2rem;
      left: 2rem;
      bottom: unset;
      width: auto;
  
      @media ${({ theme }) => theme.device.tablet} {
        top: 3rem;
        left: 4.2rem;
      }
  
      li {
        margin: 0 0.7rem 0 0;

        button {
          width: 15px;
          height: 15px;
          border-radius: 100%;
        }

        &:last-child {
          margin: 0;
        }

        @media ${({ theme }) => theme.device.tablet} {
          margin: 0 2.5rem 0 0;
          width: auto;
          height: auto;

          button {
            font-size: 0;
            width: 15px;
            height: 15px;
            border: 0;
            border-radius: 50%;
  
    
            &::before {
              content: none;
            }
          }
          
        }
      }
  }

    .slick-slide {
      padding: 0;
      display: grid;
      row-gap: 9%;

      @media ${({ theme }) => theme.device.tablet} {
        display: block;
      }

    }
  
    ul.slick-dots {
      top: 100% !important;
      position: absolute;
      left: 45% !important;
      margin-top: 19.5% !important;

      @media ${({ theme }) => theme.device.laptop} {
        left: 45% !important;
        margin-top: 5.3% !important;
      }

      @media ${({ theme }) => theme.device.tablet} {
        left: 37% !important;
        margin-top: 6.3% !important;
      }
      @media ${({ theme }) => theme.device.smPhone} {
        left: 43% !important;
        margin-top: 18.5% !important;
      }
      margin: 2rem 0;
    }
  
    .slick-active button {
      font-size: 0rem;
      height: 0.5rem;
      width: 0.5rem;
    }
  
    li button {
      background: #E5E5E5;
      border: 0 !important;
    }
  
    .slick-active button {
      background: #F58E8A;
      border: 0 !important;
    }

`;

export const Description = styled(Paragraph)`
    font-family: 'Effra-Regular';
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    color: #6D6D6D;
    line-height:20.8px;

  @media ${({ theme }) => theme.device.phone} {
    line-height:2rem;
  }
`;

export const Title = styled(Heading)`
  font-size: 20px;
  font-weight: 700;
  font-family: 'Effra-Bold';
  margin-bottom: 20px;
  color: #192858;
  text-align: center;
  line-height: 25px;
  
  @media ${({ theme }) => theme.device.phone} {
    margin-bottom: 9px;
    margin-top: 9px;
  }
`

export const StyledPart = styled.div`
  background-color: #fff;
  width: 100% !important;
  height: 350px;
  max-width: none;
  border-radius: 10px;
  margin-top: 4rem;
  padding-top: 19% !important;
  padding: 1.5rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  @media ${({ theme }) => theme.device.laptop} {
    background-color: #fff;
    width: 385px !important;
    height: 450px;
    max-width: none;
    border-radius: 10px;
    margin-top: 4rem;
    padding-top: 33% !important;
    padding: 3.5rem;
    display: flex;
    justify-content: center;
  } 
  @media ${({ theme }) => theme.device.largeDesktop} {
    background-color: #fff;
    width: 378px !important;
    height: 450px;
    max-width: none;
    border-radius: 10px;
    margin-top: 4rem;
    padding-top: 33% !important;
    padding: 3.5rem;
    display: flex;
    justify-content: center;
  } 
`;

export const Slide = styled.div``;


export const StyledImage = styled(Image)`
  margin-bottom: 1.5rem;
  position: relative !important;
  margin-top: -197px !important;
  max-width: 335px;
  top: 0;
 
  img {
    object-fit: cover;

    @media ${({ theme }) => theme.device.laptop} {
      object-fit: cover;
      object-position: center;
      max-width: 350px;
      top: 0;
    }
  }

  @media ${({ theme }) => theme.device.tablet} { 
    max-width: 180px;
    position: absolute !important;
    margin-top: unset !important;
  }
  @media ${({ theme }) => theme.device.laptop} { 
    max-width: 350px !important;
    position: absolute !important;
    margin-top: unset !important;
  }
  @media ${({ theme }) => theme.device.phone} { 
    margin-top: -125px !important;
    max-width: 200px;
  }

`;

export const ImageContainer = styled.div`
    display: flex;
    flex-direction: column;

    img {
        height: 62px;
        margin-bottom: 2rem;
    }

    @media ${({ theme }) => theme.device.phone} {
      img {
        margin-bottom: 0.5rem;
    }
    }
`
