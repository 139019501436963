import React from "react";
import { Block, Heading, Text } from "./styles";

interface Props {
  heading: string;
  text: string;
}

const DeliveryBlock = ({ heading, text }: Props) => (
  <Block>
    <Heading>{heading}</Heading>
    <Text>{text}</Text>
  </Block>
);

export default DeliveryBlock;
