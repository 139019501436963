import React from "react";
import { SanityImage } from "src/interfaces/sanity";
import {
  Title,
  TextBlock,
  Description,
  StyledWrap,
  StyledImage,
} from "./styles";

interface Props {
  data: {
    heading: string;
    text: any;
    imagePosition: {
      image: SanityImage;
      position: "left" | "right";
    };
    negativeTopMargin?: boolean;
    bottomMargin?: boolean;
  };
}

const ExtrudedImageText = ({ data }: Props) => {
  const {
    imagePosition,
    heading,
    text,
    negativeTopMargin,
    bottomMargin,
  } = data;

  return (
    <StyledWrap
      noPadding={true}
      position={imagePosition.position}
      bottomMargin={bottomMargin}
    >
      <StyledImage
        imageRef={imagePosition.image}
        negativeTopMargin={negativeTopMargin}
      />
      <TextBlock position={imagePosition.position}>
        {heading && <Title>{heading}</Title>}
        {text && <Description blocks={text} />}
      </TextBlock>
    </StyledWrap>
  );
};

export default ExtrudedImageText;
