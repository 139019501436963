import styled from "styled-components";
import Wrap from "../../components/Wrap";

import Rating from "src/components/Rating";
interface StyledSectionProps {
  notificationBar?: boolean;
}

export const StyledSection = styled.section<StyledSectionProps>`
  margin: 2rem 0;
  scroll-margin-top: ${({ notificationBar }) =>
    notificationBar ? "9rem" : "6.3rem"};

  @media ${({ theme }) => theme.device.tablet} {
    padding: 0 0 3rem 0;

    scroll-margin-top: ${({ notificationBar }) =>
    notificationBar ? "11.7rem" : "6.9rem"};
  }

  #variant-B {
    display: none;
  }

  #hide-title {
    display: none;
  }

  #variant-b {
    display: none;
  }
`;


export const StyledWrap = styled(Wrap)`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 0;
  #mobileVersion { 
    margin-top: 3rem;
    padding: 3rem 2rem 0rem;
    width: 100%;

    #rating-box {
      margin-bottom: 0.5rem;
      @media ${({ theme }) => theme.device.smPhone} {
        #total-reviews-mobile {
          font-size: 8px;
        }
      }
    }

    #variant-A {
      h2 {
        margin-bottom: 0rem;
      }
    }

    #price-box-mobile {
      margin-bottom: 1.5rem;
      flex-wrap: wrap;
      #discount-text {
        font-size: 10px;
      }
    }

    #product-form {
      display: none;
    }
  }
  #hide-mobileVersion {
    display: none;
  }

  @media ${({ theme }) => theme.device.tablet} {
    flex-direction: row;
    padding: 0 8.1rem;
    #mobileVersion { 
      display: none;
    }
  }
`;

export const ANBPMobile = styled.div`
  display: flex;
  width: 100%;
  @media ${({ theme }) => theme.device.tablet} {
    display: none;
  }
`;

export const ANBPDesktop = styled.div`
  display: none;
  @media ${({ theme }) => theme.device.tablet} {
    display: block;
  }
`;

export const NewProductMessage = styled.div`
  padding: 6px 11px;
  color: #FEF9F9;
  font-weight: 400;
  font-size: 12px;
  background: #4F6EA8;
  border-radius: 57px;
  width: fit-content;
  font-family: 'Effra-Regular';
  font-style: normal;
  margin-top: 13px;
  margin-bottom: 18px;
`
export const StyledRating = styled(Rating)`
  margin-right: 0.4rem;
  width: unset;

  @media ${({ theme }) => theme.device.phone} {
    width: 22% !important;
  }
`;

export const Gallery = styled.div`
  display: flex;
  flex-direction: column;
  width: 48%;
  @media ${({ theme }) => theme.device.phone} { 
    width: 100%;
  }

  #desktopTestimonial {
    display: none;
  }
`;