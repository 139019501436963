import { lazy } from "react";
import styled, { css } from "styled-components";
import Wrap from "src/components/Wrap";
import BlockContent from "../../components/BlockContent";
import Image from "../../components/Common/ImageFluid";
import waveMobile from "../../images/underline_mobile.svg";
import wave from "../../images/underline.svg";
import { Paragraph } from "src/components/Text";
import Button from "src/components/Common/Button";
import CtaBlock from "../../components/CtaBlock";

interface TitlePositionProps {
  leftTitle: boolean;
}

interface HeadingPositionProps {
  leftHeading: boolean;
}

interface PaddingBottom {
  addBottomPadding: boolean;
}

const Model = lazy(() => import("src/components/Model"));

export const StyledImage = styled(Image)`
  width: 190px;
  display: flex;
  justify-content: flex-end;

  img {
    height: auto;
    width: 100%;
  }
  @media ${({ theme }) => theme.device.mobile} {
    display: none;
  }
  @media ${({ theme }) => theme.device.laptop} {
    display: flex;
  }
`;

export const StyledWrap = styled(Wrap)<PaddingBottom>`
  &.variant_A {
    display: none;
  }
  padding: 7rem 2rem 4.5rem 2rem;
  display: flex;
  flex-direction: row;
  width: 100%;

  .content {
    width: 100%;
  }
  .trustpilot {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
    @media ${({ theme }) => theme.device.mobile} {
      display: none;
    }
    @media ${({ theme }) => theme.device.laptop} {
      display: flex;
    }
  }
  @media ${({ theme }) => theme.device.tablet} {
    padding: ${({ addBottomPadding }) =>
      addBottomPadding
        ? " 9.3rem 8.1rem 9.3rem 8.1rem"
        : "9.3rem 8.1rem 0rem 8.1rem"};
  }
`;

export const StyledBlocks = styled(BlockContent)<HeadingPositionProps>`
  text-align: ${({ leftHeading }) => (leftHeading ? "left" : "center")};
  color: ${({ theme }) => theme.colors.font_black};
  font-size: ${({ theme }) => theme.fontSize["4xl"].mobile};
  line-height: ${({ theme }) => theme.lineHeight["4xl"].mobile};
  font-family: 'Effra-Regular';
  font-weight: 400;

  p br {
    display: none;
  }
  /* white-space: nowrap; */

  @media ${({ theme }) => theme.device.tablet} {
    font-size: ${({ theme }) => theme.fontSize["4xl"].desktop};
    line-height: ${({ theme }) => theme.lineHeight["4xl"].desktop};
    /* max-width: 87.6rem; */
    margin: 0 auto;

    p br {
      display: block;
    }
    /* white-space: pre-line; */
  }

  strong {
    background-image: url(${waveMobile});
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-y: bottom;
    padding-bottom: 0.5rem;

    @media ${({ theme }) => theme.device.tablet} {
      background-image: url(${wave});
      padding-bottom: 1rem;
    }
  }
`;

export const ContainerNameSub = styled.div<TitlePositionProps>`
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: ${({ leftTitle }) => (leftTitle ? "flex-start" : "center")};

  .Paragraph-dFWjeo {
    margin-top: 1rem;
    font-size: 2.2rem;
    line-height: 2.8rem;
    @media ${({ theme }) => theme.device.mobile} {
      font-size: 1.9rem;
    }

    @media ${({ theme }) => theme.device.laptop} {
      font-size: 2.5rem;
    }
  }

  @media ${({ theme }) => theme.device.mobile} {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  @media ${({ theme }) => theme.device.laptop} {
    display: flex;
    align-items: flex-end;
    flex-direction: row;
  }
`;

export const Name = styled(Paragraph)<TitlePositionProps>`
  font-size: 1.6rem;
  text-decoration: none;
  text-align: center;
  text-align: ${({ leftTitle }) => (leftTitle ? "left" : "center")};
`;

export const Subheading = styled(Paragraph)`
  white-space: unset;
  margin-left: 2rem;
  cursor: pointer;
  text-decoration: underline;
  @media ${({ theme }) => theme.device.tablet} {
    line-height: 3rem;
    white-space: pre-line;
  }
`;

export const StyledModel = styled(Model)`
  margin-top: 2.7rem;
  width: 100%;

  @media ${({ theme }) => theme.device.tablet} {
    margin-top: 8.1rem;
  }
`;

export const StyledButton = styled(Button)`
  margin-top: 3rem;

  @media ${({ theme }) => theme.device.tablet} {
    margin-top: 4.5rem;
  }
`;

export const TopIcon = styled(Image)`
  width: 6rem;
  margin-bottom: 3rem;

  @media ${({ theme }) => theme.device.tablet} {
    width: 9.3rem;
    margin-bottom: 2.2rem;
  }
`;

export const ActivateLink = styled(CtaBlock)`
  font-family: 'Effra-Regular';
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: left;
  padding: 0;
  display: flex;
  justify-content: flex-end;
  @media ${({ theme }) => theme.device.mobile} {
    display: none;
  }
  @media ${({ theme }) => theme.device.laptop} {
    display: flex;
  }
  a {
    border: 0;
    background: transparent;
    padding: 0 0 0 5.3rem;
    text-decoration: underline;
  }
`;
