import styled from "styled-components";
import Wrap from "src/components/Wrap";
import { Heading, Paragraph, SectionHeading } from "src/components/Text";
import Button from "src/components/Common/Button";

interface StyledWrapProps {
  isSection?: boolean;
}

export const StyledWrap = styled(Wrap)<StyledWrapProps>`
  &.variant_A {
    display: none;
  }

  position: relative;
  padding: ${({ isSection }) =>
    isSection ? "2.4rem 2rem 6.9rem" : "2.4rem 0 5rem 0"};

  @media ${({ theme }) => theme.device.tablet} {
    padding: ${({ isSection }) =>
      isSection ? "4.5rem 8.1rem" : "2.4rem 0 5rem 0"};
    max-width: 84.6rem;
    box-sizing: content-box;
  }
`;

export const StyledHeading = styled(Heading)`
  margin-bottom: 1.3rem;
  font-weight: 700;
  text-align: center;
  font-size: 25px;
  line-height: 30px;
  font-family: 'Effra-Bold';

  @media ${({ theme }) => theme.device.tablet} {
    margin-bottom: 2.8rem;
    font-size: 45px;
    line-height: 54px;
  }
`;

export const RatingBox = styled(SectionHeading)`
  margin-bottom: 2rem;

  @media ${({ theme }) => theme.device.tablet} {
    margin-bottom: 3.6rem;
  }
`;

export const RatingText = styled(Paragraph)`
  margin-top: 0.3rem;
`;

export const SearchButton = styled(Button)`
  margin-bottom: 1.4rem;

  svg {
    width: 2rem;
    height: 2rem;
  }

  @media ${({ theme }) => theme.device.tablet} {
    margin-bottom: 2.8rem;
  }
`;

export const TagButton = styled(Button)`
  padding: 0 1.5rem;
  flex-shrink: 0;
`;

export const ButtonList = styled.div`
  display: flex;
  overflow-x: scroll;
  flex-wrap: wrap;
  gap: 0.7rem;
  @media ${({ theme }) => theme.device.tablet} {
    display: static;
    overflow-x: initial;
  }

  ${TagButton} {
    margin-right: 0.7rem;
    margin-top: 0.2rem;

    &:last-child {
      margin: 0;
    }
  }
`;

export const ActionButton = styled(Button)`
  width: 100%;

  @media ${({ theme }) => theme.device.tablet} {
    width: auto;
  }

  @media ${({ theme }) => theme.device.phone} {
    background-color: #ea8b8a;
  }
`;

export const Actions = styled.div`
  margin-top: 1.2rem;

  ${ActionButton} {
    margin: 0;

    @media ${({ theme }) => theme.device.tablet} {
      margin-right: 2.1rem;
    }

    &:last-child {
      margin: 0;
    }
  }
`;

export const ReviewsContainer = styled.div`
    #truspilot-container {
      display: none;
    }
`
