import React from "react";
import { SanityColor, SanityImage } from "src/interfaces/sanity";
import { useReturnMoneyDrawer } from "src/context/siteContext";
import {
  StyledWrap,
  TextContainer,
  FirstColumn,
  SecondColumn,
  FirstParagraph,
  LinkParagraph,
  OtherParagraph,
  StyledImage,
} from "./style";

interface Props {
  data: {
    firstColumnImage: SanityImage[];
    firstColumnText: string;
    firstColumnLink: string;
    secondColumnImage: SanityImage[];
    secondColumnText: string;
    secondColumnLink: string;
    sectionSettings: {
      backgroundColor: SanityColor;
    };
  };
  context: {};
  props?: any;
}

const AnnouncementBarProduct = ({ data, context, ...props }: Props) => {
  const { openReturnMoney } = useReturnMoneyDrawer();
  return (
    <>
      {data && (
        <StyledWrap {...props}>
          <TextContainer>
            <FirstColumn>
              <StyledImage imageRef={data?.firstColumnImage} alt={data?.firstColumnImage?.alt} />
              <div className="paragraph-container">
                <FirstParagraph> {data?.firstColumnText}</FirstParagraph>
                <LinkParagraph onClick={openReturnMoney}>
                  {data?.firstColumnLink}
                </LinkParagraph>
              </div>
            </FirstColumn>
            <SecondColumn>
              <StyledImage imageRef={data?.secondColumnImage} alt={data?.secondColumnImage?.alt} />
              <div className="paragraph-container">
                <FirstParagraph>{data?.secondColumnText}</FirstParagraph>
                <OtherParagraph>{data?.secondColumnLink}</OtherParagraph>
              </div>
            </SecondColumn>
          </TextContainer>
        </StyledWrap>
      )}
    </>
  );
};

export default AnnouncementBarProduct;
