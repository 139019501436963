import styled from "styled-components";
import Wrap from "src/components/Wrap";
import Icon from "src/components/Common/Icon";
import Slider from "react-slick";
import ArrowIcon from "src/images/arrow-left.svg";

import { SectionHeading, Paragraph } from "src/components/Text";

export const StyledWrap = styled(Wrap)`
  padding: 3.4rem 2rem 5rem;
  padding-bottom: 15rem;

  @media ${({ theme }) => theme.device.tablet} {
    padding: 6.8rem 8.1rem;
  }
`;

export const StyledHeading = styled(SectionHeading)`
  font-size: 25px;
  font-weight: 700;
  
  @media ${({ theme }) => theme.device.tablet} {
    ont-style: normal;
    font-weight: 700;
    font-size: 58px;
    line-height: 1.2;
    text-align: center;
  }
`;

export const Description = styled(Paragraph)``;

export const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3.4rem;
  

  h1 {
    font-style: normal;
    font-weight: 700;
    line-height: 1.2;
    font-size: 35px;
    font-family: 'Effra-Bold';
    text-align: center;
    @media ${({ theme }) => theme.device.tablet} {
    font-size: 58px;
    }
  }

  h2 {
    font-style: normal;
    font-weight: 600;
    line-height: 1.2;
    font-size: 32px;
    font-family: 'Effra-Bold';
    text-align: center;
    @media ${({ theme }) => theme.device.tablet} {
    font-size: 52px;
    }
  }

  h3 {
    font-style: normal;
    font-weight: 500;
    line-height: 1.2;
    font-size: 28px;
    font-family: 'Effra-Medium';
    text-align: center;
    @media ${({ theme }) => theme.device.tablet} {
    font-size: 44px;
    }
  }

  h4 {
    font-style: normal;
    font-weight: 700;
    line-height: 1.2;
    font-size: 28px;
    text-align: center;
    font-family: 'Effra-Bold';
    @media ${({ theme }) => theme.device.tablet} {
    font-size: 38px;
    }
  }

  h5 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.2;
    text-align: center;
    font-family: 'Effra-Medium';
    @media ${({ theme }) => theme.device.tablet} {
    font-size: 24px;
    }
  }

  h6 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.2;
    text-align: center;
    font-family: 'Effra-Medium';
    @media ${({ theme }) => theme.device.tablet} {
    font-size: 20px;
    }
  }

  p {
    padding: 10px 0;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 1.3;
    text-align: center;
    font-family: 'Effra-Regular';
    @media ${({ theme }) => theme.device.tablet} {
    font-size: 18px;
    }
  }

  strong {
    font-weight: 900;
    font-family: 'Effra-Bold';
  }
`;

export const SlideCount = styled.span`
  color: ${({ theme }) => theme.colors.lighter_gray};
`;

export const SliderContainer = styled.div`
  position: relative;
`;

export const Arrow = styled(Icon)`
  position: absolute;
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  @media ${({ theme }) => theme.device.tablet} {
    width: 3.2rem;
    height: 3.2rem;
  }
`;

export const RatingBox = styled.div`
  margin 1rem 0;
  display: flex;
  align-items: center;
  justify-content: center;

`;

export const RatingText = styled(Paragraph)`
  margin: 0.3rem 0 0 0.5rem;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;  
  text-transform: uppercase;
`;