import React from "react";
import { StyledVideo } from "./styles";

interface Props {
  id: string;
  src: string;
  muted?: boolean;
  controls?: boolean;
  autoPlay?: boolean;
  playsInline?: boolean;
  className?: string;
  isPopUp?: boolean;
}

const Video = ({ src, isPopUp = false, ...props }: Props) => {
  return (
    isPopUp ? (
      <StyledVideo {...props}>
        <source src={src} type="video/mp4" />
        Your browser does not support the video tag.
      </StyledVideo>
    ) : (
      <StyledVideo {...props}>
        <source data-src={src} type="video/mp4" />
        Your browser does not support the video tag.
      </StyledVideo>
    )
  );
};

export default Video;
