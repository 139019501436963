import styled, { css } from "styled-components";
import Image from "src/components/Common/ImageFluid";
import Wrap from "src/components/Wrap";
import { SectionHeading, Paragraph } from "src/components/Text";

export const StyledImage = styled(Image)`
  margin: 0 auto 5rem auto;

  @media ${({ theme }) => theme.device.tablet} {
    margin: 0;
    width: 935px;
    max-width: 935px;
  }

  @media ${({ theme }) => theme.device.phone} {
    margin-top: 2rem;
  }
`;

export const StyledWrap = styled(Wrap)`
  &.variant_A {
    display: none;
  }

  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  padding: 5.5rem 2rem 2rem;

  @media ${({ theme }) => theme.device.tablet} {
    padding: 7rem 8rem 3rem;
    justify-content: space-between;

    ${StyledImage} {
      max-width: 80%;
    }
  }
`;

export const Title = styled(SectionHeading)`
  margin-bottom: 3.2rem;
  white-space: initial;
  font-size: 3.75rem;

  @media ${({ theme }) => theme.device.tablet} {
    white-space: pre-line;
    font-size: 3.75rem;
    line-height: 4.8rem;
    margin-bottom: 5.6rem;
  }
`;

export const Description = styled(Paragraph)`
  white-space: initial;
  font-family: 'Effra-Regular';
  font-size: 18px;
  font-weight: 300;
  line-height: 22px;
  text-align: left;

  @media ${({ theme }) => theme.device.phone} {
    line-height: 16.5px;
    font-size: 15px;
  }
`;

export const TextBlock = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: baseline;

  ${Title}, ${Description} {
    flex: 50%;
  }

  @media ${({ theme }) => theme.device.tablet} {
    ${Title} {
      flex: 30%;
    }
    ${Description} {
      flex: 70%;
    }
    padding: 5rem 0 0 10rem;
  }

  @media ${({ theme }) => theme.device.phone} {
    flex-direction: column;
  }
`;
