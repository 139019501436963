import React from "react";
import {
  StyledWrap,
  StyledImage,
  StyledAnchor,
  Heading,
  StyledIcon,
  StyledBlocks,
  ImageGallery,
  CaptionContainer,
  SectionHeader,
} from "./styles";
import { SanityImage, SectionSettings } from "../../interfaces/sanity";
import { SmallDesktop } from "../../components/Responsive";

interface Props {
  data: {
    title: {
      text: string;
      textLink: string;
    };
    caption: {};
    images: SanityImage[];
    sectionSettings: SectionSettings;
    imageLink: string;
  };
}

const InstagramListSection = ({ data }: Props) => {
  const renderCaption = (
    <CaptionContainer>
      <StyledIcon name="instagram" />
      <StyledBlocks blocks={data.caption} />
    </CaptionContainer>
  );

  return (
    <StyledWrap>
      <SectionHeader>
        <a href={data?.title?.textLink} target="_blank">
          <Heading>{data?.title?.text}</Heading>
        </a>
        <SmallDesktop type="minWidth">{renderCaption}</SmallDesktop>
      </SectionHeader>
      <ImageGallery>
        {data?.images?.map((image) => (
          <StyledAnchor key={image?._key} href={data?.imageLink} target="_blank">
            <StyledImage imageRef={image} />
          </StyledAnchor>
        ))}
      </ImageGallery>
      <SmallDesktop type="maxWidth">{renderCaption}</SmallDesktop>
    </StyledWrap>
  );
};

export default InstagramListSection;
