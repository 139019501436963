const fnExists = (fnName) => {
    return {
        "fbq": typeof fbq !== 'undefined'
    }[fnName];
};

const tryGlobal = (fnName, ...args) => {
    if (fnExists(fnName)) {
        switch (fnName) {
          case 'fbq': fbq(...args); break;
        }
    }
};

export default tryGlobal;