import React from "react";
import { SanityButton } from "src/interfaces/common";
import { Heading, StyledBlocks, Row, StyledButton } from "./styles";

interface Props {
  heading: string;
  text?: any;
  button?: SanityButton;
}

const InformationRow = ({ heading, text, button }: Props) => {
  return (
    <Row>
      <Heading>{heading}</Heading>
      {text && <StyledBlocks blocks={text} />}
      {button && button.enabled && (
        <StyledButton to={button.link} buttonType="outline" small={true}>
          {button.text}
        </StyledButton>
      )}
    </Row>
  );
};

export default InformationRow;
