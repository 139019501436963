import React from 'react';
import Image from 'src/components/Common/ImageFluid';
import { DesktopImage, MobileImage, StyledImage } from './styles';

interface Props {
  data: {
    image: any;
    mobileImage: any;
  };
}

const ImageModule = ({ data }: Props) => {
  const { image, mobileImage } = data;
  
  return (
    <StyledImage>
        <DesktopImage>
            <Image imageRef={image} />
        </DesktopImage>
        <MobileImage>
            <Image imageRef={mobileImage} />
        </MobileImage>
    </StyledImage>
  );
};

export default ImageModule;
