import React from "react";
import {
  StyledList,
  StyledListItem,
  StyledIcon,
  StyledParagraph,
} from "./styles";

interface Props {
  items: string[];
  options: "column" | "columnTwo";
  className: string;
  arrowsWhite?: boolean;
}

const List = ({ items, options, className, arrowsWhite }: Props) => {
  return (
    <StyledList options={options} className={className}>
      {items.map((item, i) => (
        <StyledListItem key={`${item}_${i}`}>
          <StyledIcon name={arrowsWhite ? "arrow-right-white" : "arrow-right"} />
          <StyledParagraph>{item}</StyledParagraph>
        </StyledListItem>
      ))}
    </StyledList>
  );
};

export default List;
