import React from "react";
import { StyledPositionRow, Container } from "./styles";

interface Props {
  rows: Array<RowsType>;
}

interface RowsType {
  title: string;
  type: string;
  description: any;
}

const Positions = ({ rows }: Props) => {
  return (
    <Container>
      {rows.map((row, index) => {
        return (
          <StyledPositionRow
            key={index}
            title={row.title}
            type={row.type}
            description={row.description}
          />
        );
      })}
    </Container>
  );
};

export default Positions;
