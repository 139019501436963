import styled from "styled-components";
import { SectionHeading } from "src/components/Text";
import BlockContent from "src/components/BlockContent";

export const Row = styled.div`
  margin-bottom: 5rem;

  @media ${({ theme }) => theme.device.tablet} {
    margin-bottom: 10.7rem;
  }
`;

export const Heading = styled(SectionHeading)`
  margin-bottom: 2.6rem;

  @media ${({ theme }) => theme.device.tablet} {
    line-height: 5.4rem;
    margin-bottom: 3rem;
  }
`;

export const Deliveries = styled.div`
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 1fr;

  @media ${({ theme }) => theme.device.tablet} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;
