import Wrap from "src/components/Wrap";
import styled, { css } from "styled-components";
import Image from "src/components/Common/ImageFluid";
import BlockContent from "src/components/BlockContent";
import { SectionHeading } from "src/components/Text";
import ArrowLeft from "src/images/arrow-left.svg";
import ArrowLeftWhite from "src/images/arrow-left-white.svg";
import CheckOrange from "src/images/check-orange.svg";

interface DescriptionProps {
  color: string;
  listIcon: "arrow" | "check";
}

interface WrapProps {
  mobilePadding?: boolean;
}

interface ImgProps {
  first?: boolean;
}

const listIconStyles = (
  listIcon: "arrow" | "check",
  textColor: "#FFFFFF" | "#0F0923"
) => {
  switch (listIcon) {
    case "arrow":
      return css`
        width: 2rem;
        height: 2rem;
        background-image: ${textColor === "#FFFFFF"
          ? `url(${ArrowLeftWhite})`
          : `url(${ArrowLeft})`};
      `;

    case "check":
      return css`
        width: 2.4rem;
        height: 2.4rem;
        background-image: url(${CheckOrange});

        @media ${({ theme }) => theme.device.tablet} {
          width: 3.2rem;
          height: 3.2rem;
        }
      `;
  }
};

export const StyledWrap = styled(Wrap)<WrapProps>`
  display: flex;
  justify-content: flex-end;
  padding: 4rem 2rem;
  padding: ${({ mobilePadding }) =>
    mobilePadding ? "4rem 2rem 40rem" : "4rem 2rem"};
  min-height: 55rem;

  @media ${({ theme }) => theme.device.tablet} {
    padding: 8.5rem 8.1rem 6.5rem;
    min-height: 63.7rem;
  }
`;

export const Container = styled.div`
  @media ${({ theme }) => theme.device.tablet} {
    max-width: 52.2rem;
  }
`;

export const Title = styled(SectionHeading)`
  margin-bottom: 2.8rem;

  @media ${({ theme }) => theme.device.tablet} {
    font-size: 3.6rem;
    line-height: 4.2rem;
    margin-bottom: 5rem;
  }
`;

export const Description = styled(BlockContent)<DescriptionProps>`
  color: ${({ color }) => color};
  font-size: 1.4rem;
  line-height: 2.2rem;
  font-family: 'Effra-Regular';
  font-weight: ${({ theme }) => theme.fontWeight.regular};

  @media ${({ theme }) => theme.device.tablet} {
    font-size: 1.6rem;
    line-height: 3rem;
  }

  p {
    margin: 2.2rem 0;

    @media ${({ theme }) => theme.device.tablet} {
      margin: 3rem 0;
    }
  }

  li {
    list-style-type: none;
    position: relative;
    padding: ${({ listIcon }) =>
      listIcon === "check" ? " 0 0 0 3.7rem" : "0 0 0 3.2rem"};
    margin: 0;

    @media ${({ theme }) => theme.device.tablet} {
      padding: ${({ listIcon }) =>
        listIcon === "check" ? " 0 0 0 4.2rem" : "0 0 0 3.2rem"};
    }

    &:before {
      content: "";
      ${({ listIcon, color }) => listIconStyles(listIcon, color)};
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
    }
  }
`;

export const ImageContainer = styled.div`
  position: absolute;
  bottom: 2.8rem;
  right: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${({ theme }) => theme.device.tablet} {
    left: 5.1rem;
    top: 4.6rem;
    bottom: unset;
    right: unset;
  }
`;

export const AdditionalImage = styled(Image)<ImgProps>`
  ${({ first }) => (first ? firstStyles : secondStyles)};
  width: 100%;
`;

const firstStyles = css`
  margin-bottom: 1.6rem;
  max-width: 8.9rem;

  @media ${({ theme }) => theme.device.tablet} {
    max-width: 13.6rem;
    margin-bottom: 2.3rem;
  }
`;

const secondStyles = css`
  max-width: 12.7rem;

  @media ${({ theme }) => theme.device.tablet} {
    width: 19.4rem;
    max-width: none;
  }
`;
