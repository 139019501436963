import React from "react";
import { SanityImage } from "src/interfaces/sanity";
import {
    StyledWrap,
    StyledTextContainer,
    StyledBlogContainer,
    StyledBlogItems,
    StyledImage, 
    StyledSection
} from "./style";
import Arrow from "src/images/arrow.svg";
import Link from "src/components/Link"

interface Props {
    data: {
        title: string;
        subtitle: string;
        blogItemsData: [
            {
                image: SanityImage;
                title?: string;
                url?: string;
            }
        ];
    }
}

const BlogCategories = ({ data }: Props) => {

    return (
        <StyledSection id="blog-categories">
        <StyledWrap>
            <StyledBlogContainer>

                <StyledTextContainer>
                    <h1 className="blog-title">{data.title}</h1>
                    <h3 className="blog-subtitle">{data.subtitle}</h3>
                </StyledTextContainer>
                <StyledBlogItems>
                    {data?.blogItemsData?.map((datas, i) => {
                        return (
                            <div className="single-blog-item" key={i}>
                                <a className="blog-group-box" href={datas?.url}>
                                    <StyledImage imageRef={datas?.image} />
                                    <p className="category-title">{datas?.title}</p>
                                </a>
                            </div>
                        )
                    })}
                </StyledBlogItems>
            </StyledBlogContainer>
        </StyledWrap>
        </StyledSection>
    )
}

export default BlogCategories;