import styled from "styled-components";
import Wrap from "src/components/Wrap";
import Slider from "react-slick";
import VideoPopup from "src/components/VideoPopup";
import { SectionHeading, Paragraph } from "src/components/Text";
import ArrowIcon from "src/images/new_white_arrow_1.svg";
import Rating from "../Rating";
import Image from "src/components/Common/ImageFluid";

export const SlideNumber = styled.button``;

export const StyledSlider = styled(Slider)`
  .slick-track {
    display: flex;
  }

  .slick-slider {
    padding: 4rem 0;
  }

  .slick-arrow,
  .slick-next {
    opacity: 1;
  }

  .slick-list {
    padding: 5rem 50px !important;
  }

  .slick-dots {

    li {
      background-color: ${({ theme }) => theme.colors.light_white};
      border-radius: 50px;
      width: 15px !important;
      height: 15px !important;

      button:before {
        content: "" !important;
      }
    }
    .slick-active {
      background-color: ${({ theme }) => theme.colors.blue_yonder};
      border-radius: 50px;
      width: 15px !important;
      height: 15px !important;
    }
  }

  .slick-arrow {
    width: 3rem;
    height: 3rem;
    background-image: url(${ArrowIcon});
    background-size: cover;
    background-size: unset;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    z-index: 1;

    &:before {
      content: none;
    }

    &.slick-next {
      transform: translateY(0) rotate(180deg);
      background-color: ${({ theme }) => theme.colors.blue_yonder};
      width: 49px;
      height: 49px;
      border-radius: 4px;

      bottom: -38px;
      top: auto;
      right: 10px;

      @media ${({ theme }) => theme.device.tablet} { 
        bottom: unset;
        top: 50%;
        right: -30px;
      }
    }

    &.slick-prev {
        transform: translateX(0);
        background-color: ${({ theme }) => theme.colors.blue_yonder};
        width: 49px;
        height: 49px;
        border-radius: 4px;

        bottom: -38px;
        top: auto;
        left: 10px;
  
        @media ${({ theme }) => theme.device.tablet} { 
          bottom: unset;
          top: 50%;
          left: -20px;
        }
    }

  }

  

`;

export const Description = styled(Paragraph)`
  margin-bottom: 3.1rem;
  font-size: 1.8rem;
  line-height: 2.6rem;
  text-align: center;
  font-family: 'Effra-Regular';
  @media ${({ theme }) => theme.device.tablet} {
    margin: 1.3rem 0;
    font-family: 'Effra-Regular';
    font-size: 1.4rem;
    line-height: 2.2rem;
  }
`;

export const Slide = styled.div`

  @media ${({ theme }) => theme.device.phone} {
    max-width: 34.2rem;
  }

  @media ${({ theme }) => theme.device.tablet} {
    max-width: 38.2rem;
  }
`;

export const StyledVideo = styled(VideoPopup)`
  margin-bottom: 1.5rem;
  width: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    @media ${({ theme }) => theme.device.tablet} {
      height: 21.7rem;
      object-fit: cover;
      object-position: center;
    }
  }
`;

export const Author = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 40px;
  width: 92%;
  @media ${({ theme }) => theme.device.tablet} { 
    widthL 100%;
  }
`;

export const BoxContainer = styled.div`
  background: white;
    color: black; 
    margin: -3px 0 0 0;
    box-shadow: 0px 4px 42px rgba(0, 0, 0, 0.1);
    border-radius: 0 0 40px 40px;
    color: ${({ theme }) => theme.colors.dark_blue};
    padding: 3rem;
`;

export const StyledRating = styled(Rating)`
  margin-top: 0.3rem;

  svg {
    width: 1.283rem;
    height: 1.283rem;
    margin-right: 0.321rem;
  }
`;

export const StyledImage = styled(Image)`
  border-radius: 40px 40px 0 0;
  @media ${({ theme }) => theme.device.tablet} {

  }
`;

export const TextBox = styled(Paragraph)`
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
  line-height: 1.8rem;
  align-items: center;
  font-family: 'Effra-Regular';
  @media ${({ theme }) => theme.device.tablet} {
    font-size: 1.4rem;
  }
`;

export const StyledName = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const StyledImageBox = styled.div`
  position: relative;

`;

export const VideoBox = styled.div`
position: absolute;
top: 50%;
left: 50%;

svg {
  height: 4rem;
  width: 4rem;
}

`;