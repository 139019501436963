import React, { useRef, useState, useEffect } from "react";
import { SanityImage } from "src/interfaces/sanity";
import Image from "../Common/ImageFluid";
import Slider from "react-slick";
import Video from "../Video";
import {
  MainSlider,
  Nav,
  NavImage,
  NavContainer,
  SliderCount,
  AdditionalContainer,
  FirstAdditionalImage,
  SecondAdditionalImage,
  GalleryContainer,
  ImageContainer,
  NavImageContainer,
  SecondAdditionalContainer,
  ThirdAdditionalImage,
} from "./styles";
import playSVG from "src/images/Play.svg";
interface Props {
  list: SanityImage[];
  mainImage: any;
}

const MobileGallery = ({ list, mainImage }: Props) => {
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [slideCount, setSlideCount] = useState<number>(list?.length);
  const SliderRef = useRef<Slider>(null);
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  let _slider1 = useRef(null);
  let _slider2 = useRef(null);

  const numberOfSlides = list?.length;

  useEffect(() => {
    setNav1(_slider1);
    setNav2(_slider2);
  }, []);

  useEffect(() => {
    let actualVideo = document.getElementById(`video-${currentSlide}`);
    actualVideo && actualVideo.play();
  }, [currentSlide])


  const handleAfter = (index: number) => {
    document.querySelectorAll(".video").forEach(vid => vid.pause());
    setCurrentSlide(index);
  };

  const changeSlide = (i: number) => {
    if (!SliderRef.current) return;

    SliderRef.current.slickGoTo(i);
  };

  const renderSlides = (item, i) => {
    switch (item._type) {
      case "image":
        return (
          <ImageContainer key={item._key}>
            {i === 0 &&
              (mainImage?.firstAdditionalImage ||
                mainImage?.secondAdditionalImage) && (
                <AdditionalContainer>
                  <FirstAdditionalImage
                    imageRef={mainImage?.firstAdditionalImage}
                    alt={mainImage?.firstAdditionalImage?.alt}
                  />
                  <SecondAdditionalImage
                    imageRef={mainImage?.secondAdditionalImage}
                    alt={mainImage?.secondAdditionalImage?.alt}
                  />
                </AdditionalContainer>
              )}
              {i === 0 &&
              (mainImage?.thirdAdditionalImage &&
                <SecondAdditionalContainer>
                  <ThirdAdditionalImage
                    imageRef={mainImage?.thirdAdditionalImage}
                    alt={mainImage?.thirdAdditionalImage?.alt}
                  />
                </SecondAdditionalContainer>
              )}
            <Image imageRef={item} alt={item.alt}/>
          </ImageContainer>
        );

      case "videoWithThumbnail":
        return (
          <Video src={item?.videoShopify ? item.videoShopify : item.url} id={`video-${i}`} autoPlay muted controls playsInline className="video" />
        )

      case "model":
        return (
          !isSSR && (
            <React.Suspense key={item._key} fallback={<div />}>
              <StyledModel alt="A 3D model" src={item.url} />
            </React.Suspense>
          )
        );

      default:
        break;
    }
  };

  const renderNav = (item, i) => {
    switch (item._type) {
      case "image":
        return (
          <NavImageContainer
            key={item._key}
            className={currentSlide === i ? "active" : ""}
            onClick={() => changeSlide(i)}
          >
            <NavImage imageRef={item} alt={item.alt}/>
          </NavImageContainer>
        );

      case "videoWithThumbnail":
        return (
          <NavImageContainer
            key={item._key}
            className={currentSlide === i ? "active" : ""}
            onClick={() => changeSlide(i)}
          >
            <div className={currentSlide === i ? "current" : ""}>
              <img src={item?.thumbnail} alt="thumbnail" />
              <img src={playSVG} alt="playBtn" />
            </div>
          </NavImageContainer>
        )

      case "model":
        return (
          <NavImageContainer
            key={item._key}
            className={currentSlide === i ? "active" : ""}
            onClick={() => changeSlide(i)}
          >

            <img src={imgPlaceholder} alt="model" />
          </NavImageContainer>
        );

      // case "galleryRow":
      //   return item.options.map((option, j) => {
      //     return renderNav(option, i + j);
      //   });

      default:
        break;
    }
  }

  return (
    <GalleryContainer>
      <MainSlider>
        <Slider asNavFor={nav2}  ref={slider => (_slider1 = slider)}>
        {
          list?.map((item, i) => {return renderSlides(item, i)})
        }
        </Slider>
      
      </MainSlider>
      <NavContainer>
        <Slider
          asNavFor={nav1}
          ref={slider => (_slider2 = slider)}
          slidesToShow={3}
          arrows={true}
          swipeToSlide={true}
          focusOnSelect={true}
          dots= {false}
          infinite= {numberOfSlides >= 3 ? true : false}
         >
          {list?.map((item, i) => renderNav(item, i))}
        </Slider>
        {/* <SliderCount>
          {currentSlide + 1} <span>/ {slideCount}</span>
        </SliderCount> */}
      </NavContainer>
    </GalleryContainer>
  );
};

export default MobileGallery;
