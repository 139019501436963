import styled, { css } from "styled-components";
import Image from "src/components/Common/ImageFluid";
import { Paragraph } from "src/components/Text";

interface Props {
  direction: "horizontal" | "vertical";
}

interface IconProps {
  big: boolean;
  direction: "horizontal" | "vertical";
}

const handleItemDirection = (direction: "horizontal" | "vertical") => {
  switch (direction) {
    case "horizontal":
      return `
        flex-direction: row;
        align-items: center;
      `;
    case "vertical":
      return `
        flex-direction: column;
        align-items: flex-start;
      `;
  }
};

const handleIconDirection = (
  direction: "horizontal" | "vertical",
  big: boolean
) => {
  switch (direction) {
    case "horizontal":
      return css`
        margin-right: 1.1rem;
        max-height: 3.2rem;
      `;
    case "vertical":
      return css`
        margin-bottom: 1rem;
        max-height: ${big ? "6rem" : "2.4rem"};
        height: 100%;
      `;
  }
};

const handleTextDirection = (direction: "horizontal" | "vertical") => {
  switch (direction) {
    case "horizontal":
      return `
      font-size: 1.4rem;
          `;
    case "vertical":
      return `
            font-size: 1.2rem;
          `;
  }
};

export const List = styled.ul`
  display: flex;
  justify-content: space-between;
`;

export const ListItem = styled.li<Props>`
  margin: 0;
  padding: 0;
  list-style-type: none;
  margin-right: 1.5rem;
  max-width: 17.2rem;
  display: flex;

  &:last-child {
    margin-right: 0;
  }

  ${({ direction }) => handleItemDirection(direction)};
`;

export const Text = styled(Paragraph)<Props>`
  line-height: 1.8rem;

  ${({ direction }) => handleTextDirection(direction)};
`;

export const Icon = styled(Image)<IconProps>`
  ${({ direction, big }) => handleIconDirection(direction, big)};
`;
