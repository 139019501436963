import React from "react";
import {
  Container,
  StyledImage,
  StyledName,
  TextBox,
  BoxContainer,
  StyledRating,
} from "./styles";
import { SanityImage } from "src/interfaces/sanity";
import { useTheme } from "styled-components";

interface Props {
  image?: SanityImage;
  name: string;
  position: string;
  rating?: number;
  slug: any;
}

const Author = ({ image, name, position, rating, slug }: Props) => {
  const theme = useTheme();

  return (
    <Container>
      {image && <StyledImage imageRef={image} />}
      <BoxContainer slug={slug}>
        <TextBox>
          <StyledName>@{name}</StyledName>
        </TextBox>
        {rating && <StyledRating number={rating} color={theme.colors.yellow} />}
      </BoxContainer>
      {position}
    </Container>
  );
};

export default Author;
