import styled, { css } from 'styled-components';
import Image from '../../components/Common/ImageFluid';
import { SectionHeading, Paragraph } from '../../components/Text';
import Link from '../../components/Link';
import Wrap from '../../components/Wrap';
import Button from 'src/components/Common/Button';
import List from '../../components/List';
import Video from '../../components/Video';
import Rating from '../../components/Rating';


export const StyledWrap = styled(Wrap)`
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  padding: 15rem 2rem 7rem 2rem;

  @media ${({ theme }) => theme.device.tablet} {
    padding: 15rem 8.1rem 10rem 8.1rem;
    flex-direction: row;
  }

`;

export const BgContainer = styled.div`
  position: relative;
`;

export const LeftPart = styled.div`
  width: 100%;
  margin: 2rem 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: flex-start;

  @media ${({ theme }) => theme.device.tablet} {
    width: 50%;
    margin: unset;
  }
`;

export const RightPart = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${({ theme }) => theme.device.tablet} {
    width: 50%;
  }
`;

export const StyledImage = styled(Image)`
  width: 90%;
  display: block;
  margin: auto;

`;

export const StyledHeading = styled.div`
  margin: 2rem auto;
  width: 100%;
  display: block;

  h1 {
    font-style: normal;
    font-weight: 700;
    line-height: 1.2;
    font-size: 35px;
    font-family: 'Effra-Bold';
    text-align: center;
    @media ${({ theme }) => theme.device.tablet} {
    text-align: left;
    font-size: 58px;
    }
  }

  h2 {
    font-style: normal;
    font-weight: 600;
    line-height: 1.2;
    font-size: 32px;
    font-family: 'Effra-Bold';
    text-align: center;
    @media ${({ theme }) => theme.device.tablet} {
    text-align: left;
    font-size: 52px;
    }
  }

  h3 {
    font-style: normal;
    font-weight: 500;
    line-height: 1.2;
    font-size: 28px;
    font-family: 'Effra-Medium';
    text-align: center;
    @media ${({ theme }) => theme.device.tablet} {
    text-align: left;
    font-size: 44px;
    }
  }

  h4 {
    font-style: normal;
    font-weight: 700;
    line-height: 1.2;
    font-size: 28px;
    font-family: 'Effra-Bold';
    text-align: center;
    @media ${({ theme }) => theme.device.tablet} {
    text-align: left;
    font-size: 38px;
    }
  }

  h5 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.2;
    text-align: center;
    font-family: 'Effra-Medium';
    @media ${({ theme }) => theme.device.tablet} {
    text-align: left;
    font-size: 24px;
    }
  }

  h6 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.2;
    text-align: center;
    font-family: 'Effra-Medium';
    @media ${({ theme }) => theme.device.tablet} {
    text-align: left;
    font-size: 20px;
    }
  }

  p {
    padding: 10px 0;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 1.3;
    text-align: center;
    font-family: 'Effra-Regular';
    @media ${({ theme }) => theme.device.tablet} {
    text-align: left;
    font-size: 18px;
    }
  }

  strong {
    font-weight: 900;
    font-family: 'Effra-Bold';
  }

`;




export const StyledSubtext = styled(Paragraph)`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
  font-family: 'Effra-Regular';
  text-align: center;
  @media ${({ theme }) => theme.device.tablet} {
  text-align: left;
  }

`;

export const DesktopStyle = styled.div`
  display: none;
  @media ${({ theme }) => theme.device.tablet} { 
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;