import styled from "styled-components";
import Wrap from "src/components/Wrap";
import { SectionHeading, Paragraph } from "src/components/Text";
import IconList from "src/components/IconList";
import { ListItem, Icon } from "src/components/IconList/styles";

export const StyledWrap = styled(Wrap)`
  &.varinat_B {
    display: none;
  }
  padding: 3.4rem 0rem 5rem;
  padding-bottom: 27rem;

  @media ${({ theme }) => theme.device.tablet} {
    padding: 6.8rem 8.1rem;
  }
  @media ${({ theme }) => theme.device.phone} {
    padding: 3.4rem 0rem 5rem;
    padding-bottom: 17rem;
  }
`;

export const StyledHeading = styled(SectionHeading)`
  font-size: 25px;
  font-weight: 700;
  font-family: 'Effra-Bold';
  text-align: center;
  margin-top: 2rem;

  @media ${({ theme }) => theme.device.laptop} {
    font-size: 40px;
    font-weight: 700;
    padding-left: 30%;
    padding-right: 30%;
    text-align: center;
    margin-top: 0rem;
  }

    padding-left: 15%;
    padding-right: 15%;
`;

export const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3.4rem;
  padding: 0 2rem;

  

  @media ${({ theme }) => theme.device.tablet} {
    margin-bottom: 5.3rem;
    font-size: 40px;
    font-weight: 700;
  }
`;

export const SliderContainer = styled.div`
  position: relative;
  padding-left: 0;
  padding-right: 0;

  .slick-slide {
    margin: 4rem 2rem;
    height: 100%;
    @media ${({ theme }) => theme.device.tablet} {
      margin: 0 2rem;
      height: 100%;
    }
    @media ${({ theme }) => theme.device.phone} {
      margin: 0 2rem;
      height: 100%;
    }
  }
  .slick-track:before {
    display: block;
  }

  @media ${({ theme }) => theme.device.laptop} {
    padding-left: 0% !important;
    padding-right: 0% !important;
    .slick-prev {
      left: -2% !important;
    }
    .slick-next {
      right: -2% !important;
    }
  }
  @media ${({ theme }) => theme.device.largeDesktop} {
    padding-left: 10% !important;
    padding-right: 10% !important;
  }
  
`;

export const TopIconList = styled(IconList)`
  margin-top: 5rem;
  display: none;

  @media ${({ theme }) => theme.device.laptop} {
    margin-top: 8rem;
    display: flex;
    padding-left: 0rem;
    padding-right: 0rem;
  }

  @media ${({ theme }) => theme.device.largeDesktop} {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  ${ListItem} {
    flex-direction: row;
    align-items: center;
    margin-right: 0;
    text-align: left;
    max-width: none;
    display: flex;
    justify-content: center;
  }

  ${Icon} {
    margin-bottom: 0.5rem;
    margin-right: 0;
    height: 30px;
    max-height: 30px;
    overflow: visible;
    flex:1;
  }
  ${Paragraph} {
    font-size: 14px;
    margin-left: 1.85rem;
    font-weight: 400;
    font-family: 'Effra-Regular';
    width: 64%;
    flex: 6;
  }
`;
