import styled from "styled-components";

export const StyledImage = styled.div`
  margin-top: 13rem;
  margin-bottom: -1rem;

  @media ${({ theme }) => theme.device.tablet} {
    margin-top: 11rem;
  }
`;

export const DesktopImage = styled.div`
  display: none;

  @media ${({ theme }) => theme.device.tablet} {
    display: block;
  }
`;

export const MobileImage = styled.div`
  display: block;

  @media ${({ theme }) => theme.device.tablet} {
    display: none;
  }
`;

