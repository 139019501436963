import styled from 'styled-components';
import Wrap from '../../components/Wrap';

export const SectionWrapper = styled.div`
  background-color: #F0F5FE;
`;

export const StyledWrap = styled(Wrap)`
  display: flex;
  flex-direction: column;
  padding: 0rem;

  #mobileVersion {
    text-align: center;
  }

  @media ${({ theme }) => theme.device.tablet} {
    padding: 11.1rem 2rem 0rem;
    text-align: left;
  }
`;
