import React, { useRef, useState, useEffect } from "react";
import { SanityImage } from "src/interfaces/sanity";
import Image from "../Common/ImageFluid";
import Slider from "react-slick";
import {
  MySlider,
  NavImage,
  AdditionalContainer,
  FirstAdditionalImage,
  SecondAdditionalImage,
  ThirdAdditionalImage,
  GalleryContainer,
  ImageContainer,
  NavImageContainer,
  StyledModel,
  SecondAdditionalContainer,
} from "./styles";
import { isSSR } from "src/utils/ssr";
import imgPlaceholder from "src/images/3d-placeholder.jpg";
import playSVG from "src/images/Play.svg";
import Video from "../Video";

interface Props {
  list: SanityImage[];
  mainImage: any;
}

// const ClientSideOnlyLazy = React.lazy(() => import("src/components/Model"));

const ProductGallery = ({ list, mainImage }: Props) => {
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  let _slider1 = useRef(null);
  let _slider2 = useRef(null);

  const numberOfSlides = list.length;


  useEffect(() => {
    setNav1(_slider1);
    setNav2(_slider2);
  }, []);

  useEffect(() => {
    let actualVideo = document.getElementById(`video-${currentSlide}`);
    actualVideo && actualVideo.play();
  }, [currentSlide])

  const SliderRef = useRef<Slider>(null);

  const handleAfter = (index: number) => {
    document.querySelectorAll(".videos").forEach(vid => vid.pause());
    setCurrentSlide(index);
  };

  const changeSlide = (i: number) => {
    setCurrentSlide(i);

    if (!SliderRef.current) return;

    SliderRef.current.slickGoTo(i);
  };

  const renderSlides = (item, i) => {
    switch (item._type) {
      case "image":
        return (
          <ImageContainer key={item._key}>
            {i === 0 &&
              (mainImage?.firstAdditionalImage ||
                mainImage?.secondAdditionalImage) && (
                <AdditionalContainer>
                  <FirstAdditionalImage
                    imageRef={mainImage?.firstAdditionalImage}
                    alt={mainImage?.firstAdditionalImage?.alt}
                  />
                  <SecondAdditionalImage
                    imageRef={mainImage?.secondAdditionalImage}
                    alt={mainImage?.secondAdditionalImage?.alt}
                  />
                </AdditionalContainer>
              )}
            {i === 0 &&
              (mainImage?.thirdAdditionalImage &&
                <SecondAdditionalContainer>
                  <ThirdAdditionalImage
                    imageRef={mainImage?.thirdAdditionalImage}
                    alt={mainImage?.thirdAdditionalImage?.alt}
                  />
                </SecondAdditionalContainer>
              )}
            <Image imageRef={item} alt={item.alt}/>
          </ImageContainer>
        );

      case "videoWithThumbnail":
        return (
          <Video src={item?.videoShopify ? item.videoShopify : item.url} id={`videos-${i}`} autoPlay muted controls playsInline className="videos" />
        )

      case "model":
        return (
          !isSSR && (
            <React.Suspense key={item._key} fallback={<div />}>
              <StyledModel alt="A 3D model" src={item.url} />
            </React.Suspense>
          )
        );

      // case "galleryRow":
      //   return item.options.map((option, i) => renderSlides(option, i));

      default:
        break;
    }
  };

  const renderNav = (item: object, i: number) => {
    switch (item._type) {
      case "image":
        return (
          <NavImageContainer
            key={item._key}
            className={currentSlide === i ? "active" : ""}
            onClick={() => changeSlide(i)}
          >

            <NavImage imageRef={item} alt={item.alt}/>
          </NavImageContainer>
        );

      case "videoWithThumbnail":
        return (
          <NavImageContainer
            key={item._key}
            className={currentSlide === i ? "active" : ""}
            onClick={() => changeSlide(i)}
          >
            <div className={currentSlide === i ? "current" : ""}>
              <img src={item?.thumbnail} alt="thumbnail" />
              <img src={playSVG} alt="playBtn" />
            </div>
          </NavImageContainer>
        )

      case "model":
        return (
          <NavImageContainer
            key={item._key}
            className={currentSlide === i ? "active" : ""}
            onClick={() => changeSlide(i)}
          >

            <img src={imgPlaceholder} alt="model" />
          </NavImageContainer>
        );

      // case "galleryRow":
      //   return item.options.map((option, j) => {
      //     return renderNav(option, i + j);
      //   });

      default:
        break;
    }
  };

  // const settings = {
  //   ref: SliderRef,
  //   afterChange: handleAfter,
  //   arrows: true,
  //   draggable: false,
  // };

  const settings = {
    infinite: numberOfSlides >= 5 ? true : false,
    rows: 1,
    centerPadding: "70",
    afterChange: handleAfter,
    speed: 500,
    slidesToShow: 5,
    dots: false,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          slidesToScroll: 1,
          infinite: numberOfSlides >= 5 ? true : false,
          dots: false,
        }
      },
    ]
  };

  const settings2 = {
    rows: 1,
    speed: 500,
    slidesToShow: 1,
    arrows: false,
    dots: false,
  };

  return (
    <GalleryContainer>
      {/* <MainSlider {...settings}>
        {list.map((item, i) => renderSlides(item, i))}
      </MainSlider> */}

      <Slider
        asNavFor={nav2}
        ref={slider => (_slider1 = slider)}
        {...settings2}
      >
        {list.map((item, i) => renderSlides(item, i))}
      </Slider>
      <MySlider numberOfSlides={numberOfSlides}>
        <Slider
          asNavFor={nav1}
          ref={slider => (_slider2 = slider)}
          slidesToShow={5}
          swipeToSlide={true}
          focusOnSelect={true}
          {...settings}
        >
          {list
            .filter((item) => item._type !== "galleryRow")
            .map((item, i) => renderNav(item, i))}
        </Slider>
      </MySlider>
    </GalleryContainer>
  );
};

export default ProductGallery;
