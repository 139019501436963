import styled from "styled-components";
import Icon from "../Common/Icon";

export const CloseIcon = styled(Icon)`
  position: absolute;
  cursor: pointer;
  top: 2rem;
  right: 2rem;

  @media ${({ theme }) => theme.device.tablet} {
    top: 3.5rem;
    right: 3.5rem;
  }
`;
