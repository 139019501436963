import styled from "styled-components";
import Wrap from "src/components/Wrap";
import BlockContent from "../../components/BlockContent";
import waveMobile from "../../images/underline_mobile.svg";
import wave from "../../images/underline.svg";
import Image from "src/components/Common/ImageFluid";

export const StyledWrap = styled(Wrap)`
  &.variant_A {
    display: none;
  }
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${({ theme }) => theme.device.tablet} {
    padding: 6rem 8.1rem;
  }
`;

export const StyledBlocks = styled(BlockContent)`
  text-align: center;
  color: ${({ theme }) => theme.colors.font_black};
  font-size: ${({ theme }) => theme.fontSize["4xl"].mobile};
  line-height: ${({ theme }) => theme.lineHeight["4xl"].mobile};
  font-family: 'Effra-Regular';
  font-weight: 400;
  margin-bottom: ${({ image }) => (image ? "4.5rem" : "0")};

  p br {
    display: none;
  }

  @media ${({ theme }) => theme.device.tablet} {
    font-size: ${({ theme }) => theme.fontSize["4xl"].desktop};
    line-height: ${({ theme }) => theme.lineHeight["4xl"].desktop};
    margin: 0 auto;
    margin-bottom: ${({ image }) => (image ? "9.3rem" : "0")};

    p br {
      display: block;
    }
  }

  strong {
    background-image: url(${waveMobile});
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-y: bottom;
    padding-bottom: 0.5rem;

    @media ${({ theme }) => theme.device.tablet} {
      background-image: url(${wave});
      padding-bottom: 1rem;
    }
  }
`;

export const StyledImage = styled(Image)`
  width: 100%;

  @media ${({ theme }) => theme.device.tablet} {
    max-width: 98.9rem;
  }
`;
