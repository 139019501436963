import React from "react";
import { SanityImage, SanityColor } from "src/interfaces/sanity";
import { StyledWrap, StyledBlocks, StyledImage } from "./styles";

interface Props {
  data: {
    heading: object[];
    image: SanityImage;
    experiment: {
      variantA?: boolean;
      variantB?: boolean;
    };
    sectionSettings: {
      backgroundColor: SanityColor;
      backgroundImage: SanityImage;
    };
  };
}

const ImageHeadingSection = ({ data }: Props) => {
  const { heading, image } = data;

  return (
    <StyledWrap className={data?.experiment?.variantB ? 'variant_B' : data?.experiment?.variantA ? 'variant_A': ''}>
      {heading && (
        <StyledBlocks blocks={heading} image={image ? true : false} />
      )}
      {image && <StyledImage imageRef={image} />}
    </StyledWrap>
  );
};

export default ImageHeadingSection;
