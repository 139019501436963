import React from "react";
import { buildFileUrl, getFile } from "@sanity/asset-utils";

const sanityConfig = () => ({
  projectId: process.env.GATSBY_SANITY_PROJECT_ID,
  dataset: process.env.GATSBY_SANITY_DATASET,
});

import { StyledRow } from "./styles";
import Video from "src/components/Video";
import ProductReviews from "../ProductReviews";
import QuestionAnswer from "../QuestionAnswer";
import Wrap from "src/components/Wrap";

interface Props {
  data: {
    main: {};
    ReviewsTitle: string;
    questionAnswerTitle: string;
    QuestionAndAnswer: [];
    experiment: {
      variantA?: boolean;
      variantB?: boolean;
    };
    sectionSettings: {};
  };
  context: {};
}

const ReviewsAndFAQSection = ({ data, context }: Props) => {
  const questionAnswerData = {
    title: data?.questionAnswerTitle,
    list: data?.QuestionAndAnswer,
    sectionSettings: data?.sectionSettings,
  };

  const productReviewsData = {
    title: data?.ReviewsTitle,
    sectionSettings: data?.sectionSettings,
  };
  
const group_id = `${process.env.GATSBY_CURRENCY_SYMBOL == "$" ? '236108bc-8815-4a8c-ba8e-00fac3d1b254' : '40473462-07c0-4e22-95ef-aa543e493a83'}` 

  return (
    <Wrap className={data?.experiment?.variantB ? 'variant_B' : data?.experiment?.variantA ? 'variant_A': ''}>
      <StyledRow>
        <div>
          <div data-oke-reviews-widget-holder data-oke-reviews-group-id={group_id}>
              <div data-oke-reviews-nav-bar></div>
              <div id="oke-starSnippet" data-oke-reviews-product-listing-rating data-oke-reviews-scroll-target-id="drawer-container"  data-oke-reviews-group-id={group_id}></div>
              <div data-oke-reviews-widget></div>
              <div data-oke-reviews-qanda></div>
          </div>
        </div>
        <div>
          <QuestionAnswer isSection={false} data={questionAnswerData} />
        </div>
      </StyledRow>
    </Wrap>
  );
};

export default ReviewsAndFAQSection;
