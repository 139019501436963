import axios from 'axios';

interface Payload {
    buyerZipCode: string;
    monetaryValue: string;
    responseFormat: string;
    sessionTrackId: string;
    skus: [{
        sku: string;
        quantity: number;
    }]

}

const CURRENCY_SYMBOL = process.env.GATSBY_CURRENCY_SYMBOL;
const tenantId = CURRENCY_SYMBOL === "$" ? "8b4401e8243b4e14983f991f96ced3f0" : "bd6281718f4b42fa97134170c31bbcbf"; // tenantID
const apiurl = "https://sleepeasycommunityusa.delest.fenixcommerce.com/fenixdelest/api/v2/deliveryestimates"; // API ENDPOINT URL
const apiurl_uk = "https://sleepeasycommunityuk.delest.fenixcommerce.com/fenixdelest/api/v2/deliveryestimates"; // API ENDPOINT URL
const url = CURRENCY_SYMBOL === "$" ? apiurl : apiurl_uk;

export const ___fenixRecallApi = async (requestData: Payload) => {
    const results = await axios.post(
        url,
        requestData,
        {
            headers: {
                tenantId: tenantId, 'Content-Type': 'application/json'
            }
        }
    )

    return results
}

export const getFullDay = (day: string) => {
  switch(day) {
    case 'Mon': return "Monday";
    case 'Tue': return "Tuesday";
    case 'Wed': return "Wednesday";
    case 'Thu': return "Thursday";
    case 'Fri': return "Friday";
    case 'Sat': return "Saturday";
    case 'Sun': return "Sunday";
  }
}
