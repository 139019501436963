import React, { FC, ReactElement } from "react";
import {
  StyledSlider,
  SlideNumber,
  CarouselListContainer,
  TitleQuoteContainer,
} from "./styles";
import { SanityImage } from "src/interfaces/sanity";
import Image from "../../components/Common/ImageFluid";
import { useMediaQuery } from "react-responsive";
import Stars from "src/images/stars.png";
interface MediaProps {
  children: ReactElement<any, any> | null;
  type: "minWidth" | "maxWidth";
}

export const size = {
  phone: 480,
  tablet: 768,
  smallDesktop: 992,
  largeDesktop: 1200,
};
interface Props {
  carousel: [
    {
      stars?: SanityImage;
      reviewTitle?: string;
      reviewQuote?: string;
      reviewerName?: string;
    }
  ];
  className: string;
}

// const isTablet = useMediaQuery({ [type]: size.tablet });
// const isMobile = useMediaQuery({ [type]: size.phone });

const Carousel = ({ carousel, className }: Props) => {
  const isMobile = useMediaQuery({ maxWidth: size.phone });
  const isTablet = useMediaQuery({ maxWidth: size.tablet });

  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: isMobile ? 1 : isTablet ? 2 : 3,
    slidesToScroll: 1,

    customPaging: (i) => <SlideNumber>{i + 1}</SlideNumber>,
  };

  return (
    <StyledSlider {...settings} className={className}>
      {/* {images.map((image) => (
        <Image key={image._key} imageRef={image} />
      ))} */}
      {carousel &&
        carousel.map((c) => (
          <CarouselListContainer>
            <img className="carousel-stars" src={Stars} />
            <TitleQuoteContainer>
              <p className="carousel-title">{c.reviewTitle}</p>
              <p className="carousel-review-quote">{c.reviewQuote}</p>
            </TitleQuoteContainer>

            <p className="carousel-reviewer-name">{c.reviewerName}</p>
          </CarouselListContainer>
        ))}
    </StyledSlider>
  );
};

export default Carousel;
