import styled from "styled-components";
import Image from "../Common/ImageFluid";
import { Paragraph } from "../Text";
import Rating from "../Rating";

interface BoxProps {
  slug: any;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
  line-height: 2.1rem;
  font-family: 'Effra-Regular';

  @media ${({ theme }) => theme.device.tablet} {
    font-size: 1.4rem;
    line-height: 2.5rem;
  }
  @media ${({ theme }) => theme.device.phone} {
    align-items: center;
  }
`;

export const BoxContainer = styled.div<BoxProps>`
  display: ${({slug}) => slug == "easy-sleeper" ? "flex" : "block"};

  @media ${({theme}) => theme.device.phone} {
    justify-content: center;
  }
`;

export const StyledRating = styled(Rating)`
  // margin-top: 0.3rem;
  padding-left: 3px;
  display: flex;
  align-items: center;
  svg {
    width: 1.283rem;
    height: 1.283rem;
    margin-right: 0.321rem;
    @media ${({ theme }) => theme.device.tablet} {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
`;

export const StyledImage = styled(Image)`
  max-width: 5rem;
  margin-right: 1.2rem;
  border-radius: 50%;

  @media ${({ theme }) => theme.device.tablet} {
    margin-right: 1.6rem;
  }
`;

export const TextBox = styled(Paragraph)`
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
  line-height: 2.1rem;

  @media ${({ theme }) => theme.device.tablet} {
    font-size: 1.4rem;
    line-height: 2.5rem;
  }
`;

export const StyledName = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color:#F58E8A;
  font-size: 18px;
  font-family: 'Effra-Bold';

  @media ${({theme}) => theme.device.phone} {
    font-size: 14px;
  }
`;
