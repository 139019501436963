import React from 'react';
import mobileImg from "src/images/mobile.svg";
import desktopImg from "src/images/desktop.svg";
import { StyledImage } from "./styles";

const CustomerTestimonial = () => {

    return (
        <StyledImage id="customerTestimonial">
            <img src={desktopImg} className='desktopImg' alt="Customer testimonial"/>
            <img src={mobileImg} className='mobileImg' alt="Customer testimonial"/>
        </StyledImage>
    );
};

export default CustomerTestimonial;