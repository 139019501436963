import React, { useState, useEffect } from "react";
import ImageGallery from "src/components/ImageGallery";
import ProductInfo from "src/components/ProductInfo";
import { StyledSection, StyledWrap, ANBPMobile, NewProductMessage, StyledRating, Gallery } from "./styles";
import { size } from "src/components/Responsive";
import { useMediaQuery } from "react-responsive";
import MobileGallery from "src/components/MobileGallery";
import { useReviewDrawer } from "src/context/siteContext";
import { eventData } from 'src/utils/dataLayer';
import ProductComment from "src/components/Porduct-comment";
import { ProductUpsells } from "src/interfaces/sanity";
import AnnouncementBarProduct from "src/components/AnnouncementBarProduct";
import { useTheme } from 'styled-components';
import ProductForm from 'src/components/ProductForm';
import {
  Title,
  RatingsBox,
  RatingParagraph,
} from "src/components/ProductInfo/styles"
import locales from 'src/locales';
import useInView from "src/hooks/useInView";
import CustomerTestimonial from "src/components/CustomerTestimonial";
interface Props {
  main: {
    title?: string;
    pdpTitle?: string;
    subTitle?: string;
    slug: {
      current: string;
    };
    productDescription?: [];
    okendoReviews: {
      totalReviews: number;
      rating: number;
    };
    productValues?: []
    additionalInformationTitle?: string;
    mainImage: {
      asset: {
        _id: string;
      };
    };
    linkedSite: string;
    linkedSiteName: string;
    cerealImage: {};
    atcButton?: {
      caption?: string;
      price?: boolean;
    };
    productBundles?: [];
    productCases?: [];
    productQuantity?: [];
    productGallery?: [];
    mobileGallery?: [];
    bundleIcon: any;
    bundleText: string;
    quantityText: string;
    bundleSubheader: string;
    caseText?: string;
    caseSubheader?: [];
  };
  type: any;
  product: {
    title: string
    defaultPrice: string;
    productId: number;
    defaultVariant: {
      title: string
      variantIdShopify: string
      sku: string
    }
  };
  setProductPack: any;
  removeProductPack: any;
  activePack: any;
  setActivePack: any;
  addQuantity: any;
  removeQuantity: any;
  removeQuantityBundle: any;
  quantity: any;
  setQuantity: any;
  rating?: number;
  totalReviews?: number;
  notificationBar: boolean;
  upsells: Array<ProductUpsells>;
  upsellReviews: [];
  announcementBarProduct: any;
  showPayment?: boolean;
  paymentImage: Image;
  showTrialMessage: boolean;
  showDeliveryTimeWidget: boolean;
  showCountdown: boolean;
  newProductMessage: boolean;
  date: any;
  showTestimonial: boolean;
  showBuyNowButton: boolean;
}

const ProductHero = (props: Props) => {
  const {
    product, main, type, setProductPack, removeProductPack, activePack, setActivePack, addQuantity, removeQuantity,
    removeQuantityBundle, quantity, setQuantity, rating, totalReviews, notificationBar, upsells, upsellReviews,
  } = props;
  const { openReviews } = useReviewDrawer();

  const {
    title, pdpTitle, slug, okendoReviews, productDescription, productValues, linkedSite, linkedSiteName, mainImage,
    productGallery, mobileGallery, iconListTop, announcementBarProduct, iconListBottom, additionalInformationTitle,
    productBundles, productCases, productQuantity, quantityText, caseText, caseSubheader, bundleIcon,
    bundleText, bundleSubheader, showPayment, paymentImage, showTrialMessage, showDeliveryTimeWidget,
    showCountdown, newProductMessage, date, showTestimonial, showBuyNowButton, enableProdQuantity, enableProdBundles
  } = main;

  const isMobile = useMediaQuery({ maxWidth: size.phone });
  const theme = useTheme();

  const openReviewsDrawer = () => {
    openReviews({
      pdpTitle,
      id: product?.productId,
      sku: product?.defaultVariant.sku,
      slug: slug?.current,
    });
  };

  const [discountedPrice, setDiscountedPrice] = useState<string | undefined>(
    product.defaultPrice
  );
  const [comparePrice, setComparePrice] = useState<string | undefined>(
    undefined
  );
  const [discount, setDiscount] = useState<number>(0);
  const [pillowcasePrice, setPillowcasePrice] = useState<number>(0);
  const [savedMoney, setSavedMoney] = useState(false);

  const getPrice = () => {
    const symbol = process.env.GATSBY_CURRENCY_SYMBOL;
    /*
      2022-04-11 : Aaron
      Disable price calcualtion section
      Started using the custom price values from CMS for each quantity
    */
    if (quantity === 0 && pillowcasePrice === 0) {
      return symbol + parseFloat(0).toFixed(2);
    }

    if (productQuantity?.length) {
      if (quantity !== 0) {
        return (
          symbol +
          parseFloat(
            productQuantity[quantity - 1]?.salesPrice + pillowcasePrice
          ).toFixed(2)
        );
      } else if (quantity === 0 && pillowcasePrice !== 0) {
        return symbol + parseFloat(pillowcasePrice).toFixed(2);
      }
    } else {
      if (activePack) {
        return activePack.price;
      }

      if (quantity !== 0 && pillowcasePrice === 0) {
        return symbol + parseFloat(discountedPrice * quantity).toFixed(2);
      }
    }
  };

  const currency = process.env.GATSBY_CURRENCY_SYMBOL == '$' ? 'USD' : 'GBP';

  const [ref, isInView] = useInView();

  useEffect(() => {
    if (isInView)
      dataLayer?.push({
        ...eventData('dl_view_item'),
        ecommerce: {
          currencyCode: currency,
          detail: {
            products: [
              {
                name: title,
                id: product.defaultVariant.sku,
                price: parseFloat(product.defaultPrice),
                brand: 'GroovePillows',
                variant: product.variants[0]?.content?.shopify?.variantTitle ?? product.defaultVariant.title,
                category: 'Pillow',
                currency
              },
            ],
          },
        },
      });
  }, [isInView]);


  let message = locales.productInfo.new_product_message;

  const shopifyProductId = product?.productId;
  if (shopifyProductId == 6852326654038 || shopifyProductId == 6852243685462) {
      message = locales.productInfo.moreStock;
  }

  return (
    <StyledSection ref={ref} id="product-hero" notificationBar={notificationBar}>
      <StyledWrap>
        <div id={slug?.current == 'easy-sleeper' ? "variant-B" : "hide-title"}>
          <Title>{title}</Title>
        </div>

        {(
          <div id="mobileVersion">
            {
              newProductMessage
                ? <NewProductMessage>{message}</NewProductMessage>
                : <RatingsBox onClick={openReviewsDrawer}>
                  <StyledRating fieldName="productHero" number={okendoReviews?.rating} color={theme.colors.yellow} />
                  <RatingParagraph>
                    <span>Rated {okendoReviews?.rating} out of 5 by {okendoReviews?.totalReviews} happy customers</span>
                  </RatingParagraph>
                </RatingsBox>
            }

            <div id={slug?.current == 'easy-sleeper' ? "variant-A" : "hide-a-title"} >
              <Title>{pdpTitle}</Title>
            </div>
            <ProductForm
              id={product.productId}
              type={type}
              title={pdpTitle}
              sku={product.defaultVariant.sku}
              variantTitle={product.defaultVariant.sku}
              slug={slug}
              productId={product.productId}
              defaultPrice={product.defaultPrice}
              showQuantity={true}
              activePack={activePack}
              removePack={removeProductPack}
              setActivePack={setActivePack}
              addQuantity={addQuantity}
              quantity={quantity}
              removeQuantity={removeQuantity}
              removeQuantityBundle={removeQuantityBundle}
              setQuantity={setQuantity}
              mobilePhoto={mobileGallery?.length > 0 && mobileGallery[0]}
              atcButton={main.atcButton}
              setComparePrice={setComparePrice}
              setDiscountedPrice={setDiscountedPrice}
              discountedPrice={discountedPrice}
              mainImage={mainImage}
              upsellData={upsells}
              upsellReviews={upsellReviews}
              bundles={productBundles}
              pillowcases={productCases}
              caseText={caseText}
              caseSubheader={caseSubheader}
              setSavedMoney={setSavedMoney}
              setDiscount={setDiscount}
              discount={discount}
              bundleIcon={bundleIcon}
              bundleText={bundleText}
              bundleSubheader={bundleSubheader}
              productQuantity={productQuantity}
              quantityText={quantityText}
              pillowcasePrice={setPillowcasePrice}
              showDeliveryTimeWidget={showDeliveryTimeWidget}
              showBuyNowButton={showBuyNowButton}
            />
          </div>
        )}
        {productGallery && (
          <Gallery>
            <ImageGallery list={productGallery} mainImage={main.mainImage} />
            <MobileGallery list={mobileGallery} mainImage={main.mainImage} />
            {slug?.current == 'easy-sleeper' &&
              <div id="desktopTestimonial">
                <CustomerTestimonial />
              </div>
            }
          </Gallery>
        )}
        <ANBPMobile>
          <AnnouncementBarProduct id="variant-a" data={announcementBarProduct} />
        </ANBPMobile>
        <ProductInfo
          id={product.productId}
          slug={slug}
          type={type}
          title={pdpTitle}
          sku={product.defaultVariant.sku}
          okendoReviews={okendoReviews}
          variantTitle={product.defaultVariant.title}
          description={productDescription}
          productValues={productValues}
          additionalInformationTitle={additionalInformationTitle}
          price={product.defaultPrice}
          discountLabel={main.discountLabel}
          productId={product.productId}
          iconListTop={iconListTop}
          iconListBottom={iconListBottom}
          bundles={productBundles}
          productQuantity={productQuantity}
          quantityText={quantityText}
          pillowcases={productCases}
          caseText={caseText}
          caseSubheader={caseSubheader}
          setProductPack={setProductPack}
          removeProductPack={removeProductPack}
          activePack={activePack}
          setActivePack={setActivePack}
          addQuantity={addQuantity}
          quantity={quantity}
          removeQuantity={removeQuantity}
          removeQuantityBundle={removeQuantityBundle}
          setQuantity={setQuantity}
          openReviews={openReviewsDrawer}
          rating={rating}
          totalReviews={totalReviews}
          notificationBar={notificationBar}
          mobilePhoto={mobileGallery?.length > 0 && mobileGallery[0]}
          atcButton={main.atcButton}
          mainImage={main.mainImage}
          productTestimonial={main.productTestimonial}
          upsells={upsells}
          upsellReviews={upsellReviews}
          bundleIcon={bundleIcon}
          bundleText={bundleText}
          bundleSubheader={bundleSubheader}
          showPayment={showPayment}
          paymentImage={paymentImage}
          showTrialMessage={showTrialMessage}
          showDeliveryTimeWidget={showDeliveryTimeWidget}
          showCountdown={showCountdown}
          date={date}
          showTestimonial={showTestimonial}
          newProductMessage={newProductMessage}
          showBuyNowButton={showBuyNowButton}
          announcementBarProduct={announcementBarProduct}
          enableProdBundles={enableProdBundles}
          enableProdQuantity={enableProdQuantity}
        />
      </StyledWrap>
      <ProductComment
        notificationBar={notificationBar}
        productTestimonial={main.productTestimonial}
      />
    </StyledSection>
  );
};

export default ProductHero;
