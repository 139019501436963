import React, { useState, useEffect, useRef } from "react";
import NewTestimonialSlider from "src/components/NewTestimonialSlider";
import {
  StyledWrap,
  StyledHeading,
  TextContainer,
  SlideCount,
  SliderContainer,
  Arrow,
  RatingBox,
  RatingText
} from "./styles";
import BlockContent from '@sanity/block-content-to-react';
import { Serializer } from 'src/utils/serializer';
import Rating from "src/components/Rating";
import { useTheme } from "styled-components";
import { useMediaQuery } from "react-responsive";
import { RenderReviews } from "src/components/GeneralSection";

export const size = {
  tablet: 768,
};

interface Props {
  data: any;
  context: {
    reviews: object;
  }
}

const NewTestimonialList = ({ data, context }: Props) => {
  const sliderRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [slideCount, setSlideCount] = useState<number>(data?.list?.length);
  const theme = useTheme();
  const nextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };
  const previousSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  }

  const { reviews } = context;

  const isMobile = useMediaQuery({ maxWidth: size.tablet });


  return (
    <StyledWrap>
      <TextContainer>
        <StyledHeading><BlockContent blocks={data?.title} serializers={Serializer} /></StyledHeading>
      </TextContainer>
      {(data?.enableRating && !isMobile)&& <RatingBox>
        <Rating number={reviews?.ratingAll} color={theme.colors.gold} />
        {reviews?.ratingAll && (
          <RatingText> Rated <b>{reviews?.rating.toFixed(1)}</b> by <strong>{reviews?.total}</strong> Happy Sleepers</RatingText>
        )}
      </RatingBox>}
      {isMobile && <RenderReviews reviews={reviews}/>}
      <SliderContainer>
        <NewTestimonialSlider
          list={data.list}
          setCurrent={setCurrentSlide}
          ref={sliderRef}
        />
      </SliderContainer>
    </StyledWrap>
  );
};

export default NewTestimonialList;