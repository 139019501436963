import React, { useEffect, useState } from 'react';
import Ribbon from 'src/components/Ribbon';
import { Product } from 'src/interfaces/sanity';
import { useTheme } from 'styled-components';
import { encode, decode } from 'shopify-gid';
import {
  client,
  useAddItemToCart,
  useRemoveItemFromCart,
  useCart,
} from 'src/context/siteContext';
import tryGlobal from 'src/lambda/tryGlobal';
import Icon from 'src/components/Common/Icon';
import {
  ListContainer,
  Container,
  StyledImage,
  InfoContainer,
  InfoWrap,
  Title,
  PriceContainer,
  Price,
  ComparePrice,
  PopUpDiscount,
  StyledButton,
  RemoveIcon,
  RatingsBox,
  StyledRating,
  RatingParagraph,
  ButtonContainer,
  SelectWrapper,
  VariantSelect,
  AdditionalText,
  ChooseOffer,
  ImageWrapper,
} from './styles';

import {
  Quantity,
  QuantityButton,
  NumberInput,
} from '../../ProductForm/styles';

interface Props {
  productInfo: {
    product: Product;
    price: number;
    compareAtPrice;
    compareAtPriceV2?: number;
    discount?: number;
    type: 'add' | 'upgrade';
    buttonText?: string;
    activeButtonText?: string;
    additionalText?: string;
    buttonColor:
      | { title: 'geraldine'; value: '#FA8E8A' }
      | { title: 'blue'; value: '#4f6ea8' };
  };
  boughtItem: {
    id: string;
    title: string;
  };
  review: {
    id: string;
    rating: number;
    total: number;
  };
  onClose?: any;
}

const UpsellListItem = ({
  productInfo,
  boughtItem,
  review,
  onClose,
}: Props) => {
  const {
    product,
    price,
    compareAtPriceV2,
    discount,
    type,
    buttonText,
    activeButtonText,
    buttonColor,
    additionalText,
  } = productInfo;
  const theme = useTheme();
  const { addItemToCart } = useAddItemToCart();
  const removeFromCart = useRemoveItemFromCart();
  const { checkout: lineItems } = useCart();
  const [adding, setAdding] = useState<boolean>(false);
  const [isAdded, setAdded] = useState<boolean>(false);
  const [removing, setRemoving] = useState<boolean>(false);
  const [check, setCheck] = useState<boolean>(true);
  const [available, setAvailable] = useState<boolean>(false);
  const [variants, setVariants] = useState([]);
  const [activeVariantId, setActiveVariantId] = useState<string>('');
  const [qty, setQty] = useState(1);

  const getButtonTitle = () => {
    if (removing) return 'REMOVING...';

    switch (type) {
      case 'add':
        if (isAdded) return activeButtonText ? activeButtonText : 'ADDED';

        if (adding) return 'ADDING...';

        return buttonText ? buttonText : '+ ADD';

      case 'upgrade':
        if (isAdded) return activeButtonText ? activeButtonText : 'UPGRADED';

        if (adding) return 'UPGRADING...';

        return buttonText ? buttonText : '↑ UPGRADE';

      default:
        return '';
    }
  };

  const handleClick = () => {
    if (isAdded) return;

    dataLayer?.push({
      event: 'acceptOffer',
      ecommerce: {
        currencyCode: process.env.GATSBY_CURRENCY_SYMBOL == '$' ? 'USD' : 'GBP',
        detail: {
          products: [
            {
              name: product?.content?.main?.title,
              id: product?.content?.shopify?.productId,
              price: parseFloat(price),
              brand: 'SleepEasy',
              variant: product?.content?.shopify?.defaultVariant?.title,
              category: 'Pillow',
            },
          ],
        },
      },
    });

    tryGlobal('fbq', 'track', 'AcceptOffer', {
      content_name: product?.content?.main?.title,
      content_ids: [product?.content?.shopify?.productId],
      content_type: 'upsell_offer',
      value: price,
      currency: process.env.GATSBY_CURRENCY_SYMBOL == '$' ? 'USD' : 'GBP',
    });

    switch (type) {
      case 'add':
        handleAddSubmit();
        break;

      case 'upgrade':
        handleUpgradeSubmit();
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (check) {
      const shopifyId = encode('Product', product._id, {
        accessToken: process.env.GATSBY_SHOPIFY_STOREFRONT_TOKEN,
      });

      client.product.fetch(shopifyId).then((product: any) => {
        const decodedVariants = [] as any;
        if (!product) {
          setAvailable(false);
          setCheck(false);
          return;
        }

        product.variants.forEach((variant: any) => {
          decodedVariants.push({
            ...variant,
            cleanId: parseInt((variant.id).id, 0),
          });
        });

        setVariants(decodedVariants);
        setActiveVariantId(decodedVariants[0].id as string);
        setAvailable(decodedVariants[0].available);

        setCheck(false);
      });
    }
  }, [check]);

  const handleUpgradeSubmit = async () => {
    setAdding(true);
    const attributes = [];
    const quantity = 1;

    if (available) {
      const boughtCartItem = lineItems.find(
        (item) => boughtItem.id == (item.variant.product.id).id
      );

      if (boughtCartItem) await removeFromCart(boughtCartItem.id);

      await addItemToCart(activeVariantId, quantity, attributes, true);

      setAdding(false);
      setAdded(true);
      onClose();
    }
  };

  const handleAddSubmit = async () => {
    setAdding(true);
    const attributes = [];

    if (available) {
      await addItemToCart(activeVariantId, qty, attributes, true);
      setAdding(false);
      setAdded(true);
      onClose();
    }
  };

  const handleRemove = async () => {
    setRemoving(true);

    const cartItem = lineItems.find(
      (item) => product._id == (item.variant.product.id).id
    );

    if (cartItem) await removeFromCart(cartItem.id);
    setRemoving(false);
    setAdded(false);
  };

  const handleVariantChange = (e: React.FormEvent) => {
    setActiveVariantId(e.target.value);
  };

  return (
    <ListContainer>
      <ButtonContainer>
        <Container>
          <ImageWrapper>
            <StyledImage imageRef={product.content?.main.mainImage?.image} />
          </ImageWrapper>
          <InfoContainer>
            <InfoWrap>
              <div>
                <Title>{product.content?.main?.title}</Title>
                {additionalText && (
                  <AdditionalText>{additionalText}</AdditionalText>
                )}
              </div>
              {discount && (
                <PopUpDiscount className="popUpDiscount">
                  {discount}% OFF
                </PopUpDiscount>
              )}
            </InfoWrap>
            {review?.total > 0 && (
              <RatingsBox>
                <StyledRating number={5} color={theme.colors.yellow} />
                <RatingParagraph color="font_black">
                  <span>{review?.rating && review.rating.toFixed(1)}</span>
                  {`${review.total} customer reviews`}
                </RatingParagraph>
              </RatingsBox>
            )}
            {/* {price.toFixed(2) && <Ribbon left>{process.env.GATSBY_CURRENCY_SYMBOL + price.toFixed(2) + ' PER ITEM'}</Ribbon>} */}
            {variants.length > 1 && (
              <SelectWrapper>
                <VariantSelect onChange={handleVariantChange}>
                  {variants.map(
                    ({
                      id,
                      title,
                      available,
                    }: {
                      id: string;
                      title: string;
                      available: boolean;
                    }) => (
                      <option disabled={!available} key={id} value={id}>
                        {title}
                      </option>
                    )
                  )}
                </VariantSelect>
              </SelectWrapper>
            )}

            <ChooseOffer>
              <Quantity>
                <QuantityButton
                  type="button"
                  onClick={() => qty !== 1 && setQty(qty - 1)}
                >
                  <Icon name="minus" />
                </QuantityButton>

                <NumberInput
                  type="number"
                  value={qty}
                  onChange={(e) => setQty(parseInt(e.currentTarget.value, 10))}
                  name="quantity"
                  readOnly={true}
                  min="1"
                  max="99"
                />

                <QuantityButton
                  type="button"
                  onClick={() => qty !== 4 && setQty(qty + 1)}
                >
                  <Icon name="plus" />
                </QuantityButton>
              </Quantity>
            </ChooseOffer>
            <PriceContainer>
              <Price>
                {process.env.GATSBY_CURRENCY_SYMBOL +
                  (Number(price) * qty).toFixed(2)}{' '}
                {compareAtPriceV2 && (
                  <ComparePrice>
                    {process.env.GATSBY_CURRENCY_SYMBOL +
                      (parseFloat(compareAtPriceV2?.amount) * qty).toFixed(2)}
                  </ComparePrice>
                )}
              </Price>
              <StyledButton onClick={onClose} color={'lightgrey'}>
                No Thanks
              </StyledButton>
              <StyledButton onClick={handleClick} color={buttonColor?.value}>
                {getButtonTitle()}
              </StyledButton>
            </PriceContainer>
          </InfoContainer>
        </Container>
      </ButtonContainer>
      {isAdded && (
        <RemoveIcon
          name="close"
          color={theme.colors.font_black}
          onClick={handleRemove}
        />
      )}
    </ListContainer>
  );
};

export default UpsellListItem;
