import styled from "styled-components";
import Slider from "react-slick";
import ArrowIcon from "src/images/arrow-left-white.svg";
import { Paragraph, SectionHeading } from "../Text";

interface SliderProps {
  isMobile?: boolean;
}

export const VideoRowContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5rem 0;

  @media ${({ theme }) => theme.device.phone} {
    padding: 0rem;
  }
`;

export const VideoRowHeading = styled(SectionHeading)`
  margin: 2rem 0;
  font-family: 'Effra-Regular';
  font-size: 44px;
  font-style: normal;
  font-weight: 400;
  line-height: 53px;
  text-align: left;
  color: white;

  @media ${({ theme }) => theme.device.phone} {
    line-height: 28px;
    font-size: 28px;
  } 
`;

export const VideoRowSubHeading = styled(Paragraph)`
  white-space: initial;
  font-family: 'Effra-Regular';
  font-size: 18px;
  font-weight: 300;
  line-height: 22px;
  text-align: left;
  color: white;
  max-width: 598px;
  margin-bottom: 4rem;
  @media ${({ theme }) => theme.device.phone} {
    line-height: 16.5px;
    font-size: 15px;
  }
`;

export const VideoRow = styled.div`
  display: flex;
  flex-direction: row;

  video {
    width: 90%;
  }

  @media ${({ theme }) => theme.device.phone} {
    display: none;
  }
`;

export const VideoSlider = styled(Slider)<SliderProps>`
  display: none;

  @media ${({ theme }) => theme.device.phone} {
    display: ${({ isMobile }) => isMobile ? 'block': 'none' };
  }
  
  margin-bottom: 4rem;

  .slick-arrow {
    position: absolute;
    margin-top: 33%;
    background-image: url(${ArrowIcon});
    z-index: 1;
  }

  .slick-arrow::before {
    font-size: 4rem;
  }

  .slick-next {
    margin-right: 32%;
  }

  .slick-next::before,
  .slick-prev::before {
    content: "";
  }

  .slick-prev {
    margin-top: 35%;
    margin-left: 30%;
    transform: translateY(-1.6rem) rotate(180deg);
  }

  .slick-dots li button::before {
    color: white;
    font-size: 25px;
  }
`;
