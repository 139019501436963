import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { Title, ButtonsContainer, CloseIcon, ConfirmButton, ExitButton, ModalBody, ModalHeader, ImageContainer } from "./style";
import Image from 'src/components/Common/ImageFluid';

interface Props {
	data: {
		title: string,
		flag: {
			asset: string,
			alt: string
		},
		link: string
	}
}

const RedirectModal = ({ data }: Props) => {
	const [isOpen, setIsOpen] = useState(false);
	const isUK = process.env.GATSBY_SANITY_DATASET === "production";

	useEffect(() => {
		if (window?.openRedirectModal &&
			window.localStorage.getItem("redirect") !== "rejected")
			setIsOpen(true);
	}, []);

	const doNotRedirect = () => {
		window.localStorage.setItem("redirect", "rejected");
		setIsOpen(false);
	}

	const redirectTo = () => {
		window.localStorage.setItem("redirect", "accepted");
		window.location.href = isUK ?
			window.location.href.replace(".co.uk", ".com") : 
			window.location.href.replace(".com", ".co.uk")
	}

	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={doNotRedirect}
			contentLabel={`redirect modal`}
			className="redirect-modal"
			overlayClassName="redirect-modal-overlay">
			<ModalHeader>
				<CloseIcon
					name="close"
					onClick={doNotRedirect}
					color="black"
				/>
			</ModalHeader>
			<ModalBody>
				<Title>{data.title}</Title>
				<ImageContainer>
					<Image imageRef={data?.flag} alt={data?.flag?.alt} />
				</ImageContainer>
				<ButtonsContainer>
					<ExitButton onClick={doNotRedirect}>No thanks</ExitButton>
					<ConfirmButton onClick={() => redirectTo(data.link)}>Yes</ConfirmButton>
				</ButtonsContainer>
			</ModalBody>
		</ Modal >
	);
};

export default RedirectModal;
