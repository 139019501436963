import React, { useEffect, useState } from "react";
import { SanityImage } from "src/interfaces/sanity";
import { StyledSvg } from "../Common/ImageFluid/styles";
import { size } from "src/components/Carousel";
import {
    StyledContainer,
    ContainerLinkSlider,
    StyledImg,
    ImageContainer,
    CarouselContainer,
    StyledSlider,
    Content,
    UpperTextContainer,
    Title,
    Description,
    ProductTitle,
    ProductPrice,
    OkendoReviews,
    StyledButton,
    StyledProduct,
    StyledProductRating,
    MainImageContainer,
    NewProductMessage,
    RatingsBox,
    RatingParagraph,
    StyledRating,
    NewProductMessageContainer,
    BtnBox,
    ComparePrice, 
    Price, 
    ProductSubtitle
} from "./style";
import trackKlavyioAddedToCart from 'src/utils/mapKlaviyoPayload';
import { eventData } from 'src/utils/dataLayer';
import { useTheme } from "styled-components";
import locales from 'src/locales';
import {
    client,
    useAddItemToCart,
    useUpsells,
    useCartDrawer,
    useStore,
    usePillowcaseMaterialDrawer,
    useCheckout,
    addToCartRedirectToCheckout,
    useCart,
} from 'src/context/siteContext';

interface Props {
    data: {
        title?: string;
        description?: string;
        products?: [
            {
                image: SanityImage;
                product: any;
            }
        ];
    }
    onClick?: () => void;
}

export const BestSellingProductsSlider = ({ data, onClick }: Props) => {
    const { title, description, products } = data
    const { checkout } = useCart();
    const theme = useTheme();

    const settings = {
        dots: false,
        arrows: true,
        speed: 500,
        infinite: false,
        adaptiveHeight: true,
        slidesToScroll: 1,
        slidesToShow: 4,
        responsive: [
            {
                breakpoint: 1267,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 885,
                settings: {
                    slidesToShow: 2,

                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    dots: true,

                }
            },
            {
                breakpoint: 655,
                settings: {
                    slidesToShow: 1,
                    dots: true,
                }
            },
        ]
    };

    const symbol = process.env.GATSBY_CURRENCY_SYMBOL;

    return (
        <StyledContainer>
            <UpperTextContainer>
                <Title>{title}</Title>
                <Description>{description}</Description>
            </UpperTextContainer>
            <CarouselContainer id="bestSelling">
                {products && (
                    <StyledSlider {...settings}>
                        {products.map((prod, i) => {

                            const price = prod?.product?.content?.shopify?.defaultPrice;

                            const shopifyProductId = prod?.product?.content?.shopify?.productId;
                            let message = locales.productInfo.new_product_message;
                            if (shopifyProductId == 6852326654038 || shopifyProductId == 6852243685462) {
                                message = locales.productInfo.moreStock;
                            }        

                            return (
                                <ContainerLinkSlider
                                    to={`/products/${prod.product.content.main.slug.current}`}
                                    className="best-selling-container"
                                >
                                    <MainImageContainer>
                                        <StyledImg imageRef={prod.image} alt={prod?.image?.alt}/>
                                    </MainImageContainer>
                                    <Content>
                                        <ProductTitle className="product-title">{prod.product.content.main.title}</ProductTitle>
                                        {
                                            prod.product.content.main.newProductMessage
                                                ? <NewProductMessageContainer className="out-of-stock"><NewProductMessage className="out-of-stock-message">{message}</NewProductMessage></NewProductMessageContainer>
                                                : <RatingsBox id="rating-box">
                                                    <StyledRating number={prod?.product?.content?.main?.okendoReviews?.rating} color={theme.colors.yellow} />
                                                    <RatingParagraph>
                                                        <span>{prod?.product?.content?.main?.okendoReviews?.totalReviews} Reviews</span>
                                                    </RatingParagraph>
                                                </RatingsBox>
                                        }
                                        <ProductSubtitle className="product-subtitle">
                                            {prod?.description}
                                        </ProductSubtitle>

                                        <ProductPrice id="productPrice">{symbol + price}</ProductPrice>
                                        <StyledProduct
                                            id={prod.product.content.shopify?.productId}
                                            key={prod.product._id}
                                            addToCart={true}
                                            price={prod?.product?.content?.shopify?.defaultPrice}
                                            title={prod?.product?.content?.main?.title}
                                            productQuantity={prod?.product?.content?.main?.productQuantity?.[0]}
                                        />
                                    </Content>
                                </ContainerLinkSlider>
                            )
                        })}
                    </StyledSlider>)}
            </CarouselContainer>

            {data?.button?.enabled &&
                <BtnBox>
                    <StyledButton to={data?.button?.link}>
                        <span className="label">
                            {data?.button?.text}
                        </span>
                    </StyledButton>
                </BtnBox>}
        </StyledContainer>
    )
}