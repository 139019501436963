import React from 'react';
import { buildFileUrl, getFile } from '@sanity/asset-utils';
import { SanityImage, SanityColor } from 'src/interfaces/sanity';
import VideoArrow from 'src/images/videoarrow.svg';
import { useTheme } from 'styled-components';
import BlockContent from '@sanity/block-content-to-react';
import { Serializer } from 'src/utils/serializer';

const sanityConfig = () => ({
  projectId: process.env.GATSBY_SANITY_PROJECT_ID,
  dataset: process.env.GATSBY_SANITY_DATASET,
});

const getFilePath = (node) => {
  if (!node) return;

  const config = sanityConfig();
  return buildFileUrl(getFile(node, config).asset, config);
};

import { Row } from '../../interfaces/sanity';
import {
  StyledRow,
  StyledImage,
  TextBlock,
  StyledHeading,
  StyledParagraph,
  StyledLink,
  BgContainer,
  StyledWrap,
  SectionWrap,
  RatingsList,
  StyledButton,
  StyledList,
  ReviewerName,
  VideoStyle,
  ReviewerNameStars,
  MobileVersion,
  TextContainerMobile,
  StyledRating,
  DesktopVersion,
  ContentImage,
  NewStyledRow,
  NewTextBlock,
  NewStyledHeading,
  NewStyledParagraph,
  CustomStyledImage,
  ImageInfoWrapper,
  ImageInfo,
  ImageArrow,
  NewTextContainerMobile,
  ImageArrowMobile,
  ImageInfoMobile,
  StyledThumbnail,
  ImageTextContainer,
  StyledTextContainer,
  ImageTextContainerMobile,
  StyledThumbnailMobile,
  TopIconList,
  StyledTextContainerMobile,
  StyledButtonSecond,
  RatingBox,
  RatingText,
  StyledImageSecond,
  RatingBoxSecond,
  StyledAboveButtonText,
  FirstAdditionalImage,
  SecondAdditionalImage,
  ImagesBox,
  MobileView,
  StyledReviewsAbove,
  RatingParagraph
} from './styles';
import arrow1 from 'src/images/arrow-1.svg';
import arrow2 from 'src/images/arrow-2.svg';
import arrow3 from 'src/images/arrow-3.svg';
import arrow2down from 'src/images/arrow-2-down.svg';
import arrow3down from 'src/images/arrow-3-down.svg';
import Rating from "src/components/Rating";
import ArrowRight from 'src/images/rightArrow.svg';
import { StyledReviews } from '../HeroSection/styles';
import locale from 'src/locales';
import PaymentIcons from 'src/components/PaymentIcons';

interface Props {
  data: {
    main: {
      rows: Row[];
      textAboveButton: Object[];
      disableMarginBottom?: boolean;
      disableMarginTop?: boolean;
      hideImgMobile?: boolean;
      fullWidthSection?: boolean;
      linearbackground?: boolean;
      showHideArrow: boolean;
      iconListTop: any;
      videoCorner: boolean;
      imageCorner: boolean;
      ratingNumber: number;
      reviewerName: string;
      button?: {
        enabled: boolean;
        link: string;
        text: string;
      };
      enableReviews?: {
        enabled: boolean;
        productShopify: any;
      }
      paymentIcons?: boolean;
    };
    sectionSettings: {
      backgroundColor: SanityColor;
      backgroundImage: SanityImage;
    };
    experiment: {
      variantA?: boolean;
      variantB?: boolean;
    };
  };
  context: any;
  isSection?: boolean;
}

const ImageRowSection = ({ data, context }: Props) => {
  const theme = useTheme();
  const {
    textAboveButton,
    rows,
    disableMarginBottom,
    disableMarginTop,
    hideImgMobile,
    fullWidthSection,
    linearbackground,
  } = data?.main;

  return (
    <BgContainer id="uspSectionImageRow" className={data?.experiment?.variantB ? 'variant_B' : data?.experiment?.variantA ? 'variant_A' : ''}>
      <StyledWrap noPadding={true} hideImgMobile={hideImgMobile} fullWidthSection={fullWidthSection}>
        {rows?.map((row: Row) => {
          const {
            _key,
            _type,
            image,
            video,
            imagePosition,
            heading,
            customHeading,
            showHideArrow,
            ratingNumber,
            reviewerName,
            videoCorner,
            imageCorner,
            headlineReviewAboveImage,
            fullWidthSection,
            text,
            textAboveButton,
            iconListTop,
            numberOfcolumns,
            listItems,
            listOptions,
            linkEnabled,
            linkTitle,
            linkUrl,
            linkColor,
            buttonInsteadLink,
            linearbackground,
            arrowsWhite,
            button,
            enableReviews,
            okendoReviews,
            paymentIcons,
            videoShopify,
          } = row;

          const renderButton = (
            <StyledButtonSecond to={button?.link}>{button?.text}<img src={ArrowRight} alt="ArrowRight" /></StyledButtonSecond>
          );
        
        
          const renderReviews = (
            <RatingBox>
              <StyledRating fieldName="imageRowSection" number={okendoReviews?.rating} color={theme.colors.yellow} />
              <RatingParagraph>
                <span>Rated {okendoReviews?.rating} out of 5 by {okendoReviews?.totalReviews} happy customers</span>
              </RatingParagraph>
            </RatingBox>
          )

          const renderReviewsMobile = (
            <RatingBox>
              <StyledRating fieldName="imageRowSectionMobile" number={okendoReviews?.rating} color={theme.colors.yellow} />
              <RatingParagraph>
                <span>Rated {okendoReviews?.rating} out of 5 by {okendoReviews?.totalReviews} happy customers</span>
              </RatingParagraph>
            </RatingBox>
          )
        
          if (!image?.asset._ref && !video?.asset._ref) return; // Gatsby Build fix

          return (
            <>
              <DesktopVersion fullWidthSection={fullWidthSection}>
                {_type === 'uspSection' ? (
                  <NewStyledRow key={_key} imagePosition={imagePosition} fullWidthSection={fullWidthSection}>
                    <NewTextBlock
                      imagePosition={imagePosition}
                      hideImgMobile={hideImgMobile}
                      fullWidthSection={fullWidthSection}
                    >
                      <NewStyledHeading>
                        <BlockContent
                          blocks={customHeading}
                          serializers={Serializer}
                        />
                      </NewStyledHeading>
                      <NewStyledParagraph>{text}</NewStyledParagraph>
                    </NewTextBlock>
                    {image && (
                      <ImageInfoWrapper>
                        {listItems.length > 0 &&
                          listItems.map((item: any, idx: number) => (
                            <ImageInfo
                              key={idx}
                              itemNumber={idx + 1}
                              underlined={item.underlined}
                            >
                              <ImageArrow
                                itemNumber={idx + 1}
                                src={
                                  idx === 0
                                    ? arrow1
                                    : idx === 1
                                      ? arrow2
                                      : arrow3
                                }
                              />
                              <ImageTextContainer>
                                <StyledThumbnail imageRef={item?.thumbnail} alt={item?.alt}/>
                                <StyledTextContainer>
                                  <h5>{item.title}</h5>
                                  <span>{item.description}</span>
                                </StyledTextContainer>
                              </ImageTextContainer>

                            </ImageInfo>
                          ))}
                        <CustomStyledImage
                          imageCorner={imageCorner}
                          imageRef={row?.image}
                          hideMobile={hideImgMobile}
                          alt={item?.alt}
                        />
                      </ImageInfoWrapper>
                    )}
                  </NewStyledRow>
                ) : (
                  <StyledRow key={_key} imagePosition={imagePosition} fullWidthSection={fullWidthSection}>
                    <FirstAdditionalImage imageRef={row?.firstAdditionalImage}  alt={row?.firstAdditionalImage?.alt}/>
                    <SecondAdditionalImage imageRef={row?.secondAdditionalImage} alt={row?.secondAdditionalImage?.alt}/>
                    {image ? (
                      <StyledImage
                        imageCorner={imageCorner}
                        imageRef={row?.image}
                        hideMobile={hideImgMobile}
                        fullWidthSection={fullWidthSection}
                        linearbackground={linearbackground}
                        alt={row?.image?.alt}
                      />
                    ) : (
                      <VideoStyle
                        videoCorner={videoCorner}
                        src={
                          videoShopify
                            ? videoShopify
                            : getFilePath(row?.video?.asset._ref)
                        }
                        autoPlay={true}
                        playsInline={true}
                        controls={true}
                        muted={true}
                      />
                    )}

                    <TextBlock
                      imagePosition={imagePosition}
                      hideImgMobile={hideImgMobile}
                      fullWidthSection={fullWidthSection}
                    >

                      {!enableReviews?.enabled && enableReviews?.productShopify != undefined && renderReviews}
                      <StyledHeading fullWidthSection={fullWidthSection}>{heading}</StyledHeading>
                      <StyledParagraph fullWidthSection={fullWidthSection}>{text}</StyledParagraph>

                      {iconListTop && (
                        <TopIconList
                          direction={iconListTop.direction}
                          list={iconListTop.list}
                          bigIcons={iconListTop.bigIcons}
                          numberOfcolumns={numberOfcolumns}
                        />
                      )}

                      <SectionWrap numberOfcolumns={numberOfcolumns}>
                        {enableReviews?.enabled && enableReviews?.productShopify != undefined && renderReviews}
                        <StyledAboveButtonText blocks={textAboveButton} />
                        {button?.enabled && renderButton}
                        {paymentIcons && <PaymentIcons />}
                      </SectionWrap>

                      {ratingNumber && (
                        <StyledRating
                          number={ratingNumber}
                          color={theme.colors.reviews_yellow}
                        />
                      )}

                      {reviewerName && (
                        <ReviewerName>
                          <p>{reviewerName}</p>
                        </ReviewerName>
                      )}

                      {showHideArrow && (
                        <div className="videoArrow">
                          <img src={VideoArrow} alt="video" />
                        </div>
                      )}

                      {listItems && (
                        <StyledList
                          arrowsWhite={arrowsWhite}
                          items={listItems}
                          options={listOptions}
                        />
                      )}

                      {linkEnabled &&
                        (buttonInsteadLink ? (
                          <StyledButton to={linkUrl}>{linkTitle}</StyledButton>
                        ) : (
                          <StyledLink
                            to={linkUrl}
                            color={linkColor ? linkColor.title : 'font_black'}
                          >
                            {linkTitle}
                          </StyledLink>
                        ))}
                    </TextBlock>
                  </StyledRow>
                )}
              </DesktopVersion>

              <MobileVersion>
                {_type === 'uspSection' ? (
                  <NewStyledRow key={_key} imagePosition={imagePosition} id="uspSectionImageRowMobile">
                    <NewTextContainerMobile>
                      <NewTextBlock
                        imagePosition={imagePosition}
                        hideImgMobile={hideImgMobile}
                        fullWidthSection={fullWidthSection}
                      >
                        <NewStyledHeading>
                          <BlockContent
                            blocks={customHeading}
                            serializers={Serializer}
                          />
                        </NewStyledHeading>
                        <NewStyledParagraph>{text}</NewStyledParagraph>
                      </NewTextBlock>
                    </NewTextContainerMobile>
                    {image && (
                      <ImageInfoWrapper>
                        {listItems.length > 0 &&
                          listItems.map((item: any, idx: number) => (
                            <ImageInfoMobile
                              key={idx}
                              itemNumber={idx + 1}
                              underlined={item.underlined}
                            >
                              <ImageArrowMobile
                                itemNumber={idx + 1}
                                src={
                                  idx === 0
                                    ? arrow1
                                    : idx === 1
                                      ? arrow2down
                                      : arrow3down
                                }
                              />
                              <ImageTextContainerMobile itemNumber={idx + 1}>
                                <StyledThumbnailMobile
                                  itemNumber={idx + 1}
                                  imageRef={item?.thumbnail} 
                                  alt={item?.alt}/>
                                <StyledTextContainerMobile>
                                  <h5>{item?.title}</h5>
                                  <span>{item?.description}</span>
                                </StyledTextContainerMobile>
                              </ImageTextContainerMobile>
                            </ImageInfoMobile>
                          ))}
                        <CustomStyledImage
                          imageCorner={imageCorner}
                          imageRef={row?.image}
                          hideMobile={hideImgMobile}
                          alt={row?.image?.alt}
                        />
                      </ImageInfoWrapper>
                    )}
                  </NewStyledRow>
                ) : (
                  <StyledRow key={_key} imagePosition={imagePosition} fullWidthSection={fullWidthSection}>
                    <TextContainerMobile>
                      {!headlineReviewAboveImage &&
                        <>
                          {!enableReviews?.enabled && enableReviews?.productShopify != undefined && renderReviewsMobile}
                          <StyledHeading numberOfcolumns={numberOfcolumns} fullWidthSection={fullWidthSection}>{heading}</StyledHeading></>}
                      <StyledParagraph fullWidthSection={fullWidthSection}>{text}</StyledParagraph>

                      {iconListTop && (
                        <TopIconList
                          direction={iconListTop.direction}
                          list={iconListTop.list}
                          bigIcons={iconListTop.bigIcons}
                          numberOfcolumns={numberOfcolumns}
                        />
                      )}

                      <SectionWrap numberOfcolumns={numberOfcolumns}>
                        {enableReviews?.enabled && enableReviews?.productShopify != undefined && renderReviewsMobile}
                        <StyledAboveButtonText blocks={textAboveButton} />
                        {button?.enabled && renderButton}
                        {paymentIcons && <PaymentIcons />}
                      </SectionWrap>


                      {listItems && (
                        <StyledList
                          arrowsWhite={arrowsWhite}
                          items={listItems}
                          options={listOptions}
                        />
                      )}
                    </TextContainerMobile>

                    {image ? (
                      <ImagesBox>
                        <StyledImage
                          fullWidthSection={fullWidthSection}
                          imageCorner={imageCorner}
                          imageRef={row?.image}
                          hideMobile={hideImgMobile}
                          numberOfcolumns={numberOfcolumns}
                          linearbackground={linearbackground}
                          alt={row?.image?.alt}
                        />
                        <FirstAdditionalImage imageRef={row?.firstAdditionalImage} alt={row?.firstAdditionalImage?.alt}/>
                        <SecondAdditionalImage imageRef={row?.secondAdditionalImage} alt={row?.secondAdditionalImage?.alt}/>
                      </ImagesBox>
                    ) : (
                      <VideoStyle
                        videoCorner={videoCorner}
                        src={
                          videoShopify
                            ? videoShopify
                            : getFilePath(row?.video?.asset._ref)
                        }
                        autoPlay={true}
                        playsInline={true}
                        controls={true}
                        muted={true}
                      />
                    )}

                    <TextBlock
                      style={reviewerName && ratingNumber ? { display: "block" } : { display: "none" }}
                      imagePosition={imagePosition}
                      hideImgMobile={hideImgMobile}
                      fullWidthSection={fullWidthSection}
                    >
                      <ReviewerNameStars>
                        {ratingNumber && (
                          <StyledRating
                            number={ratingNumber}
                            color={theme.colors.reviews_yellow}
                          />
                        )}

                        {reviewerName && (
                          <ReviewerName>
                            <p>{reviewerName}</p>
                          </ReviewerName>
                        )}
                      </ReviewerNameStars>

                      {showHideArrow && (
                        <div className="videoArrow">
                          <img src={VideoArrow} alt="video-arrow"/>
                        </div>
                      )}

                      {linkEnabled &&
                        (buttonInsteadLink ? (
                          <StyledButton to={linkUrl}>{linkTitle}</StyledButton>
                        ) : (
                          <StyledLink
                            to={linkUrl}
                            color={linkColor ? linkColor.title : 'font_black'}
                          >
                            {linkTitle}
                          </StyledLink>
                        ))}
                    </TextBlock>

                    {headlineReviewAboveImage &&
                      <MobileView numberOfcolumns={numberOfcolumns}>
                        {!enableReviews?.enabled && enableReviews?.productShopify != undefined && renderReviewsMobile}
                        <StyledHeading numberOfcolumns={numberOfcolumns} fullWidthSection={fullWidthSection}>{heading}</StyledHeading></MobileView>}
                  </StyledRow>
                )}
              </MobileVersion>
            </>
          );
        })}
      </StyledWrap>
    </BgContainer >
  );
};

export default ImageRowSection;
