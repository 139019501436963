import React, { FC } from "react";
import { StyledButton } from "./styles";

const BottomButton: FC = ({ children }) => (
  <StyledButton buttonType="outline" small={true} iconType="right">
    {children}
  </StyledButton>
);

export default BottomButton;
