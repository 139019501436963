import React from "react";
import {
  StyledWrap,
  Heading,
  IconList,
  IconText,
  IconImage,
  Icon,
  StyledButton,
  StyledImage,
  RatingBox,
  StyledRating,
  RatingParagraph,
  RatingText,
  IconTitle,
  RichText,
  SectionWrap,
  ImageContainer,
  StyledSlider,
} from "./styles";
import {
  SectionSettings,
  SanityImage,
  SanityColor,
} from "../../interfaces/sanity";
import Rating from "src/components/Rating";
import { useTheme } from "styled-components";
import ArrowRight from 'src/images/rightArrow.svg';
import locale from 'src/locales';
import PaymentIcons from "src/components/PaymentIcons";

interface Props {
  data: {
    heading?: {
      text: string;
      textColor: SanityColor;
    };
    button?: {
      enabled: boolean;
      link: string;
      text: string;
    };
    reviews?: {
      enabled: boolean;
      productReviews: any;
    };
    okendoReviews: {
      totalReviews: number;
      rating: number;
    },
    image?: {
      _type: "textIconImage";
      enabled: boolean;
      image: SanityImage;
    };
    paymentIcons?: boolean;
    icons?: {
      enabled: boolean;
      bigIcons: boolean;
      enableSlider: boolean;
      horizontal?: boolean;
      enableTitle?: boolean;
      mobileDir?: "row" | "column";
      list: [
        {
          _key: string;
          _type: "icon";
          image: SanityImage;
          text: string;
          enableTitle: boolean;
          changeableText: Object[];
        }
      ];
      text: string;
      minHeight?: number;
      textTop?: boolean;
    };
    experiment: {
      variantA?: boolean;
      variantB?: boolean;
    };
    sectionSettings: SectionSettings;
  };
  context: any;
  isSection?: boolean;
}

const TextIconSection = ({ data, context }: Props) => {
  const { icons, button, heading, image, paymentIcons, reviews, okendoReviews, minHeight, textTop, sectionSettings, experiment } = data;
  const theme = useTheme();

  const settings = {
    dots: true,
    arrows: false,
    speed: 500,
    infinite: false,
    slidesToScroll: 1,
    slidesToShow: 3,
    responsive: [
      {
          breakpoint: 480,
          settings: {
              slidesToShow: 1,
          }
      },
      {
        breakpoint: 768,
        settings: {
            slidesToShow: 2,
        }
      },
      {
        breakpoint: 992,
        settings: {
            slidesToShow: 3,
        }
      }
    ]
  }; 

  const renderIcons = (
    icons?.enableSlider ? 
     <IconList style={{paddingBottom: "2rem"}} enableTitle={icons?.enableTitle} mobileDir={icons?.mobileDir} horizontal={icons?.horizontal}>
        <StyledSlider {...settings}>
          {icons?.list.map((icon) => (
            <Icon key={icon._key} horizontal={icons?.horizontal}>
              {icons?.horizontal ? 
                <IconImage imageRef={icon.image} alt={icon?.image?.alt} big={icons.bigIcons} horizontal={icons?.horizontal} /> :   
                <ImageContainer  horizontal={icons?.horizontal} big={icons.bigIcons}><IconImage imageRef={icon.image} big={icons.bigIcons} horizontal={icons?.horizontal} alt={icon?.image?.alt}/></ImageContainer>
              }
              <IconTitle enableTitle={icon.enableTitle}>{icon.title}</IconTitle>
              <IconText style={{color: sectionSettings?.textColor?.value}} enableTitle={icon.enableTitle} horizontal={icons?.horizontal}>{icon.text}</IconText>
              <RichText enableTitle={icon.enableTitle} horizontal={icons?.horizontal} blocks={icon.changeableText}/>
            </Icon>
          ))}
        </StyledSlider>
      </IconList>
      :
      <IconList enableTitle={icons?.enableTitle} mobileDir={icons?.mobileDir} horizontal={icons?.horizontal}>
        {icons?.list.map((icon) => (
          <Icon key={icon._key} horizontal={icons?.horizontal}>
            {icons?.horizontal ? 
              <IconImage imageRef={icon.image} big={icons.bigIcons} horizontal={icons?.horizontal} alt={icon?.image?.alt}/> :   
              <ImageContainer  horizontal={icons?.horizontal} big={icons.bigIcons}><IconImage imageRef={icon.image} big={icons.bigIcons} horizontal={icons?.horizontal} alt={icon?.image?.alt}/></ImageContainer>
            }
            <IconTitle enableTitle={icon.enableTitle}>{icon.title}</IconTitle>
            <IconText style={{color: sectionSettings?.textColor?.value}} enableTitle={icon.enableTitle} horizontal={icons?.horizontal}>{icon.text}</IconText>
            <RichText enableTitle={icon.enableTitle} horizontal={icons?.horizontal} blocks={icon.changeableText}/>
          </Icon>
        ))}
      </IconList>
  )

  const renderButton = (
    <StyledButton to={button?.link}>{button?.text}<img src={ArrowRight} alt="ArrowRight" /></StyledButton>
  );

  const renderReviews = (
    <RatingBox id="rating-box">
          <StyledRating fieldName="textIconSection" number={okendoReviews?.rating} color={theme.colors.yellow} />
          <RatingParagraph>
            <span>Rated {okendoReviews?.rating} out of 5 by {okendoReviews?.totalReviews} happy customers</span>
          </RatingParagraph>
        </RatingBox>
  )

  return (
    <StyledWrap className={experiment?.variantA ? 'variant_A' : experiment?.variantB ? 'variant_B': ''}
      headingOnly={!button?.enabled && !icons?.enabled}
      minHeight={minHeight}
      textTop={textTop}
      horizontal={icons?.horizontal}
      enableTitle={icons?.enableTitle}
    >
      {heading && (
        <Heading
          color={heading?.textColor?.title}
          paddingBottom={image?.enabled}
        >
          {heading?.text}
        </Heading>
      )}
      {image?.enabled && <StyledImage imageRef={image.image} alt={image?.image?.alt}/>}
      {icons?.enabled && renderIcons}
      <SectionWrap enableReview = {reviews?.enabled}>
        {reviews?.enabled && renderReviews}
        {button?.enabled && renderButton}
        {paymentIcons && <PaymentIcons />}
      </SectionWrap>
    </StyledWrap>
  );
};

export default TextIconSection;
