import React from "react";
import Ribbon from "src/components/Ribbon";
import { SanityPack } from "src/interfaces/sanity";
import {
  Button,
  Title,
  Content,
  DiscountPrice,
  Price,
  PriceBlock,
  AdditionalContainer,
  AdditionalTitle,
} from "./styles";

interface Props extends SanityPack {
  onClick: () => void;
  active: boolean;
  className: string;
}

const Pack = ({
  content,
  discountPrice,
  price,
  quantity,
  ribbonText,
  title,
  active,
  additionalTitle,
  ...props
}: Props) => {
  return (
    <Button
      active={active}
      paddingTopSmall={additionalTitle && additionalTitle !== "" ? true : false}
      {...props}
    >
      {additionalTitle && additionalTitle !== "" && (
        <AdditionalContainer>
          <AdditionalTitle>{additionalTitle}</AdditionalTitle>
        </AdditionalContainer>
      )}
      <Ribbon>{ribbonText}</Ribbon>
      <Title>{title}</Title>
      <Content>{`${quantity} x ${content}`}</Content>
      <PriceBlock>
        <DiscountPrice>{discountPrice}</DiscountPrice>
        <Price>{price}</Price>
      </PriceBlock>
    </Button>
  );
};

export default Pack;
