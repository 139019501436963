import React from "react";
import ProductList from "../../components/ProductList";
import {
  StyledHeading,
  StyledWrap,
  StyledButton,
  StyledBlocks,
} from "./styles";
import { SectionSettings, Product } from "../../interfaces/sanity";

interface Props {
  data: {
    button: {
      enabled: boolean;
      text: string;
      link: string;
    };
    firstLarge?: boolean;
    buttonBackgroundColors?: object;
    buttonColors?: object;
    isCollection?: boolean;
    showAddToCart?: boolean;
    products: Product[];
    sectionSettings: SectionSettings;
    heading: any;
    showPrice?: boolean;
    showRating?: boolean;
  };
}

const ProductListSection = ({ data }: Props) => {
  const { button, heading, products, firstLarge = false, buttonBackgroundColors, buttonColors,  showAddToCart, showPrice = true, showRating = true, isCollection } = data;
  
  return (
    <StyledWrap>
      {heading && <StyledBlocks blocks={heading} />}
      <ProductList
        products={products}
        firstLarge={firstLarge}
        addToCart={showAddToCart}
        withDescription={true}
        showPrice={showPrice}
        showRating={showRating}
        isCollection={isCollection}
        buttonBackgroundColors={buttonBackgroundColors}
        buttonColors={buttonColors}
      />
      {button?.enabled && (
        <StyledButton
          buttonType={button.type ? button.type : "outline"}
          to={button.link}
        >
          {button.text}
        </StyledButton>
      )}
    </StyledWrap>
  );
};

export default ProductListSection;
