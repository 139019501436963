import React from "react";
import {
  StyledWrap,
  Heading,
  Description,
  AuthorBox,
  AuthorImage,
  AuthorPosition,
  AuthorName,
  TitleLogo,
} from "./styles";
import { SectionSettings, Product } from "../../interfaces/sanity";
import { SectionHeading, Paragraph } from "../../components/Text";
import Wrap from "../../components/Wrap";
import Rating from "src/components/Rating";

interface Props {
  data: {
    sectionSettings: SectionSettings;
  };
}

const EndorsementSection = ({ data }: Props) => {
  return (
    <StyledWrap>
      {data.title?.image ? (
        <TitleLogo imageRef={data.title?.image} />
      ) : (
        <Heading color="font_black">{data.title?.text}</Heading>
      )}

      <Description color="font_black">{data.description}</Description>
      {(data.author?.image || data.author?.name) && (
        <AuthorBox>
          <AuthorImage imageRef={data.author.image} width={77} height={77} />
          <AuthorName>{data.author.name}</AuthorName>
          <AuthorPosition>{data.author.position}</AuthorPosition>
        </AuthorBox>
      )}

      {/* <Author color="font_white">{data.author}</Author>
        <Rating number={data.ratingNumber} color={theme.colors.geraldine} />
        <StyledLink to={data.linkUrl} color="geraldine">
          {data.linkText}
        </StyledLink> */}
    </StyledWrap>
  );
};

export default EndorsementSection;
