import styled, { css } from 'styled-components';
import Image from '../../components/Common/ImageFluid';
import { SectionHeading, Paragraph } from '../../components/Text';
import Link from '../../components/Link';
import Wrap from '../../components/Wrap';
import Button from 'src/components/Common/Button';
import List from '../../components/List';
import Video from '../../components/Video';
import Rating from '../../components/Rating';


interface PositionProps {
  imagePosition: 'left' | 'right';
}


interface ImageProps {
  hideMobile?: boolean;
  imageCorner?: boolean;
}

interface BgProps {
  marginBottom?: boolean;
  marginTop?: boolean;
}

interface TextBlockProps {
  hideImgMobile: boolean;
  imagePosition: 'left' | 'right';
}

interface ImageInfoProps {
  itemNumber: number;
  underlined: boolean;
}


const rowPosStyles = (imagePosition: 'left' | 'right') => {
  switch (imagePosition) {
    case 'left':
      return css`
        flex-direction: row;
      `;
    case 'right':
      return css`
        flex-direction: row-reverse;
      `;
  }
};

const handlePosMargin = (imagePosition: 'left' | 'right') => {
  switch (imagePosition) {
    case 'left':
      return 'auto 0 0';
    case 'right':
      return '0';
    default:
      return 'row';
  }
};

const handlePosPadding = (imagePosition: 'left' | 'right') => {
  switch (imagePosition) {
    case 'left':
      return '10.3rem 10.3rem 0 0;';
    case 'right':
      return '10.3rem 0 0 10.3rem;';
    default:
      return '0 10.3rem 0 0;';
  }
};

export const StyledWrap = styled(Wrap)<PositionProps>`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media ${({ theme }) => theme.device.tablet} {
    ${({ imagePosition }) => rowPosStyles(imagePosition)}
    padding: 2rem 8.1rem;
  }

`;

export const BgContainer = styled.div<BgProps>`
  position: relative;
`;

export const StyledHeading = styled.div`
  margin: 2rem auto;
  width: 100%;
  display: block;

  h1 {
    font-style: normal;
    font-weight: 700;
    line-height: 1.2;
    font-size: 35px;
    font-family: 'Effra-Bold';

    text-align: left;
    @media ${({ theme }) => theme.device.tablet} {
    font-size: 58px;
    text-align: center;
    }
  }

  h2 {
    font-style: normal;
    font-weight: 600;
    line-height: 1.2;
    font-size: 32px;
    font-family: 'Effra-Bold';
    text-align: left;
    @media ${({ theme }) => theme.device.tablet} {
    font-size: 52px;
    text-align: center;
    }
  }

  h3 {
    font-style: normal;
    font-weight: 600;
    line-height: 1.2;
    font-size: 28px;
    font-family: 'Effra-Bold';
    text-align: left;
    @media ${({ theme }) => theme.device.tablet} {
    font-size: 44px;
    text-align: center;
    }
  }

  h4 {
    font-style: normal;
    font-weight: 600;
    line-height: 1.2;
    font-size: 28px;
    text-align: left;
    font-family: 'Effra-Bold';
    @media ${({ theme }) => theme.device.tablet} {
    font-size: 38px;
    text-align: center;
    }
  }

  h5 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.2;
    text-align: left;
    font-family: 'Effra-Medium';
    @media ${({ theme }) => theme.device.tablet} {
    font-size: 24px;
    text-align: center;
    }
  }

  h6 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.2;
    text-align: left;
    font-family: 'Effra-Medium';
    @media ${({ theme }) => theme.device.tablet} {
    font-size: 20px;
    text-align: center;
    }
  }

  p {
    padding: 10px 0;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 1.3;
    text-align: left;
    font-family: 'Effra-Regular';
    @media ${({ theme }) => theme.device.tablet} {
    font-size: 18px;
    text-align: center;
    }
  }

  strong {
    font-weight: 900;
    font-family: 'Effra-Bold';
  }

  @media ${({ theme }) => theme.device.tablet} { 
    width: 80%;
  }

`;

export const StyledBox = styled.div`
  background: #FDFDEE;
  border: 1px solid #566DA4;
  border-radius: 5px;
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: space-between;
`;


export const StyledDescription = styled.div`

strong {
  font-weight: 900;
  font-family: 'Effra-Bold';
}

p {
  padding: 10px 0;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 1.2;
  text-align: left;
  font-family: 'Effra-Regular';

  @media ${({ theme }) => theme.device.tablet} { 
    font-size: 18px;
  }
}

`;


export const StyledImageContainer = styled.div`
width: 35%;
max-height: 400px;

`;
export const StyledImage = styled(Image)`

img {
  object-fit: contain !important;
}

`;

export const StyledContent = styled.div`
  width: 66%;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media ${({ theme }) => theme.device.tablet} {  
    width: 60%;
    padding: 0 3rem;
  }
`;

export const StyledList = styled.ul``

export const StyledListItem = styled.li`
    list-style-type: none;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.2;
    display: flex;
    align-items: flex-start;
    font-family: 'Effra-Regular';

    strong {
      font-weight: 900;
      font-family: 'Effra-Bold';
    }

    @media ${({ theme }) => theme.device.tablet} {  
      font-size: 16px;
    }
    img {
      margin: 3px 10px 0 0;
    }
`