import styled, { css } from "styled-components";
import Image from "src/components/Common/ImageFluid";
import Wrap from "src/components/Wrap";
import { SectionHeading, Paragraph } from "src/components/Text";

interface PositionProps {
  position: "left" | "right";
}

const positionWrap = (position: "left" | "right") => {
  switch (position) {
    case "left":
      return css`
        flex-direction: row;
      `;

    case "right":
      return css`
        flex-direction: row-reverse;
      `;
  }
};

export const StyledWrap = styled(Wrap)<PositionProps>`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 5.5rem 2rem 5.5rem;

  @media ${({ theme }) => theme.device.tablet} {
    ${({ position }) => positionWrap(position)}
    padding: 5rem 8rem 5rem;
    justify-content: space-between;
  }
`;

export const StyledImage = styled(Image)`
  max-width: 70.625vw;
  margin: 0 auto 5rem auto;

  @media ${({ theme }) => theme.device.tablet} {
    margin: 0;
    width: 38.05vw;
    max-width: 54.8rem;
  }
`;

export const Title = styled(SectionHeading)`
  margin-bottom: 3.2rem;
  white-space: initial;

  @media ${({ theme }) => theme.device.tablet} {
    white-space: pre-line;
    font-size: 3.6rem;
    line-height: 4.8rem;
    margin-bottom: 5.6rem;
  }
`;

export const Description = styled(Paragraph)``;

export const TextBlock = styled.div<PositionProps>`
  @media ${({ theme }) => theme.device.tablet} {
    max-width: 57rem;
    width: 39.583vw;
    padding: 5rem 0 0;
  }
`;
