import styled from "styled-components";
import Wrap from "src/components/Wrap";
import Icon from "src/components/Common/Icon";
import Slider from "react-slick";
import ArrowIcon from "src/images/arrow-left.svg";

import { SectionHeading, Paragraph } from "src/components/Text";

interface WrapProps {
  slug: any;
}

export const StyledWrap = styled(Wrap)<WrapProps>`
  &.variant_A {
    display: none;
  }
  padding: 3.4rem 2rem 5rem;
  padding-bottom: ${({slug}) => slug == "easy-sleeper" ? "15rem" : "3.4rem"};
  @media ${({ theme }) => theme.device.tablet} {
    padding: 6.8rem 8.1rem;
    padding-bottom:  ${({slug}) => slug == "easy-sleeper" ? "17rem" : "6.8rem"};
  }
`;

export const StyledHeading = styled(SectionHeading)<WrapProps>`
  font-size: 25px;
  font-weight: 700;
  font-family: 'Effra-Bold';
  text-align:center;
  padding-right: ${({slug}) => slug == "easy-sleeper" ? "0rem" : "3rem"};
  @media ${({ theme }) => theme.device.tablet} {
    font-size: 40px;
    font-weight: 700;
  }
  @media ${({ theme }) => theme.device.phone} {
    flex: 2;
    justify-content: flex-start;
    display: flex;
  }
`;

export const StyledHeadingCount = styled(SectionHeading)<WrapProps>`
  font-size: 25px;
  font-weight: 700;
  font-family: 'Effra-Bold';
  text-align:center;
  display: ${({slug}) => slug == "easy-sleeper" ? "none" : "flex"};
  @media ${({ theme }) => theme.device.tablet} {
    font-size: 40px;
    font-weight: 700;
  }
`;

export const Description = styled(Paragraph)``;

export const TextContainer = styled.div<WrapProps>`
  display: flex;
  justify-content: ${({slug}) => slug == "easy-sleeper" ? "center" : "space-between"}; 
  align-items: center;
  margin-bottom: 3.4rem;
  padding: 0;
  

  @media ${({ theme }) => theme.device.tablet} {
    margin-bottom: 5.3rem;
    font-size: 40px;
    font-weight: 700;
    padding:  ${({slug}) => slug == "easy-sleeper" ? "0px 20rem" : "0rem"};
  }
`;

export const SlideCount = styled.span`
  color: ${({ theme }) => theme.colors.lighter_gray};
`;

export const SliderContainer = styled.div`
  position: relative;
`;

export const Arrow = styled(Icon)`
  position: absolute;
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  @media ${({ theme }) => theme.device.tablet} {
    width: 3.2rem;
    height: 3.2rem;
  }
`;