import styled, { css } from "styled-components";
import Wrap from "src/components/Wrap";
import Rating from "src/components/Rating";
import { Paragraph, SectionHeading } from "src/components/Text";
import Image from "src/components/Common/ImageFluid";
import Button from "src/components/Common/Button";
import BlockContent from "src/components/BlockContent";
import Slider from "react-slick";
import ArrowIcon from "src/images/main-arrow.svg";

interface WrapProps {
  headingOnly: boolean;
  minHeight: number;
  textTop: boolean;
  horizontal: boolean;
  enableTitle?: boolean;
}

interface ImageProps {
  big: boolean;
  horizontal: boolean;
}

interface ListProps {
  mobileDir?: "row" | "column";
  horizontal?: boolean;
  enableTitle?: boolean;
}

interface ParagraphProps {
  enableTitle?: boolean;
}

interface HeadingProps {
  paddingBottom: boolean;
}

interface WrapSectionProps {
  enableReview: boolean;
}

export const StyledWrap = styled(Wrap)<WrapProps>`
  &.variant_A {
    display: none
  }
  padding: ${({ headingOnly, textTop, horizontal }) =>
    headingOnly
      ? textTop
        ? "2.5rem 2rem 6.5rem"
        : "6.5rem 2rem 9.8rem"
      : horizontal ? "3.8rem 2rem 3.8rem"
      :"3.4rem 2rem 6.8rem"};
  /* padding: 6.5rem 2rem 15.8rem; */
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${({ theme }) => theme.device.tablet} {
    padding: ${({ headingOnly, textTop }) =>
      headingOnly
        ? textTop
          ? "10rem 8.1rem 15.6rem"
          : "10rem 8.1rem 15.6rem"
        : "5.5rem 2rem 3.5rem"};
    min-height: ${({ minHeight, headingOnly }) =>
      minHeight ? `${minHeight / 10}rem` : headingOnly ? "48rem" : "none"};
    padding: ${({horizontal }) => horizontal ? "0" : ({enableTitle}) => enableTitle? "1.5rem 2rem 3.5rem" : "5.5rem 2rem 3.5rem"};  
  }

  @media ${({ theme }) => theme.device.phone} {
    padding: ${({horizontal }) => horizontal ? "3.8rem 2rem 3.8rem" : "6.4rem 2rem 6.8rem"};  
  }
`;

export const Heading = styled(SectionHeading)<HeadingProps>`
  text-align: center;
  white-space: normal;
  font-size: 25px;
  font-weight: 700;
  margin: 0 0 2.5rem 0;
  font-family: 'Effra-Bold';

  @media ${({ theme }) => theme.device.tablet} {
    font-size: 40px;
    white-space: pre-line;
    margin: 0;
  }

  ${({ paddingBottom }) =>
    paddingBottom &&
    css`
      padding-bottom: 3.4rem;

      @media ${({ theme }) => theme.device.tablet} {
        padding-bottom: 7rem;
      }
    `}
`;

export const IconList = styled.div<ListProps>`
  display: flex;
  margin-top: ${({ enableTitle}) => enableTitle? '0rem' : ({ horizontal }) => horizontal ? '2.5rem' : '4.2rem'}};
  margin-bottom: 0rem;
  ${({ mobileDir }) => (mobileDir === "column" ? listColumnStyles : "")};
  width: 100%;
  overflow-x: scroll;
  justify-content: center;
  align-items: ${({ horizontal }) => horizontal ? 'center' : 'unset'};

  @media ${({ theme }) => theme.device.tablet} {
    margin-top: ${({ horizontal }) => horizontal ? '0rem important' : '3.5rem'};
    width: ${({ horizontal }) => horizontal ? '100%' : '85%'};
    overflow-x: initial;
    max-width: none;
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: ${({ horizontal }) => horizontal ? '2.5rem' : '0rem'};
  }
`;

export const Icon = styled.div<ListProps>`
  display: flex !important;
  flex-direction: ${({ horizontal }) => horizontal ? 'row' : 'column'};
  align-items: center;
  width: ${({ horizontal }) => horizontal ? '100%' : 'unset'};
  margin-right: 2rem;
  flex:1;

  &:last-child {
    margin-right: 0;
  }

  @media ${({ theme }) => theme.device.tablet} {
    margin-right: 0;
  }
`;

const listColumnStyles = css<ListProps>`
  flex-direction: column;

  ${Icon} {
    margin-right: 0;
    margin-bottom: ${({ horizontal }) => horizontal ? '2.5rem' : '4rem'};
    justify-content: ${({ horizontal }) => horizontal ? 'center' : 'unset'};

    @media ${({ theme }) => theme.device.tablet} {
       margin-bottom: ${({ horizontal }) => horizontal ? '0rem' : '4rem'};
       margin-bottom: ${({ enableTitle}) => enableTitle? '3rem' : ({ horizontal }) => horizontal ? '0rem' : '4rem'};
    }

    // &:last-child {
    //   margin-bottom: 0;
    // }
  }
`;

export const IconImage = styled(Image)<ImageProps>`
 // max-width: ${({ big }) => (big ? "4rem" : "3.5rem")};
  max-height: ${({ big }) => (big ? "50px" : "3.5rem")};
  flex: ${({ horizontal }) => horizontal ? '1' : 'unset'};

  @media ${({ theme }) => theme.device.tablet} {
    //max-width: ${({ big }) => (big ? "6rem" : "4.5rem")};
    max-height: ${({ big }) => (big ? "6rem" : "4.5rem")};
    flex: unset;
  }
  @media ${({ theme }) => theme.device.phone} {
    margin-right: ${({ horizontal }) => horizontal ? '1rem' : 'unset'};
  }
`;


export const IconText = styled.span<ListProps>`
  white-space:  ${({ enableTitle}) => enableTitle? 'unset' : 'pre-line'};
  font-family: 'Effra-Regular';
  font-size:  ${({ horizontal }) => horizontal ? '14px' : '14px'};
  font-size:  ${({ enableTitle}) => enableTitle? '12px' : '14px'};
  line-height: 18.2px;
  font-weight: 400;
  text-align: ${({ horizontal }) => horizontal ? 'left' : 'center'};
  margin-top: ${({ horizontal }) => horizontal ? '0rem' : '1.1rem'};

  @media ${({ theme }) => theme.device.tablet} {
    white-space: pre-line;
    margin-top: ${({ horizontal }) => horizontal ? '0rem' : '1.9rem'};
    font-size: ${({ horizontal }) => horizontal ? '14px' : '1.6rem'};
    line-height: ${({ theme }) => theme.lineHeight.xs.desktop};
    font-size: ${({ enableTitle}) => enableTitle? '15px' : '1.6rem'};
    width: ${({ enableTitle}) => enableTitle? '83%' : 'unset'};
 
  }
`;

export const RichText = styled(BlockContent)<ListProps>`
  white-space:  ${({ enableTitle}) => enableTitle? 'unset' : 'pre-line'};
  font-family: 'Effra-Regular';
  color: ${({ theme }) => theme.colors.font_black};
  font-size:  ${({ enableTitle}) => enableTitle? '12px' : ({ horizontal }) => horizontal ? '14px' : '0.9rem'};
  line-height: ${({ theme }) => theme.lineHeight.xs.mobile};
  font-weight: 400;
  text-align: ${({ horizontal }) => horizontal ? 'left' : 'center'};
  margin-top: ${({ horizontal }) => horizontal ? '0rem' : '1.1rem'};
  padding-left: 0rem;
  flex: ${({ horizontal }) => horizontal ? '2' : 'unset'};

  @media ${({ theme }) => theme.device.tablet} {
    white-space: pre-line;
    margin-top: ${({ horizontal }) => horizontal ? '0rem' : '1.9rem'};
    font-size: ${({ horizontal }) => horizontal ? '14px' : '1.6rem'};
    line-height: ${({ theme }) => theme.lineHeight.xs.desktop};
    padding-left: 2rem;
    flex: unset;
  }
  
  strong {
    font-weight: 700;
  }
`;

export const StyledButton = styled(Button)`
  // margin-top: 4.8rem;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.02em;
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding-top: 1em;
  padding-bottom: 1em;
  height:60px !important;
  width: 100%;
  justify-content: center;

  @media ${({ theme }) => theme.device.tablet} {
    // margin-top: 8.1rem;
    font-size: 20px;
  } 
  @media ${({ theme }) => theme.device.smPhone} {
    padding: 1rem 2.3rem !important;
  }

  img {
    padding-left: 0.5em;
  }
`;

export const StyledImage = styled(Image)`
  width: 100%;
  /* margin-bottom: 2.4rem; */
  &:last-child {
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 4%;
    padding: 0 4rem;
    @media ${({ theme }) => theme.device.tablet} {
      padding: 0 5rem;
    }
  }

  @media ${({ theme }) => theme.device.tablet} {
    max-width: 98.9rem;
    /* margin-bottom: 5.5rem; */
  }
`;

export const RatingBox = styled(SectionHeading)`
  margin-bottom: 1px;
  display: flex !important;
  align-items: center !important;
  margin-top: 5rem !important;
  width: 100%;
  justify-content: center;

  @media ${({ theme }) => theme.device.tablet} {
    width: 100%;

    div {
      width: 21%;
    }
  }

  div {
    width: auto;
  }
  div svg {
    margin-right: 0 !important;
  }
`;

export const RatingText = styled(Paragraph)`
  margin-top: 0.3rem;
  padding-left: 7px;
  font-size: 10px;
  font-weight: 400;

  @media ${({ theme }) => theme.device.tablet} {
    font-size: 12px;
  }
`;
export const StyledRating = styled(Rating)`
  margin-right: 0.4rem;
  width: unset;

  @media ${({ theme }) => theme.device.phone} {
    width: 22% !important;
  }
`;

export const RatingParagraph = styled(Paragraph)`
  span {
    margin-right: .5rem;
    font-size: 13px;
  }
  @media ${({ theme }) => theme.device.phone} {
    span{
      font-size: 11px;
    }
  }
`;

export const IconTitle = styled(Paragraph)<ParagraphProps>`
  display: ${({ enableTitle}) => enableTitle? 'block' : 'none'};
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  font-family: "Effra-Bold";
  color: ${({ enableTitle}) => enableTitle? '#FFFFFF' : ({ theme }) => theme.colors.font_black};
  margin-top: 1.5em;

  @media ${({ theme }) => theme.device.tablet} {
    font-size: 25px;
    line-height: 24px;
    margin-top: 1.9em;
    text-align: center;
    display: ${({ enableTitle}) => enableTitle? 'block' : 'none'};
    width: ${({ enableTitle}) => enableTitle? '60%' : 'unset'};
  }
`;

export const SectionWrap = styled.div<WrapSectionProps>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 6rem;
    // margin-top: 4rem;
    margin-top: ${({enableReview}) => enableReview ? "4rem" : "0rem"};
    width: 100%;

 @media ${({ theme }) => theme.device.phone} {
     padding: 0;
  }
  @media ${({ theme }) => theme.device.largeDesktop} {
    padding: 0 34% !important;
  }

  @media ${({ theme }) => theme.device.tablet} {
    padding: 0 27%;
  }
`;

export const ImageContainer = styled.div<ImageProps>`
  height: ${({ horizontal }) => horizontal ? 'unset' : '6rem'};
  display: flex;
`

export const StyledSlider = styled(Slider)`
  width: 100%; 
  .slick-track {
    display: flex;
    justify-content: center;
  }

  ul.slick-dots {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50% !important;
    margin: 2rem 0;
    top: 94%;
    bottom: unset;
    width: auto;

    @media ${({ theme }) => theme.device.tablet} {
      top: 86% !important;
    }
    li {
      margin: 0 0.9rem 0 0;
      width: 10px;
      height: 10px;

      &:last-child {
        margin: 0;
      }
    }
  }

  .slick-slide {
    padding: 0 1rem;
  }

  .slick-active button {
    font-size: 0rem;
    height: 0.5rem;
    width: 0.5rem;
  }

  li button {
    background: #E0E0E0;
    border: 0 !important;
    width: 10px;
    height: 10px;
    border-radius: 100px;
  }
  li button::before {
    display: none;
  }

  .slick-active button {
    background: #F58E8A;
    border: 0 !important;
  }
`;