import styled, { css } from "styled-components";
import { Paragraph, SectionHeading } from "../Text";
import BlockContent from "src/components/BlockContent";
import Link from "gatsby-link";
import { size } from "src/theme/globalStyle";
import Rating from "../Rating";
import Image from "../Common/ImageFluid";

interface InfoProps {
  bigPadding: boolean;
}

interface ContLinkProps {
  mobileSmall?: boolean;
}

interface TextContProps {
  addToCart?: boolean;
}

interface LinkProps {
  disabled?: boolean;
}

export const TextContainer = styled.div<TextContProps>`
  margin: 0;

  @media ${({ theme }) => theme.device.phone} {
    margin: 0;
  }
`;
export const MobileTextContainer = styled.div<TextContProps>`
  display: flex;
  flex-directon: row;


  @media ${({ theme }) => theme.device.phone} {
    margin: 0;
  }
`;
export const DescriptionHeading = styled(SectionHeading)`
  font-size: 3rem;
  font-weight: 500;

  @media ${({ theme }) => theme.device.tablet} {
    font-size: 3rem;
    margin-bottom: 2rem;
    line-height: 3.5rem;
    max-width: 50rem;
  }

  @media ${({ theme }) => theme.device.phone} {
    font-size: 2rem;
    margin-bottom: 2rem;
    line-height: 2rem;
    max-width: 60%;
}
`;

export const Description = styled(BlockContent)`
  white-space: pre-line;
  color: ${({ theme, color }) => theme.colors[color as string]};
  font-size: ${({ theme }) => theme.fontSize.base.mobile};
  line-height: ${({ theme }) => theme.lineHeight.base.mobile};
  font-family: 'Effra-Regular';
  font-weight: 400;

`;

export const Heading = styled.h2`
  font-size: ${({ theme }) => theme.fontSize.lg.desktop};
  line-height: ${({ theme }) => theme.lineHeight.lg.desktop};

  @media ${({ theme }) => theme.device.tablet} {
    font-size: ${({ theme }) => theme.fontSize.base.desktop};
    line-height: ${({ theme }) => theme.lineHeight.base.desktop};
    max-width: 41rem;
  }
  
  font-family: 'Effra-Regular';
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

export const PriceHeading = styled(Heading)`
  font-size: ${({ theme }) => theme.fontSize["4xl"].mobile};
  font-family: 'Effra-Bold';
  font-weight: 700;
  line-height: 23px;
`;

export const InfoContainer = styled.div<InfoProps>`
  padding: 0 2rem;

  @media ${({ theme }) => theme.device.tablet} {
    padding: 0 4rem 3rem; 
    margin-top: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  @media ${({ theme }) => theme.device.phone} {
    padding: 0;
  }
`;

export const StyledRating = styled(Rating)`
  @media ${({ theme }) => theme.device.tablet} {
    margin-bottom: auto;
  }
`;

export const PriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media ${({ theme }) => theme.device.phone} {
    flex-direction: column;

    ${Heading} {
      margin: 0;
    }
  }
`;

export const ComparePrice = styled(PriceHeading)`
  font-size: 1.5rem;
  color: #c3c1c8;
  text-decoration: line-through;
  margin-left: 1rem;
`

export const CartLink = styled(SectionHeading) <LinkProps>`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: 1.2rem;
  line-height: 1.8rem;
  margin-top: 0.8rem;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  background-color: ${({ theme }) => theme.colors.geraldine};
  padding: 1.5rem;
  width: 33%;
  text-align: center;
  @media ${({ theme }) => theme.device.tablet} {
    font-size: 1.8rem;
    margin-top: 4.7rem;
    /* margin-top: auto; */
  }

  @media ${({ theme }) => theme.device.phone} {
    width: 60%;
  }
`;

export const ContainerLink = styled(Link) <ContLinkProps>`
  position: relative;
  display: block;
  background-color: ${({ theme }) => theme.colors.bg_white};
  width: 50%;
  padding: 15px 15px 0 15px;
  border-radius: 20px;
  box-shadow: 0px 4px 4px 0px #00000040;

  img {
    width: 100%;
    height: 100%;
  }

  @media ${({ theme }) => theme.device.phone} {
    padding: 20px;
    width: 100%;
  }
  
  @media ${({ theme }) => theme.device.tablet} {
    display: flex;
    margin-bottom: 2rem;
    flex-direction: column;
  }

  @media (max-width: ${size.smallDesktop}) {
    ${({ mobileSmall }) =>
    mobileSmall &&
    css`
        ${InfoContainer} {
          padding: 0.8rem 1.2rem 0.8rem;
          /* padding: 0.6rem 1.2rem 1.7rem; */
          /* padding: 0.3rem 1.2rem 1.7rem; */
        }

        ${Heading} {
          font-size: 1.2rem;
          line-height: 1.4rem;
          margin-bottom: 0.3rem;
        }

        ${PriceHeading} {
          font-size: 1.2rem;
          line-height: 1.4rem;
        }

        ${TextContainer} {
          /* margin-bottom: 1.2rem; */
          margin-bottom: 0.6rem;
        }

        ${StyledRating} {
          svg {
            width: 1rem;
            margin-right: 0.25rem;
          }
        }
      `}
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  padding: 3rem;
  max-height: 450px;

  @media ${({ theme }) => theme.device.phone} {
    padding: 0;
    margin-bottom: 2rem;
    max-height: 250px;

    & > div {
      max-height: 250px;
    }
  }

  /* &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  } */
`;

export const StyledImage = styled(Image)`
  img {
    max-height: 80%;
    object-position: center; 
    object-fit: cover;
    }

  @media ${({ theme }) => theme.device.phone} {
  img {
    max-height: none;
    }
  }
  
  /* position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center; */
`;

export const DescriptionParagraph = styled(BlockContent)`
  color: ${({ theme }) => theme.colors.font_black};
  font-size: ${({ theme }) => theme.fontSize.base.mobile};
  line-height: ${({ theme }) => theme.lineHeight.base.mobile};
  font-family: 'Effra-Regular';
  font-weight: 400;
  margin: 0;
  max-width: 84%;

  @media ${({ theme }) => theme.device.tablet} {
    margin: 0 0 3rem;
    font-size: ${({ theme }) => theme.fontSize.base.desktop};
    line-height: ${({ theme }) => theme.lineHeight.base.desktop};
    max-width: 84%;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: baseline;

  ${PriceContainer} {
    flex: 50%;
  }
  ${CartLink} {
    flex: 30%;
  }

  @media ${({ theme }) => theme.device.phone} {
    display: block;
    ${CartLink} {
      width: 50%;
    }
  }
`