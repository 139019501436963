import styled, { css } from "styled-components";
import Image from "src/components/Common/ImageFluid";
import Wrap from "src/components/Wrap";
import { SectionHeading, Paragraph } from "src/components/Text";
import BlockContent from "src/components/BlockContent";
import Check from "src/images/check.svg";

interface PositionProps {
  position: "left" | "right";
}

interface ImageProps {
  marginBottom: boolean;
  marginTop: boolean;
}

const positionWrap = (position: "left" | "right") => {
  switch (position) {
    case "left":
      return css`
        flex-direction: row;
      `;

    case "right":
      return css`
        flex-direction: row-reverse;
      `;
  }
};

export const StyledWrap = styled(Wrap)<PositionProps>`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 0 2rem 6rem;

  @media ${({ theme }) => theme.device.tablet} {
    ${({ position }) => positionWrap(position)}
    padding: 9.5rem 8.1rem;
    justify-content: space-between;
  }
`;

export const StyledImage = styled(Image)<ImageProps>`
  margin-bottom: 2.6rem;
  margin-top: 4rem;
  z-index: 1;
  /* margin-top: ${({ marginTop }) => (marginTop ? "-6.7rem" : "0")}; */

  @media ${({ theme }) => theme.device.tablet} {
  margin-top: 0
    /* margin-bottom: ${({ marginBottom }) => (marginBottom ? "-4rem" : "0")};
    margin-top: ${({ marginTop }) => (marginTop ? "-4rem" : "0")}; */
    width: 51.25vw;
    max-width: 73.8rem;
  }
`;

export const Title = styled(SectionHeading)`
  font-size: 4rem;
  line-height: 4.8rem;
  margin-bottom: 4.2rem;

  @media ${({ theme }) => theme.device.tablet} {
    font-size: 8rem;
    line-height: 8.8rem;
    margin-bottom: 7.6rem;
  }
`;

export const TextBlock = styled.div<PositionProps>`
  @media ${({ theme }) => theme.device.tablet} {
    padding: 9rem 0 0;
    width: 28.6805vw;
    max-width: 41.3rem;
  }
`;

export const Description = styled(BlockContent)`
  font-size: 1.4rem;
  line-height: 2.2rem;
  font-family: 'Effra-Regular';
  font-weight: ${({ theme }) => theme.fontWeight.regular};

  @media ${({ theme }) => theme.device.tablet} {
    font-size: 1.6rem;
    line-height: 3rem;
  }

  p {
    margin: 2.2rem 0;

    @media ${({ theme }) => theme.device.tablet} {
      margin: 3rem 0;
    }
  }

  li {
    list-style-type: none;
    position: relative;
    padding: 0 0 0 3.6rem;
    margin: 0;

    &:before {
      content: "";
      position: absolute;
      width: 2.4rem;
      height: 2.4rem;
      background-image: url(${Check});
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      background-position: center;
      background-size: contain;
    }
  }
`;
