import styled from "styled-components";
import Wrap from "src/components/Wrap";
import Image from "../../components/Common/ImageFluid";
import { Paragraph } from "src/components/Text";

export const StyledWrap = styled(Wrap)`
  padding: 1rem;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;

  @media ${({ theme }) => theme.device.tablet} {
    padding: 0 8.1rem;
  }

  .paragraph-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @media ${({ theme }) => theme.device.tablet} {
      flex-direction: row;
      align-items: center;
    }
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 15px 0px 0px 5px;
  font-size: 1.2rem;
  width: 100%;
  justify-content: space-around;
  @media ${({ theme }) => theme.device.tablet} {
    font-size: 1.7rem;
    width: 90%;
    justify-content: space-around;
    @media ${({ theme }) => theme.device.laptop} {
      font-size: 1.7rem;
      width: 88%;
      justify-content: space-around;
    }
  }
`;

export const FirstColumn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const SecondColumn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledImage = styled(Image)`
  width: 42px;
  height: 42px;
  margin: 0 1rem 0 0;
  @media ${({ theme }) => theme.device.tablet} {
  }
`;

export const FirstParagraph = styled(Paragraph)`
  margin: 0 1rem 0 0;
  line-height: 1.2;
  font-size: 1.2rem;
  @media ${({ theme }) => theme.device.tablet} {
    font-size: 1.7rem;
  }
`;
export const LinkParagraph = styled.div`
  text-decoration: underline;
  cursor: pointer;
`;

export const OtherParagraph = styled(Paragraph)`
  font-size: 1.2rem;
  line-height: 1.2;
  @media ${({ theme }) => theme.device.tablet} {
    font-size: 1.7rem;
  }
`;
