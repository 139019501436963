import Wrap from "src/components/Wrap";
import styled, { css } from "styled-components";
import { Heading, Paragraph, SectionHeading } from "src/components/Text";
import Icon from "src/components/Common/Icon";
import BlockContent from "src/components/BlockContent";

interface StyledWrapProps {
	isSection?: boolean;
}

interface StyledHeadingProps {
	open?: boolean;
}

export const StyledWrap = styled(Wrap) <StyledWrapProps>`
  padding: ${({ isSection }) =>
		isSection ? "4.1rem 0 3.3rem" : "4.1rem 0 0 0"};
		display: flex;
		flex-direction: column;
		justify-content: space-between;
  @media ${({ theme }) => theme.device.tablet} {
    padding: ${({ isSection }) =>
		isSection ? "4.5rem 5rem" : "2.4rem 0rem 0rem 4.5rem"};
    max-width: 144rem;
    box-sizing: content-box;
		flex-direction: row;
  }
  @media ${({ theme }) => theme.device.laptop} {
    padding: ${({ isSection }) =>
		isSection ? "4.5rem 20rem" : "2.4rem 0rem 0rem 4.5rem"};
    max-width: 144rem;
    box-sizing: content-box;
		flex-direction: row;
  }

  @media ${({ theme }) => theme.device.smTablet} {
    padding: ${({ isSection }) =>
		isSection ? "4.5rem 5rem" : "2.4rem 0rem 0rem 4.5rem"};
    max-width: 144rem;
    box-sizing: content-box;
		flex-direction: row;
  }
  @media ${({ theme }) => theme.device.phone} {
    padding: ${({ isSection }) => (isSection ? "5rem 0" : "0")};
    margin: ${({ isSection }) => (isSection ? "0" : "0")};
    flex-direction: column;
  }
`;

export const StyledHeading = styled(SectionHeading)`
  padding: 0 2rem;
	text-align: center;
	display: block;
	margin: 0 auto 2.8rem auto;
  font-weight: 700;
  font-size: 30px;
	margin: 0;
	line-height: 40px;
  font-family: 'Effra-Bold';
  color: ${({ theme }) => theme.colors.dark_blue};
  @media ${({ theme }) => theme.device.tablet} {
    margin-bottom: 2rem;
    padding: 0;
		width: 70%;
		margin: 1rem;
    font-style: normal;
    font-weight: 700;
    text-align: left;
    font-size: 40px;
    line-height: 48px;
  }
`;


interface ListItemProps {
	plusSign?: boolean;
	notificationBar: boolean;
  open?: boolean
}


export const ListItem = styled.li<ListItemProps>`
  position: relative;
  cursor: pointer;
  list-style-type: none;
  padding: ${({ open }) => (open ? "2.5rem 0 0 0" : "2.5rem 0")};
  margin: 0;
  border-bottom: 1px solid hsla(136, 24%, 22%, 30%);

  &:last-child {
    border-bottom: none;
  }
  scroll-margin-top: ${({ notificationBar }) =>
		notificationBar ? "9rem" : "6.3rem"};
  @media ${({ theme }) => theme.device.tablet} {
    padding: ;
    padding: ${({ open }) => (open ? "3rem 0 0 0" : "3rem 0")};
    scroll-margin-top: ${({ notificationBar }) =>
		notificationBar ? "17.5rem" : "12.7rem"};
  }
`;

export const StyledHeadingg = styled(Heading)<StyledHeadingProps>`
  font-size: 13px;
  line-height: 15px;
	margin: 0 0 0 10px;
  color: #12142C;
  font-weight: 700;
  font-family: ${({ open }) => open ? 'Effra-Regular' : 'Effra-Regular'};
  color: ${({ theme }) => theme.colors.font_black};

  @media ${({ theme }) => theme.device.tablet} { 
    font-size: 16px;
    line-height: 19px;
    margin: 0 0 0 26px;
  }
`;

export const StyledIcon = styled(Icon)`
  margin-right: 1.5rem;
  max-width: 4.6rem;
  width: 100%;
  height: 4.6rem;
  align-self: flex-start;
`;

export const ItemHeader = styled.div<ListItemProps>`
  display: flex;
  flex-direction: ${({ plusSign }) => plusSign ? 'row-reverse' : 'row'};
  justify-content: ${({ plusSign }) => plusSign ? 'space-between' : 'left'};
  align-items: baseline;

  .plus-sign {
   color: #CE603A;
   font-weight: 700;
  }
`;

export const ItemContent = styled.div`
  padding: ${({ open }) => (open ? "1.7rem 0.2rem 2rem 0.2rem" : "0")};
  overflow: hidden;
	width: 100%;
  max-height: ${({ open }) => (open ? "initial" : "0")};
  @media ${({ theme }) => theme.device.tablet} {
    padding: ${({ open }) => (open ? "2.2rem 0 1.9rem 0rem" : "0")};
    width: 100%;
  }
`;

export const Content = styled(BlockContent)`
  font-size: 12px;
  line-height: 120%;
  font-family: 'Effra-Regular';
  font-weight: 400;
  max-width: 100%;
  font-weight: 400;
  color: #6D6D6D;

  @media ${({ theme }) => theme.device.tablet} {
    font-size: 15px;
    line-height: 130%;
  }
`;

export const ContentImage = styled.img`
  max-width: 5rem;
  margin-bottom: 1rem;
`;

export const QuestionList = styled.ul``;

export const SectionWrap = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	@media ${({ theme }) => theme.device.tablet} { 
		width: 48%;
	}
`;

export const QuestionsPart = styled.div`
	width: 100%;
  padding: 0 2.5rem;
	@media ${({ theme }) => theme.device.tablet} { 
		width: 48%;
    margin-right: 1rem;
    padding: 0;
	}
`;

export const StyledDescription = styled.div`
  font-family: 'Effra-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #6D6D6D;
  margin: 1rem;
  display: block;
  text-align: center;
  padding: 0 1rem;
@media ${({ theme }) => theme.device.tablet} { 
  text-align: left;
  width: 70%;
  padding: 0;
}

`;