import styled, { css } from 'styled-components';

export const DeliveryTimeWidget = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 17.6px;
  font-family: 'Effra-Regular';
  margin: 1rem 0 -2.3rem 0;

  #minutes {
    display: none;
  }

  #seconds {
    display: none;
  }

  .delivery {
    display: flex;
    flex-direction: row;
    margin: 0 0 1rem 0;

    .text {
      margin: 0 0 0 2rem;

      .days {
        font-weight: 700;
        font-family: 'Effra-Bold';
      }
    }
  }

  .order {
    display: flex;
    flex-direction: row;
    margin: 0 0 1rem 0;

    .text {
      margin: 0 0 0 2rem;

      span {
        color: #10BB21;
        font-weight: 700;
        font-family: 'Effra-Bold';
        display: flex;
        flex-direction: row;
      }
    }
  }
`;