import React from 'react';
import {
  GeneralSectionBlock,
  GeneralSectionButton,
  RatingBox,
  RatingText,
  IconTitle,
  AdditionalTextStyle
} from "./styles";
import Link from "src/components/Link";
import NewArrow from "src/images/new_right_arrow.svg";
import Rating from "src/components/Rating";
import { useTheme } from "styled-components";
import PaymentIcons from "src/images/paymentIcons.svg";


export const RenderReviews = ({ reviews }) => {

  const theme = useTheme();
  return (
    <RatingBox>
      <Rating number={reviews?.ratingAll} color={theme.colors.yellow} />
      {reviews?.ratingAll && (
        <RatingText> {reviews?.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}+ {reviews?.rating.toFixed(1)}/{reviews?.ratingAll} STARS</RatingText>
      )}
    </RatingBox>
  )
}
const GeneralSection = ({ content, reviews }) => {


  return (
    <GeneralSectionBlock>
      {content?.disableRatings && <RenderReviews reviews={reviews} />}
      {content?.button?.enabled &&
        <GeneralSectionButton style={{ background: content?.buttonColors?.value}}>
          <Link to={content?.button?.link}>{content?.button?.text}
            <img src={NewArrow} alt="arrow" />
          </Link>
        </GeneralSectionButton>}

      {content?.additionalText &&
        <AdditionalTextStyle>
          {content?.additionalText}
        </AdditionalTextStyle>}

      {content?.disablePaymentIcons &&
        <img className='payment-icons' src={PaymentIcons} alt="payment icons" />
      }


    </GeneralSectionBlock>
  )
}

export default GeneralSection
