import styled from "styled-components";
import Slider from "react-slick";

export const SlideNumber = styled.button``;

export const CarouselListContainer = styled.div`
  width: 100%;
  padding: 2.5rem;
  background: #ffffff;
  border-radius: 20px;

  .carousel-stars {
    margin: 1rem 0 !important;
    height: 27px;

    width: 50%;
    @media ${({ theme }) => theme.device.mobile} {
      width: 50%;
      img {
        height: auto;
        width: 100%;
      }
    }

    @media ${({ theme }) => theme.device.tablet} {
      width: 50%;

      img {
        height: auto;
        width: 100%;
      }
    }
    @media ${({ theme }) => theme.device.laptop} {
      width: 42%;
      img {
        height: auto;
        width: 100%;
      }
    }
    img {
      @media ${({ theme }) => theme.device.mobile} {
      }

      @media ${({ theme }) => theme.device.laptop} {
      }
    }
  }

  .carousel-reviewer-name {
    font-family: 'Effra-Regular';
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.7rem;
    color: #0f0923;
  }
  .slick-active {
    background: #ffffff;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
  }

  .slick-dots {
    top: 100%;
    margin: 2rem 0;
  }
`;

export const StyledSlider = styled(Slider)`
  background-color: #d5dcea;
  ul.slick-dots {
    top: 2rem;
    left: 2rem;
    bottom: unset;
    width: auto;

    @media ${({ theme }) => theme.device.tablet} {
      top: 3rem;
      left: 4.2rem;
    }

    li {
      margin: 0 0.9rem 0 0;
      width: auto;
      height: auto;

      &:last-child {
        margin: 0;
      }

      ${SlideNumber} {
        font-size: 0;
        width: 0.8rem;
        height: 0.8rem;
        border: 0;
        border-radius: 50%;

        &::before {
          content: none;
        }
      }
    }
  }
`;

export const TitleQuoteContainer = styled.div`
  min-height: 190px;
  margin: 0 0 1rem 0;

  @media ${({ theme }) => theme.device.phone} {
    min-height: 240px;
  }

  @media ${({ theme }) => theme.device.tablet} {
    min-height: 190px;
  }

  .carousel-title {
    font-family: 'Effra-Regular';
    font-size: 2.1rem;
    line-height: 2.1rem;
    color: #0f0923;
    margin: 0 0 1.8rem 0;
    font-weight: 400;
  }

  .carousel-review-quote {
    font-family: 'Effra-Regular';
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.7rem;

    color: #0f0923;
  }
`;
