import styled from "styled-components";
import Button from "src/components/Common/Button";

export const StyledButton = styled(Button)`
  position: absolute;
  bottom: 0;
  display: block;
  width: 100%;
  left: 0;
  border-color: ${({ theme }) => theme.colors.lighter_gray};
  border-left: 0;
  border-right: 0;
  font-size: 1.2rem;
  text-align: left;
  padding-left: 2.2rem;
`;
