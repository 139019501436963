import React from "react";
import {
  StyledWrap, RowContainer, StyledContactForm
} from "./styles";
import CtaBlock from "src/components/CtaBlock";
import { SanityButton } from "src/interfaces/common";
import InformationRow from "src/components/InformationRow";
import Positions from "src/components/Positions";
import DeliveryRow from "src/components/Rows/DeliveryRow";

interface Props {
  data: {
    contactCta?: {
      button: SanityButton;
      enabled: boolean;
      text: string;
    };
    contactForm?: {
      description: string;
      enabled: boolean;
      heading: string;
    };
    whatsapp?: {
      enabled?: boolean;
      number?: string;
      message?: string;
    },
    informationRows?: [
      {
        _type: "deliveryRow" | "informationRow" | "positionRow";
        heading: string;
        text?: any;
        description?: any;
        title?: string;
        type?: string;
        rows?: [
          {
            heading: string;
            text: string;
          }
        ];
        button?: SanityButton;
      }
    ];
  };
}

const InformationSection = ({ data }: Props) => {
  const { contactCta, contactForm, informationRows, positionRows, whatsapp } = data;


  return (
    <StyledWrap>
      {informationRows && (
        <RowContainer>
          {informationRows.map((row, index) => {
            switch (row._type) {
              case "informationRow":
                return (
                  <InformationRow
                    key={index}
                    heading={row.heading}
                    text={row.text}
                    button={row.button}
                  />
                );

              case "deliveryRow":
                return <DeliveryRow key={index} heading={row.heading} rows={row.rows} />;
            }
          })}
        </RowContainer>
      )}
      {positionRows && <Positions rows={positionRows} />}
        {contactCta?.enabled && (
          <CtaBlock heading={contactCta?.text} button={contactCta?.button} whatsapp={whatsapp}/>
        )}
      {contactForm?.enabled && (
        <StyledContactForm
          heading={contactForm?.heading}
          description={contactForm?.description}
        />
      )}
    </StyledWrap>
  );
};

export default InformationSection;
