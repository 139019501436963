import React, { forwardRef } from "react";
import { Description, Slide, StyledSlider, StyledImage, Title, StyledPart, ImageContainer } from "./styles";
import goodPillow from "src/images/good.svg";
import badPillow from "src/images/bad.svg";

interface Props {
  list: any;
  setCurrent: any;
  ref: any;
}

const TestimonialCardsSlider = forwardRef(({ list, setCurrent }: Props, ref) => {
  const handleAfter = (index: number) => {
    setCurrent(index);
  };

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    arrows: true,
    dots: false,
    slidesToScroll: 2,
    afterChange: handleAfter,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          arrows: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 2,
          arrows: true,
        },
      },
    ],
  };

  return (
    <StyledSlider {...settings} ref={ref}>
      {list.map((item, i) => {
        return (
          <Slide key={item._key}>
            <StyledPart>
              {item.image && (
                <StyledImage key={item._key} imageRef={item.image} alt={item?.image?.alt}/>
              )}
              <ImageContainer>
                {item.goodOrbad && item.goodOrbad == "goodPillow" ? <img src={goodPillow} alt="Supportive Pillow"/> : <img src={badPillow} alt="Unsupportive Pillow"/>}
                {item.title && <Title>{item.title}</Title>}
                {item.description && <Description>{item.description}</Description>}
              </ImageContainer>
            </StyledPart>
          </Slide>
        );
      })}
    </StyledSlider>
  );
});

export default TestimonialCardsSlider;
