import React, { useState } from "react";
import Button from "../../components/Common/Button";
import {
  StyledHeading,
  StyledSubheading,
  TextBox,
  RatingsBox,
  StyledWrap,
  Ratings,
  RatingsList,
  AwardsContainer,
  StyledImage,
  RatingsListUrl,
  StyledTrustpilot,
  StyledTrustpilotImage,
  StyledReviews,
  ReviewsImageContainer,
  StyledReviewsMobile,
  MobileVersionContainer,
  TextBoxAwardContainerMobileVersion,
  DesktopVersionContainer,
  StyledLink,
  ExploreVideo,
  StyledText
} from "./styles";
import { Paragraph } from "../../components/Text";
import { SanityImage, SanityColor } from "src/interfaces/sanity";
import { useTheme } from "styled-components";
import { Subheading } from "../HeadingSection/styles";
import { VideoModal } from "src/components/VideoPopup/styles";
import { StyledVideo } from "src/components/Video/styles";

interface Props {
  data: {
    main: {
      awardsImage: SanityImage;
      ratingEnabled: boolean;
      buttonLink: string;
      buttonText: string;
      heading: string;
      text?:string;
      headingColor: {
        title: string;
        value: string;
      };
      headingColorMobile: {
        title: string;
        value: string;
      };
      ratingLineOne: string;
      ratingLineTwo: string;
      ratingNumber: number;
      subheading: string;
      subHeadingColor: {
        title: string;
        value: string;
      };
      subHeadingColorMobile: {
        title: string;
        value: string;
      };
      ctaPosition?: "flex-start" | "center" | "flex-end";
      videoTitle: string;
      videoIcon: SanityImage;
      trustpilotImageDesktop: SanityImage;
      trustpilotImageIcon: SanityImage;
      trustpilotImageStars: SanityImage;
      videoTextColor: {
        title: string;
        value: string;
      };
      buttonTextColor: {
        title: string;
        value: string;
      };
      buttonBackgroundColor: {
        title: string;
        value: string;
      };
      extBtnText: string;
      extBtnTextColor: {
        title: string;
        value: string;
      }
      extBtnBgColor: {
        title: string;
        value: string;
      };
      extBtnLink: string;
      Video: any;
    };
    experiment: {
      variantA?: boolean;
      variantB?: boolean;
    };
    sectionSettings: {
      backgroundColor: SanityColor;
      backgroundImage: SanityImage;
    };
  };
  context: any;
}


const HeroSection = ({ data, context }: Props) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const {
    heading,
    subheading,
    buttonText,
    buttonLink,
    awardsImage,
    ratingEnabled,
    text,
    headingColorMobile, 
    subHeadingColorMobile,
    // ratingLineOne,
    // ratingLineTwo,
    // ratingNumber,
    ctaPosition,
    videoTitle,
    videoIcon,
    trustpilotImageDesktop,
    trustpilotImageIcon,
    trustpilotImageStars,
    videoTextColor,
    Video,
    headingColor,
    subHeadingColor,
    buttonTextColor,
    buttonBackgroundColor,
    extBtnText,
    extBtnTextColor,
    extBtnBgColor,
    extBtnLink,
    videoShopify
  } = data.main;

  return (
    <>
      <StyledWrap
        notificationBar={
          context.notificationBar ? context.notificationBar[0].enabled : false
        }
        align={ctaPosition}
        className={data?.experiment?.variantB ? 'variant_B' : data?.experiment?.variantA ? 'variant_A': ''}
      >
        <DesktopVersionContainer>
          <TextBox>
            {videoTitle && videoIcon && (
              <ExploreVideo>
                <Paragraph color={videoTextColor?.title}>
                  {videoTitle}
                </Paragraph>
                <div onClick={openModal}>
                  <StyledImage imageRef={videoIcon} />
                </div>
              </ExploreVideo>
            )}
            <StyledText color={headingColor?.title}>{text}</StyledText>
            <StyledHeading color={headingColor?.title}>{heading}</StyledHeading>
            {subheading && (
              <StyledSubheading color={subHeadingColor?.title}>
                {subheading}
              </StyledSubheading>
            )}
            {buttonLink && buttonText && (
              <Button boxShadow color={buttonTextColor?.title} to={buttonLink}>
                {buttonText}
              </Button>
            )}
            {extBtnLink && extBtnText && (
              <Button boxShadow color={extBtnTextColor?.title} to={extBtnLink} style={{ marginTop: "3rem" }}>
                {extBtnText}
              </Button>
            )}
          </TextBox>
          {(ratingEnabled || awardsImage) && (
            <AwardsContainer>
              {awardsImage && (
                <StyledImage className="awards" imageRef={awardsImage} />
              )}
              <StyledReviews>
                <Paragraph textStyle="underline" color="font_black">
                  {/* {context?.reviews?.total} reviews */}
                  <a
                    target="_blank"
                    rel="noreferrer noopener"
                    href="https://uk.trustpilot.com/review/www.sleepeasycommunity.co.uk"
                  >
                    1332 reviews
                  </a>
                </Paragraph>
                <StyledTrustpilot>
                  {trustpilotImageIcon && (
                    <StyledImage imageRef={trustpilotImageIcon} />
                  )}
                  {trustpilotImageStars && (
                    <StyledImage imageRef={trustpilotImageStars} />
                  )}
                </StyledTrustpilot>
              </StyledReviews>

              {/* <StyledReviewsMobile>
                <Paragraph textStyle="underline" color="font_black">
                  {context?.reviews?.total} reviews
                  <a
                    target="_blank"
                    rel="noreferrer noopener"
                    href="https://uk.trustpilot.com/review/www.sleepeasycommunity.co.uk"
                  >
                    1332 reviews
                  </a>
                </Paragraph>

                <ReviewsImageContainer>
                  {trustpilotImageDesktop && (
                    <StyledImage imageRef={trustpilotImageDesktop} />
                  )}
                </ReviewsImageContainer>

                <StyledTrustpilot>
                  {trustpilotImageIcon && (
                    <StyledImage imageRef={trustpilotImageIcon} />
                  )}
                  {trustpilotImageStars && (
                    <StyledImage imageRef={trustpilotImageStars} />
                  )}
                </StyledTrustpilot>
              </StyledReviewsMobile> */}
              {ratingEnabled && (
                <RatingsBox>
                  {/* <Ratings>
                <RatingsListUrl> g
                    <RatingsList
                      number={ratingNumber}
                      color={theme.colors.reviews_green}
                    />
                    <Paragraph color="font_black">{ratingLineTwo}</Paragraph>
                </RatingsListUrl>
              </Ratings> */}
                </RatingsBox>
              )}
            </AwardsContainer>
          )}
        </DesktopVersionContainer>

        <MobileVersionContainer>
          <TextBoxAwardContainerMobileVersion>
            <TextBox>
              <div className="text-container">
              {videoTitle && videoIcon && (
                <ExploreVideo>
                  <Paragraph color={videoTextColor?.title}>
                    {videoTitle}
                  </Paragraph>
                  <div onClick={openModal}>
                    <StyledImage imageRef={videoIcon} />
                  </div>
                </ExploreVideo>
              )}
              <StyledText color={headingColorMobile?.title}>{text}</StyledText>
              <StyledHeading color={headingColorMobile?.title}>
                {heading}
              </StyledHeading>
              {subheading && (
                <StyledSubheading color={subHeadingColorMobile?.title}>
                  {subheading}
                </StyledSubheading>
              )}
              </div>
              {buttonLink && buttonText && (
                <Button
                  boxShadow
                  color={buttonTextColor?.title}
                  to={buttonLink}
                >
                  {buttonText}
                </Button>
              )}
            </TextBox>
            {(ratingEnabled || awardsImage) && (
              <AwardsContainer>
                {awardsImage && <StyledImage imageRef={awardsImage} />}
                <StyledReviews>
                  <Paragraph textStyle="underline" color="font_black">
                    {/* {context?.reviews?.total} reviews */}
                    {/* <a
                      target="_blank"
                      rel="noreferrer noopener"
                      href="https://uk.trustpilot.com/review/www.sleepeasycommunity.co.uk"
                    >
                      1332 reviews
                    </a> */}
                  </Paragraph>
                  <StyledTrustpilot>
                    {trustpilotImageIcon && (
                      <StyledImage imageRef={trustpilotImageIcon} />
                    )}
                    {trustpilotImageStars && (
                      <StyledImage imageRef={trustpilotImageStars} />
                    )}
                  </StyledTrustpilot>
                </StyledReviews>
                {ratingEnabled && (
                  <RatingsBox>
                    {/* <Ratings>
                <RatingsListUrl> g
                    <RatingsList
                      number={ratingNumber}
                      color={theme.colors.reviews_green}
                    />
                    <Paragraph color="font_black">{ratingLineTwo}</Paragraph>
                </RatingsListUrl>
              </Ratings> */}
                  </RatingsBox>
                )}
              </AwardsContainer>
            )}
          </TextBoxAwardContainerMobileVersion>
          <StyledReviewsMobile>
            <Paragraph textStyle="underline" color="font_black">
              {/* {context?.reviews?.total} reviews */}
              {/* <a
                target="_blank"
                rel="noreferrer noopener"
                href="https://uk.trustpilot.com/review/www.sleepeasycommunity.co.uk"
                className="reviews"
              >
                1332 reviews
              </a> */}
            </Paragraph>
            <StyledTrustpilot>
              {trustpilotImageIcon && (
                <StyledTrustpilotImage imageRef={trustpilotImageIcon} />
              )}
              {trustpilotImageStars && (
                <StyledTrustpilotImage imageRef={trustpilotImageStars} />
              )}
            </StyledTrustpilot>
          </StyledReviewsMobile>
        </MobileVersionContainer>
      </StyledWrap>
      <VideoModal isOpen={open} onRequestClose={closeModal}>
        <StyledVideo
          isPopUp={true}
          src={videoShopify ? videoShopify : Video?.url}
          controls={true}
          autoPlay={true}
        />
      </VideoModal>
    </>
  );
};

export default HeroSection;
