import styled from "styled-components";
import PositionRow from "src/components/Rows/PositionRow";

export const StyledPositionRow = styled(PositionRow)`
  margin-bottom: 2rem;

  @media ${({ theme }) => theme.device.tablet} {
    margin-bottom: 5rem;
  }
`;

export const Container = styled.div`
  padding: 0 2rem;

  @media ${({ theme }) => theme.device.tablet} {
    padding: 0;
  }
`;
