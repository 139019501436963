import { Heading } from "src/components/Text";
import Image from "src/components/Common/ImageFluid";
import Wrap from "src/components/Wrap";
import styled from "styled-components";

export const Container = styled.div`
&.variant_A {
  display: none;
}

`;

export const StyledWrap = styled(Wrap)`
  padding: 3.7rem 2rem 4.6rem;

  @media ${({ theme }) => theme.device.tablet} {
    padding: 10.1rem 8.1rem 7.6rem;
    // min-height: 54.9rem;
  }
`;

export const StyledHeading = styled(Heading)`
  font-size: 2.4rem;
  line-height: 2.6rem;
  margin-bottom: 4.2rem;
  color: ${({ theme }) => theme.colors.font_black};

  @media ${({ theme }) => theme.device.tablet} {
    font-size: 3.6rem;
    line-height: 4.8rem;
  }
`;

export const StyledImage = styled(Image)`
  max-width: 20.4rem;
`;
