import styled from "styled-components";
import Wrap from "../../components/Wrap";
import { Heading, Paragraph, SectionHeading } from "src/components/Text";
import Image from "../../components/Common/ImageFluid";
import Button from "src/components/Common/Button";
import Rating from "src/components/Rating";
interface ListCont {
  noPoints: boolean;
}

interface ImgContainerProps {
  alignment: "flex-start" | "center" | "flex-end";
}

export const StyledWrap = styled(Wrap)`
  &.variant_A {
    display: none;
  }
  // display: flex;
  // flex-direction: column-reverse;
  // padding-top: 4.5rem;
  /* padding: 0; */
  max-width: none;
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
  padding: 0;

  @media ${({ theme }) => theme.device.tablet} {
    padding: 5.5rem 2rem 0rem;
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const StyledWrapHeading = styled(Wrap)`
    display: flex;
    justify-content: center;
    align-items: center;
    // margin-top: 2.5em;
    margin-bottom: 1em;
    padding: 3rem 0 0 0;
    text-align: center;
`
export const StyledWrapTextContainer = styled(Wrap)`
    margin: auto;
    display: flex;
    flex-direction:column-reverse;
    justify-content: space-between;
    padding-top: 0;
    padding-bottom: 0;

    @media ${({ theme }) => theme.device.tablet} {
        margin: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-top: 0;
        width: 100%;
        padding: 0 6rem;
        padding-bottom: 0;
        max-width: 144rem;
    }
`
export const StyledWrapButton = styled(Wrap)`
    display: flex;
    padding-top: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 4% 2rem 13%;
    max-width: 140rem;

    @media ${({ theme }) => theme.device.laptop} {
        padding: 0px 30% 5% !important;
    }
    @media ${({ theme }) => theme.device.largeDesktop} {
      padding: 0px 39rem 5% !important;
      width: unset;
  }

    @media ${({ theme }) => theme.device.tablet} {
      padding: 0px 26% 5%;
      max-width: 140rem;
      padding-top: unset; 
    }
    @media ${({ theme }) => theme.device.phone} {
      padding: 2rem;
    } 
`

export const StyledHeading = styled(SectionHeading)`
  white-space: unset;
  font-size: 25px;
  font-weight: 700;
  line-height: 2.6rem;
  margin-bottom: 3.8rem;
  font-family: 'Effra-Bold';
  color: ${({ theme }) => theme.colors.font_black};
  padding: 0 2rem;

  @media ${({ theme }) => theme.device.tablet} {
    white-space: pre-line;
    font-size: 40px;
    line-height: 45px;
    padding:0;
    margin-bottom: 6.2rem;
  }
`;

export const StyledImage = styled(Image)`
  &:nth-child(3) {
    width: 100%;
    padding: 0 2rem;
    margin-top: 1rem;
    @media ${({ theme }) => theme.device.phone} {
      padding: 0 1rem;
    }
  }
  &:last-child {
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 4%;
  }
`;
export const StyledImagePayment = styled(Image)`
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 4%;
    padding: 0 5rem;
`;


export const ListContainer = styled.ol<ListCont>`
  counter-reset: list-counter;

  li {
    position: relative;
    list-style: none;
    padding-bottom: 0;
    margin-left: 0;
    margin-bottom: ${({ noPoints }) => (noPoints ? 0 : "3rem")};
    counter-increment: list-counter;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    padding-left: 0;
  

    @media ${({ theme }) => theme.device.tablet} {
      padding-left: ${({ noPoints }) => (noPoints ? 0 : "8.6rem")};
      margin-bottom: ${({ noPoints }) => (noPoints ? 0 : "6rem")};
      justify-content: flex-start;
      align-items: flex-start;
      text-align: left;
      width: none;
  
    }

    p:first-child {
      font-size: 16px;
      font-weight:700;
      line-height: 20.8px;
  
      @media ${({ theme }) => theme.device.tablet} {
        font-size: 20px;
      }
    }

    p:last-child {
      padding-top: 1.5rem;
      font-size: 15px;
      color: #6D6D6D;
      line-height: 20.8px;
      
      @media ${({ theme }) => theme.device.tablet} {
        font-size: 16px;
      }
    }

    &::before {
      content: counter(list-counter);
      background: #12142C;
      width: 50px;
      height: 50px;
      border-radius: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      margin-bottom: 1em;
      font-family: 'Effra-Regular';
      left: 0;
      font-size: 25px;
      line-height: 1;
      color: ${({ theme }) => theme.colors.font_white};

      @media ${({ theme }) => theme.device.tablet} {
        font-size: 25px;
        position: absolute;
      }
    }
  }
`;

export const ListItem = styled.li``;

export const TextContainer = styled.div`
  padding: 2rem 0 0;
  @media ${({ theme }) => theme.device.tablet} {
    max-width: 52.2rem;
    width: 31vw;
  }
`;

export const ImageContainer = styled.div<ImgContainerProps>`
  padding-bottom: 3rem;

  @media ${({ theme }) => theme.device.tablet} {
    width: 43.472vw;
    max-width: 62.6rem;
    padding-bottom: 0;
    display: flex;
    align-items: ${({ alignment }) => alignment};
  }
`;

export const ListTitle = styled(Paragraph)`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-family: 'Effra-Bold';
  
  @media ${({ theme }) => theme.device.tablet} {
    font-size: 1.4rem;
    line-height: 2.2rem;
  }
`;

export const ListDescription = styled(Paragraph)`
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-family: 'Effra-Regular';

  @media ${({ theme }) => theme.device.tablet} {
    font-size: 1.4rem;
    line-height: 2.2rem;
  }
`;

export const RatingBox = styled(SectionHeading)`
  margin-bottom: 1px;
  display: flex !important;
  align-items: center !important;
  margin-top: 0 !important;
  width: 100%;
  justify-content: center;

  @media ${({ theme }) => theme.device.tablet} {
    margin-bottom: 1px;
    display: flex !important;
    align-items: center !important;
    margin-top: 0 !important;
    width: 100%;

    div {
      width: 21%;
    }
  }

  div {
    width: auto;
  }
  }
  div svg {
    margin-right: 0 !important;
  }
`;

export const RatingText = styled(Paragraph)`
  margin-top: 0.3rem;
  padding-left: 7px;
  font-size: 10px;
  font-weight: 400;
 

  @media ${({ theme }) => theme.device.tablet} {
    font-size: 12px;
  }
`;

export const StyledButton = styled(Button)`
  // margin-top: 4.8rem;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.02em;
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding-top: 1em;
  padding-bottom: 1em;
  height:60px !important;
  width: 100%;
  justify-content: center;


  @media ${({ theme }) => theme.device.tablet} {
    // margin-top: 8.1rem;
    font-size: 20px;
  } 
  @media ${({ theme }) => theme.device.smPhone} {
    padding: 1rem 2.3rem !important;
  }

  img {
    padding-left: 0.5em;
  }
`;

export const StyledRating = styled(Rating)`
  margin-right: 0.4rem;
  width: unset;

  @media ${({ theme }) => theme.device.phone} {
    width: 22% !important;
  }
`;

export const RatingParagraph = styled(Paragraph)`
  span {
    margin-right: .5rem;
    font-size: 13px;
  }
  @media ${({ theme }) => theme.device.phone} {
    span{
      font-size: 11px;
    }
  }
`;