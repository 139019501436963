
import React from "react";
import BlockContent from "@sanity/block-content-to-react";
import getImagePath from "src/utils/getImagePath"
import Link from "src/components/Link"
import { Serializer } from "src/utils/serializer"
import { StyledWrap, StyledButton } from "./style";

export interface RichTextProps {
    data: {
    portableText: [];
  }
}

const LinkHeader = ({ mark, children }) => (
  <span id={mark?.href} className={headerLinkWrapper}>
    { children }
  </span>
);

const smallText = ({ mark, children }) => (
  <small id={mark?.small} className={smallTxt}>
    {children}
  </small>
);

const ImageRenderer = ({ node }) => {
  return (
    <img className="rich-text-img" srcSet={getImagePath(node, true)} alt={node.alt} />
  );
};

const ButtonRenderer = ({ node: { link, text } }) => (
  <StyledButton to={link} className="rich-text-btn">
    { text }
  </StyledButton>
);

const highlight = (props) => <span style={{ backgroundColor: props.mark.color }}>{props.children}</span>;

const ColorRender = (node) => { 
  return React.createElement(node._type ,{ style: { color: node.mark.hex } },[...node.children]);
};


const TableSerializer = ({ node } = node) => {
  let headers = node.rows[0].cells.map((header, i) => { return <th key={i}>{header}</th>; });
  let tableRows = node.rows.slice(1, Infinity).map((tr,i) => { return <tr key={i}> {tr.cells.map((cells, i) => { return <td key={i}>{cells}</td>; })}</tr>;});

  return (
    <div className="table">
      <table>
        <tr>{headers}</tr>
        {tableRows}
      </table>
    </div>
  ); 
};

const TableTwoHeadersSerializer = ({ node } = node) => {
  let headers = node.rows[0].cells.map((header, i) => { return <th key={i}>{header}</th>; });
  let tableRows = node.rows.slice(1, Infinity).map((tr,i) => { return <tr key={i}> {tr.cells.map((cells, i) => { return <td className="side-header" key={i}>{cells}</td>; })}</tr>;});

  return (
    <div className="table">
      <table>
        <tr>{headers}</tr>
        {tableRows}
      </table>
    </div>
  ); 
};

const embedHTMLSerializer = (props : any) => <div dangerouslySetInnerHTML={{ __html: props.node.html }} />

const serializers = {
  types: {
    image: ImageRenderer,
    limitedImage: ImageRenderer,
    button: ButtonRenderer,
    table: TableSerializer,
    tableTwoHeaders: TableTwoHeadersSerializer,
    embedHTML: embedHTMLSerializer
  },
  marks: {
    sectionHeader: LinkHeader,
    color: ColorRender,
    highlight: highlight, 
    small: smallText,
  },
};

const RichText = ({data}: RichTextProps) => {
  const block = data?.portableText;

  return (
    <StyledWrap>
      <div className="rich-text">
        <BlockContent
          blocks={block}
          projectId={process.env.GATSBY_SANITY_PROJECT_ID}
          dataset={process.env.GATSBY_SANITY_DATASET}
          serializers={serializers}
      />
      </div>
    </StyledWrap>
  );
};

export default RichText;
