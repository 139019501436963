import React, { useRef, forwardRef } from "react";
import {
  Description, 
  Slide, 
  StyledSlider, 
  StyledVideo,
  StyledImage,
  StyledName,
  TextBox,
  BoxContainer,
  Author,
  StyledRating,
  VideoBox,
  StyledImageBox
} from "./styles";
import { useTheme } from "styled-components";

interface Props {
  list: any;
  setCurrent: any;
  ref: any;
}

const TestimonialSlider = forwardRef(({ list, setCurrent }: Props, ref) => {
  const theme = useTheme();
  const handleAfter = (index: number) => {
    setCurrent(index);
  };

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    arrows: true,
    dots: true,
    slidesToScroll: 3,
    afterChange: handleAfter,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.1,
          centerMode: true,
          centerPadding: '40px',

          variableWidth: true,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.1,
          centerMode: true,
          centerPadding: '40px',

          variableWidth: true,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  return (
    <StyledSlider {...settings} ref={ref}>
      {list.map((item, i) => {
        return (
          <Slide key={item._key}>
            <Author >
              <StyledImageBox>
                <VideoBox>
                  {item?.video && (
                    <StyledVideo poster={item?.video?.poster} src={item?.videoShopify ? item?.videoShopify : item?.video?.url} />
                  )}
                </VideoBox>
                {item?.author?.image && <StyledImage imageRef={item?.author?.image} />}
              </StyledImageBox>
              <BoxContainer>
                <TextBox>
                  {item?.author?.ratingNumber && <StyledRating number={item?.author?.ratingNumber} color={theme.colors.gold} />}
                  {item?.text && <Description>{item?.text}</Description>}
                  <StyledName>{item?.author?.name}</StyledName>
                </TextBox>
              </BoxContainer>

            </Author>


          </Slide>
        );
      })}
    </StyledSlider>
  );
});

export default TestimonialSlider;