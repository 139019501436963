import styled from 'styled-components';

export const StyledImage = styled.div`
    width: 100%;
    height: 100%;
    margin: 4rem 0 0 0;

    img {
        width: 100%;
        height: 100%;
    }

    .desktopImg {
        display: block;
        @media ${({ theme }) => theme.device.phone} { 
            display: none;

        }
    }

    .mobileImg {
        display: none;

        @media ${({ theme }) => theme.device.phone} { 
            display: block;
        }
    }

`;