import styled from 'styled-components';

export const PaymentIconsStyled = styled.div`
    display: flex;
    flex-drection: row;
    margin: 1rem 0 0 0;
    justify-content: center;

    img {
        width: 55px;
        height: auto;
    }
`;