import React from "react";
import {
  SanityColor,
  SanityImage,
  SectionSettings,
} from "src/interfaces/sanity";
import {
  StyledWrap,
  Container,
  Title,
  Description,
  ImageContainer,
  AdditionalImage,
} from "./styles";

interface Props {
  data: {
    textColor: SanityColor;
    sectionSettings: SectionSettings;
    listIcon: "arrow" | "check";
    heading: string;
    description?: any;
    mobilePadding?: boolean;
    additionalImages?: {
      imageFirst?: SanityImage;
      imageSecond?: SanityImage;
    };
  };
}

const BackgroundText = ({ data }: Props) => {
  const {
    heading,
    description,
    textColor,
    listIcon,
    mobilePadding,
    additionalImages,
  } = data;

  return (
    <StyledWrap mobilePadding={mobilePadding}>
      <Container>
        <Title color={textColor.title}>{heading}</Title>
        {description && (
          <Description
            blocks={description}
            color={textColor.value}
            listIcon={listIcon}
          />
        )}
        {additionalImages && (
          <ImageContainer>
            {additionalImages.imageFirst && (
              <AdditionalImage
                imageRef={additionalImages.imageFirst}
                first={true}
              />
            )}
            {additionalImages.imageSecond && (
              <AdditionalImage imageRef={additionalImages.imageSecond} />
            )}
          </ImageContainer>
        )}
      </Container>
    </StyledWrap>
  );
};

export default BackgroundText;
