import styled from "styled-components";

interface VideoProps {
  videoCornerRounded?: boolean;
}

export const StyledVideo = styled.video<VideoProps>`
  border-radius: ${({ videoCornerRounded}) =>  videoCornerRounded ? '20px' : '0px'};
  width: 100%;
`;
