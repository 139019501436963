import styled, { css } from 'styled-components';
import Image from 'src/components/Common/ImageFluid';
import { SectionHeading, Paragraph } from 'src/components/Text';
import Button from "src/components/Common/Button";
import Link from 'src/components/Link';
import Wrap from 'src/components/Wrap';
import IconList from "src/components/IconList";
import { ListItem, Icon } from "src/components/IconList/styles";
import List from 'src/components/List';
import Video from 'src/components/Video';
import Rating from 'src/components/Rating';
import BlockContent from "src/components/BlockContent";

interface PositionProps {
  imagePosition: 'left' | 'right';
  fullWidthSection?: boolean;
}

interface IconsPosition {
  numberOfcolumns: 'columnTwo' | "columnThree";
}

interface DesktopVersion {
  fullWidthSection?: boolean;
}

interface VideoCornersProps {
  videoCorner?: boolean;
}

interface ImageProps {
  hideMobile?: boolean;
  imageCorner?: boolean;
  fullWidthSection?: boolean;
  linearbackground?: boolean;
  titleAboveImage?: boolean;
}

interface HeadingPosition {
  fullWidthSection?: boolean;
}

interface ParagraphProps {
  fullWidthSection?: boolean;
}

interface BgProps {
  marginBottom?: boolean;
  marginTop?: boolean;
}

interface WrapProps {
  hideImgMobile: boolean;
  fullWidthSection?: boolean;
}

interface TextBlockProps {
  hideImgMobile: boolean;
  imagePosition: 'left' | 'right';
  fullWidthSection?: boolean;
}

interface ImageInfoProps {
  itemNumber: number;
  underlined: boolean;
}

interface ImageArrowProps {
  itemNumber: number;
}

const rowPosStyles = (imagePosition: 'left' | 'right') => {
  switch (imagePosition) {
    case 'left':
      return css`
        flex-direction: row;
      `;
    case 'right':
      return css`
        flex-direction: row-reverse;
      `;
  }
};

const handlePosMargin = (imagePosition: 'left' | 'right') => {
  switch (imagePosition) {
    case 'left':
      return 'auto 0 0';
    case 'right':
      return '0';
    default:
      return 'row';
  }
};

const handlePosPadding = (imagePosition: 'left' | 'right') => {
  switch (imagePosition) {
    case 'left':
      return '10.3rem 10.3rem 0 0;';
    case 'right':
      return '10.3rem 0 0 10.3rem;';
    default:
      return '0 10.3rem 0 0;';
  }
};

const handleIconsPosition = (numberOfcolumns: 'columnTwo' | "columnThree") => {
  switch (numberOfcolumns) {
    case 'columnTwo':
      return css`
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-top: 6rem;
        column-gap: 4rem;
        row-gap: 3rem;
      `;
    case 'columnThree':
      return css`
        display: grid;
        margin-top: 4rem;
        column-gap: 0rem;
        padding-left: 10%;
        grid-template-columns: 1fr 1fr 1fr;
      `;
  }
};

const handleIconsPositionMobile = (numberOfcolumns: 'columnTwo' | "columnThree") => {
  switch (numberOfcolumns) {
    case 'columnTwo':
      return css`
        display: grid;
        grid-template-columns: 1fr;
        column-gap: 2rem;
        margin-top: 4rem;
        row-gap: 5rem;
      `;
    case 'columnThree':
      return css`
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        margin-top: 2rem;
        column-gap: 4rem;
        row-gap: 3rem;
      `;
  }
};

const handleButtonsMargin = (numberOfcolumns: 'columnTwo' | "columnThree") => {
  switch (numberOfcolumns) {
    case 'columnTwo':
      return css`
        margin-top: 5rem;
      `;
    case 'columnThree':
      return css`
        margin-top: 4rem;
      `;
  }
};

const handleButtonsMarginMobile = (numberOfcolumns: 'columnTwo' | "columnThree") => {
  switch (numberOfcolumns) {
    case 'columnTwo':
      return css`
        margin-top: 5rem;
      `;
    case 'columnThree':
      return css`
        margin-top: 2.5rem;
      `;
  }
};

const handleList = (numberOfcolumns: 'columnTwo' | "columnThree") => {
  switch (numberOfcolumns) {
    case 'columnTwo':
      return css`
        flex-direction: row;
        text-align: left;
      `;
    case 'columnThree':
      return css`
        flex-direction: column;
        text-align: center;
      `;
  }
};

const handleIconMobile = (numberOfcolumns: 'columnTwo' | "columnThree") => {
  switch (numberOfcolumns) {
    case 'columnTwo':
      return css`

      `;
    case 'columnThree':
      return css`
      max-width: 40px !important;
      @media ${({ theme }) => theme.device.tablet} {
        max-width: 60px !important;
        width: 50px;
      }
      `;
  }
};
const handleBottomMarginMobile = (numberOfcolumns: 'columnTwo' | "columnThree") => {
  switch (numberOfcolumns) {
    case 'columnTwo':
      return css`
        margin-bottom: 0.5rem
      `;
    case 'columnThree':
      return css`
        margin-bottom: 1.5rem
      `;
  }
};

const handleParagraphMargin = (numberOfcolumns: 'columnTwo' | "columnThree") => {
  switch (numberOfcolumns) {
    case 'columnTwo':
      return css`
        margin-left: 3.85rem;
        width: 64%;
        text-align: left;
      `;
    case 'columnThree':
      return css`
        margin-left: 0rem;
        width: 100%;
        text-align: center;
        align-items: center;
      `;
  }
};

const handleLargeDeviceFont = (numberOfcolumns: 'columnTwo' | "columnThree") => {
  switch (numberOfcolumns) {
    case 'columnTwo':
      return css`
       font-size: 14px;
       line-height: 16.63px;
      `;
    case 'columnThree':
      return css`
      font-size: 26px;
      line-height: 3rem;
      `;
  }
};

const handleTitlePosition = (numberOfcolumns: 'columnTwo' | "columnThree") => {
  switch (numberOfcolumns) {
    case 'columnTwo':
      return css`
        position: absolute;
        top: 5rem;
        margin-top: 0rem;
      `;
    case 'columnThree':
      return css`
        position: relative !important;
        top: 0rem !important;
        margin-top: 2rem;
      `;
  }
};

const handleStyledImage = (numberOfcolumns: 'columnTwo' | "columnThree") => {
  switch (numberOfcolumns) {
    case 'columnTwo':
      return css`
        margin-top: 14rem; 
        margin-left: 5% !important;
        margin-right: 5% !important;
      `;
    case 'columnThree':
      return css`
        margin-top: 0rem !important; 
        margin-left: 0 !important;
        margin-right: 0 !important;
        img{ 
          -webkit-mask-image: linear-gradient(to left, rgba(0, 0, 0, 1.0) 60%, transparent 100%);
        }
      `;
  }
};


export const StyledWrap = styled(Wrap)<WrapProps>`
  /* padding: ${({ hideImgMobile }) =>
    hideImgMobile ? '1.7rem 0' : '3.4rem 0'}; */
    width: ${({ fullWidthSection }) => fullWidthSection ? '100%' : 'unset'};

  @media ${({ theme }) => theme.device.tablet} {
    padding:  ${({ fullWidthSection }) => fullWidthSection ? '0' : '8rem 8.1rem'};
    margin:  ${({ fullWidthSection }) => fullWidthSection ? '0' : '0 auto'};
    width: ${({ fullWidthSection }) => fullWidthSection ? '100%' : 'unset'};
    max-width: ${({ fullWidthSection }) => fullWidthSection ? 'none' : '144rem'};
  }

  @media ${({ theme }) => theme.device.largeDesktop} {
    /* padding: 8rem 10.5rem; */
  }
`;

export const SectionWrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 5rem;
    ${({ numberOfcolumns }) => handleButtonsMargin(numberOfcolumns)};

   @media ${({ theme }) => theme.device.phone} {
     ${({ numberOfcolumns }) => handleButtonsMarginMobile(numberOfcolumns)};
     padding: 0;
  }

  @media ${({ theme }) => theme.device.laptop} {
    padding: 0rem !important;
  }
  @media ${({ theme }) => theme.device.largeDesktop} {
    padding: 0rem 14% !important;
  }
  @media ${({ theme }) => theme.device.tablet} {
    padding: 0 20rem;
  }
  
  
`;


export const BgContainer = styled.div<BgProps>`
  position: relative;

  &.variant_A {
    display: none;
  }
`;

export const StyledLink = styled(Link)`
  display: block;
  margin-top: 2.8rem;
  font-size: ${({ theme }) => theme.fontSize.lg.mobile};
  line-height: ${({ theme }) => theme.lineHeight.lg.mobile};
  font-family: 'Effra-Bold';
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const StyledButton = styled(Button)`
  margin-top: 2.8rem;
  color: #000000;
  display: none;
  justify-content: center;

  @media ${({ theme }) => theme.device.phone} {
    display: flex;
    justify-content: center;
    width: 80%;
    margin: auto;
  }
`;

export const StyledImage = styled(Image)<ImageProps>`
  z-index: 0;
  position: relative;
  max-width: 100%;
  border-radius: ${({ imageCorner }) => (imageCorner ? '20px' : '0px')};
  margin-top: ${({ fullWidthSection }) => fullWidthSection ? '17rem' : '0'};
  margin-left: ${({ fullWidthSection }) => fullWidthSection ? '5%' : '0'};
  margin-right: ${({ fullWidthSection }) => fullWidthSection ? '5%' : '0'};
  ${({ numberOfcolumns }) => handleStyledImage(numberOfcolumns)};
 
  @media ${({ theme }) => theme.device.phone} { 
    margin-top: ${({ titleAboveImage }) => titleAboveImage ? '20rem !important' : '0'};
    vertical-align: bottom;
  }

  img {
      -webkit-mask-image: ${({ linearbackground }) => linearbackground ? 'linear-gradient(to bottom, rgba(0, 0, 0, 1.0) 60%, transparent 100%) !important' : 'unset'}; 
  }
  @media ${({ theme }) => theme.device.tablet} { 
    img {
    -webkit-mask-image: ${({ linearbackground }) => linearbackground ? 'linear-gradient(to left, rgba(0, 0, 0, 1.0) 60%, transparent 100%) !important' : 'unset'};
    }
  }

  &.gatsby-image-wrapper {
    display: ${({ hideMobile }) => (hideMobile ? 'none' : 'inline-block')};
  }

  @media ${({ theme }) => theme.device.laptop} {
    &.gatsby-image-wrapper {
      display: inline-block;
    }

    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    width: 49.24%;
    max-width: ${({fullWidthSection}) => fullWidthSection ? 'none' : '71rem'};
    height: ${({fullWidthSection}) => fullWidthSection ? '100%' : 'unset'};
  }
`;

export const FirstAdditionalImage = styled(Image)`
  z-index: 2;
  left: 79%;
  top: 83%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: absolute !important;
  height: auto;
  width: 18%;
  max-width: 23rem;

  @media ${({ theme }) => theme.device.laptop} { 
    left: 53%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 12%;
  }
`;


export const SecondAdditionalImage = styled(Image)`
  z-index: 1;
  position: absolute !important;
  height: auto;
  left: 10%;
  top: 81%;
  width: 80%;
  max-width: 140rem;

  @media ${({ theme }) => theme.device.phone} { 
    top: 85%;
  }

  @media ${({ theme }) => theme.device.largeDesktop} { 
    left: 71%;
    top: 92% !important;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    width: 44%;
  }
  @media ${({ theme }) => theme.device.laptop} { 
    left: 73%;
    top: 94%;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    width: 48%;
  }

`;

export const ImageInfoWrapper = styled.div`
  position: relative;
`;

export const ImageInfo = styled.div<ImageInfoProps>`
  max-width: 330px;
  position: absolute;
  left: ${({ itemNumber }) => (itemNumber === 1 ? '-70px' : 'none')};
  top: ${({ itemNumber }) =>
    itemNumber === 1 || itemNumber === 2 ? '0' : 'none'};
  bottom: ${({ itemNumber }) => (itemNumber === 3 ? '0' : 'none')};
  right: ${({ itemNumber }) =>
    itemNumber === 2 ? '-50px' : itemNumber === 3 ? '-30px' : 'none'};

  h5 {
    font-size: 21px;
    font-weight: 700;
    margin-bottom: ${({ underlined }) => (underlined ? '12px' : '0')};
    padding-bottom: 0.5rem;
    line-height: 130%;
    border-bottom: ${({ underlined }) =>
      underlined ? '1px solid #fa8e8a' : 'none'};
  }

  span {
    font-family: 'Effra-Medium';
    font-weight: 500;
    letter-spacing: 1px;
    font-size: 16px;

    @media ${({ theme }) => theme.device.mobile} {
      font-size: 12px;
    }
  }
`;

export const ImageInfoMobile = styled.div<ImageInfoProps>`
  max-width: 200px;
  position: absolute;
  left: ${({ itemNumber }) =>
    itemNumber === 1 ? '40px' : itemNumber === 3 ? '40px' : 'none'};
  top: ${({ itemNumber }) => (itemNumber === 1 ? '0' : 'none')};
  bottom: ${({ itemNumber }) =>
    itemNumber === 2 ? '15px' : itemNumber === 3 ? '-96px' : 'none'};
  right: ${({ itemNumber }) =>
    itemNumber === 2 ? '15px' : itemNumber === 3 ? '-200px' : 'none'};

  h5 {
    font-size: 21px;
    font-weight: 700;
    margin-bottom: ${({ underlined }) => (underlined ? '12px' : '0')};
    padding-bottom: 0.5rem;
    line-height: 130%;
    border-bottom: ${({ underlined }) =>
      underlined ? '1px solid #fa8e8a' : 'none'};

    @media ${({ theme }) => theme.device.mobile} {
      font-size: 16px;
    }
  }

  span {
    font-family: 'Effra-Medium';
    font-weight: 500;
    letter-spacing: 1px;
    font-size: 16px;

    @media ${({ theme }) => theme.device.mobile} {
      font-size: 12px;
    }
  }

  @media ${({ theme }) => theme.device.mobile} {
    max-width: 275px;
  }
`;

export const ImageArrow = styled.img<ImageArrowProps>`
  position: absolute;
  bottom: ${({ itemNumber }) =>
    itemNumber === 1 || itemNumber === 2 ? '-100px' : '100px'};
  left: ${({ itemNumber }) =>
    itemNumber === 1
      ? '0px'
      : itemNumber === 2
      ? '150px'
      : itemNumber === 3
      ? '-80px'
      : 'none'};
`;

export const ImageArrowMobile = styled.img<ImageArrowProps>`
  position: absolute;
  height: 57px;
  bottom: ${({ itemNumber }) =>
    itemNumber === 1 || itemNumber === 2 ? '-60px' : '100px'};
  top: ${({ itemNumber }) =>
    itemNumber === 2 ? '-70px' : itemNumber === 3 ? '-80px' : 'none'};
  left: ${({ itemNumber }) =>
    itemNumber === 1
      ? '50px'
      : itemNumber === 2
      ? '150px'
      : itemNumber === 3
      ? '0'
      : 'none'};
`;

export const CustomStyledImage = styled(Image)<ImageProps>`
  z-index: 1;
  position: relative;
  max-width: 100%;
  border-radius: ${({ imageCorner }) => (imageCorner ? '20px' : '0px')};

  img {
    width: 100%;
    height: 100%;
  }

  &.gatsby-image-wrapper {
    display: ${({ hideMobile }) => (hideMobile ? 'none' : 'flex')};
  }
`;

export const TextBlock = styled.div<TextBlockProps>`
  /* padding: 1.7rem 2rem 0; */
  padding: ${({ hideImgMobile }) =>
    hideImgMobile ? '1.25rem 2rem' : '2rem 2rem 0'};
  position: relative;
  z-index: 1;
  max-width: 100rem;
  margin: ${({ fullWidthSection }) => fullWidthSection ? '0 0 0 auto' : 'unset'};
  height: 100%;
  align-content: center;

  @media ${({ theme }) => theme.device.mobile} {
    .videoArrow {
      display: none;
      width: 100%;
      height: 100%
    }
  }

  @media ${({ theme }) => theme.device.laptop} {
    padding: ${({ fullWidthSection }) => fullWidthSection ? '4rem' : '0'};
    width: ${({ fullWidthSection }) => fullWidthSection ? '55%' : '42%'};
    max-width: ${({ fullWidthSection }) => fullWidthSection ? '100rem' : '55rem'};
  }
  
  @media ${({ theme }) => theme.device.largeDesktop} {
    width: ${({ fullWidthSection }) => fullWidthSection ? '55%' : '38.194%'};
    padding: ${({ fullWidthSection }) => fullWidthSection ? '8rem !important' : '0'};
  }

  @media ${({ theme }) => theme.device.laptop} {
    .videoArrow {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      height: 100%
    }
  }
`;

export const NewTextBlock = styled.div<TextBlockProps>`
  /* padding: 1.7rem 2rem 0; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: ${({ hideImgMobile }) =>
    hideImgMobile ? '1.25rem 2rem' : '2rem 2rem 0'};
  position: relative;
  z-index: 1;

  @media ${({ theme }) => theme.device.mobile} {
    .videoArrow {
      display: none;
      width: 100%;
      height: 100%
    }
  }

  @media ${({ theme }) => theme.device.tablet} {
    padding: ${({ fullWidthSection }) => fullWidthSection ? '8rem' : '0'};
    width: ${({ fullWidthSection }) => fullWidthSection ? '55%' : '42%'};
    max-width: ${({ fullWidthSection }) => fullWidthSection ? 'none' : '55rem'};
  }

  @media ${({ theme }) => theme.device.largeDesktop} {
    width: ${({ fullWidthSection }) => fullWidthSection ? '55%' : '38.194%'};
  }

  @media ${({ theme }) => theme.device.laptop} {
    .videoArrow {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      height: 100%
    }
  }
`;

export const StyledRow = styled.div<PositionProps>`
  padding: 0rem;
  display: ${({ fullWidthSection }) => fullWidthSection ? 'flex': 'block'};
  flex-direction: ${({ fullWidthSection }) => fullWidthSection ? 'column-reverse': 'unset'};
  position: relative;
  width: ${({ fullWidthSection }) => fullWidthSection ? '100% !important': 'unset'};

  @media ${({ theme }) => theme.device.tablet} {
    padding: 0 0 2rem;
    flex-direction: ${({ fullWidthSection }) => fullWidthSection ? 'column-reverse': 'unset'};
  }

  @media ${({ theme }) => theme.device.laptop} {
    display: flex;
    ${({ imagePosition }) => rowPosStyles(imagePosition)}
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom:  ${({ fullWidthSection }) => fullWidthSection ? '0 !important': '8.3rem'};
    padding: 0;

    &:last-child {
      margin-bottom: 0;
    }

    &:first-child {
      ${TextBlock} {
        padding-top: 1.8rem;
      }
    }
  }

  @media ${({ theme }) => theme.device.laptop} {
    margin-bottom: 4rem;
  }

  @media ${({ theme }) => theme.device.largeDesktop} {
    margin-bottom: 8.3rem;
  }
`;

export const NewStyledRow = styled.div<PositionProps>`
  padding: 0 0 12rem;

  @media ${({ theme }) => theme.device.tablet} {
    display: flex;
    ${({ imagePosition }) => rowPosStyles(imagePosition)}
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: ${({ fullWidthSection }) => fullWidthSection ? '0 !important': '8.3rem'};
    padding: 0;
    width: ${({ fullWidthSection }) => fullWidthSection ? '100%': 'unset'};

    &:last-child {
      margin-bottom: 0;
    }

    &:first-child {
      ${TextBlock} {
        padding-top: 1.8rem;
      }
    }
  }

  @media ${({ theme }) => theme.device.laptop} {
    margin-bottom: 4rem;
    padding: 0 0 2rem;
  }

  @media ${({ theme }) => theme.device.largeDesktop} {
    margin-bottom: 8.3rem;
  }
`;

export const StyledHeading = styled(SectionHeading)<HeadingPosition>`
  margin: 0 0 2.3rem 0;
  text-align: ${({fullWidthSection}) => fullWidthSection ? 'center': 'left'};
  // padding-left: ${({fullWidthSection}) => fullWidthSection ? '10%': '0'};
  // padding-right: ${({fullWidthSection}) => fullWidthSection ? '10%': '0'};
  font-size: ${({fullWidthSection}) => fullWidthSection ? '40px': '4.4rem'};
  font-family: 'Effra-Bold';
  font-weight: ${({fullWidthSection}) => fullWidthSection ? '700': '400'};
  width: ${({fullWidthSection}) => fullWidthSection ? '100%': 'auto'};

  @media ${({theme}) => theme.device.phone } {
    position: ${({ numberOfcolumns, fullWidthSection }) => numberOfcolumns && fullWidthSection ? 'absolute' : 'unset'};
    ${({ numberOfcolumns }) => handleTitlePosition(numberOfcolumns)};
    top: ${({ fullWidthSection }) => fullWidthSection ? '5rem' : 'unset'};
    font-size: ${({fullWidthSection}) => fullWidthSection ? '25px': '2.4rem'};
    left: ${({fullWidthSection}) => fullWidthSection ? '0': 'unset'};
    padding: ${({fullWidthSection}) => fullWidthSection ? '0 2rem': 'unset'};
  }
`; 

export const StyledParagraph = styled(Paragraph)<ParagraphProps>`
  margin: 0;
  font-family: 'Effra-Regular';
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  text-align: ${({fullWidthSection}) => fullWidthSection ? 'center': 'left'};
  padding-right: ${({fullWidthSection}) => fullWidthSection ? '2rem': 'unset'};
  padding-left: ${({fullWidthSection}) => fullWidthSection ? '2rem': 'unset'};


  @media ${({ theme }) => theme.device.tablet} {
    font-size: 16px;
  }
`;

export const NewStyledHeading = styled(SectionHeading)`
  font-size: 44px;
  font-weight: 700;
  line-height: 54px;
  margin: 0 0 2.3rem 0;

  @media ${({ theme }) => theme.device.phone} {
    font-size: 24px;
    max-width: 210px;
  }
`;

export const NewStyledParagraph = styled(Paragraph)`
  font-family: 'Effra-Regular';
  font-weight: 400;
  margin: 0;
  white-space: initial;
  font-weight: 300;
  line-height: 24px;

  @media ${({ theme }) => theme.device.tablet} {
    white-space: pre-line;
  }
`;

export const StyledList = styled(List)`
  margin: 2.8rem 0 0 0;
`;

export const VideoStyle = styled(Video)<VideoCornersProps>`
  width: 100%;
  @media ${({ theme }) => theme.device.mobile} {
    width: 94%;
    margin: 0 1rem;
  }
  @media ${({ theme }) => theme.device.tablet} {
    width: 50%;
  }
  border-radius: ${({ videoCorner }) => (videoCorner ? '20px' : '0px')};
`;

export const ContentImage = styled.img`
  max-width: 10.7rem;
  margin-bottom: 1rem;
`;

export const RatingsList = styled(Rating)`
  display: flex;
  margin-top: 0.3rem;

  svg {
    width: 1.283rem;
    height: 1.283rem;
    margin-right: 0.321rem;
  }
`;

export const ReviewerName = styled.div`
  font-size: 2rem;
  line-height: 1.8rem;
  font-family: 'Effra-Regular';
  margin: 1.5rem 0;
  font-weight: 400;
  color: #0f0923;
  @media ${({ theme }) => theme.device.mobile} {
    font-size: 1.8rem;
  }
  @media ${({ theme }) => theme.device.laptop} {
    font-size: 2rem;
  }
`;

export const StyledRating = styled(Rating)`
  margin-right: 0.4rem; 

  @media ${({ theme }) => theme.device.phone} {
    width: 22% !important;
  }
`;

export const TextContainerMobile = styled.div`
  display: flex;
  flex-direction: column;
  margin: 4rem 2rem;
`;
export const MobileVersion = styled.div`
  display: none;
  

  @media ${({ theme }) => theme.device.mobile} {
    display: flex;
  }
  
  }
  @media ${({ theme }) => theme.device.tablet} {
    display: flex;
    flex-direction: column-reverse;
  }
  @media ${({ theme }) => theme.device.laptop} {
    display: none;
  }
`;

export const NewTextContainerMobile = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem 2rem 6rem;
`;

export const DesktopVersion = styled.div<DesktopVersion>`
  display: flex;
  margin:  ${({ fullWidthSection }) => fullWidthSection ? '0': '2.5rem 0 0 0'};
  @media ${({ theme }) => theme.device.mobile} {
    display: none;
  }
  @media ${({ theme }) => theme.device.tablet} {
    display: none;
  }
  @media ${({ theme }) => theme.device.laptop} {
    display: flex;
  }
`;

export const ReviewerNameStars = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
`;

export const ImageTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;
export const StyledThumbnail = styled(Image)`
  width: 100px;
  height: 90px;
  border-radius: 50px;
  margin: 0 2rem 0 0;
`;

export const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
`;

export const ImageTextContainerMobile = styled.div<ImageArrowProps>`
  display: flex;
  flex-direction: ${({ itemNumber }) =>
  itemNumber === 2 ? 'row-reverse' : 'row'};
  width: 100%;
`;
export const StyledThumbnailMobile = styled(Image)<ImageArrowProps>`
  width: 90px;
  height: 60px;
  border-radius: 50px;
  margin: ${({ itemNumber }) =>
  itemNumber === 2 ? '0 0 0 2rem' : '0 2rem 0 0'};
`;

export const StyledTextContainerMobile = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TopIconList = styled(IconList)`
  ${({ numberOfcolumns }) => 
    numberOfcolumns ? handleIconsPositionMobile(numberOfcolumns):
    `
      margin: 4rem 0;
    `  
};

  @media ${({ theme }) => theme.device.tablet} {
    padding-left: 0;
    padding-right: 0;
    ${({ numberOfcolumns }) => 
      numberOfcolumns ? handleIconsPosition(numberOfcolumns):
      `
        justify-content: center;
        gap: 3rem;
        margin: 4rem 0;
      `
    };
  }

  ${ListItem} {
    align-items: center;
    margin-right: 0;
    max-width: none;
    display: flex;
    justify-content: center;
    ${({ numberOfcolumns }) => 
      numberOfcolumns ? handleList(numberOfcolumns) : `gap: 1rem`};

    @media ${({ theme }) => theme.device.tablet} {
      flex-direction: row;
      align-items: center;
      margin-right: 0;
      text-align: left;
      max-width: none;
      display: flex;
      justify-content: center;
    }
  }

  ${Icon} {
    margin-bottom: 0.5rem;
    margin-right: 0;
    overflow: visible;
    max-height: unset !important;
    flex:1;
  
    ${({ numberOfcolumns }) => handleBottomMarginMobile(numberOfcolumns)};

    div {
      ${({ numberOfcolumns }) => handleIconMobile(numberOfcolumns)};
    }

    @media ${({ theme }) => theme.device.tablet} {
      margin-bottom: 0.5rem;
      margin-right: 0;
      // height: 60px;
      // max-height: 60px;
      overflow: visible;
      flex:1;
    }    
    
  }
  ${Paragraph} {
    font-size: 14px;
    ${({ numberOfcolumns }) => handleParagraphMargin(numberOfcolumns)};
    font-weight: 400;
    font-family: 'Effra-Regular';
    flex: 6;
    display: flex;
    justify-content:center;

    @media ${({ theme }) => theme.device.tablet} {
      font-size: 14px;
      margin: 0 2rem 0 1.5rem;
      font-weight: 400;
      font-family: 'Effra-Regular';
      width: 64%;
      flex: 6;
      justify-content: left;
    }
  }
`;

export const RatingBox = styled(SectionHeading)`
  margin-bottom: 1px;
  display: flex !important;
  align-items: center !important;
  margin-top: 0 !important;
  width: 100%;
  justify-content: center;

  @media ${({ theme }) => theme.device.tablet} {
    margin-bottom: 1px;
    display: flex !important;
    align-items: center !important;
    margin-top: 0 !important;
    width: 100%;
    padding: 0.75rem 0px 0;
    margin: 0.6rem 0px 0;

    div {
      width: 21%;
    }
  }

  div {
    width: auto;
  }
  div svg {
    margin-right: 0 !important;
  }
`;
export const RatingParagraph = styled(Paragraph)`
  span {
    margin-right: .5rem;
    font-size: 13px;
  }
  @media ${({ theme }) => theme.device.phone} {
    span{
      font-size: 11px;
    }
  }
`;

export const RatingBoxSecond = styled(SectionHeading)`
  margin-bottom: 1px;
  display: flex !important;
  align-items: center !important;
  justify-content: center;
  margin-top: 0 !important;
  width: 100%;

  @media ${({ theme }) => theme.device.tablet} {
    margin-bottom: 1px;
    display: flex !important;
    align-items: center !important;
    margin-top: 0 !important;
    width: 100%;
    div {
      width: 13% !important;
    }
  }
  @media ${({ theme }) => theme.device.lasrgestDevice} {
    margin-bottom: 1px;
    display: flex !important;
    align-items: center !important;
    margin-top: 0 !important;
    width: 100%;
    div {
      width: auto !important;
    }
  }

  div {
    width: auto;
    @media ${({ theme }) => theme.device.tablet} {
      width: 20%;
   }
   @media ${({ theme }) => theme.device.phone} {
    width: 20% !important;
 }
  }
  div svg {
    margin-right: 0 !important;
  }
`;

export const RatingText = styled(Paragraph)`
  margin-top: 0.3rem;
  padding-left: 7px;
  font-size: 10px;
  font-weight: 400;

  @media ${({ theme }) => theme.device.tablet} {
    font-size: 12px;
  }
  @media ${({ theme }) => theme.device.laptopDesktop} {
    font-size: 10px;
  }
`;

export const StyledButtonSecond = styled(Button)`
  // margin-top: 4.8rem;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.02em;
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding-top: 1em;
  padding-bottom: 1em;
  height:60px !important;
  // padding-left: 6rem;
  // padding-right: 6rem;
  width: 100%;
  justify-content: center;

  @media ${({ theme }) => theme.device.tablet} {
    // margin-top: 8.1rem;
    font-size: 20px;
    // padding-left: 7rem;
    // padding-right: 7rem;
  } 
  @media ${({ theme }) => theme.device.laptopDesktop} {
    font-size: 18px;
  }
  
  @media ${({ theme }) => theme.device.smPhone} {
    padding: 1rem 2.3rem !important;
  }

  img {
    padding-left: 0.5em;
  }
`;

export const StyledImageSecond = styled(Image)`
  width: 100%;
  margin-top: 1rem;
  padding: 0 5rem;

` 
export const StyledAboveButtonText = styled(BlockContent)`
  font-family: 'Effra-Regular';
  font-weight: 400;
  font-size: 14px;
  margin: 0.4rem 0 1rem 0;
  // margin-bottom: 1rem;

  p {
    text-transform: uppercase;
  }

  @media ${({ theme }) => theme.device.tablet} {
    font-size: 16px; 
    // margin-bottom: 1.5rem;
    text-align: center;
  }

  strong {
    font-weight: 700;
    text-transform: uppercase;
  }
`;

export const ImagesBox = styled.div`
    position: relative;
    display: flex;
    justify-content: center;

`;

export const MobileView = styled.div`
  display: block;
  padding: 4rem 0 3rem 0;

  ${({ numberOfcolumns }) => 
    numberOfcolumns  === "off" &&
    `
      ${RatingBox} {
        padding-top: 10rem;
      }
    `}
`;

