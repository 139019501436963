import styled, { css } from 'styled-components';
import Image from '../../components/Common/ImageFluid';
import { SectionHeading, Paragraph } from '../../components/Text';
import Link from '../../components/Link';
import Wrap from '../../components/Wrap';
import Button from 'src/components/Common/Button';
import List from '../../components/List';
import Video from '../../components/Video';
import Rating from '../../components/Rating';


interface PositionProps {
  imagePosition: 'left' | 'right';
}


interface ImageProps {
  hideMobile?: boolean;
  imageCorner?: boolean;
}

interface BgProps {
  marginBottom?: boolean;
  marginTop?: boolean;
}

interface TextBlockProps {
  hideImgMobile: boolean;
  imagePosition: 'left' | 'right';
}

interface ImageInfoProps {
  itemNumber: number;
  underlined: boolean;
}


const rowPosStyles = (imagePosition: 'left' | 'right') => {
  switch (imagePosition) {
    case 'left':
      return css`
        flex-direction: row;
      `;
    case 'right':
      return css`
        flex-direction: row-reverse;
      `;
  }
};

const handlePosMargin = (imagePosition: 'left' | 'right') => {
  switch (imagePosition) {
    case 'left':
      return 'auto 0 0';
    case 'right':
      return '0';
    default:
      return 'row';
  }
};

const handlePosPadding = (imagePosition: 'left' | 'right') => {
  switch (imagePosition) {
    case 'left':
      return '10.3rem 10.3rem 0 0;';
    case 'right':
      return '10.3rem 0 0 10.3rem;';
    default:
      return '0 10.3rem 0 0;';
  }
};

export const StyledWrap = styled(Wrap)<PositionProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  /* padding: ${({ hideImgMobile }) =>
    hideImgMobile ? '1.7rem 0' : '3.4rem 0'}; */

  @media ${({ theme }) => theme.device.tablet} {
    ${({ imagePosition }) => rowPosStyles(imagePosition)}
    padding: 4rem 8.1rem;
  }

  @media ${({ theme }) => theme.device.largeDesktop} {
    /* padding: 8rem 10.5rem; */
  }
`;

export const BgContainer = styled.div<BgProps>`
  position: relative;
`;

export const StyledHeading = styled.div`
  margin: 3rem auto;
  width: 100%;
  display: block;

  h1 {
    font-style: normal;
    font-weight: 700;
    line-height: 1.2;
    font-size: 35px;
    font-family: 'Effra-Bold';

    text-align: center;
    @media ${({ theme }) => theme.device.tablet} {
    font-size: 58px;
    }
  }

  h2 {
    font-style: normal;
    font-weight: 600;
    line-height: 1.2;
    font-size: 32px;
    font-family: 'Effra-Bold';
    text-align: center;
    @media ${({ theme }) => theme.device.tablet} {
    font-size: 52px;
    }
  }

  h3 {
    font-style: normal;
    font-weight: 500;
    line-height: 1.2;
    font-size: 28px;
    font-family: 'Effra-Medium';
    text-align: center;
    @media ${({ theme }) => theme.device.tablet} {
    font-size: 44px;
    }
  }

  h4 {
    font-style: normal;
    font-weight: 600;
    line-height: 1.2;
    font-size: 28px;
    text-align: center;
    font-family: 'Effra-Bold';
    @media ${({ theme }) => theme.device.tablet} {
    font-size: 38px;
    }
  }

  h5 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.2;
    text-align: center;
    font-family: 'Effra-Medium';
    @media ${({ theme }) => theme.device.tablet} {
    font-size: 24px;
    }
  }

  h6 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.2;
    text-align: center;
    font-family: 'Effra-Medium';
    @media ${({ theme }) => theme.device.tablet} {
    font-size: 20px;
    }
  }

  p {
    padding: 10px 0;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 1.3;
    text-align: center;
    font-family: 'Effra-Regular';
    @media ${({ theme }) => theme.device.tablet} {
    font-size: 18px;
    }
  }

  strong {
    font-weight: 900;
    font-family: 'Effra-Bold';
  }

  @media ${({ theme }) => theme.device.tablet} { 
    width: 80%;
    margin: 3rem auto 6rem auto;
  }

`;

export const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  flexo-direction: row;
  justify-content: space-between;
  position: relative;

  .white-arrow {
    transform: translate(-50%, 10px);
    left: 50%;
    position: absolute;
    width: 15%;
  }
`;

export const LeftPartTitle = styled(Paragraph)`

  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 1.2;
  padding: 30px 0;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Effra-Bold';
  color: #FFFFFF;

  @media ${({ theme }) => theme.device.tablet} {
    font-size: 30px;
  }


`
export const LeftPart = styled.div`
  width: 48%;
  padding: 10px;
  background: ${({ theme }) => theme.colors.font_black};
  border-radius: 5px;

`;

export const ListLefPart = styled.ul`
  background: #FFFFFF;
  border-radius: 5px;


`

export const ListItemLeftPart = styled.li`
  border-bottom: 1px solid #E1E1E1;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 110%;
  list-style-type: none;
  font-family: 'Effra-Regular';
  display: flex;
  margin: 0 0 0 10px;
    align-items: flex-start;
  color: ${({ theme }) => theme.colors.dark_blue};
  padding: 20px 0;

  @media ${({ theme }) => theme.device.tablet} { 
    font-size: 20px;
    margin: 0 0 0 30px;
  }

  img {
    margin: 2px 10px 0 0;
  }
`;




export const RightPart = styled.div`
  width: 48%;
  background:  ${({ theme }) => theme.colors.blue_yonder};;
  padding: 10px;
  border-radius: 5px;
`;
export const ListRightPart = styled.ul`
  background: #FDFDEE;
  border-radius: 5px;
`

export const ListItemRightPart = styled.li`
  border-bottom: 1px solid #E1E1E1;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 110%;
  list-style-type: none;
  font-family: 'Effra-Bold';
  display: flex;
  align-items: flex-start;
  margin: 0 0 0 10px;
  color: ${({ theme }) => theme.colors.dark_blue};
  padding: 20px 0;
  @media ${({ theme }) => theme.device.tablet} { 
    font-size: 20px;
    margin: 0 0 0 30px;
  }

  img {
    margin: 2px 10px 0 0;
  }
`


export const RightPartTitle = styled(Paragraph)`

font-style: normal;
font-weight: 900;
font-size: 14px;
line-height: 1.2;
padding: 30px 0;
font-family: 'Effra-Bold';
text-align: center;
text-transform: uppercase;

color: #FFFFFF;

@media ${({ theme }) => theme.device.tablet} {
  font-size: 30px;
}
`