import { useEffect, useRef, useState } from 'react';

type UseInViewResult = [
  React.RefObject<Element>,
  boolean
];

const useInView = (): UseInViewResult => {
  const [isInView, setIsInView] = useState(false);
  const ref = useRef<Element>(null);

  useEffect(() => {
    const element = ref.current;

    const handleIntersection: IntersectionObserverCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !isInView) {
          setIsInView(true);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: '0px',
      threshold: 0 // Adjust the threshold as needed
    });

    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, []);

  return [ref, isInView];
};

export default useInView;