import React from "react";
import {
  StyledWrap,
  StyledHeading,
  TextContainer,
  ImageContainer,
  StyledImage,
  ListContainer,
  ListItem,
  ListTitle,
  ListDescription,
  StyledButton,
  RatingBox,
  RatingText,
  StyledWrapButton,
  StyledWrapHeading,
  StyledWrapTextContainer,
  StyledImagePayment,
  StyledRating,
  RatingParagraph,
} from "./styles";
import {
  SectionSettings,
  SanityImage,
} from "../../interfaces/sanity";
import Rating from "src/components/Rating";
import { useTheme } from "styled-components";
import ArrowRight from 'src/images/rightArrow.svg';
import locale from "src/locales";
import PaymentIcons from "src/components/PaymentIcons";

interface Props {
  data: {
    title: string;
    image: any;
    list: [];
    imageAlignment: "flex-start" | "center" | "flex-end";
    button?: {
      enabled: boolean;
      link: string;
      text: string;
    };
    reviews?: {
      enabled: boolean;
      productReviews: any;
    };
    okendoReviews: {
      totalReviews: number;
      rating: number;
    },
    paymentIcons?: boolean;
    experiment: {
      variantA?: boolean;
      variantB?: boolean;
    };
    sectionSettings: SectionSettings;
  };
  context: any;
  isSection?: boolean;
}

const ListImageSection = ({ data, context }: Props) => {

  const { button, paymentIcons, reviews } = data;
  const theme = useTheme();

  const renderButton = (
    <StyledButton to={button?.link}>{button?.text}<img src={ArrowRight} alt="ArrowRight" /></StyledButton>
  );

  const renderReviews = (
    <RatingBox>
      <StyledRating fieldName="listImageSection" number={data?.okendoReviews?.rating} color={theme.colors.yellow} />
      <RatingParagraph>
        <span>Rated {data?.okendoReviews?.rating} out of 5 by {data?.okendoReviews?.totalReviews} happy customers</span>
      </RatingParagraph>
    </RatingBox>
  )

  return (
    <StyledWrap className={data?.experiment?.variantB ? 'variant_B' : data?.experiment?.variantA ? 'variant_A' : ''}>
      <StyledWrapHeading>
        <StyledHeading>{data.title}</StyledHeading>
      </StyledWrapHeading>
      <StyledWrapTextContainer>
        <TextContainer>
          <ListContainer noPoints={data.list.length === 1}>
            {data.list.map((item, index) => {
              return (
                <ListItem key={index}>
                  <ListTitle>{item.title}</ListTitle>
                  <ListDescription>{item.description}</ListDescription>
                </ListItem>
              );
            })}
          </ListContainer>
        </TextContainer>
        <ImageContainer alignment={data.imageAlignment}>
          <StyledImage imageRef={data.image} alt={data?.image?.alt} />
        </ImageContainer>
      </StyledWrapTextContainer>
      <StyledWrapButton style={reviews?.enabled ? { display: "flex" } : { display: "none" }}>
        {reviews?.enabled && renderReviews}
        {button?.enabled && renderButton}
        {paymentIcons && <PaymentIcons />}
      </StyledWrapButton>
    </StyledWrap>
  );
};

export default ListImageSection;
