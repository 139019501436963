import styled, { css } from "styled-components";
import Image from "src/components/Common/ImageFluid";
import Wrap from "src/components/Wrap";
import { SectionHeading } from "src/components/Text";
import BlockContent from "src/components/BlockContent";

interface WrapProps {
  position: "left" | "right";
  bottomMargin: boolean;
}

interface ImageProps {
  negativeTopMargin: boolean;
}

const positionWrap = (position: "left" | "right") => {
  switch (position) {
    case "left":
      return css`
        flex-direction: row;
      `;

    case "right":
      return css`
        flex-direction: row-reverse;
      `;
  }
};

const positionBlock = (position: "left" | "right") => {
  switch (position) {
    case "left":
      return css`
        /* padding: 6.5rem 8rem 0 0;*/
        margin-left: 9rem;
      `;

    case "right":
      return css`
        /* padding: 6.5rem 0 0 8rem; */
        margin-right: 9rem;
      `;
  }
};

export const StyledWrap = styled(Wrap)<WrapProps>`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @media ${({ theme }) => theme.device.tablet} {
    padding: 6.2rem 8.1rem 6.6rem;
    /* padding: ${({ bottomMargin }) => (bottomMargin ? "0 0 6.6rem" : "0")}; */
    ${({ position }) => positionWrap(position)}
  }
`;

export const StyledImage = styled(Image)<ImageProps>`
  flex-shrink: 0;

  @media ${({ theme }) => theme.device.tablet} {
    width: 49.2361vw;
    max-width: 70.9rem;
    /* margin-top: ${({ negativeTopMargin }) =>
      negativeTopMargin ? "-3rem" : "0"}; */
  }
`;

export const Title = styled(SectionHeading)`
  margin-bottom: 2.7rem;
  white-space: initial;

  @media ${({ theme }) => theme.device.tablet} {
    white-space: pre-line;
    line-height: 5.4rem;
    margin-bottom: 5.2rem;
  }
`;

export const Description = styled(BlockContent)`
  white-space: pre-line;
  color: ${({ theme, color }) => theme.colors[color as string]};
  font-size: ${({ theme }) => theme.fontSize.base.mobile};
  line-height: ${({ theme }) => theme.lineHeight.base.mobile};
  font-family: 'Effra-Regular';
  font-weight: 400;

  @media ${({ theme }) => theme.device.tablet} {
    font-size: ${({ theme }) => theme.fontSize.base.desktop};
    line-height: ${({ theme }) => theme.lineHeight.base.desktop};
  }
`;

export const TextBlock = styled.div`
  padding: 4.5rem 2rem 6rem;

  @media ${({ theme }) => theme.device.tablet} {
    padding: 0;
    ${({ position }) => positionBlock(position)}
  }
`;
