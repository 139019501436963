import React, { useRef } from "react";
import Slider from "react-slick";
import {
  StyledWrap,
  StyledTextContainer,
  StyledBlogContainer,
  StyledBlogItems,
  StyledImage,
  StyledSection,
  StyledButton
} from "./style";
import { SanityImage } from "src/interfaces/sanity";
import Link from "src/components/Link"
import RightArrow from "src/images/right-arrow.svg";
import LeftArrow from "src/images/left-arrow.svg";

interface Props {
  data: {
    title: string;
    subtitle: string;
    singleBlog: [
      {
        name: string;
        description: string;
        link: string;
        image: SanityImage;

      }
    ]
  }
}

const BlogGroup = ({ data }: Props) => {
  let _slider = useRef(null);

  const previous = () => {
    _slider.slickPrev();
  };

  const next = () => {
    _slider.slickNext();
  };

  const blogLength = (data?.singleBlog?.length <= 4) ? 1 : 2;

  const settings = {
    rows: blogLength,
    speed: 500,
    arrows: false,
    slidesToShow: 1,
    slidesPerRow: 4,
    slidesToScroll: 1,
    className: "blogsGroup",

    responsive: [
      {
        breakpoint: 1200,
        settings: {
          rows: blogLength,
          slidesToShow: 1,
          slidesPerRow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesPerRow: 2,
          slidesToScroll: 1,
          rows: 2,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesPerRow: 1,
          slidesToScroll: 1,
          rows: 1,
        }
      },
    ]
  };

  return (
    <StyledSection id="Blog-group">
      <StyledWrap>
        <h1 className="blog-group-title">{data?.title}</h1>
        <p className="blog-group-subtitle">{data?.subtitle}</p>

        <div className="blog-groups-container">
          <Slider ref={c => (_slider = c)} {...settings}>
            {data?.singleBlog?.map((bg) => {
              return (
                <Link key={bg._key} to={bg.link}>
                  <div className="main-div">
                    <StyledImage imageRef={bg?.image} />
                    <div className="bg-content">
                      <div className="title">
                        <h3 className="name">{bg?.name}</h3>
                        <p className="description">{bg?.description}</p>
                      </div>
                      <div className="learn-more-container">
                        <h5 className="learn-more">Learn more</h5>
                      </div>
                    </div>
                  </div>
                </Link>
              );
            })}
          </Slider>
          <div className="slider-arrows">
            <StyledButton onClick={previous}>
              <span className="prev-icon"><img src={LeftArrow} /></span>
            </StyledButton>
            <StyledButton onClick={next}>
              <span className="next-icon"><img src={RightArrow} /></span>
            </StyledButton>

          </div>
        </div>
      </StyledWrap>
    </StyledSection>
  )
}

export default BlogGroup;