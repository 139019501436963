import React from "react";
import {
  StyledWrap,
  Heading,
  Description,
  Author,
  StyledLink,
  Container,
  StyledButton,
} from "./styles";
import { SectionSettings, Product } from "../../interfaces/sanity";
import { useTheme } from "styled-components";
import Rating from "src/components/Rating";

interface Props {
  data: {
    sectionSettings: SectionSettings;
  };
}

const TestimonialSection = ({ data }: Props) => {
  const theme = useTheme();

  return (
    <StyledWrap>
      <Container>
        <Heading color="font_white">{data.title}</Heading>
        <Description color="font_white">{data.description}</Description>
        {data.author && <Author color="font_white">{data.author}</Author>}
        <Rating number={data.ratingNumber} color={theme.colors.yellow} />
        {data.button ? (
          <StyledButton to={data.linkUrl}>{data.linkText}</StyledButton>
        ) : (
          <StyledLink to={data.linkUrl} color="geraldine">
            {data.linkText}
          </StyledLink>
        )}
      </Container>
    </StyledWrap>
  );
};

export default TestimonialSection;
