import ImageFluid from 'src/components/Common/ImageFluid';
import Button from 'src/components/Common/Button';
import Icon from 'src/components/Common/Icon';
import Rating from 'src/components/Rating';
import { Paragraph, SectionHeading } from 'src/components/Text';
import styled from 'styled-components';
import arrow from 'src/images/select_arrow.svg';

interface ButtonProps {
  color: '#FA8E8A' | '#4f6ea8';
}

export const Container = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-grow: 1;
  background-color: ${({ theme }) => theme.colors.bg_white};
  width: 100%;
  margin: 0 10rem;

  @media ${({ theme }) => theme.device.tablet} {
    flex-direction: row;
    // margin-right: 2rem;
  }

  @media ${({ theme }) => theme.device.phone} {
    flex-direction: column;
    margin: unset;
  }
`;

export const ListContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const StyledImage = styled(ImageFluid)`
  object-fit: cover;

  @media ${({ theme }) => theme.device.tablet} {
    max-width: 35rem;
  }

  @media ${({ theme }) => theme.device.phone} {
    max-width: unset;
    max-height: 19rem;
  }
`;

export const InfoContainer = styled.div`
  padding: 1rem 4rem 1rem 1.5rem;
  width: 100%;

  @media ${({ theme }) => theme.device.tablet} {
    padding: 2rem 3.5rem;
  }

  @media ${({ theme }) => theme.device.phone} {
    padding: 1.5rem;
  }
`;

export const InfoWrap = styled.div`
  display: flex;
  align-items: baseline;
`;

export const Title = styled(SectionHeading)`
  font-size: 1.6rem;
  line-height: 2rem;
  margin-bottom: 1rem;

  @media ${({ theme }) => theme.device.tablet} {
    font-size: 1.8rem;
    line-height: 2.4rem;
  }
`;

export const PriceContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${({ theme }) => theme.device.phone} {
    flex-direction: column;
    align-items: unset;
  }
`;

export const Price = styled(SectionHeading)`
  font-size: 1.4rem;
  line-height: 1.8rem;
  font-weight: bold;

  @media ${({ theme }) => theme.device.tablet} {
    font-size: 1.8rem;
    line-height: 2.4rem;
  }
`;

export const ComparePrice = styled.span`
  color: #c3c1c8;
  text-decoration: line-through;
  margin-left: 1rem;
`;

export const PopUpDiscount = styled.span`
  padding: 5px 10px;
  background-color: #0f0824;
  color: white;
  border-radius: 40px;
  font-size: 14px;
  text-align: center;
  width: 30%;

  @media ${({ theme }) => theme.device.phone} {
    position: absolute;
    top: 2rem;
    right: 2rem;

    margin-left: 1rem;
    padding: 6px 10px;
    font-size: 18px;

    width: unset;
  }
`;

export const StyledButton = styled(Button)<ButtonProps>`
  width: 100%;
  padding: 0;
  color: white;
  background-color: ${({ color }) => color};
  margin-top: 10px;

  @media ${({ theme }) => theme.device.tablet} {
    width: 12.5rem;
  }
`;

export const RemoveIcon = styled(Icon)`
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  margin-left: 2rem;

  @media ${({ theme }) => theme.device.tablet} {
    width: 2.6rem;
    height: 2.6rem;
  }
`;

export const RatingsBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.1rem;

  @media ${({ theme }) => theme.device.tablet} {
    align-items: center;
    flex-direction: row;
    margin-bottom: 0.6rem;
  }
`;

export const StyledRating = styled(Rating)`
  margin-right: 0.7rem;

  svg {
    margin-right: 0.15rem;
    width: 1.4rem;
    height: 1.4rem;
  }
`;

export const RatingParagraph = styled(Paragraph)`
  font-size: 1.12rem;
  line-height: 2.1rem;

  span {
    margin-right: 1.4rem;
  }
`;

export const AdditionalText = styled(Paragraph)`
  font-size: 1.3rem;
  font-weight: 500;
  margin: 1.5rem 0;
  line-height: 1.5rem;
  color: #b7b6b6;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  @media ${({ theme }) => theme.device.tablet} {
    flex-direction: row;
  }
`;

export const SelectWrapper = styled.div`
  margin-top: 1rem;
  position: relative;
  max-width: 25rem;

  &:after {
    content: '';
    position: absolute;
    width: 1.1rem;
    height: 1.1rem;
    background-image: url(${arrow});
    top: 50%;
    transform: translateY(-50%);
    right: 1.5rem;
    background-repeat: no-repeat;
  }
`;

export const VariantSelect = styled.select`
  border: 0.1rem solid #c3c1c8;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  font-family: 'Effra-Regular';
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  line-height: 2.4rem;
  font-size: 1.6rem;
  height: 3.5rem;
  padding: 0 0 0 1.3rem;
  width: 100%;
`;

export const ChooseOffer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 1.4rem;

  @media ${({ theme }) => theme.device.phone} {
    display: none;
  }

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    width: 100%;

    div.chooseOffer {
      display: flex;
      flex-direction: row;
      justify-content: center;
      border: 1px solid #cecece;
      width: 70%;
      padding: 1.6rem 0rem 1.6rem 1.4rem;
      align-items: center;
      border-radius: 1rem;
      font-size: 1.6rem;

      @media ${({ theme }) => theme.device.phone} {
        width: 75%;
      }

      @media ${({ theme }) => theme.device.tablet} {
        width: 70%;
      }
    }

    div {
      span {
        img {
          width: 40px;
          height: 15px;
          transform: translateY(0.2rem) rotate(90deg);
        }
      }
    }
    span {
      margin-right: 1rem;
    }
  }
`;
