import React, { useEffect } from "react";
import {
  StyledHeading,
  StyledWrap,
  RatingBox,
  RatingText,
  SearchButton,
  ButtonList,
  TagButton,
  Actions,
  ActionButton,
  NotSection,
  ReviewsContainer,
} from "./styles";
import { SectionSettings } from "../../interfaces/sanity";
import ReviewList from "src/components/ReviewList";
import Rating from "src/components/Rating";
import { useTheme } from "styled-components";
import BottomButton from "src/components/BottomButton";
import { SmallDesktop } from "src/components/Responsive";
import { useReviewDrawer } from "src/context/siteContext";
import { isSSR } from "src/utils/ssr";
import { useLocation } from "@reach/router";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import OkendoWidget from "src/components/OkendoWidget";

interface Props {
  data: {
    sectionSettings: SectionSettings;
    title: string;
    list: any;
    experiment: {
      variantA?: boolean;
      variantB?: boolean;
    };
  };
  context: any;
  isSection?: boolean;
}

const tags = [
  "Pillow",
  "Neck",
  "Neck pain",
  "Sleep",
  "Pain",
  "Nights",
  "Side",
  "Head",
  "Shoulders",
  "Difference",
];

const ProductReviews = ({ data, context, isSection = true }: Props) => {
  const theme = useTheme();
  const { openReviews } = useReviewDrawer();
  const breakpoints = useBreakpoint();
  const location = useLocation();

  const { title, sectionSettings } = data;

  function openReviewsDrawer(tag) {
    openReviews({
      title: context?.content.shopify?.title,
      id: context?.content.shopify?.productId,
      sku: context?.content.shopify?.defaultVariant.sku,
      slug: context?.main?.slug.current,
      search: tag,
    });
  }

  useEffect(() => {
    if (isSSR || !sectionSettings?.id) return;
    const hash = location.hash;

    if (hash === `#${sectionSettings?.id}?open`) openReviewsDrawer();
  }, [location]);

  return (
    <StyledWrap isSection={isSection} className={data?.experiment?.variantB ? 'variant_B' : data?.experiment?.variantA ? 'variant_A': ''}>
      <StyledHeading style={{color: data?.sectionSettings?.textColor?.value}}>{title}</StyledHeading>
      {/* <RatingBox>
        <Rating number={5} color={theme.colors.yellow} />
        {context?.reviews?.ratingAll && (
          <RatingText>Rated {context?.reviews?.rating.toFixed(1)}</RatingText>
        )}
      </RatingBox>
      <SearchButton
        buttonType="outline"
        iconType="search"
        small={true}
        onClick={openReviewsDrawer}
      >
        Search {context?.reviews?.total} customer reviews
      </SearchButton>
      <ButtonList>
        {tags.map((tag, i) => (
          <TagButton
            key={i}
            buttonType="outline"
            small={true}
            onClick={() => openReviewsDrawer(tag)}
          >
            {tag}
          </TagButton>
        ))}
      </ButtonList>
      {context?.reviews?.data && (
        <ReviewList list={context?.reviews?.data} questions={false} />
      )}
      <Actions>
        <ActionButton
          buttonType="outline"
          small={true}
          onClick={openReviewsDrawer}
        >
          Write a review
        </ActionButton>

        {breakpoints.tablet ? (
          <ActionButton
            buttonType="outline"
            small={true}
            onClick={openReviewsDrawer}
          >
            See all {context?.reviews?.total} reviews
          </ActionButton>
        ) : (
          <BottomButton onClick={openReviewsDrawer}>
            See all {context?.reviews?.total} reviews
          </BottomButton>
        )}
      </Actions> */}
      <ReviewsContainer>
          <OkendoWidget productInfo={context} />
      </ReviewsContainer>
      
      </StyledWrap>
  );
};

export default ProductReviews;
