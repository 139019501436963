import React from 'react';
import Amex from "src/images/amex.svg";
import Paypal from "src/images/paypal.svg";
import Discover from "src/images/discover.svg";
import Visa from "src/images/visa.svg";
import MasterCard from "src/images/mastercard.svg";
import { PaymentIconsStyled } from './styles.tsx';

const PaymentIcons = () => {

  const images = [
    { id: 1, src: Visa, alt: "Visa" },
    { id: 2, src: MasterCard, alt: "Master card" },
    { id: 3, src: Discover, alt: "Discover" },
    { id: 4, src: Amex, alt: "Amex" },
    { id: 5, src: Paypal, alt: "Paypal" }
  ]
  return (
    <PaymentIconsStyled>
      {images?.map((img) => {
        return (
          <img src={img?.src} key={img?.id} alt={img?.alt} />
        )
      })}
    </PaymentIconsStyled>
  );
};

export default PaymentIcons;