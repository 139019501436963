import React from 'react';
import GeneralSection from "src/components/GeneralSection";
import {
  BgContainer,
  StyledWrap,
  LeftPart,
  RightPart,
  StyledImage,
  StyledHeading,
  StyledSubtext,
  DesktopStyle
} from './styles';
import { useTheme } from 'styled-components';
import BlockContent from '@sanity/block-content-to-react';
import { Serializer } from 'src/utils/serializer';
import { RenderReviews } from "src/components/GeneralSection";
import { useMediaQuery } from "react-responsive";

export const size = {
  phone: 480,
};

interface Props {
  data: {
    description?: string;
    enableRating?: boolean;
    heading?: Array;
    image?: SanityImage;
    generalFooter?: object;
    sectionSettings: {
      backgroundColor: SanityColor;
      backgroundImage: SanityImage;
    };
  };
  context: {
    reviews: any;
  };
}
const NewHeroSection = ({ data, context }: Props) => {

  const isMobile = useMediaQuery({ maxWidth: size.phone });

  const { enableRating, heading, description, image, generalFooter } = data;


  return (
    <BgContainer>
      <StyledWrap>
        <LeftPart>
          <DesktopStyle>
           {heading &&
              <StyledHeading>
                <BlockContent serializers={Serializer} blocks={heading} />

              </StyledHeading>}</DesktopStyle>
          {description && <StyledSubtext>{description}</StyledSubtext>}
          <GeneralSection reviews={context?.reviews} content={data?.generalFooter} />
        </LeftPart>
        <RightPart>
          {image &&
            <StyledImage imageRef={image} alt="image" />}
        </RightPart>
        {isMobile && <div>
          {/* {enableRating && <RenderReviews reviews={context?.reviews} />} */}
          {heading &&
            <StyledHeading>
              <BlockContent serializers={Serializer} blocks={heading} />

            </StyledHeading>}</div>}
      </StyledWrap>
    </BgContainer>
  )
}

export default NewHeroSection