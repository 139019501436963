import React, { FC, useEffect, useState } from "react";
import {
  Container,
  Form,
  Input,
  TextArea,
  Heading,
  Description,
  SubmitButton,
} from "./styles";
import { useForm } from "react-hook-form";

interface Props {
  className?: string;
  heading?: string;
  description?: string;
}

interface FormInputs {
  fullName: string;
  email: string;
  phone: string;
  message: string;
}

const ContactForm: FC<Props> = ({ heading, description, className }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormInputs>();
  const [sent, setSent] = useState<boolean>(false);

  // Transforms the form data from the React Hook Form output to a format Netlify can read
  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };

  const onSubmit = async (formData, event) => {
    if (sent) return;

    const response = await fetch(`/`, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": event.target.getAttribute("name"),
        ...formData,
      }),
    });

    if (!response.ok) return;

    setSent(true);
  };

  return (
    <Container className={className}>
      {heading && <Heading>{heading}</Heading>}
      {description && <Description>{description}</Description>}
      <Form
        name="contact-form"
        method="POST"
        onSubmit={handleSubmit(onSubmit)}
        data-netlify="true"
      >
        <input type="hidden" name="form-name" value="contact-form" />
        <Input
          placeholder="Your full name please *"
          {...register("fullName", {
            required: true,
          })}
          error={!!errors.fullName}
        />
        <Input
          placeholder="Email address *"
          type="email"
          {...register("email", {
            required: true,
          })}
          error={!!errors.email}
        />
        <Input
          placeholder="Phone number *"
          type="tel"
          {...register("phone", {
            required: true,
          })}
          error={!!errors.phone}
        />
        <TextArea
          placeholder="Message *"
          {...register("message", {
            required: true,
          })}
          rows={1}
          error={!!errors.message}
        />
        <SubmitButton type="submit" small={true} sent={sent}>
          {sent ? "Done!" : "Send it"}
        </SubmitButton>
      </Form>
    </Container>
  );
};

export default ContactForm;
