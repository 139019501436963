import styled from 'styled-components';
import Wrap from '../../components/Wrap';
import Button from 'src/components/Common/Button';
import BlockContent from 'src/components/BlockContent';
import { SectionHeading } from '../../components/Text';
import waveMobile from '../../images/underline_mobile.svg';
import wave from '../../images/underline.svg';

export const SectionWrapper = styled.div`
  background-color: #d5dcea;
`;

export const StyledHeading = styled(SectionHeading)`
  text-align: center;
  margin-bottom: 4rem;

  @media ${({ theme }) => theme.device.tablet} {
    margin-bottom: 5.9rem;
  }
`;

export const StyledButton = styled(Button)`
  margin: 2.4rem auto 0 auto;

  @media ${({ theme }) => theme.device.tablet} {
    margin: 4.3rem auto 0 auto;
  }
`;

export const StyledWrap = styled(Wrap)`
  padding: 7.1rem 2rem 5.3rem;
  display: flex;
  flex-direction: column;

  @media ${({ theme }) => theme.device.tablet} {
    padding: 7.5rem 8.1rem 6.9rem;
  }
`;

export const StyledBlocks = styled(BlockContent)`
  text-align: center;
  color: ${({ theme }) => theme.colors.font_black};
  font-size: ${({ theme }) => theme.fontSize['4xl'].mobile};
  line-height: ${({ theme }) => theme.lineHeight['4xl'].mobile};
  font-family: 'Effra-Regular';
  font-weight: 400;
  margin-bottom: 4rem;

    @media ${({ theme }) => theme.device.tablet} {
      font-size: ${({ theme }) => theme.fontSize['4xl'].desktop};
      line-height: ${({ theme }) => theme.lineHeight['4xl'].desktop};
      margin: 0 auto;
      margin-bottom: 5.9rem;
    }
    strong {
      padding-bottom: 0.5rem;

      @media ${({ theme }) => theme.device.tablet} {
        padding-bottom: 1rem;
      }
  }
`;
