import React from "react";
import { SanityImage, SanityColor } from "src/interfaces/sanity";
import {
  StyledWrap,
  ImagesRowContainer,
  StyledImage,
  ImageContainer,
  SubHeading,
  TextBox,
  Heading,
} from "./styles";
interface Props {
  data: {
    heading: string;
    subHeading: string;
    firstImage: SanityImage[];
    secondImage: SanityImage[];
    experiment: {
      variantA?: boolean;
      variantB?: boolean;
    };

    sectionSettings: {
      backgroundColor: SanityColor;
      backgroundImage: SanityImage;
    };
  };

  context: any;
}

const TwoImagesRow = ({ data, context }: Props) => {
  const { heading, subHeading, firstImage, secondImage } = data;
  return (
    <StyledWrap className={data?.experiment?.variantB ? 'variant_B' : data?.experiment?.variantA ? 'variant_A': ''}>
      <TextBox>
        {heading && <Heading>{heading}</Heading>}

        {subHeading && <SubHeading>{subHeading}</SubHeading>}
      </TextBox>
      <ImagesRowContainer>
        {(firstImage || secondImage) && (
          <>
            <ImageContainer>
              <StyledImage imageRef={firstImage} />
            </ImageContainer>

            <ImageContainer>
              <StyledImage imageRef={secondImage} />
            </ImageContainer>
          </>
        )}
      </ImagesRowContainer>
    </StyledWrap>
  );
};

export default TwoImagesRow;
