import styled from "styled-components";
import Image from "../../components/Common/ImageFluid";
interface Props {
  color: string;
}

export const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;
  max-width: 3rem;

  &.phone {
    max-width: 2rem;
  }
`;

export const MoneyBackBanner = styled.div<Props>`
  &#variant_A {
    display: none;
  }
  &#variant_A_Mobile {
    display: none;
  }
  display: none;
  color: ${({ color }) => color};

  &.showInDesktop {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 15px 0px 15px 5px;
    font-size: 1.7rem;

    & div:nth-child(2) {
      margin-left: 12rem;
    }

    div {
      img {
        margin-right: 1rem;
      }
    }
  }

  &.showInMobile {
    div {
      span {
        span {
          display: block;
        }
      }
      img {
        width: 35px;
        height: 35px;
        margin-right: 1rem;
      }
    }
  }

  @media ${({ theme }) => theme.device.phone} {
    padding: 1rem 0 1rem 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-size: 1.2rem;
    margin: 0;

    &.showInDesktop {
      display: none;
    }

    div {
      padding: 0.3rem 0.5rem;
    }
  }

  div {
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
      width: 35px;
    }

    span {
      span {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
  img {
    margin-left: 1rem;
  }
`;
