import React from "react";
import { buildFileUrl, getFile } from '@sanity/asset-utils';
import {
    StyledWrapContainer,
    Heading,
    IconList,
    IconText,
    IconImage,
    Icon,
    IconTitle,
    Description,
    StyledVideo,
    TextWithIcons,
    TextBeforeIcons,
} from "./styles";

const sanityConfig = () => ({
    projectId: process.env.GATSBY_SANITY_PROJECT_ID,
    dataset: process.env.GATSBY_SANITY_DATASET,
});

const getFilePath = (node) => {
    if (!node) return;

    const config = sanityConfig();
    return buildFileUrl(getFile(node, config).asset, config);
};

import {
    SectionSettings,
    SanityImage,
    SanityColor,
} from "../../interfaces/sanity";
import { useTheme } from "styled-components";

interface Props {
    data: {
        heading?: {
            text: string;
            textColor: SanityColor;
        };
        description: string;
        textBeforeIcons: string;
        video: any;
        videoShopify: any;
        icons?: {
            enabled: boolean;
            bigIcons: boolean;
            horizontal?: boolean;
            mobileDir?: "row" | "column";
            list: [
                {
                    _key: string;
                    _type: "icon";
                    image: SanityImage;
                    text: string;
                }
            ];
        };
        experiment: {
            variantA?: boolean;
            variantB?: boolean;
        };
        backgroundColor: string | undefined;
        sectionSettings: SectionSettings;
    };
}

const TextVideoIconsSection = ({ data }: Props) => {
    const { icons, heading, description, video, videoShopify, backgroundColor, textBeforeIcons } = data;
    const theme = useTheme();

    const renderIcons = (
        <IconList mobileDir={icons?.mobileDir} horizontal={icons?.horizontal}>
            {icons?.list.map((icon) => (
                <Icon key={icon._key} horizontal={icons.horizontal}>
                    <IconImage imageRef={icon.image} big={icons.bigIcons} alt={icon?.image?.alt}/>
                    <IconTitle>{icon.title}</IconTitle>
                    <IconText horizontal={icons?.horizontal}>{icon.text}</IconText>
                </Icon>
            ))}
        </IconList>
    )

    return (
        <StyledWrapContainer id='videoWithIconsSection' backgroundColor={backgroundColor} className={data?.experiment?.variantB ? 'variant_B' : data?.experiment?.variantA ? 'variant_A': ''}>
            {heading && (
                <Heading
                    color={heading?.textColor?.title}
                >
                    {heading?.text}
                </Heading>
            )}
            {description && <Description>{description}</Description>}
            {video && (
                <StyledVideo src={videoShopify ? videoShopify : getFilePath(video?.asset._ref)} autoPlay={true}
                    playsInline={true}
                    controls={true}
                    muted={true} />
            )}
            <TextWithIcons>
                <TextBeforeIcons>{textBeforeIcons}</TextBeforeIcons>
                {icons?.enabled && renderIcons}
            </TextWithIcons>
        </StyledWrapContainer>
    );
};

export default TextVideoIconsSection;
