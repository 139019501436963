import React from 'react';
import { buildFileUrl, getFile } from '@sanity/asset-utils';
import { SanityImage, SanityColor } from 'src/interfaces/sanity';
import VideoArrow from 'src/images/videoarrow.svg';
import { useTheme } from 'styled-components';
import BlockContent from '@sanity/block-content-to-react';
import { Serializer } from 'src/utils/serializer';
import { Row } from '../../interfaces/sanity';
import GeneralSection from "src/components/GeneralSection";
import {
  BgContainer,
  StyledWrap,
  LeftPart,
  RightPart,
  StyledImage,
  StyledHeading,
  StyledList,
  StyledListItem,
  StyledText,
  StyledSubtext,
  StyledLogo, 
  MobileStyle
} from './styles';
import RedX from "src/images/red_x.svg";
import GreenTick from "src/images/green_tick.svg";
import { useMediaQuery } from "react-responsive";
import Rating from "src/components/Rating";

export const size = {
  phone: 480,
};

const sanityConfig = () => ({
  projectId: process.env.GATSBY_SANITY_PROJECT_ID,
  dataset: process.env.GATSBY_SANITY_DATASET,
});

const getFilePath = (node) => {
  if (!node) return;

  const config = sanityConfig();
  return buildFileUrl(getFile(node, config).asset, config);
};

interface Props {
  data: {
    main: {
      desktopHeading?: object;
      mobileHeading?: object;
      image?: SanityImage;
      listItems?: Array;
      rating?: number;
      logo?: SanityImage;
      text?: string;
      subtext?: string;
      headingBelowIcons?: boolean;
      moduleType?: "unsupportive" | "supportive" | "default";
      imagePosition?: "left" | "right";

    };
    sectionSettings: {
      backgroundColor: SanityColor;
      backgroundImage: SanityImage;
    };
    generalFooter?: object;
  };
  context: {
    reviews: any;
  };
};


const NewImageRowSection = ({ data, context }: Props) => {
  const theme = useTheme();
  const { desktopHeading, mobileHeading, image, moduleType, imagePosition, listItems, rating, text, subtext, logo, headingBelowIcons } = data?.main;


  const isMobile = useMediaQuery({ maxWidth: size.phone });

  return (
    <BgContainer>
      <StyledWrap noPadding={true} imagePosition={imagePosition}>
        {(!headingBelowIcons) &&
        <MobileStyle>
          <StyledHeading>
            <BlockContent blocks={mobileHeading} serializers={Serializer} />
          </StyledHeading> </MobileStyle>}
        <LeftPart>
          {(isMobile && logo) && <StyledLogo imageRef={logo} alt="logo" />}
          <StyledImage imageRef={image} alt="image" />
        </LeftPart>
        <RightPart>
          {(!isMobile && logo) && <StyledLogo imageRef={logo} alt="logo" />}
          {!isMobile &&
            <StyledHeading>
              <BlockContent blocks={desktopHeading} serializers={Serializer} />
            </StyledHeading>}
          <StyledList>
            {listItems?.map((item, i) => {
              return (
                <StyledListItem>
                  {moduleType === "unsupportive" &&
                    <img src={RedX} alt="icon" />}
                  {moduleType === "supportive" &&
                    <img src={GreenTick} alt="icon" />}
                  <BlockContent blocks={item?.list} serializers={Serializer} />
                </StyledListItem>

              )
            })}
          </StyledList>
          {rating > 0 &&
            <Rating number={rating} color={theme.colors.yellow} />
          }
          {text &&
            <StyledText>{text}</StyledText>}
          {subtext &&
            <StyledSubtext>{subtext}</StyledSubtext>}



          <GeneralSection reviews={context?.reviews} content={data?.generalFooter} />


        </RightPart>

        {(isMobile && headingBelowIcons) &&
          <StyledHeading>
            <BlockContent blocks={mobileHeading} serializers={Serializer} />
          </StyledHeading>}

      </StyledWrap>
    </BgContainer>
  );
};

export default NewImageRowSection;
