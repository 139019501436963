import styled from "styled-components";
import Modal from "src/components/Modal";
import Icon from "src/components/Common/Icon";
import { SectionHeading, Paragraph } from "../../Text";

export const StyledModal = styled(Modal).attrs({
  overlayClassName: "overlay",
  modalClassName: "modal",
})`
  & .overlay {
    position: fixed;
    background-color: rgba(45, 72, 101, 0.5);
    z-index: 2;
    inset: 0;
  }

  & .modal {
    position: absolute;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    left: 50%;
    max-width: 95vw;
    width: 100%;
    /* max-height: 80vh; */

    /* @media ${({ theme }) => theme.device.tablet} {
      top: 3.5rem;
      right: 3.5rem;
    } */

    @media ${({ theme }) => theme.device.phone} {
      max-width: 100vw !important;
      width: 100% !important;
      position: fixed !important;
      top: 90px;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(10, 10, 10, 0.86);
      opacity: 1;
      transition: opacity 0.3s ease-out;
      transform: translate(0,0) !important;
      overflow: auto;
    }

    @media ${({ theme }) => theme.device.smPhone} {
      top: 1.5rem;
    }
  }
`;

export const CloseIcon = styled(Icon)`
  display: none;
  position: absolute;
  cursor: pointer;
  top: 1.5rem;
  right: 1.5rem;
  width: 2rem;
  height: 2rem;

  @media ${({ theme }) => theme.device.tablet} {
    top: 3.5rem;
    right: 3.5rem;
    width: 2.6rem;
    height: 2.6rem;
  }

  @media ${({ theme }) => theme.device.phone} {
    display: block;
  }
`;

export const UpsellContainer = styled.div`
  position: relative;
  background-color: lightgrey;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  max-width: 97.2rem;
  margin: 0 auto;
  padding: 4.5rem 2rem;
  max-height: 80vh;
  overflow-y: auto;

  @media ${({ theme }) => theme.device.tablet} {
    /* max-height: 90vh; */
    max-height: 92vh;
    overflow-y: auto;
    padding: 4.5rem 3.5rem;
  }

  @media ${({ theme }) => theme.device.phone} {
    margin: 0 2rem;
    background-size: auto;
  }

  @media ${({ theme }) => theme.device.smPhone} {
    margin: 0 1.5rem;
    max-height: calc(100vh - 3rem);
  }
`;

export const Title = styled(SectionHeading)`
  font-size: 1.8rem;
  line-height: 2.4rem;
  text-align: center;

  @media ${({ theme }) => theme.device.tablet} {
    font-size: 2.4rem;
    line-height: 3.2rem;
  }
`;

export const SubTitle = styled(Paragraph)`
  text-align: center;
  margin: 1.5rem 0 2rem 0;

  @media ${({ theme }) => theme.device.tablet} {
    margin-bottom: 1.5rem 0 3rem 0;
  }
`

export const UpsellList = styled.div``;

export const SleepIcon = styled(Icon)`
  width: 2rem;
  height: 2rem;

  @media ${({ theme }) => theme.device.tablet} {
    width: 3.2rem;
    height: 3.2rem;
  }
`;
