import styled from "styled-components";
import Button from "src/components/Common/Button";

interface BundleItemProps {
  isPopular: boolean;
  backgroundColor?: string;
}

export const StyledBundleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Effra-Regular';
  padding: 38px 2rem;
`;

export const StyledHeader = styled.div`
  font-size: 44px;
  padding: 18px;
  @media ${({ theme }) => theme.device.phone} {
    display: none;
  }
`;

export const StyledDescription = styled.div`
  font-size: 21px;
  padding: 12px;
  color: #878491;
  @media ${({ theme }) => theme.device.phone} {
    display: none;
  }
`;

export const StyledBundleWrap = styled.div`
  display: flex;
  @media ${({ theme }) => theme.device.phone} {
    display: block;
  }
`;

export const StyledBundleItem = styled.div<BundleItemProps>`
  position: relative;
  background-color: #f6f6fa;
  border: 1px solid #000000;
  border-radius: 10px;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  margin: 32px 16px;
  min-width: 200px;
  cursor: default;
  width: 33%;
  min-height: ${({ isPopular }) => (isPopular ? "650px" : "500px")};
  ${({ isPopular }) => !isPopular && "margin-top: auto"};


  @media ${({ theme }) => theme.device.tablet} {
   width: 100%;
  }

  @media ${({ theme }) => theme.device.phone} {
    min-height: 500px;
    width: 90%;
  }

  div.itemWrap {
    display: flex;
    flex-direction: column;
    justify-content: end;
    text-align: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 28px 36px;
    position: relative;
    overflow: hidden;
  }

  div.discount {
    position: absolute;
    top: ${({ isPopular }) => (isPopular ? "25px" : "20px")};
    right: ${({ isPopular }) => (isPopular ? "50px" : "15px")};
    padding: 4px 8px;
    color: white;
    background-color: ${({ isPopular }) => (isPopular ? "#F97878" : "#1A1F59")};
    border-radius: 10px;
    font-size: 15px;
    @media ${({ theme }) => theme.device.phone} {
      top: 20px;
      right: ${({ isPopular }) => (isPopular ? "55px" : "15px")};
    }
  }

  p.title {
    color: #0f0923;
    font-size: 35px;
    padding: 15px 0;
  }

  div.items {
    padding: 15px 0;
    min-height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    div {
      font-size: 14px;
      text-align: center;
    }
  }

  div.seprateLine {
    width: 50%;
    border-bottom: 1px solid #0f0824;
    margin: 15px 0;
  }

  div.originPrice {
    font-size: 21px;
    color: #0f0923;
    opacity: 0.6;
    text-decoration-line: line-through;
  }

  div.discountedPrice {
    font-size: 28px;
    color: #0f0923;
    padding: 6px 0;
  }

  img.pillowImage {
    position: absolute;
    top: -35px;
    left: 20%;
    width: 70px;
    height: 70px;
    @media ${({ theme }) => theme.device.phone} {
      display: none;
    }
  }

  div.saveMoney {
    font-size: 18px;
    opacity: 0.6;
    font-style: italic;
  }
`;

export const StyledButton = styled(Button)<BundleItemProps>`
  background-color: ${({ theme }) => theme.colors.font_pink};
  width: 100%;
  margin: 25px 0 5px;
  padding: 1.5rem 2.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

  @media ${({ theme }) => theme.device.tablet} {
    padding-left: 0;
    padding-right: 0;
  }

  span.label {
    margin: 0 1rem 0 0;
  }
`;

export const Badge = styled.div`
  position: absolute;
  top: 15px;
  right: -45px;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : "transparent"};
  color: white;
  transform: rotate(45deg);
  font-size: 12px;
  padding: 5.5px 45px;
  text-transform: uppercase;
`;

export const BundleHeader = styled.div`
  display: none;
  padding: 28px 0 16px;
  font-family: 'Effra-Regular';
  font-size: 22px;
  align-items: center;
  div.mobileHeader {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  span {
    font-weight: bold;
    font-size: 24px;
    padding: 0 4px 0 12px;
  }
  @media ${({ theme }) => theme.device.phone} {
    display: flex;
  }
`;
