import React from "react";
import { Modules } from "src/components/modules";

export const RenderModules = (modules: [], context) => {
  if (modules) {
    return modules.map((module: { _key: string; _type: string }) => (
      <React.Fragment key={module._key}>
        <Modules type={module._type} reactModule={module} context={context} />
      </React.Fragment>
    ));
  }
};
