import React from "react";
import { VideoRowContainer, VideoRowHeading, VideoRowSubHeading, VideoRow, VideoSlider } from "./styles";
import { useMediaQuery } from "react-responsive";
import { size } from "../Responsive";
import Wrap from "../Wrap";
import Video from "../Video";

interface Props {
  data: {
    heading: string;
    subHeading: string;
    firstVideo: {
      url: string;
    }
    secondVideo: {
      url: string;
    },
    videoCornerRounded?: boolean;
    experiment: {
      variantA?: boolean;
      variantB?: boolean;
    };
  };
  context: {};
}

const VideosRow = ({ data, context }: Props) => {
  const {
    firstVideo, firstVideoShopify, secondVideo, secondVideoShopify, videoCornerRounded,
  } = data;

  const isMobile = useMediaQuery({ maxWidth: size.phone });

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <Wrap className={data?.experiment?.variantB ? 'variant_B' : data?.experiment?.variantA ? 'variant_A': ''}>
      <VideoRowContainer>
        <VideoRowHeading>
          {data?.heading}
        </VideoRowHeading>
        <VideoRowSubHeading>
          {data?.subHeading}
        </VideoRowSubHeading>
        <VideoRow>
          <div>
            <Video videoCornerRounded={videoCornerRounded} src={firstVideoShopify ? firstVideoShopify : firstVideo?.url} controls={true} />
          </div>
          <div>
            <Video videoCornerRounded={videoCornerRounded} src={secondVideoShopify ? secondVideoShopify : secondVideo?.url} controls={true} />
          </div>
        </VideoRow>
        <VideoSlider isMobile={true} {...settings}>
          <Video videoCornerRounded={videoCornerRounded} src={firstVideoShopify ? firstVideoShopify : firstVideo?.url} controls={true} />
          <Video videoCornerRounded={videoCornerRounded} src={secondVideoShopify ? secondVideoShopify : secondVideo?.url} controls={true} />
        </VideoSlider>
      </VideoRowContainer>
    </Wrap>
  );
};

export default VideosRow;
