import React, { useEffect, useState } from "react";
import { DeliveryTimeWidget } from "./style";
import TruckIcon from "src/images/truckIcon.svg";
import TickIcon from "src/images/tickIcon.svg";
import moment from "moment-business-days";
import { duration } from "src/utils/helpers";


interface Props {
  showDeliveryTimeWidget: boolean;
}

const DeliveryTime = ({ showDeliveryTimeWidget }) => {

  if (!showDeliveryTimeWidget) return <></>;

  let interval = null;
  let message;
  let currentTime = new Date();
  let hour = currentTime.getHours();

  let end = moment('2023-12-12 15:00:00').toDate();

  const [timeLeft, setTimeLeft] = useState(duration(end));

  useEffect(() => {
    interval = setInterval(() => {
      setTimeLeft(duration(end));

    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const { days, hours, minutes, seconds } = timeLeft;

  if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
    clearInterval(interval);
  }

  let day = moment(currentTime).businessAdd(4)._d;

  if (hour >= 15) {
    let day = moment(currentTime).businessAdd(5)._d;

  }

  const dayOfWeekOption = { weekday: 'long' };
  const dateOption = { day: "numeric" };
  const monthOption = { month: "long" };

  let dayOfWeek = day.toLocaleDateString("en-GB", dayOfWeekOption);
  let dateOfMonth = day.toLocaleDateString("en-GB", dateOption);
  let monthOfWeek = day.toLocaleDateString("en-GB", monthOption);

  let ordinalDate = "th";
  if (dateOfMonth == "01") {
    ordinalDate = "st"
  } else if (ordinalDate == "02") {
    ordinalDate = "nd"
  } else if (ordinalDate == "03") {
    ordinalDate = "d"
  }


  return (
    <DeliveryTimeWidget>
      <div className="delivery">
        <img src={TruckIcon} alt="Truck Icon" />
        <div className="text">
          <p>For delivery:</p>
          <p className="days">{dayOfWeek} {dateOfMonth}{ordinalDate} {monthOfWeek}</p>
        </div>
      </div>
      <div className="order">
        <img src={TickIcon} alt="Icon" />
        <div className="text">
          <p>Order within:</p>
          <span>{hours.toFixed(0).padStart(2, "0")} hrs <span id="minutes">{minutes.toFixed(0).padStart(2, "0")} min</span> <span id="seconds">{seconds.toFixed(0).padStart(2, "0")} sec</span></span>
        </div>
      </div>


    </DeliveryTimeWidget>
  )
};

export default DeliveryTime;