import React from "react";
import {TestimonialPDPSection} from "./styles";
import TestimonialImage from "src/images/image.png";


interface Props {
    showTestimonial: boolean;
}

const TestimonialPDP = ({showTestimonial}: Props) => {

  return (
    <div id="testimonial-pdp">
        {showTestimonial && (

            <TestimonialPDPSection>
                <div className="testimonial-image">
                  <img src={TestimonialImage} alt="Testimonial image" />
                </div>
                <div className="testimonial-message">
                  
                  <p className="message">"I recommend The Original Groove Pillow. We found the specialist groove to offer excellent comfort and flexible biomechanical positioning for different patient needs."</p>
                  <div className="personal-info">
                    <p className="name">Mr B. Chatterjee</p>

                    <p className="position">Spinal Consultant Surgeon MBBS MRCS, MSc(Distinction) FRCS (Tr&Orth)</p>

                  </div>
                </div>
            </TestimonialPDPSection>

            
        )}
    </div>
  )
}

export default TestimonialPDP;