import styled, { css } from 'styled-components';
import Image from '../../components/Common/ImageFluid';
import { SectionHeading, Paragraph } from '../../components/Text';
import Link from '../../components/Link';
import Wrap from '../../components/Wrap';
import Button from 'src/components/Common/Button';
import List from '../../components/List';
import Video from '../../components/Video';
import Rating from '../../components/Rating';


interface PositionProps {
  imagePosition: 'left' | 'right';
}


const rowPosStyles = (imagePosition: 'left' | 'right') => {
  switch (imagePosition) {
    case 'left':
      return css`
        flex-direction: row;
      `;
    case 'right':
      return css`
        flex-direction: row-reverse;
      `;
  }
};

const handlePosMargin = (imagePosition: 'left' | 'right') => {
  switch (imagePosition) {
    case 'left':
      return 'auto 0 0';
    case 'right':
      return '0';
    default:
      return 'row';
  }
};

const handlePosPadding = (imagePosition: 'left' | 'right') => {
  switch (imagePosition) {
    case 'left':
      return '10.3rem 10.3rem 0 0;';
    case 'right':
      return '10.3rem 0 0 10.3rem;';
    default:
      return '0 10.3rem 0 0;';
  }
};

export const StyledWrap = styled(Wrap)<PositionProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  /* padding: ${({ hideImgMobile }) =>
    hideImgMobile ? '1.7rem 0' : '3.4rem 0'}; */

  @media ${({ theme }) => theme.device.tablet} {
    ${({ imagePosition }) => rowPosStyles(imagePosition)}
    padding: 8rem 8.1rem;
  }

  @media ${({ theme }) => theme.device.largeDesktop} {
    /* padding: 8rem 10.5rem; */
  }
`;

export const BgContainer = styled.div`
  position: relative;
`;

export const LeftPart = styled.div`
  width: 100%;
  margin: 2rem 0;
  position: relative;

  @media ${({ theme }) => theme.device.tablet} {
    width: 50%;
    margin: unset;
  }
`;

export const MobileStyle = styled.div`
  display: block;
  @media ${({ theme }) => theme.device.tablet} { 
    display: none;
  }
`;

export const RightPart = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${({ theme }) => theme.device.tablet} {
    width: 50%;
  }
`;

export const StyledImage = styled(Image)`
  width: 90%;
  display: block !important;
  margin: auto;

`;

export const StyledHeading = styled.div`
  margin: 2rem auto;
  width: 100%;
  display: block;
  padding: 0 10px;

  h1 {
    font-style: normal;
    font-weight: 700;
    line-height: 1.2;
    font-size: 35px;
    font-family: 'Effra-Bold';
    text-align: center;
    @media ${({ theme }) => theme.device.tablet} {
    font-size: 58px;
    }
  }

  h2 {
    font-style: normal;
    font-weight: 600;
    line-height: 1.2;
    font-size: 32px;
    font-family: 'Effra-Bold';
    text-align: center;
    @media ${({ theme }) => theme.device.tablet} {
    font-size: 52px;
    }
  }

  h3 {
    font-style: normal;
    font-weight: 500;
    line-height: 1.2;
    font-size: 28px;
    font-family: 'Effra-Medium';
    text-align: center;
    @media ${({ theme }) => theme.device.tablet} {
    font-size: 44px;
    }
  }

  h4 {
    font-style: normal;
    font-weight: 700;
    line-height: 1.2;
    font-size: 28px;
    text-align: center;
    font-family: 'Effra-Bold';
    @media ${({ theme }) => theme.device.tablet} {
    font-size: 38px;
    }
  }

  h5 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.2;
    text-align: center;
    font-family: 'Effra-Medium';
    @media ${({ theme }) => theme.device.tablet} {
    font-size: 24px;
    }
  }

  h6 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.2;
    text-align: center;
    font-family: 'Effra-Medium';
    @media ${({ theme }) => theme.device.tablet} {
    font-size: 20px;
    }
  }

  p {
    padding: 10px 0;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 1.3;
    text-align: center;
    font-family: 'Effra-Regular';
    @media ${({ theme }) => theme.device.tablet} {
    font-size: 18px;
    }
  }

  strong {
    font-weight: 900;
    font-family: 'Effra-Bold';
  }
  @media ${({ theme }) => theme.device.tablet} { 
    width: 80%;
  }
`;

export const StyledList = styled.ul`
  width: 80%;
  margin: 0 auto;
  display: block;


`;
export const StyledListItem = styled.li`
  list-style-type: none;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 1rem auto;

  strong {
    font-weight: 900;
    font-family: 'Effra-Bold';
  }

  img {
    margin: 3px 10px 0 0;
  }

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.2;
    font-family: 'Effra-Regular';

    @media ${({ theme }) => theme.device.tablet} { 
      font-size: 16px;
    }
  }

`;

export const StyledText = styled(Paragraph)`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.2;
  margin: 1rem 0 .5rem 0;
  font-family: 'Effra-Regular';
  @media ${({ theme }) => theme.device.tablet} { 
    font-size: 24px;
  }

`;

export const StyledSubtext = styled(Paragraph)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  margin: .5rem 0;

  font-family: 'Effra-Regular';
  @media ${({ theme }) => theme.device.tablet} { 
    font-size: 16px;
  }
`;

export const StyledLogo = styled(Image)`
  position: absolute;
  top: -20px;
  right: 30px;
  z-index: 1;
  width: 15%;

  @media ${({ theme }) => theme.device.tablet} { 
    position: relative;
    top: unset;
    right: unset;
    width: unset;
  }
`;