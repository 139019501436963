import styled from "styled-components";
import Icon from "../Common/Icon";
import Modal from "../Modal";
import Video from "../Video";
import Image from "../Common/ImageFluid";

export const Container = styled.div`
  position: relative;
  cursor: pointer;

  &::before {
    content: "";
    // position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(46, 65, 97, 0.25);
  }
`;

export const SecondContainer = styled.div`
  position: absolute;
  z-index: 99999;
  width: 100%;
  display: flex;
  align-items: end;
  justify-content: flex-start;
  height: 160px;
  bottom: 0;
  padding-left: 5%;
  padding-bottom: 5%;
  border-radius: 4px;  
  background: linear-gradient(
    to top,
    black,
    rgba(255, 0, 0, 0)
  );
  img {
    width: 24.78px !important;
    height: 22.13px !important;
    margin-right: 0.9rem;
  }
 
`

export const VideoText = styled.div`
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Effra-Regular';
  height: 22.13px !important;
  display: flex;
  align-items: center;
`
export const PlayIcon = styled(Icon)`
  z-index: 1;
  width: 8.5rem;
  height: 8.5rem;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
`;

export const VideoModal = styled(Modal).attrs({
  overlayClassName: "overlay",
  modalClassName: "modal",
})`
  &.variant_A {
    display: none;
  }
  & .overlay {
    position: fixed;
    background-color: rgba(45, 72, 101, 0.9);
    z-index: 2;
    inset: 0;
  }

  & .modal {
    position: absolute;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    left: 50%;
    max-width: 95vw;
    width: 100%;

    video {
      width: 100%;
    }

    @media ${({ theme }) => theme.device.tablet} {
      max-width: none;
      width: auto;

      video {
        width: auto;
        max-height: 90vh;
      }
    }
  }
`;

export const StyledVideo = styled(Video)`
  max-width: 100%;
  width: auto;
  height: auto;
`;

export const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;
`;
