import styled from "styled-components";
import { Paragraph } from "../Text";
import Button from "src/components/Common/Button";
import Image from "../Common/ImageFluid";

export const LoadingText = styled(Paragraph)`
  font-size: 2rem;
  line-height: 2.4rem;
  color: ${({ theme }) => theme.colors.font_dark_gray};
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);

  @media ${({ theme }) => theme.device.tablet} {
    top: 50%;
    font-size: 3.6rem;
    line-height: 4rem;
  }
`;

export const Widget = styled.div`
  background-color: ${({ theme }) => theme.colors.pale_blue};
  z-index: 1;
  position: relative;


  .summary-overview {
    margin: 0 0 10px 0;
    width: 100% !important;
    @media ${({ theme }) => theme.device.tablet} {
      width: 400px !important;
      margin: 15px 0;
    }
  }
`;

export const TrustpilotContainer = styled.div`
  max-width: 1200px;
    margin: 0px auto!important;
    border: none!important;
    padding: 0!important;
    
}
`;

export const TrustpilotBox = styled.div`
  background-color: white;
  padding: 0px;
  border: 1px solid black;
  @media ${({ theme }) => theme.device.tablet} {
    padding: 3px 20px 20px 20px;
    border: none;
  }
`;

export const Title = styled(Paragraph)`
  font-size: 24px;
  color: black;
  text-align: center;
  margin: 0 0 20px 0;
  @media ${({ theme }) => theme.device.tablet} {
    text-align: left;
  }
`;

export const Subtitle = styled(Paragraph)`
  font-size: 15px;
  color: black;
  padding: 0 10px;
  text-align: center;
  margin: 15px 0 -11px 0;
  @media ${({ theme }) => theme.device.tablet} {
    text-align: left;
    font-size: 19px;
  }
`;
export const StyledSubtitle = styled(Paragraph)`
  font-size: 15px;
  color: black;
  padding: 12px 20px 20px 0;;
  text-align: center;
  margin: 15px 0 0px 0;
  @media ${({ theme }) => theme.device.tablet} {
    text-align: left;
    font-size: 19px;
  }
`;

export const StyledButton = styled(Button)`
  background-color: #00bc73;
  border-radius: 30px;
  padding: 8px 24px;
  height auto;
`;

export const StyledImage = styled.div`
  height: 32px;
  width: 125px;
  display: flex;
  margin: auto;
  @media ${({ theme }) => theme.device.tablet} {
    height: 39px;
    width: 160px;
    margin: 0;
  }
`;
export const ImageButtonBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -10px;

  @media ${({ theme }) => theme.device.tablet} {
    flex-direction: row;
    align-items: flex-end;
    margin-top: 0px;
  }
`;
export const LinkedText = styled(Paragraph)`
  text-decoration: underline;
  line-height: 20px;
  margin: 0 20px;
`;

export const LinkedTextButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin: 10px 0;
  @media ${({ theme }) => theme.device.tablet} {
    margin: 0px;
  }
`;

export const ReviewsWidget = styled.div`
  .okeReviews .okeReviews-reviewsWidget-header-controls-writeReview {
    display: inline-block !important;
  }
`

export const StarRating = styled.div`
  padding: 0 0 1.5rem;
  padding-top: 5px;
`

export const OkendoContainer = styled.div`
  margin: 0 auto;
  max-width: 1200px;

  .okeReviews .okeReviews-reviewsWidget.is-okeReviews-empty {
    display: block;
  }

`