import styled from "styled-components";
import Icon from "../Common/Icon";
import { Heading } from "../Text";

export const ModalHeader = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ModalBody = styled.div`
  padding: 0 3rem 3rem 3rem;
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  img {
    width: 80%;
    margin: 0 auto;
  }
`;

export const Title = styled(Heading)`
  white-space: pre-line;
  color: #133048 ;
  font-size: 2rem;
  line-height: 1rem;
  font-family: 'Effra-Bold';
  font-weight: 700;
  text-align: center;

  @media ${({ theme }) => theme.device.tablet} {
    font-size: 2rem;
    line-height: 2.5rem;
  }

  @media ${({ theme }) => theme.device.phone} {
    line-height: 3rem;
  }
  margin-bottom: 2rem;
`;
export const CloseIcon = styled(Icon)`
  cursor: pointer;
  border: 1px solid gray;
  border-radius: 50%;
  padding: 5px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 0.75rem;
  margin-top: 3rem;
`;

export const ExitButton = styled.button`
  background-color: white;
  border: 1px solid #133048;
  cursor: pointer;
  color: #133048;
  text-transform: uppercase;
  padding: 10px 20px;
  width: 50%;
`;

export const ConfirmButton = styled.button`
  background-color: #133048;
  color: white;
  border: 1px solid #133048;
  cursor: pointer;
  padding: 10px 20px;
  text-transform: uppercase;
  width: 50%;
`;

