import styled from "styled-components";
import { Paragraph } from "src/components/Text";

interface ButtonProps {
  active: boolean;
  paddingTopSmall: boolean;
}

export const Button = styled.button<ButtonProps>`
  border: 0;
  cursor: pointer;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  padding: 3.3rem 1.4rem 0.875rem;
  /* padding: 1.425rem 1.4rem 0.875rem; */
  /* padding: ${({ paddingTopSmall }) =>
    paddingTopSmall ? "1.2rem 1.4rem 0.875rem" : "1.425rem 1.4rem 0.875rem"} */
  background-color: ${({ theme }) => theme.colors.bg_pink};
  border-width: 0.1rem;
  border-color: ${({ theme, active }) =>
    active ? theme.colors.geraldine : "transparent"};
  border-style: solid;
  max-width: 19.6rem;
  width: 100%;
  text-align: left;

  @media ${({ theme }) => theme.device.tablet} {
    padding: 3.3rem 2.4rem 1.6rem;
  }
`;

export const Title = styled(Paragraph)`
  line-height: 1.8rem;
  /* margin-bottom: 2.3rem; */
  margin-bottom: 1.3rem;
  text-align: left;
  font-weight: ${({ theme }) => theme.fontWeight.medium};

  @media ${({ theme }) => theme.device.tablet} {
    line-height: 2rem;
    margin-bottom: 1.4rem;
  }
`;

export const Content = styled(Paragraph)`
  text-align: left;
  font-size: 1.2rem;
  line-height: 2.4rem;
  margin-bottom: 0.2rem;

  @media ${({ theme }) => theme.device.tablet} {
    font-size: 1.4rem;
    line-height: 1.6rem;
  }
`;

export const PriceBlock = styled.span`
  display: flex;
  align-items: center;
`;

export const Price = styled(Paragraph)`
  font-size: 1.2rem;
  line-height: 2.4rem;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.colors.geraldine};
  margin-left: 0.9rem;

  @media ${({ theme }) => theme.device.tablet} {
    font-size: 1.4rem;
  }
`;

export const DiscountPrice = styled(Price)`
  margin-left: 0;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  text-decoration-line: line-through;
`;

export const AdditionalTitle = styled(Paragraph)`
  font-size: 0.8rem;
  line-height: 1.2rem;
`;

export const AdditionalContainer = styled.div`
  position: absolute;
  top: 1.3rem;
  display: inline-flex;
  align-items: center;
  margin-bottom: 0.3rem;
  min-height: 1.7rem;
  padding: 0 0.5rem;
  background-color: ${({ theme }) => theme.colors.geraldine};
  color: ${({ theme }) => theme.colors.font_white};
`;
