import React, {useState, useEffect} from 'react';
import {
  StyledWrap,
  SectionWrapper
} from "./styles";
import ProductHero from '../ProductHero';

interface Props {
  data: {
    product:any;
  };
}
export interface PackType {
  key: string;
  quantity: number;
  price: string;
}

const ProductInformationsSection = ({ data }: Props) => {
    const {product} = data;
    const [activePack, setActivePack] = useState<PackType | undefined>();
    const [quantity, setQuantity] = useState(1 as number);
    const [tempQuantity, setTempQuantity] = useState<number | undefined>(1);

    const main = {
      title: product?.content?.main?.title,
      slug: product?.content?.main?.slug,
      pdpTitle: product?.content?.main?.pdpTitle,
      okendoReviews: product?.content?.main?.okendoReviews,
      productDescription: product?.content?.main?.productDescription,
      mainImage: product?.content?.main?.mainImage,
      productGallery: product?.content?.main?.productGallery,
      mobileGallery: product?.content?.main?.mobileGallery,
      iconListTop: product?.content?.main?.iconListTop,
      announcementBarProduct: product?.content?.main?.announcementBarProduct,
      iconListBottom: product?.content?.main?.iconListBottom,
      additionalInformationTitle: product?.content?.main?.additionalInformationTitle,
      productBundles: product?.content?.main?.productBundles,
      productCases: product?.content?.main?.productCases,
      productQuantity: product?.content?.main?.productQuantity,
      productValues: product?.content?.main?.productValues,
      quantityText: product?.content?.main?.quantityText,
      caseText: product?.content?.main?.caseText,
      caseSubheader: product?.content?.main?.caseSubheader,
      bundleIcon: product?.content?.main?.bundleIcon,
      bundleText: product?.content?.main?.bundleText,
      bundleSubheader: product?.content?.main?.bundleSubheader,
      showPayment: product?.content?.main?.showPayment, 
      paymentImage: product?.content?.main?.paymentImage,
      showTrialMessage: product?.content?.main?.showTrialMessage, 
      showDeliveryTimeWidget: product?.content?.main?.showDeliveryTimeWidget, 
      showCountdown: product?.content?.main.showCountdown, 
      date: product?.content?.main?.date, 
      showTestimonial: product?.content?.main?.showTestimonial, 
      enableProdQuantity: product?.content?.main?.enableProdQuantity, 
      // ...product.content.main
    }
  
    const newProduct = {
      defaultPrice: product.content.shopify.defaultPrice,
      ...product.content.shopify
    }
  

 
    useEffect(() => {
      if (!activePack) return;
  
      setQuantity(activePack.quantity);
    }, [activePack]);
  
    const removeQuantity = () => {
      if (quantity === 1) return;
  
      removeProductPack();
      setQuantity(quantity - 1);
    };
  
    const removeQuantityBundle = () => {
      if (quantity === 0) {
        setQuantity(0)
        setTempQuantity(undefined)
        setActivePack(undefined)
      } else {
        removeProductPack();
        setQuantity(quantity - 1);
      }
  
    }
  
    const addQuantity = () => {
      if (activePack && quantity == 4) return;
      removeProductPack();
      setQuantity(quantity + 1);
    };
  
    const setProductPack = (pack: PackType) => {
      if (activePack && pack.key === activePack.key) {
        removeProductPack();
        return;
      }
  
      setTempQuantity(quantity);
  
      setActivePack({
        ...pack,
      });
    };
  
    const removeProductPack = () => {
      setActivePack(undefined);
  
      if (tempQuantity) {
        setQuantity(tempQuantity);
        setTempQuantity(undefined);
      } else {
        setQuantity(quantity - 1);
      }
    };

  return (
    <SectionWrapper>
      <StyledWrap>
        <ProductHero
              main={main}
              product={newProduct}
              setProductPack={setProductPack}
              removeProductPack={removeProductPack}
              activePack={activePack}
              setActivePack={setActivePack}
              quantity={quantity}
              addQuantity={addQuantity}
              removeQuantity={removeQuantity}
              removeQuantityBundle={removeQuantityBundle}
              setQuantity={setQuantity}
          />
      </StyledWrap>
    </SectionWrapper>
    
  );
};

export default ProductInformationsSection;
