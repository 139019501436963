import React, { FC } from "react";
//@ts-ignore
import { getGatsbyImageData } from "gatsby-source-sanity";
import { BgImage } from "gbimage-bridge";

interface Props {
  Tag?: "div" | "section";
  imageRef: string | undefined;
}

const sanityConfig = {
  projectId: process.env.GATSBY_SANITY_PROJECT_ID!,
  dataset: process.env.GATSBY_SANITY_DATASET!,
};

const Image: FC<Props> = ({ children, imageRef, ...props }) => {
  const image = getGatsbyImageData(imageRef, { width: 1920 }, sanityConfig);

  return (
    <BgImage image={image!} {...props}>
      {children}
    </BgImage>
  );
};

export default Image;
