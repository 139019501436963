import styled from "styled-components";
import Wrap from "src/components/Wrap";
import { Paragraph, SectionHeading } from "src/components/Text";
import Button from "src/components/Common/Button";

export const StyledWrap = styled(Wrap)`
  padding: 2.7rem 2rem 6.6rem;
  position: relative;

  @media ${({ theme }) => theme.device.tablet} {
    /* padding: 4.5rem 8.1rem 3.5rem; */
    padding: 4.5rem 8.1rem;
    max-width: 84.6rem;
    box-sizing: content-box;
  }
`;

export const StyledHeading = styled(SectionHeading)`
  margin-bottom: 2rem;

  @media ${({ theme }) => theme.device.tablet} {
    margin-bottom: 2.8rem;
  }
`;

export const RatingBox = styled(SectionHeading)`
  margin-bottom: 3.6rem;
`;

export const RatingText = styled(Paragraph)`
  margin-top: 0.3rem;
`;

export const SearchButton = styled(Button)`
  width: 100%;

  svg {
    width: 2rem;
    height: 2rem;
  }

  @media ${({ theme }) => theme.device.tablet} {
    width: auto;
    margin-bottom: 2.8rem;
  }
`;

export const TagButton = styled(Button)`
  padding: 0 1.5rem;
`;

export const ButtonList = styled.div`
  ${TagButton} {
    margin-right: 0.7rem;

    &:last-child {
      margin: 0;
    }
  }
`;

export const ActionButton = styled(Button)`
  width: 100%;

  @media ${({ theme }) => theme.device.tablet} {
    width: auto;
  }
`;

export const Actions = styled.div`
  margin-top: 1.2rem;

  ${ActionButton} {
    margin-right: 2.1rem;

    &:last-child {
      margin: 0;
    }
  }
`;
