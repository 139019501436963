import styled, { css } from "styled-components";
import Wrap from "src/components/Wrap";
import { Paragraph, SectionHeading } from "src/components/Text";
import Image from "src/components/Common/ImageFluid";
import Video from 'src/components/Video';

interface ListProps {
  mobileDir?: "row" | "column";
}

interface ImageProps {
  big: boolean;
}

interface HeadingProps {
  paddingBottom: boolean;
}

interface WrapProps {
  backgroundColor: string | undefined;
}

export const StyledWrapContainer = styled(Wrap)<WrapProps>`

&.variant_A {
  display:none;
}
  padding: '3.4rem 2rem 6.8rem';
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 0;
  padding-right: 0;
  padding-top: 4rem;
  background: ${({ backgroundColor }) => backgroundColor ? `linear-gradient(180deg,transparent 50%, ${backgroundColor.value} 50%)` : "initial"};
  max-width: none;

  @media ${({ theme }) => theme.device.tablet} {
    min-height: none;
    padding-top: 7.5rem;
    padding-left: 0;
    padding-right: 0;
    max-width: none;
  }
`;

export const Heading = styled(SectionHeading) <HeadingProps>`
  text-align: center;
  font-size: 25px;
  font-weight: 700;
  font-family: 'Effra-Bold';
  max-width: 140rem;
  white-space: pre-line;
  width: 90%;
  line-height: 30px;

  @media ${({ theme }) => theme.device.tablet} {
    white-space: pre-line;
    font-size: 40px !important;
    width: 45%;
    line-height: 45px;
  }

  ${({ paddingBottom }) =>
    paddingBottom &&
    css`
      padding-bottom: 3.4rem;

      @media ${({ theme }) => theme.device.tablet} {
        padding-bottom: 7rem;
      }
    `}
`;

export const IconList = styled.div<ListProps>`
  margin-top: '4.2rem';
  ${({ mobileDir }) => (mobileDir === "column" ? listColumnStyles : "")};
  width: 100%;
  overflow-x: scroll;
  display: grid;
  grid-template-columns: (3, 1fr);
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 3rem;

  @media ${({ theme }) => theme.device.laptop} {
    overflow-x: initial;
    display: flex;
    max-width: none;
    justify-content: space-between;
    flex-direction: row;
    width: 85%;
  }
`;

export const Icon = styled.div<ListProps>`
  display: flex;
  align-items: center;
  margin: 0 1rem;
  flex: 1;
  justify-content: center;
  overflow: visible;

  &:last-child {
    margin-right: 0;
  }

  @media ${({ theme }) => theme.device.tablet} {
    margin-right: 0;
  }
`;

const listColumnStyles = css<ListProps>`
  flex-direction: column;

  ${Icon} {
    margin-right: 0;
    margin-bottom: '4rem';

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const IconImage = styled(Image) <ImageProps>`
 // max-width: ${({ big }) => (big ? "4rem" : "3.5rem")};
  max-height: ${({ big }) => (big ? "3.5rem" : "3.5rem")};
  overflow: visible;

  @media ${({ theme }) => theme.device.tablet} {
    //max-width: ${({ big }) => (big ? "6rem" : "4.5rem")};
    max-height: ${({ big }) => (big ? "6rem" : "4.5rem")};
  }
`;

export const IconText = styled.span<ListProps>`
  white-space: pre-line;
  font-family: 'Effra-Regular';
  color: ${({ theme }) => theme.colors.font_black};
  font-size: 12px;
  line-height: ${({ theme }) => theme.lineHeight.xs.mobile};
  font-weight: 400;
  text-align: 'center';
  margin-top: 'rem';
  padding-left: '2rem';

  @media ${({ theme }) => theme.device.tablet} {
    margin-top: '1.9rem';
    font-size: '14px';
    line-height: ${({ theme }) => theme.lineHeight.xs.desktop};
  }
`;

export const IconTitle = styled(Paragraph)`
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: black;
  margin-top: 0;

  @media ${({ theme }) => theme.device.tablet} {
    font-size: 18px;
    line-height: 24px;
    margin-top: 0;
  }
`;

export const Description = styled.span`
    font-weight: 400;
    text-align: center;
    font-family: 'Effra-Regular';
    line-height: 20.8px;
    padding-top: 2.5rem;
    width: 80%;
    font-size: 14px;
    padding-bottom: 3rem;
    max-width: 140rem;

    @media ${({ theme }) => theme.device.largeDesktop} {
      width: 60%;
      font-size: 16px;
      padding-bottom: 6rem;
    }
`


export const StyledVideo = styled(Video)`
 
  max-width: 110rem;
  width: 80%;
  @media ${({ theme }) => theme.device.phone} {
    width: 94%;
    margin: 0 1rem;
  }
  @media ${({ theme }) => theme.device.largeDesktop} {
    width: 70%;
  }
`;

export const TextWithIcons = styled.div`
  display: flex;
  margin-top: 6rem;
  margin-bottom: 8rem;
  width: 90%;
  justify-content: space-evenly;
  max-width: 140rem;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;
  margin-bottom: 6rem;

  @media ${({ theme }) => theme.device.laptop} {
    flex-direction: row;
    margin-top: 6rem;
    margin-bottom: 8rem;
    align-items: center;
  }

`;

export const TextBeforeIcons = styled(SectionHeading)`
  font-weight: 700;
  font-size: 18px;
  color: #12142C;
  font-family: 'Effra-Bold';
  padding-bottom: 4rem;

  @media ${({ theme }) => theme.device.laptop} {
    padding-bottom: 0rem;
  }
 
`;
