import React, { useState, useEffect, useRef } from "react";
import { SanityImage } from "src/interfaces/sanity";
import {
  Container,
  PlayIcon,
  VideoModal,
  StyledVideo,
  StyledImage,
  SecondContainer,
  VideoText,
} from "./styles";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";
import { isSSR } from "src/utils/ssr";
import videoPlay from "src/images/play-videoimg.svg";

interface Props {
  poster: SanityImage;
  className?: string;
  src: string;
  type: any;
}

const VideoPopup = ({ poster, src, className, type }: Props) => {
  const [open, setOpen] = useState(false);
  const containerRef = useRef(null);

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (isSSR || !containerRef.current) return;

    if (open) {
      document.documentElement.style.overflow = "hidden";

      disableBodyScroll(containerRef.current);
    } else {
      document.documentElement.style.overflow = "auto";

      enableBodyScroll(containerRef.current);
    }

    return () => clearAllBodyScrollLocks();
  }, [open]);

  return (
    <>
      <Container onClick={openModal} className={className} ref={containerRef}>
        {type == "productsShopify" ?
        <SecondContainer>
           <img src={videoPlay} alt="Play Video"/> 
           <VideoText>
            <span>PLAY VIDEO</span>
           </VideoText>
        </SecondContainer> : 
        <>
          <PlayIcon name="video-play" />
        </>}
        <StyledImage imageRef={poster} />
      </Container>
      <VideoModal isOpen={open} onRequestClose={closeModal}>
        <StyledVideo isPopUp={true} src={src} controls={true} autoPlay={true} />
      </VideoModal>
    </>
  );
};

export default VideoPopup;
