import React from "react";
import ProductStyled from "src/components/ProductStyled";
import { StyledWrap } from "../HeroSection/styles";
import QuestionAnswer from "../QuestionAnswer";
import { RowStyled, CallUs, CallUsMobile } from "./styles";
import phone from "src/images/phone.svg";
import phoneWhite from "src/images/phoneWhite.svg";
import { useMediaQuery } from "react-responsive";
import { SectionSettings } from "../../interfaces/sanity";
import Wrap from "src/components/Wrap";

interface Props {
  data: {
    product: any;
    questionAnswerList: any;
    questionAnswerListPlus: any;
    sectionSettings: SectionSettings;
    experiment: {
      variantA?: boolean;
      variantB?: boolean;
    };
  };
  context?: any;
}


const ProductWithFAQSection = ({ data, context }: Props) => {
  const questionAnswerData = {
    list: data?.questionAnswerList,
    sectionSettings: data?.sectionSettings,
  };
  const questionAnswerListPlusData = {
    list: data?.questionAnswerListPlus,
    sectionSettings: data?.sectionSettings,
  };

  const isMobile = useMediaQuery({ maxWidth: "480px" });

  const { product } = data;
  return (
    <Wrap className={data?.experiment?.variantB ? 'variant_B' : data?.experiment?.variantA ? 'variant_A': ''}>
      <RowStyled>
        <ProductStyled
          id={product?.content?.shopify.productId}
          key={product?._id}
          slug={product.content?.main.slug.current}
          title={product?.content?.main.title}
          image={product?.content?.shopify.image}
          price={product?.content?.shopify.defaultPrice}
          ribbon={
            product.content?.main.ribbon?.enabled
              ? product.content?.main.ribbon?.text
              : undefined
          }
          large={true}
          addToCart={true}
          description={product?.content?.main?.productDescription[0]}
          mobilePhoto={
            product?.content?.main.mobileGallery?.length > 0 &&
            product?.content?.main.mobileGallery[0]
          }
        />
        <div>
          <div>
            <QuestionAnswer isSection={false} plusSign={true} data={questionAnswerListPlusData} />
          </div>
          <div>
            <QuestionAnswer isSection={false} data={questionAnswerData} />
          </div>
          <CallUs>
            <img src={phone} alt="phone"/>
            <p>WANT AN ANSWER NOW? Call us at <a href={`tel:${process.env.GATSBY_CURRENCY_SYMBOL == "$" ? "+1 888-445-1718" : "0808 258 3559"}`}>{process.env.GATSBY_CURRENCY_SYMBOL == "$" ? "+1 888-445-1718" : "0808 258 3559"}</a></p>
          </CallUs>
          <CallUsMobile>
            <img src={phoneWhite} alt="phone"/>
            <div>
              <p>WANT AN ANSWER NOW?</p>
              <p>Call us at <a href={`tel:${process.env.GATSBY_CURRENCY_SYMBOL == "$" ? "+1 888-445-1718" : "0808 258 3559"}`}>{process.env.GATSBY_CURRENCY_SYMBOL == "$" ? "+1 888-445-1718" : "0808 258 3559"}</a></p>
            </div>
          </CallUsMobile>

        </div>
      </RowStyled >
    </Wrap>
  );
};

export default ProductWithFAQSection;
