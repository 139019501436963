import React, { useEffect, useState } from "react";
import ProductList from "src/components/ProductList";
import { SectionSettings, Product } from "../../interfaces/sanity";
import { StyledWrap, StyledHeading, StyledButton } from "./styles";
import { client, useAddItemToCart } from "src/context/siteContext";
import { encode, decode } from "shopify-gid";

interface Props {
  data: {
    buttonText: string;
    products: Product[];
    title: string;
    sectionSettings: SectionSettings;
  };
}

const BoughtTogetherSection = ({ data }: Props) => {
  const [ready, setReady] = useState(false);
  const [idList, setIdList] = useState([]);
  const { addItemsToCart } = useAddItemToCart();

  const getIds = (products) => products.map((product) => product._id);

  useEffect(() => {
    if (!data?.products) return;

    const idList = getIds(data?.products);

    const shopifyIds = idList.map((id) => {
      const shopifyId = encode("Product", id, {
        accessToken: process.env.GATSBY_SHOPIFY_STOREFRONT_TOKEN,
      });

      return shopifyId;
    });

    const list = getData(shopifyIds);

    setReady(true);
  }, []);

  const getData = async (shopifyIds: []) => {
    const idList = await Promise.all(
      shopifyIds.map(async (id) => {
        const product = await client.product.fetch(id);
        const decodedVariants = [] as any;

        product?.variants.forEach((variant: any) => {
          decodedVariants.push({
            ...variant,
            cleanId: parseInt((variant.id).id, 0),
          });
        });

        if (!(decodedVariants.length > 0)) return;

        return decodedVariants[0].id as string;
      })
    );

    setIdList(idList);
  };

  const addProducts = async () => {
    const structArr = idList.map((id) => {
      return {
        variantId: id,
        quantity: 1,
        attributes: [],
      };
    });

    addItemsToCart(structArr);
  };

  return (
    <StyledWrap>
      <StyledHeading>{data.title}</StyledHeading>
      <ProductList
        products={data.products}
        mobileGrid={true}
        addToCart={true}
      />
      {data.buttonText && ready && (
        <StyledButton onClick={addProducts}>{data.buttonText}</StyledButton>
      )}
    </StyledWrap>
  );
};

export default BoughtTogetherSection;
