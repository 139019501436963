import React from "react";
import styled from "styled-components";
import Slider from "react-slick";
import Image from "../Common/ImageFluid";
import { SectionHeading } from "../Text";
import ArrowIcon from "src/images/arrow_left.svg";
import WhiteArrowIcon from "src/images/white_arrow.svg";
import { theme } from "src/theme/globalStyle";

const ClientSideOnlyLazy = React.lazy(() => import("src/components/Model"));

interface SliderProps {
  numberOfSlides: any,
}

export const GalleryContainer = styled.div`
  max-width: 100%;
  width: 100%;
  min-width: 0;
  display: none;
  @media ${({ theme }) => theme.device.tablet} {
    display: block;
  }
  
`;

export const MainSlider = styled(Slider)`
  width: 100%;

  .slick-arrow {
    width: 2rem;
    height: 1.2rem;
    background-image: url(${ArrowIcon});
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;

    &:before {
      content: none;
    }

    &.slick-next {
      right: 1.2rem;
    }

    &.slick-prev {
      left: 1.2rem;
      transform: translateY(-1.6rem) rotate(180deg);
    }
  }
`;

export const MySlider = styled.div<SliderProps>`
  .slick-slide,
  .slick-active {
    padding: 1rem 0.5rem;
  }

  .slick-list {
   margin: 0 5rem;
  }

  .slick-current { 
    img {
      // box-shadow: rgb(0 0 0 / 91%) 0px 0px 4px 0px;
      border: 03px solid #12142C;
    }
  }
  .slick-current .gatsby-image-wrapper {
   
  }

  .slick-arrow,
  .slick-next {
    opacity: 1;
  }

  .slick-arrow {
    width: 3rem;
    height: 3rem;
    background-image: url(${WhiteArrowIcon});
    background-repeat: no-repeat;
    background-size: 47%;
    background-position: center;
    z-index: 1;
    background-color: ${theme.colors.light_pink};
    border-radius: 4px;

    &:before {
      content: none;
    }

    &.slick-next {
      right: 0;
      transform: rotate(180deg);
      transform-origin: center 24%;
    }

    &.slick-prev {
      left: 0;
    }
  }
`;

export const NavSlider = styled(Slider)`
  width: 100%;
  padding: 0 4.2rem 0 2rem;

  .slick-slide {
    max-width: 14.0625vw;
    margin-right: 2.5vw;

    &:last-child {
      margin-right: 0;
    }
  }

  &::after {
    content: "";
    width: 14.0625vw;
    height: 100%;
    background: linear-gradient(
      270deg,
      #ffffff 0%,
      rgba(255, 255, 255, 0) 100%
    );
    position: absolute;
    right: 4.2rem;
    top: 0;
    pointer-events: none;
  }

  .slick-track {
    margin: 0;
  }
`;

export const NavContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const NavSliderItem = styled(Image)``;

export const SliderCount = styled(SectionHeading)`
  position: absolute;
  top: 0;
  right: 1.7rem;
  font-size: 1.8rem;

  span {
    color: ${({ theme }) => theme.colors.font_dark_gray};
  }
`;

export const Nav = styled.nav`
  width: 100%;
  margin-top: 0.8rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 0.8rem;
`;

export const NavImage = styled(Image)`
 
`;

export const AdditionalContainer = styled.div`
  position: absolute;
  // right: 2rem;
  bottom: 2rem;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

export const SecondAdditionalContainer = styled.div`
  position: absolute;
  // right: 2rem;
  top: 2rem;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: right;
`;

export const FirstAdditionalImage = styled(Image)`
  /* margin-bottom: 1.7rem; */
  width: 100px !important;
  margin: 0 2rem  0 2rem;
`;

export const SecondAdditionalImage = styled(Image)`
width: 100px !important;
margin: 0 2rem  0 2rem;
`;

export const ThirdAdditionalImage = styled(Image)`
width: 100px !important;
margin: 0 2rem  0 2rem;
`;

export const ImageContainer = styled.div`
  position: relative;
  /* height: 37.083vw;
  max-height: 53.4rem; */
`;

export const NavImageContainer = styled.div`
  img {
    max-width: 100%;
    // height: 110px;
    object-fit: cover;
    border-radius: 4px; 

  }
  cursor: pointer;

  & > div.current {
    // box-shadow: rgb(0 0 0 / 91%) 0px 0px 4px 0px;
  }

  div {
    position: relative;
    left: 0;
    top: 0;

    img:nth-child(1) {
      position: relative;
      top: 0;
      left: 0;
    }

    img:nth-child(2) {
      position: absolute;
      top: 35%;
      left: 45%;
      z-index: 1;
      width: 2vw;
      height: 3vw;

      @media ${({ theme }) => theme.device.tablet} {
        top: 30%;
        left: 40%;
        width: 1.5vw;
        height: 1.5vw;
      }
    }
  }

  &.active {
    // img {
    //   border: 0.225rem solid #0f0824;
    // }

    // img:nth-child(2) {
    //   border: none;
    // }
  }
`;

export const StyledModel = styled(ClientSideOnlyLazy)`
  model-viewer {
    &[style] {
      height: 33.083vw;
      max-height: 53.4rem;
    }
  }
`;
