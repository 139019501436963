import styled, { css } from "styled-components";
import Button from "src/components/Common/Button";
import { Paragraph, SectionHeading } from "src/components/Text";

interface InputProps {
  error?: boolean;
}

interface ButtonProps {
  sent: boolean;
}

export const Container = styled.div``;

const errorStyles = css`
  color: ${({ theme }) => theme.colors.error_red};
  border-color: ${({ theme }) => theme.colors.error_red};

  &::placeholder {
    color: ${({ theme }) => theme.colors.error_red};
  }

  &:focus {
    border-color: ${({ theme }) => theme.colors.error_red};
  }
`;

const inputStyles = css`
  font-family: 'Effra-Regular';
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  width: 100%;
  font-size: 1.4rem;
  line-height: 3rem;
  padding-bottom: 0.7rem;
  border: 0;
  border-bottom: 0.1rem solid ${({ theme }) => theme.colors.light_gray};
  margin-bottom: 3.3rem;

  &::placeholder {
    color: ${({ theme }) => theme.colors.font_dark_gray};
  }

  &:focus {
    outline: 0;
    border-color: ${({ theme }) => theme.colors.font_black};
  }

  ${({ error }) => error && errorStyles};
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media ${({ theme }) => theme.device.tablet} {
    max-width: 41.5rem;
  }
`;

export const Input = styled.input<InputProps>`
  ${inputStyles}
`;

export const TextArea = styled.textarea<InputProps>`
  ${inputStyles}
  min-height: 3.7rem;
  min-width: 100%;
  max-width: 100%;
`;

export const Heading = styled(SectionHeading)`
  margin-bottom: 1.4rem;

  @media ${({ theme }) => theme.device.tablet} {
    margin-bottom: 2.5rem;
  }
`;

export const Description = styled(Paragraph)`
  line-height: 2.6rem;
  margin-bottom: 4rem;

  @media ${({ theme }) => theme.device.tablet} {
    line-height: 3rem;
    margin-bottom: 6rem;
  }
`;

export const SubmitButton = styled(Button)<ButtonProps>`
  width: 100%;

  ${({ theme, sent }) =>
    sent &&
    css`
      background-color: ${theme.colors.bg_blue};
    `}

  @media ${({ theme }) => theme.device.tablet} {
    max-width: 25.1rem;
  }
`;
