import styled from "styled-components";

export const CountdownTimerBox = styled.div`
    border: 1px solid #4F6EA8;
    border-radius: 20px;
    margin: 0 0 2.5rem 0;
    display: inline-block;

    span {
        padding: 10px 12px;
        display: flex;
        align-items: center;
        font-size: 12px;
        line-height: 15px;
        font-weight: ${({ theme }) => theme.fontWeight.bolder};
        color: ${({ theme }) => theme.colors.font_black};
        font-family: 'Effra-Regular';
    }

    img {
        margin: 0 10px 0 0;
    }
   
`;

export const TimerContainer = styled.div`
    display: flex;
    flex-dirrection: row;
    align-items: center;
    margin: 0 0 0 -7px;

`;

export const TimerTextBox = styled.div`
    display: block;
    margin: auto;
    font-size: 14px;
    font-weight: 700;
    font-family: "Effra-Bold";
    line-height: 20px;


`;

export const DicountStyle = styled.span`
    background-color: #f97878;
    align-items: center;
    padding: 0.2rem 0.7rem;
    color: white;
    margin: 0.3rem 0.5rem;
`;

export const TimerStyle = styled.span`
`;