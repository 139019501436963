import styled from "styled-components";
import Wrap from "src/components/Wrap";
import { SectionHeading } from "src/components/Text";

interface StyledWrapProps {
  isSection?: boolean;
}

export const StyledWrap = styled(Wrap)<StyledWrapProps>`
  padding: ${({ isSection }) =>
    isSection ? "4.1rem 0 3.3rem" : "4.1rem 0 0 0"};

  @media ${({ theme }) => theme.device.tablet} {
    padding: ${({ isSection }) =>
      isSection ? "4.5rem 8.1rem" : "2.4rem 0rem 0rem 4.5rem"};
    max-width: 84.6rem;
    box-sizing: content-box;
  }

  @media ${({ theme }) => theme.device.phone} {
    padding: ${({ isSection }) => (isSection ? "0" : "0")};
    margin: ${({ isSection }) => (isSection ? "0" : "0")};

  }
  &.variant_A {
    display: none;
  }
`;

export const StyledHeading = styled(SectionHeading)`
  margin-bottom: 1.8rem;
  padding: 2.5rem 2rem 0 2rem;

  @media ${({ theme }) => theme.device.tablet} {
    margin-bottom: 2rem;
    padding: 0;
  }
`;
