import React from "react";
import { Heading, Deliveries, Row } from "./styles";
import DeliveryBlock from "src/components/Blocks/DeliveryBlock";

interface Props {
  heading: string;
  rows?: [
    {
      heading: string;
      text: string;
    }
  ];
}

const DeliveryRow = ({ heading, rows }: Props) => {
  return (
    <Row>
      <Heading>{heading}</Heading>
      {rows && (
        <Deliveries>
          {rows.map((row, index) => (
            <DeliveryBlock key={index} heading={row.heading} text={row.text} />
          ))}
        </Deliveries>
      )}
    </Row>
  );
};

export default DeliveryRow;
