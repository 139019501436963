import React, { useEffect, useRef } from "react";
import {
  StyledModal,
  CloseIcon,
  UpsellContainer,
  Title,
  SubTitle,
  UpsellList,
} from "./styles";
import { useTheme } from "styled-components";
import UpsellListItem from "../UpsellListItem";
import { useUpsells, useCartDrawer } from "src/context/siteContext";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";
import { isSSR } from "src/utils/ssr";
import { Product } from "src/interfaces/sanity";

interface Props {
  isOpen: boolean;
  boughtItem: {
    id: string;
    quantity: number;
    title: string;
    totalPrice: string;
  } | null;
  productList: Array<{
    _type: "upsellProduct";
    _key: string;
    product: Product;
    price: number;
    compareAtPriceV2?: object;
    type: "add" | "upgrade";
  }>;
  reviews: Array<{
    id: string;
    rating: number;
    total: number;
  }>;
  header?: string;
  subHeader?: string;
}

const UpsellPopup = ({
  isOpen,
  boughtItem,
  productList,
  reviews,
  header,
  subHeader,
}: Props) => {
  const { closeUpsells } = useUpsells();
  const { openCart } = useCartDrawer();
  const theme = useTheme();
  const containerRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (isSSR || !containerRef.current) return;

    if (isOpen) {
      document.documentElement.style.overflow = "hidden";

      disableBodyScroll(containerRef.current);
    } else {
      document.documentElement.style.overflow = "auto";

      enableBodyScroll(containerRef.current);
    }

    return () => clearAllBodyScrollLocks();
  }, [isOpen, containerRef.current]);

  const onClose = () => {
    openCart();
    closeUpsells();
  };

  return (
    <StyledModal isOpen={isOpen} closeIcon={false}>
      <UpsellContainer ref={containerRef}>
        <CloseIcon
          name="close"
          onClick={onClose}
          color={theme.colors.font_black}
        />
        <Title>
          {header ? header : "Add EasySleeper™ 100% Cotton Pillow Cases"}
        </Title>
        <SubTitle>{subHeader}</SubTitle>
        <UpsellList>
          {productList.map((productInfo) => {
            return (
              <UpsellListItem
                key={productInfo._key}
                productInfo={productInfo}
                boughtItem={boughtItem}
                review={reviews?.find(
                  (review) => review.id === productInfo.product._id
                )}
                onClose={onClose}
              />
            );
          })}
        </UpsellList>
      </UpsellContainer>
    </StyledModal>
  );
};

export default UpsellPopup;
