import styled from "styled-components";
import Button from "../Common/Button";
import { Paragraph, SectionHeading } from "src/components/Text";


interface BtnColor {
  bgColor: any;
}

export const GeneralSectionBlock = styled.div`
margin: 3rem auto;

@media ${({ theme }) => theme.device.tablet} { 
  margin: 3rem 0;
}
  
    
    .payment-icons {
      display: block;
      margin: auto;
    }
`; 

export const GeneralSectionButton = styled(Button)<BtnColor>`

@media ${({ theme }) => theme.device.largeDesktop} {
  padding: 20px 100px 35px 100px;
}
    background: ${({bgColor}) => bgColor ? bgColor : "#F6804E"};
    border-radius: 5px;
    display: block;
    margin: auto;
    padding: 20px 50px 35px 50px;

    color: ${({ theme }) => theme.colors.dark_blue};

    a {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 20px;
        font-family: 'Effra-Bold';

        text-align: center;
        letter-spacing: 0.02em;
        text-transform: uppercase;

        color: #FFFFFF;

        img {
            margin: 0 0 0 10px;
        }
    }


`;

export const RatingBox = styled.div`
  margin 1rem 0;
  display: flex;
  align-items: center;
  justify-content: center;

`;

export const RatingText = styled(Paragraph)`
  margin: 0.3rem 0 0 0.5rem;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;  
  text-transform: uppercase;
  font-family: 'Effra-Regular';
`;

export const IconTitle = styled(Paragraph)`
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #FFFFFF;
  margin-top: 1.9em;
  font-family: 'Effra-Bold';
  @media ${({ theme }) => theme.device.tablet} {
    font-size: 25px;
    line-height: 24px;
    margin-top: 1.9em;
  }
`;

export const AdditionalTextStyle = styled(Paragraph)`
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
    margin: 1.5rem 0;
    font-family: 'Effra-Regular';
`;