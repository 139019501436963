import React, { useEffect, useState } from "react";
import {
  StyledHeading,
  StyledWrap,
  ListItem,
  ItemHeader,
  StyledHeadingg,
  ItemContent,
  Content,
  QuestionList,
  LeftPart,
  RightPart
} from "./styles";
import { SectionSettings } from "../../interfaces/sanity";
import { useLocation } from "@reach/router";
import GeneralSection from "src/components/GeneralSection";
import useToggle from "src/hooks/useToggle";
import BlackPlusSign from "src/images/black_plus_sign.svg";
import { useMediaQuery } from "react-responsive";

export const size = {
  phone: 480,
};
interface Props {
  data: {
    sectionSettings: SectionSettings;
    title?: string;
    replaceIcon?: boolean;
    list: Array<{
      _key: string;
      id?: string;
      question: string;
      answer: string;
    }>;
    generalFooter: object;
  };
  context?: {
    reviews: object;
  };
  isSection?: boolean;
}

const NewFAQSection = ({ data, context, plusSign, isSection = true }: Props) => {
  const { title, list, sectionSettings } = data;

  const location = useLocation();
  const [activeIndex, setActiveIndex] = useState<number>(-1);

  const isMobile = useMediaQuery({ maxWidth: size.phone });

  useEffect(() => {
    if (!sectionSettings?.id) return;
    const hash = location.hash;

    const index = list.findIndex((item) => `#${item.id}` === hash);

    setActiveIndex(index);
  }, [location]);



  return (
    <StyledWrap isSection={isSection}>

      <LeftPart>
        {!isMobile &&
          <StyledHeading>{title}</StyledHeading>}

        <GeneralSection reviews={context?.reviews} content={data?.generalFooter} />
      </LeftPart>
      <RightPart>

        <QuestionList>
          {list?.map((item, i) => {

            const [open, toggleOpen] = useToggle();

            useEffect(() => {
              toggleOpen(i === activeIndex);
            }, [i === activeIndex]);

            return (
              <ListItem onClick={toggleOpen} id={item?.id}>
                <ItemHeader plusSign={plusSign}>

                  <img src={BlackPlusSign} alt="icon" />

                  <StyledHeadingg>{item?.question}</StyledHeadingg>
                </ItemHeader>
                {item?.answer && (
                  <ItemContent open={open}>
                    <Content blocks={item?.answer} />
                  </ItemContent>
                )}
              </ListItem>
            )
          })}
        </QuestionList>
      </RightPart>
      {isMobile && <StyledHeading>{title}</StyledHeading>}
    </StyledWrap>
  );
};

export default NewFAQSection;
