import styled from "styled-components";
import { Paragraph } from "src/components/Text";

export const Block = styled.div`
  height: 10.8rem;
  background-color: ${({ theme }) => theme.colors.pale_blue};
  padding: 2.5rem 3rem 3rem;
`;

export const Heading = styled(Paragraph)`
  font-size: 2rem;
  line-height: 2.4rem;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin-bottom: 1rem;
`;

export const Text = styled(Paragraph)`
  line-height: 1.8rem;
`;
