import { lazy } from "react";
import styled from "styled-components";
import Wrap from "src/components/Wrap";
import BlockContent from "../../components/BlockContent";
import Image from "../../components/Common/ImageFluid";
import waveMobile from "../../images/underline_mobile.svg";
import wave from "../../images/underline.svg";
import { Paragraph } from "src/components/Text";
import Button from "src/components/Common/Button";

const Model = lazy(() => import("src/components/Model"));

export const StyledWrap = styled(Wrap)`
  padding: 4.5rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${({ theme }) => theme.device.tablet} {
    padding: 9.3rem 8.1rem 0;
  }
`;

export const StyledModel = styled(Model)`
  width: 100%;
`;
