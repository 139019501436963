import styled from "styled-components";
import BlockContent from "src/components/BlockContent";
import ContactForm from "src/components/Forms/ContactForm";
import Wrap from "src/components/Wrap";

export const StyledWrap = styled(Wrap)`
  padding: 3.5rem 0 5rem;

  @media ${({ theme }) => theme.device.tablet} {
    padding: 6rem 8.1rem 6rem;
    max-width: 94.7rem;
    box-sizing: content-box;
  }
`;

export const StyledBlocks = styled(BlockContent)`
  color: ${({ theme }) => theme.colors.font_black};
  margin-bottom: 4rem;
  padding: 0 2rem;

  @media ${({ theme }) => theme.device.tablet} {
    margin-bottom: 6rem;
    padding: 0 6rem;
  }

  h2 {
    font-family: 'Effra-Regular';
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    font-size: 2.4rem;
    line-height: 3.2rem;
    margin-bottom: 2.6rem;

    @media ${({ theme }) => theme.device.tablet} {
      font-size: 4.4rem;
      line-height: 5.4rem;
      margin-bottom: 3rem;
    }
  }

  p {
    font-family: 'Effra-Regular';
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    font-size: 1.4rem;
    line-height: 2.2rem;

    @media ${({ theme }) => theme.device.tablet} {
      font-size: 1.6rem;
      line-height: 3rem;
    }
  }
`;

export const StyledContactForm = styled(ContactForm)`
  margin-top: 3.3rem;
  padding: 0 2rem;

  @media ${({ theme }) => theme.device.tablet} {
    margin-top: 6.4rem;
    padding: 0 6rem;
    max-width: 41.5rem;
    box-sizing: content-box;
  }
`;

export const RowContainer = styled.div`
  padding: 0 2rem;

  @media ${({ theme }) => theme.device.tablet} {
    padding: 0 6rem;
  }
`;

export const WhatsappContainer = styled.div`
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 0px 0px 0px;
  padding: 35px 35px 35px 35px;
  background-color: ${({ theme }) => theme.colors.bg_black};
  border-radius: 0;
  width: 100%;
  margin: 0;
  display: flex;

  @media ${({ theme }) => theme.device.tablet} { 
    width: 30%;
    border-radius: 10px 10px 10px 10px;
  }

`;

export const WhatsappBtn = styled.div`
  width: 100%;
  height: auto;
  margin: auto;

  @media ${({ theme }) => theme.device.tablet} {  
    margin: auto;
  }

  img {
    width: 100%;
    height: auto;
    display: block;
    margin: auto;
  }
`;

export const BlockAndWhatsapp = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media ${({ theme }) => theme.device.tablet} { 
    flex-direction: row;

   }
`;