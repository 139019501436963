import styled, { css } from "styled-components";
import Wrap from "../../components/Wrap";
import { Paragraph, SectionHeading } from "../../components/Text";
import Image from "src/components/Common/ImageFluid";
import Button from "src/components/Common/Button";

interface WrapProps {
  headingOnly: boolean;
  minHeight: number;
  textTop: boolean;
}

interface ImageProps {
  big: boolean;
}

interface ListProps {
  mobileDir?: "row" | "column";
  mobileDirection?: "row" | "column";
  containerWidth?: "80%" | "100%";
  textMobile?: "Top" | "Center";
}

interface HeadingProps {
  paddingBottom: boolean;
}

export const StyledWrap = styled(Wrap)<WrapProps>`
  padding: ${({ headingOnly, textTop }) =>
    headingOnly
      ? textTop
        ? "2.5rem 2rem 6.5rem"
        : "6.5rem 2rem 9.8rem"
      : "3.4rem 2rem 6.8rem"};
  /* padding: 6.5rem 2rem 15.8rem; */
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${({ theme }) => theme.device.tablet} {
    padding: ${({ headingOnly, textTop }) =>
      headingOnly
        ? textTop
          ? "10rem 8.1rem 15.6rem"
          : "10rem 8.1rem 15.6rem"
        : "8.5rem 2rem 3.5rem"};
    min-height: ${({ minHeight, headingOnly }) =>
      minHeight ? `${minHeight / 10}rem` : headingOnly ? "48rem" : "none"};
  }
`;

export const Heading = styled.div<HeadingProps>`
  text-align: center;
  white-space: normal;

  h1 {
    font-style: normal;
    font-weight: 700;
    color: #12142E;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 115%;
    text-align: left;
    font-family: 'Effra-Bold';

    @media ${({ theme }) => theme.device.tablet} {
      font-size: 58px;
      line-height: 1.2;
      text-align: center;
    }


  }

  @media ${({ theme }) => theme.device.tablet} {
    white-space: pre-line;
  }

  ${({ paddingBottom }) =>
    paddingBottom &&
    css`
      padding-bottom: 3.4rem;

      @media ${({ theme }) => theme.device.tablet} {
        padding-bottom: 7rem;
      }
    `}
`;

export const IconList = styled.div<ListProps>`
  display: flex;
  margin-top: 4.2rem;
  ${({ mobileDir }) => (mobileDir === "column" ? listColumnStyles : "")};
  width: 100%;
  overflow-x: none;
  justify-content: center;

  @media ${({ theme }) => theme.device.tablet} {
    margin-top: 7.5rem;
    ${({ containerWidth }) => (containerWidth == "80%" ? halfWidth : fullWidth)};
    overflow-x: initial;
    justify-content: space-between;
    flex-direction: row;
  }
`;


const listColumnStyles = css`
  flex-direction: column;
  text-align: center;
`;

const topText = css`
  align-items: flex-start;

  img {
  margin: 10px 0 0 0;
  }
`;

const centerText = css`
  align-items: center;
`;

const fullWidth = css`
  width: 100%;

`;

const halfWidth = css`
  width: 75%;

`;

const listRowStyles = css`
  flex-direction: row;
  text-align: left;
`;

export const Icon = styled.div<ListProps>`
  display: flex;
  ${({ mobileDirection }) => (mobileDirection == "column" ? listColumnStyles : listRowStyles)};
  ${({ textMobile }) => (textMobile == "Top" ? topText : centerText)};
  margin: 1.5rem 2rem 1.5rem 0;
  width: 100%;

  &:last-child {
    margin-right: 0;
  }

  @media ${({ theme }) => theme.device.tablet} {
    margin-right: 0;
    flex-direction: column;
    width: 33%;
    align-items: center;
  }
`;
export const IconImage = styled(Image)<ImageProps>`
 max-width: ${({ big }) => (big ? "8rem" : "3.5rem")};
  max-height: ${({ big }) => (big ? "8rem" : "3.5rem")};

  @media ${({ theme }) => theme.device.tablet} {
    max-width: ${({ big }) => (big ? "16rem" : "4.5rem")};
    max-height: ${({ big }) => (big ? "16rem" : "4.5rem")};
  }
`;

export const IconTitle = styled.span`
  white-space: pre-line;
  color: ${({ theme }) => theme.colors.font_black};
  font-size: 1.2rem;
  line-height: ${({ theme }) => theme.lineHeight.xs.mobile};
  text-align: center;
  margin-top: 1.1rem;
  font-weight: 900;
  font-family: 'Effra-Bold';



  @media ${({ theme }) => theme.device.tablet} {
    margin-top: 1.9rem;
    font-size: ${({ theme }) => theme.fontSize.xs.desktop};
    line-height: ${({ theme }) => theme.lineHeight.xs.desktop};
  }
`;

export const StyledButton = styled(Button)`
  /* margin-top: 4.8rem;

  @media ${({ theme }) => theme.device.tablet} {
    margin-top: 8.1rem;
  } */
`;

export const StyledImage = styled(Image)`
  width: 100%;
  margin: 3rem 0;

  @media ${({ theme }) => theme.device.tablet} {
    max-width: 75.9rem;
  }
`;

export const IconText = styled.div<ListProps>`
  width: 90%;
  display: block;
  ${({ mobileDirection }) => (mobileDirection == "column" ? listColumnStyles : listRowStyles)};
  margin: 1rem;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 1.2;
  font-family: 'Effra-Regular';

  @media ${({ theme }) => theme.device.tablet} { 
    text-align: center;
    width: 70%;
    margin: 1rem auto;
  }

  p {
    font-size: 15px;
    font-family: 'Effra-Regular';
    line-height: 1.3;
  }
  strong {
    font-weight: 900;
    font-family: 'Effra-Bold';
  }

`;

export const AdditionalTextStyle = styled(Paragraph)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  margin: 3rem 0 0 0;
  font-family: 'Effra-Regular';
`;

export const StyledSubheading = styled(Paragraph)`
font-family: 'Effra-Medium';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 120%;
margin: 3rem 0 0 0;
text-align: left;

@media ${({ theme }) => theme.device.tablet} {  
  font-size: 24px;
  margin: 0;
  text-align: center;
}



`;