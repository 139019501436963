import React, { useState, useEffect, useRef } from "react";
// import Slider from "react-slick";
// import Author from "src/components/Author";
import TestimonialSlider from "src/components/TestimonialSlider";
import {
  StyledWrap,
  StyledHeading,
  TextContainer,
  SlideCount,
  SliderContainer,
  Arrow,
  StyledHeadingCount
} from "./styles";

interface Props {
  data: any;
  context: any;
}

const TestimonialList = ({ data, context }: Props) => {
  const sliderRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [slideCount, setSlideCount] = useState<number>(data.list.length);

  const nextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };
  const previousSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  }

  return (
    <StyledWrap slug={context.slug} className={data?.experiment?.variantB ? 'variant_B' : data?.experiment?.variantA ? 'variant_A': ''}>
      <TextContainer slug={context.slug}>
        <StyledHeading slug={context.slug}>{data.title}</StyledHeading>
        <StyledHeadingCount slug={context.slug}>
          {currentSlide + 1} <SlideCount>/ {slideCount}</SlideCount>
        </StyledHeadingCount>
      </TextContainer>
      <SliderContainer>
        <TestimonialSlider
          list={data?.list}
          setCurrent={setCurrentSlide}
          ref={sliderRef}
          slug={context?.slug}
          type={context?._type}
        />
      {/* <Arrow name="arrow-left" onClick={previousSlide}/>
        <Arrow name="arrow-right" onClick={nextSlide} /> */}
      </SliderContainer>
    </StyledWrap>
  );
};

export default TestimonialList;
