import styled from "styled-components";
import Wrap from "../../components/Wrap";
import { SectionHeading, Paragraph } from "../../components/Text";
import Link from "../../components/Link";
import Image from "../../components/Common/ImageFluid";

export const StyledWrap = styled(Wrap)`
  padding: 4.5rem 2rem;

  @media ${({ theme }) => theme.device.tablet} {
    padding: 10rem 8.1rem;
    max-width: 94.4rem;
    box-sizing: content-box;
  }
`;

export const Heading = styled(SectionHeading)`
  margin-bottom: 3rem;

  @media ${({ theme }) => theme.device.tablet} {
    margin-bottom: 3.7rem;
  }
`;

export const Description = styled(SectionHeading)`
  /* margin-bottom: 4.3rem; */

  @media ${({ theme }) => theme.device.tablet} {
    /* margin-bottom: 5.5rem; */
    line-height: 6.4rem;
  }
`;

export const AuthorBox = styled.div`
  margin-top: 4.3rem;

  @media ${({ theme }) => theme.device.tablet} {
    margin-top: 5.5rem;
  }
`;

export const AuthorImage = styled(Image)`
  max-width: 5rem;
  margin-bottom: 2.1rem;

  @media ${({ theme }) => theme.device.tablet} {
    max-width: 7.7rem;
    margin-bottom: 2.7rem;
  }
`;

export const AuthorPosition = styled(Paragraph)`
  font-size: ${({ theme }) => theme.fontSize.xs.mobile};
  line-height: ${({ theme }) => theme.lineHeight.base.mobile};

  @media ${({ theme }) => theme.device.tablet} {
    font-size: ${({ theme }) => theme.fontSize.xs.desktop};
    line-height: ${({ theme }) => theme.lineHeight.base.desktop};
  }
`;

export const AuthorName = styled(AuthorPosition)`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const TitleLogo = styled(Image)`
  margin-bottom: 3rem;
  max-width: 12.5rem;

  @media ${({ theme }) => theme.device.tablet} {
    margin-bottom: 3.7rem;
    max-width: 21rem;
  }
`;
