import styled from "styled-components";

export const TestimonialPDPSection = styled.div`

   margin: 1rem 0 2rem 0;
   border: 1.54728px solid rgb(79, 110, 168, 0.5);
   border-radius: 22.5974px;
   width: 100%;
   font-family: 'Effra-Regular';
   display: flex;
   flex-direction: row;
   background-color: #F6F6FA;
   
   .testimonial-image {
       width: 25%;
       display: flex;
       align-items: flex-end;

       img {
           width: 100%;
           height: auto;
           border-bottom-left-radius: 22px;
       }
   }

   .testimonial-message {
       width: 75%;
       display: flex;
       flex-direction: column;
       justify-content: center;
       margin: 1rem 5px 1rem 10px;
       padding: 2rem 0;

        @media ${({ theme }) => theme.device.phone} {
          padding: 0;
          margin: 10px 5px 10px 10px;
         }



        .message {
            font-size: 12px;
        }

       .personal-info {
            margin: 1rem 5px 0 1rem;
            font-size: 10px;

            .name {
                font-weight: ${({ theme }) => theme.fontWeight.bolder};
                font-family: 'Effra-Bold';

            }

       }
   }


`;