import React, { FC } from "react";
import { SanityPack } from "src/interfaces/sanity";
import { Container, StyledPack } from "./styles";
import { PackType } from "src/components/ProductInfo";

interface Props {
  list: SanityPack[];
  className?: string;
  setProductPack: (pack: PackType) => void;
  activePack?: PackType;
}

const ProductPacks: FC<Props> = ({
  list,
  className,
  setProductPack,
  activePack,
}) => {
  const onPackClick = (item: SanityPack) => {
    setProductPack({
      key: item._key,
      quantity: item.quantity,
      price: item.price,
    });
  };

  return (
    <Container className={className}>
      {list.map((item) => (
        <StyledPack
          key={item._key}
          {...item}
          onClick={() => onPackClick(item)}
          active={activePack ? item._key === activePack.key : false}
        />
      ))}
    </Container>
  );
};

export default ProductPacks;
