import React, { FC } from "react";
import { Title, Type, Row, Description, Arrow, OpenContainer } from "./styles";
import PositionForm from "src/components/Forms/PositionForm";
import useToggle from "src/hooks/useToggle";

interface Props {
  className?: string;
  title: string;
  type?: string;
  description?: any;
}

const PositionRow: FC<Props> = ({ className, title, type, description }) => {
  const [open, toggleOpen] = useToggle();

  return (
    <>
      <Row className={className} onClick={toggleOpen} open={open}>
        <Title>{title}</Title>
        <Type>{type}</Type>
        <Arrow name="arrow-right" />
      </Row>
      {open && (
        <OpenContainer>
          <Description blocks={description} />
          <PositionForm position={title} />
        </OpenContainer>
      )}
      <form name="position-form" netlify="true" hidden={true}>
        <input type="text" name="positionTitle" />
        <input type="text" name="fullName" />
        <input type="email" name="email" />
        <input type="tel" name="phone" />
        <input type="text" name="resumeUrl" />
        <textarea name="coverLetter"></textarea>
      </form>
    </>
  );
};

export default PositionRow;
