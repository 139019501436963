import styled from "styled-components";
import Slider from "react-slick";
import Image from "../Common/ImageFluid";
import { SectionHeading } from "../Text";
import WhiteArrowIcon from "src/images/white_arrow.svg";

export const GalleryContainer = styled.div`
  position: relative;
  width: 100%;
  padding: 1.5rem 0 0 0;

  @media ${({ theme }) => theme.device.tablet} {
    display: none;
  }

`;

export const MainSlider = styled(Slider)`
  width: 100%;
`;

export const NavSlider = styled(Slider)`
  width: 100%;
  padding: 0 4.2rem 0 2rem;

  .slick-slide {
    max-width: 14.0625vw;
    margin-right: 2.5vw;

    &:last-child {
      margin-right: 0;
    }
  }

  &::after {
    content: "";
    width: 14.0625vw;
    height: 100%;
    background: linear-gradient(
      270deg,
      #ffffff 0%,
      rgba(255, 255, 255, 0) 100%
    );
    position: absolute;
    right: 4.2rem;
    top: 0;
    pointer-events: none;
  }

  .slick-track {
    margin: 0;
  }
`;

export const NavContainer = styled.div`
  position: relative;
  width: 100%;
  margin-top: 0.5rem;
  padding: 0 7rem 0 7rem; 

  .slick-slider .slick-list .slick-current { 
    img {
      border: 03px solid #12142C;
    }
  }

  .slick-slider .slick-next {
    right: -9%;
    top: 100%;
    transform: translateY(0) rotate(180deg);
    background-color: #F58E8A;
    background-image: url(${WhiteArrowIcon});
    width: 27px;
    height: 27px;
    border-radius: 4px;
    margin-top: -21%;
    background-repeat: no-repeat;
    background-size: 47%;
    background-position: center;
    :before {
      display: none;
    }

    @media ${({ theme }) => theme.device.phone} {
      right: -12%;
    }

    @media ${({ theme }) => theme.device.smPhone} {
      right: -14%;
    }
  }

  .slick-slider .slick-prev {
      left: -9.5%;
      top: 100%;
      transform: translateX(0);
      background-color: #F58E8A;
      background-image: url(${WhiteArrowIcon});
      width: 27px;
      height: 27px;
      border-radius: 4px;
      margin-top: -21%;
      background-repeat: no-repeat;
      background-size: 47%;
      background-position: center;

      :before {
        display: none;
      }

      @media ${({ theme }) => theme.device.phone} {
        left: -14.5%;
       }

      @media ${({ theme }) => theme.device.smPhone} {
       left: -18.5%;;
      }
  }
`;

export const NavSliderItem = styled(Image)``;

export const SliderCount = styled(SectionHeading)`
  position: absolute;
  top: 0;
  right: 1.7rem;
  font-size: 1.8rem;

  span {
    color: ${({ theme }) => theme.colors.font_dark_gray};
  }
`;

export const Nav = styled.nav`
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;
  align-items: flex-start;

  video {
    max-width: 20.0625vw;
    width: 100%;
    padding: 0rem 0.7rem 5.1vw 0rem;
    height: 100%;
    max-height: 19vw;
  }

  &::after {
    content: "";
    width: 14.0625vw;
    height: 100%;
    background: linear-gradient(
      270deg,
      #ffffff 0%,
      rgba(255, 255, 255, 0) 100%
    );
    position: absolute;
    right: 13.125vw;
    top: 0;
    pointer-events: none;
  }
`;

export const NavImage = styled(Image)`
  max-width: none;
  width: 100%;
  flex-shrink: 0;
  border-radius: 4px;
`;

export const AdditionalContainer = styled.div`
  position: absolute;
  // right: 2.5vw;
  bottom: 2.5vw;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90%;
  justify-content: space-between;
  padding: 0 2rem 0 2rem;
`;

export const SecondAdditionalContainer = styled.div`
  position: absolute;
  top: 2.5vw;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90%;
  justify-content: right;
  padding: 0 2rem 0 2rem;
`;

export const FirstAdditionalImage = styled(Image)`
  width: 100px;
  margin: 0 2rem  0 2rem;
`;

export const SecondAdditionalImage = styled(Image)`
  width: 100px;
  margin: 0 2rem  0 2rem;
`;

export const ThirdAdditionalImage = styled(Image)`
width: 100px !important;
margin: 0 2rem  0 2rem;
`;

export const ImageContainer = styled.div`
  position: relative;
  padding: 0 2rem 0 2rem;

  img {
    border-radius: 4px;
  }
  .gatsby-image-wrapper {
    img{
      border-radius: 4px;
    }
  }
`;

export const NavImageContainer = styled.div`
  margin-right: 0.8rem;
  flex-shrink: 0;
  width: unset !important;
  cursor: pointer;

  & > div {
    position: relative;
    left: 0;
    top: 0;

    img:nth-child(1) {
      position: relative;
      top: 0;
      left: 0;
    }

    img:nth-child(2) {
      position: absolute;
      left: 40%;
      z-index: 1;
      width: 4vw;
  }
  & > div.current {
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.91);
    -webkit-box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.91);
    -moz-box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.91);
  }

  img {
    max-width: none;
    max-height: none;
    height: 100%;
    width: 100%;
    flex-shrink: 0;
    border-radius: 4px;
  }

  &:last-child {
    margin-right: 0;
  }
`;
