import styled from "styled-components";
import Pack from "src/components/Pack";

export const StyledPack = styled(Pack)`
  margin-right: 1rem;

  @media ${({ theme }) => theme.device.tablet} {
    margin-right: 0;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  ${StyledPack}:last-child {
    margin-right: 0;
  }
`;
