import React, { Suspense } from "react";
import { SanityImage, SanityColor } from "src/interfaces/sanity";
import { StyledWrap, StyledModel } from "./styles";
import { isSSR } from "src/utils/ssr";

interface Props {
  data: {
    modelFile: {
      url: string;
    };
    arModelFile?: {
      url: string;
    };
    sectionSettings: {
      backgroundColor: SanityColor;
      backgroundImage: SanityImage;
    };
  };
}

const ModelSection = ({ data }: Props) => {
  const { modelFile, arModelFile } = data;

  return (
    <StyledWrap>
      {!isSSR && modelFile && (
        <Suspense fallback={<div />}>
          <StyledModel
            alt="A 3D model"
            src={modelFile.url}
            usdzSrc={arModelFile?.url}
          />
        </Suspense>
      )}
    </StyledWrap>
  );
};

export default ModelSection;
