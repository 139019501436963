import React from "react";
import { SanityImage } from "src/interfaces/sanity";
import {
  Title,
  TextBlock,
  Description,
  StyledWrap,
  StyledImage,
} from "./styles";

interface Props {
  data: {
    negativeMarginBottom: boolean;
    negativeMarginTop: boolean;
    heading: string;
    description: any;
    imagePosition: {
      image: SanityImage;
      position: "left" | "right";
    };
  };
}

const LongImageText = ({ data }: Props) => {
  const {
    imagePosition,
    heading,
    description,
    negativeMarginBottom,
    negativeMarginTop,
  } = data;

  return (
    <StyledWrap position={imagePosition.position}>
      <StyledImage
        imageRef={imagePosition.image}
        marginBottom={negativeMarginBottom}
        marginTop={negativeMarginTop}
      />
      <TextBlock position={imagePosition.position}>
        {heading && <Title>{heading}</Title>}
        {description && <Description blocks={description} />}
      </TextBlock>
    </StyledWrap>
  );
};

export default LongImageText;
