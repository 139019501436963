import styled from "styled-components";
import IconList from "src/components/IconList";
import ProductPacks from "src/components/ProductPacks";
import Rating from "src/components/Rating";
import { Paragraph, SectionHeading } from "src/components/Text";
import { ListItem, Icon, Text } from "../IconList/styles";
import BlockContent from "src/components/BlockContent";
import Link from "src/components/Link";
import Image from "../Common/ImageFluid";

interface Props {
  notificationBar: boolean;
}

export const StyledContainer = styled.div<Props>`
  margin: 3rem auto 0;
  @media (max-width: 575px) {
    padding: 0 15px;
    margin: 0 0 2rem;
  }

  @media ${({ theme }) => theme.device.tablet} {
    max-width: 144rem;
    padding: 0 8.1rem;
    top: ${({ notificationBar }) => (notificationBar ? "14rem" : "9.2rem")};
  }
`;

export const Heading = styled(SectionHeading)`
  font-size: ${({ theme }) => theme.fontSize["3xl"].mobile};
  line-height: ${({ theme }) => theme.lineHeight["3xl"].mobile};

  @media ${({ theme }) => theme.device.tablet} {
    font-size: ${({ theme }) => theme.fontSize["3xl"].desktop};
    line-height: ${({ theme }) => theme.lineHeight["3xl"].desktop};
  }
`;

export const Title = styled(Heading)`
  margin-bottom: 1.4rem;
  @media ${({ theme }) => theme.device.tablet} {
    margin-bottom: 1.7rem;
  }
`;

export const PriceBox = styled.div`
  margin-bottom: 3rem;
  display: flex;
`;

export const Price = styled(Heading)``;

export const ComparePrice = styled(Heading)`
  opacity: 0.25;
  margin-left: 2rem;
  text-decoration: line-through;
`;

export const RatingsBox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.1rem;
  cursor: pointer;

  @media ${({ theme }) => theme.device.tablet} {
    margin-bottom: 0.6rem;
  }
`;

export const TopIconList = styled(IconList)`
  margin-top: 3.4rem;

  @media ${({ theme }) => theme.device.tablet} {
    margin-top: 3rem;
  }

  ${ListItem} {
    flex-direction: column;
    align-items: center;
    margin-right: 0;
    text-align: center;
  }

  ${Icon} {
    margin-bottom: 0.5rem;
    margin-right: 0;
    height: 8rem;
    max-height: none;

    @media ${({ theme }) => theme.device.tablet} {
      margin-bottom: 1rem;
    }
  }
`;

export const BottomIconList = styled(IconList)`
  margin-bottom: 2.7rem;

  @media ${({ theme }) => theme.device.tablet} {
    margin-bottom: 2.5rem;
  }

  ${ListItem} {
    margin-right: 2.5rem;

    @media ${({ theme }) => theme.device.tablet} {
      margin-right: 1.5rem;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  ${Icon} {
    margin-bottom: 0.7rem;

    @media ${({ theme }) => theme.device.tablet} {
      margin-bottom: 1rem;
    }
  }

  ${Text} {
    font-size: 1.1rem;
    line-height: 1.6rem;

    @media ${({ theme }) => theme.device.tablet} {
      font-size: 1.2rem;
      line-height: 1.8rem;
    }
  }
`;

export const StyledPacks = styled(ProductPacks)`
  margin-bottom: 2.8rem;
`;

export const StyledRating = styled(Rating)`
  margin-right: 0.7rem;
`;

export const RatingParagraph = styled(Paragraph)`
  span {
    margin-right: 2rem;
  }
`;

export const Description = styled.div`
  background-color: ${({ theme }) => theme.colors.bg_pink};
  width: 100%;
  padding: 4rem 5rem;

  @media (max-width: 767px) {
    padding: 3rem 2rem;
  }
  @media (max-width: 575px) {
    margin: 0;
  }
`;

export const DescriptionFooter = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DescriptionContent = styled(Paragraph)`
  font-family: 'Effra-Regular';
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  line-height: 2.4rem;
  font-size: 1.6rem;
  margin-bottom: 1.3rem;
`;

export const StyledBlocks = styled(BlockContent)`
  font-family: 'Effra-Regular';
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  line-height: 2.4rem;
  font-size: 1.6rem;
`;

export const Author = styled(Paragraph)`
  font-size: 1.4rem;
  line-height: 2rem;
  margin-left: 1.3rem;
`;

export const DescriptionLink = styled(Link)`
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-top: 0.8rem;
  flex-basis: 100%;

  @media ${({ theme }) => theme.device.phone} {
    margin-top: 0.8rem;
    flex-basis: unset;
  }

  @media ${({ theme }) => theme.device.tablet} {
  }
`;

export const RatingAndReviewerName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledImage = styled(Image)`
  display: flex;
  width: 130px;
  height: 60px;

  img {
    width: 100%;
    height: auto;
  }
`;
