import styled from "styled-components";
import Wrap from "../../components/Wrap";
import Icon from "../../components/Common/Icon";
import Image from "../../components/Common/ImageFluid";
import Rating from "../../components/Rating";
import PageLink from "src/components/Link";
import { Heading, Paragraph } from "../../components/Text";

interface WrapProps {
  notificationBar: boolean;
  align: "flex-start" | "center" | "flex-end";
}

export const ExploreVideo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 1;

  div {
    cursor: pointer;
    width: 5rem;
    margin-left: 1rem;
  }

  @media ${({ theme }) => theme.device.phone} {
    div img {
      width: 3rem;
      height: 3rem;
    }
  }

  @media ${({ theme }) => theme.device.tablet} {
    div img {
      width: 4rem;  
      height: 4rem;
    }
  }
`;

export const StyledImage = styled(Image)`
  width: 100%;
  height: auto;
  &.awards {
    width: 12rem;
    height: auto;
  }
  
`;

export const StyledText = styled(Heading)`
  margin-bottom: 2.2rem;
  white-space: initial;
  font-size: 2.5rem;
  width: 100%;
  line-height: 3.3rem;

  @media ${({ theme }) => theme.device.phone} {
    width: 70%;
  }

  @media ${({ theme }) => theme.device.tablet} {
    width: 100%;
    margin-bottom: 4.9rem;
    white-space: pre-line;
    font-size: 3.8rem;
    line-height: 4.8rem;
  }
`;

export const StyledHeading = styled(Heading)`
  margin-bottom: 5.7rem;
  white-space: initial;
  font-size: 3.7rem;
  width: 100%;

  @media ${({ theme }) => theme.device.phone} {
    width: 70%;
  }

  @media ${({ theme }) => theme.device.tablet} {
    width: 100%;
    margin-bottom: 2.9rem;
    white-space: pre-line;
    font-size: 8rem;
    line-height: 8.8rem;
  }
`;

export const StyledSubheading = styled(Paragraph)`
  margin-bottom: 2.3rem;
  /* max-width: 23.5rem; */

  @media ${({ theme }) => theme.device.phone} {
    width: 100%;
    padding: 4rem 0 0 0;
  }

  @media ${({ theme }) => theme.device.tablet} {
    width: 100%;
    margin-bottom: 4.1rem;
    max-width: none;
  }
`;

export const StyledWrap = styled(Wrap)<WrapProps>`
  /* padding: 23.9rem 2rem 2.1rem; */
  justify-content: ${({ align }) => align};
  padding: ${(notificationBar) =>
    notificationBar ? "24.4rem 2rem 3rem 2rem" : "12.7rem 2rem 15.2rem"};
  position: relative;

  @media ${({ theme }) => theme.device.tablet} {
    flex-direction: row;
    justify-content: space-between;
    padding: 13.2rem 8.1rem 5.1rem;
    min-height: 82vh;
    height: auto;
  }

  @media ${({ theme }) => theme.device.phoneLandscape} {
    height: initial;
    bacground: red;
  }
  &.variant_A {
    display: none;
  }
`;

export const RatingStar = styled(Icon)`
  margin-right: 0.5rem;
`;

export const Ratings = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.6rem;

  @media ${({ theme }) => theme.device.tablet} {
    flex-direction: row;
    margin-top: 0.9rem;
    align-items: center;
  }
`;

export const RatingsList = styled(Rating)`
  display: flex;

  @media ${({ theme }) => theme.device.tablet} {
    margin-right: 1.8rem;
  }

  ${RatingStar}:last-child {
    margin-right: 0;
  }
`;

export const AwardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  top: 10rem;
  right: 0rem;
  position: absolute;
  height: 100%;
  justify-content: space-around;
  align-items: flex-start;


  img:first-child {
    margin-right: 10rem;
  }

  @media (max-width: 768px) {
    img:first-child {
      width: 8rem;
      margin-right: 3rem;
    }
  }

  @media ${({ theme }) => theme.device.phone} {
    justify-content: start;
    top: 25%;
    right: 5%;
    img:first-child {
      margin-right: 1rem;
      width: 8rem;
      margin-bottom: 10rem;
    }

    img {
      width: 8rem;
    }
  }
`;

export const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;

  a {
    width: 67%;
    display: flex;
    justify-content: center;

    @media ${({ theme }) => theme.device.phone} {
      width: 80%;
    }
  }
`;

export const RatingsBox = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 11.5rem;
  align-items: flex-end;
  margin-top: 7rem;

  img {
    margin-bottom: 10rem;
    width: 20rem;
  }

  @media ${({ theme }) => theme.device.tablet} {
    margin-right: 8.2rem;
    width: 21.1rem;
    max-width: none;
  }

  @media ${({ theme }) => theme.device.phone} {
    display: flex;
    flex-direction: row;
    margin-bottom: 9rem;
    justify-content: flex-end;
    a {
      margin-right: 5rem;
    }
    img {
      width: 15rem;
      margin-bottom: 0;
      margin-left: 4rem;
    }
  }
`;

export const RatingsListUrl = styled.div`
  a {
    display: flex;
    flex-direction: column;

    @media ${({ theme }) => theme.device.tablet} {
      align-items: center;
      flex-direction: row;
    }
  }
`;

export const StyledLink = styled(PageLink)`
  position: relative;
`;

export const StyledReviews = styled.div`
  display: flex;
  @media ${({ theme }) => theme.device.mobile} {
    display: none;
  }
`;

export const StyledTrustpilot = styled.div`
  display: flex;
  flex-direction: column;
  @media ${({ theme }) => theme.device.mobile} {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
  }

  @media ${({ theme }) => theme.device.laptop} {
    flex-direction: column;
  }

  @media ${({ theme }) => theme.device.tablet} {
    flex-direction: column;
    img {
      height: auto;
      width: 100%;
      object-fit: contain;
    }
  }
`;
export const StyledReviewsMobile = styled.div`
  display: none;

  @media ${({ theme }) => theme.device.mobile} {
    display: flex;
    flex-direction: column;

    p {
      display: flex;
      justify-content: flex-end;
      margin-bottom: -13px;
    }
  }
`;

export const MobileVersionContainer = styled.div`
  display: none;
  @media ${({ theme }) => theme.device.mobile} {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  @media ${({ theme }) => theme.device.laptop} {
    display: none;
  }
  @media ${({ theme }) => theme.device.tablet} {
    display: none;
  }

  .reviews {
    display: flex;
    justify-content: flex-end;
  }
`;

export const DesktopVersionContainer = styled.div`
  display: flex;
  @media ${({ theme }) => theme.device.mobile} {
    display: none;
  }
  @media ${({ theme }) => theme.device.laptop} {
    display: flex;
  }
  @media ${({ theme }) => theme.device.tablet} {
    display: flex;
  }
`;

export const TextBoxAwardContainerMobileVersion = styled.div`
.text-container {
  margin: -8rem 0 0rem 0;
}
`;

export const ReviewsImageContainer = styled.div`
  .gatsby-image-wrapper {
    max-width: 18rem;
  }
`;

export const StyledTrustpilotImage = styled(Image)`
  max-width: 12rem;
  height: auto;
  width: 100%;
  margin: auto 0;
  object-fit: contain !important;

  @media ${({ theme }) => theme.device.tablet} {
    max-width: 12rem;
    height: auto;
    width: 100%;
    margin: auto 0;
    object-fit: contain !important;
  }
`;
