import styled from "styled-components";
import Wrap from "../../components/Wrap";
import BlockContent from "src/components/BlockContent";
import { SectionHeading } from "../../components/Text";
import Carousel from "src/components/Carousel";
import Check from "src/images/check.svg";

export const StyledWrap = styled(Wrap)`
  padding: 3rem 2rem 4.5rem 2rem;

  @media ${({ theme }) => theme.device.tablet} {
    padding: 0.01rem 7rem 8.1rem 8.9rem;
  }

  .trustpilot-se {
    display: flex;
    justify-content: center;
    font-family: 'Effra-Regular';
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0px;
    text-align: center;
    text-decoration: underline;
    margin: 4rem 0;
  }
`;

export const Heading = styled(SectionHeading)`
  margin-bottom: 3rem;

  @media ${({ theme }) => theme.device.tablet} {
    margin-bottom: 5rem;
  }
`;

export const CarouselContainer = styled.div`
  padding: 3rem 0rem;

  background-color: ${({ theme }) => theme.colors.darker_pale_blue};
  @media ${({ theme }) => theme.device.mobile} {
    padding: 0rem 0rem 3rem 0rem;
  }
  @media ${({ theme }) => theme.device.tablet} {
    padding: 3em 0rem 3rem 0rem;
  }
  @media ${({ theme }) => theme.device.laptop} {
    padding: 3em 0rem 3rem 0rem;
  }

  .slick-slide div:first-child {
  }

  .slick-slide {
    padding: 0 1rem;
  }

  .slick-prev: before, .slick-next: before {
    color: #0f0923;
    font-size: 20px;
  }

  @media ${({ theme }) => theme.device.phone} {
    .slick-prev {
      left: -12px;
    }

    .slick-next {
      right: -12px;
    }
  }

  ul.slick-dots {
    top: 100%;
    position: absolute;
    left: 40%;
    margin: 2rem 0;
    @media ${({ theme }) => theme.device.tablet} {
      display: none !important;
    }
    @media ${({ theme }) => theme.device.laptop} {
      display: none !important;
    }
  }

  .slick-active button {
    font-size: 0rem;
    height: 0.8rem;
    width: 0.8rem;
  }

  li button {
    background: #c3c1c8;
    border: 0 !important;
  }

  .slick-active button {
    background: #0f0923;
    border: 0 !important;
  }
`;

export const StyledCarousel = styled(Carousel)`
  @media ${({ theme }) => theme.device.tablet} {
    width: 100%;
  }
`;

export const TextContainer = styled.div`
  padding: 3rem 2rem;
  @media ${({ theme }) => theme.device.tablet} {
    padding: 6rem 9rem;
    display: none;
  }
`;

export const LeadText = styled(SectionHeading)`
  font-size: 1.8rem;
  line-height: 2.6rem;
  margin-bottom: 3.5rem;
  white-space: unset;

  @media ${({ theme }) => theme.device.tablet} {
    white-space: pre-line;
    font-size: 2.4rem;
    line-height: 3.4rem;
  }
`;

export const InnerText = styled(BlockContent)`
  font-family: 'Effra-Regular';
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.8rem;

  @media ${({ theme }) => theme.device.tablet} {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }

  li {
    margin: 0 0 2rem;
    padding: 0 0 0 4.4rem;
    list-style-type: none;
    position: relative;

    @media ${({ theme }) => theme.device.tablet} {
      padding: 0 0 0 5.2rem;
    }

    &:before {
      content: "";
      position: absolute;
      width: 2.4rem;
      height: 2.4rem;
      background-image: url(${Check});
      background-size: contain;
      left: 0;

      @media ${({ theme }) => theme.device.tablet} {
        width: 3.2rem;
        height: 3.2rem;
      }
    }
  }
`;
