import React, { useState } from "react";
import ProductForm from "src/components/ProductForm";
import { useTheme } from "styled-components";
import { Paragraph } from "src/components/Text";
import {
  StyledContainer,
  Title,
  Price,
  RatingsBox,
  TopIconList,
  BottomIconList,
  StyledPacks,
  StyledRating,
  RatingParagraph,
  Description,
  StyledBlocks,
  PriceBox,
  ComparePrice,
  DescriptionFooter,
  DescriptionContent,
  DescriptionLink,
  StyledImage,
  RatingAndReviewerName,
  Author,
} from "./styles";
import { SanityImage } from "src/interfaces/sanity";
import Rating from "src/components/Rating";
import Link from "../Link";

import Trustpilot from "../../images/TPL.png";
interface Props {
  id: string;
  title: string;
  price: string;
  productId: number;
  iconListTop: any;
  iconListBottom: any;
  activePack: any;
  setProductPack: any;
  removeProductPack: any;
  addQuantity: any;
  removeQuantity: any;
  quantity: any;
  setQuantity: any;
  description?: [];
  openReviews: () => void;
  rating?: number;
  totalReviews?: number;
  notificationBar: boolean;
  mobilePhoto?: SanityImage;
  atcButton?: {
    caption?: string;
    price?: boolean;
  };
  slug?: string;
  setOpenUpsell: (state: boolean) => void;
  productTestimonial: any;
}

const ProductComment = ({ notificationBar, productTestimonial }: Props) => {
  const theme = useTheme();

  return (
    <StyledContainer notificationBar={notificationBar} style={{display: "none"}}>
      {productTestimonial && (
        <Description>
          <DescriptionContent>
            {productTestimonial.description}
          </DescriptionContent>
          <DescriptionFooter>
            <RatingAndReviewerName>
              {productTestimonial.showBadge === true ? (
                <>
                  <StyledImage imageRef={productTestimonial.badge} />

                  {productTestimonial.author && (
                    <Author>{productTestimonial.author}</Author>
                  )}
                </>
              ) : (
                <>
                  <Rating
                    number={productTestimonial.ratingNumber}
                    color={theme.colors.geraldine}
                  />
                  {productTestimonial.author && (
                    <Author>{productTestimonial.author}</Author>
                  )}
                </>
              )}
            </RatingAndReviewerName>
            <DescriptionLink to={productTestimonial.linkUrl} color="font_black">
              {productTestimonial.linkText}
            </DescriptionLink>
          </DescriptionFooter>
        </Description>
      )}
    </StyledContainer>
  );
};

export default ProductComment;
