import React, { useRef, forwardRef } from "react";
import Author from "src/components/Author";
import { Description, Slide, StyledSlider, StyledVideo } from "./styles";

interface Props {
  list: any;
  setCurrent: any;
  ref: any;
  slug: any;
  type: any;
}

const TestimonialSlider = forwardRef(({ list, setCurrent, slug, type }: Props, ref) => {
  const handleAfter = (index: number) => {
    setCurrent(index);
  };

  const settings =  slug == "easy-sleeper" ? {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    arrows: true,
    dots: true,
    slidesToScroll: 3,
    afterChange: handleAfter,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  } : 
  { 
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    arrows: true,
    dots: false,
    slidesToScroll: 3,
    afterChange: handleAfter,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  return (
    <StyledSlider {...settings} ref={ref} slug={slug}>
      {list.map((item, i) => {
        return (
          <Slide key={item._key}>
            {item.text && <Description>{item.text}</Description>}
            {item.video && (
              <StyledVideo poster={item.video.poster} src={item?.videoShopify ? item?.videoShopify : item.video.url} type={type}/>
            )}
            <Author
              image={item?.author?.image}
              name={item?.author?.name}
              position={item?.author?.position}
              rating={item?.author?.ratingNumber}
              slug={slug}
            />
          </Slide>
        );
      })}
    </StyledSlider>
  );
});

export default TestimonialSlider;
