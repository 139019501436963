import styled, { css } from "styled-components";
import Image from "src/components/Common/ImageFluid";
import Wrap from "src/components/Wrap";
import { SectionHeading } from "src/components/Text";
import BlockContent from "src/components/BlockContent";


export const StyledSection = styled.section`
  margin: 2rem 0;
  scroll-margin-top: ${({ notificationBar }) =>
    notificationBar ? "9rem" : "6.3rem"};

  @media ${({ theme }) => theme.device.tablet} {
    padding: 0 0 3rem 0;

    scroll-margin-top: ${({ notificationBar }) =>
      notificationBar ? "11.7rem" : "6.9rem"};
  }
`;


export const StyledWrap = styled(Wrap)`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin: 12rem auto 0 auto;


  @media ${({ theme }) => theme.device.tablet} {
    padding: 6.2rem 8.1rem 6.6rem;
  }

  @media ${({ theme }) => theme.device.phone} {
    margin: 20rem 0 4rem 0;

  }
`;



export const StyledTextContainer = styled.div`
  display: flex;
  width: 100%;
  align-content: center;
  align-items: center;
  margin: 0 0 2rem 0;

  @media ${({ theme }) => theme.device.tablet} {
    width: 37%;
    margin: 0;
  }

  .blog-title {
    font-size: 2.5rem;
    line-height: 2rem;
    margin-bottom: 0;
}
  }

`;

export const StyledBlogContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  @media ${({ theme }) => theme.device.phone} {
    flex-direction: column;
  }
`;

export const StyledBlogItems = styled.div`
  display: flex;
  width: 63%;
  justify-content: space-between;
  flex-wrap: wrap;


  .category-title {
    font-size: 1.625rem;
    line-height: 1.875rem;
    margin: 1rem 0 0 0;
    font-family: 'Effra-Regular';
  }

  @media ${({ theme }) => theme.device.phone} {
    width: 100%;

  }

  .single-blog-item {
      width: 23%;
      margin: 0;

      @media ${({ theme }) => theme.device.phone} {
        width: 48%;
        margin: 0 0 5rem 0;
      }

      .blog-group-box {
        width: 100%;
        height: 100%;
      }
  }
`;

export const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;
  border-radius: 5px;
`;


