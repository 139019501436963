import styled from "styled-components";
import Wrap from "../../components/Wrap";
import Button from 'src/components/Common/Button';



export const StyledWrap = styled(Wrap)`
  padding: 1rem 2rem;

  &:first-child {
    margin-top: 3.5rem;

    @media ${({ theme }) => theme.device.phone} {
      margin-top: 7rem;
    }
  }

  @media ${({ theme }) => theme.device.tablet} {
    padding: 2rem 8.1rem 1rem;
  }

  .rich-text {
    width: 100%;
    margin: auto;

    @media ${({ theme }) => theme.device.tablet} {
      width: 50%;
    }
    .sub-header {
      color: #F48B46;
    }
  
    p {
      margin: 16px 0 !important;
    }
  
  }

  h1 {
    font-family: 'Effra-Regular';
    font-size: 3.5rem;
    line-height: 4rem;
    color: #0F0824;
  }
  
  h2 {
    font-family: 'Effra-Regular';
    font-size: 2.475rem;
    line-height: 3rem;
    color: #0F0824;
  }
  
  h3 {
    font-family: 'Effra-Regular';
    font-size: 1.925rem;
    line-height: 2.3rem;
    color: #0F0824;
  }
  
  h4 {
    font-family: 'Effra-Regular';
    font-size: 1.725rem;
    line-height: 2.1rem;
    color: #0F0824;
  }
  
  h5 {
    font-family: 'Effra-Regular';
    font-size: 1.375rem;
    line-height: 1.9rem;
    color: #0F0824;
  }
  
  h6 {
    font-family: 'Effra-Regular';
    font-size: 1.175rem;
    line-height: 1.6rem;
    color: #0F0824;
  }
  
  p {
    font-family: 'Effra-Regular';
    color: #0F0824;
    font-size: 16px;
  }
  
  small {
    font-size: 1.275rem;
    line-height: 1.5rem;
  }
  
  strong {
   font-weight: 700;
  }

  em {
    font-style: italic;
  }
  
  p:empty {
    min-height: 16px;
  }
  
  .side-header:first-child {
    font-weight: bold;
    background-color: rgb(246, 246, 250);
  }
  
  .rich-text-img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }

  table {
    font-family: 'Effra-Regular';
    width: 100%;
  }

  td,
  tr,
  th {
    border: 2px solid #0F0824;
    color: black;
    text-align: center;
    padding: 1em;
  }

  th {
    background-color: rgb(246, 246, 250);
  }
  
  .table {
    overflow: auto;
    width: 100%;
    display: flex;
    margin: 2rem 0;
  
  
  
    table {
      margin: 0 0 1rem 0;
    }
  
    &::-webkit-scrollbar {
      width: 12px;
      height: 12px;
    }
  
    &::-webkit-scrollbar-track {
      background: #E9E6E6;
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: black;
      border-radius: 20px;
      border: 2px solid #E9E6E6;
    }
  }
  
  .header-link-wrapper {
    position: relative;
  
  
    a {
      position: absolute;
      left: -25px;
      top: -3px;
      color: vars.$nile-blue;
      text-decoration: none;
      opacity: 0;
      transition: opacity 0.2s linear;
  
    }
  
    &:hover {
      a {
        opacity: 1;
      }
    }
  }
  
  .richtext-btn {
    background-color: #0F0824;
    color: #FFFFFF;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 1.6px;
    text-transform: uppercase;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    padding: 20px;
  
    &:hover {
      text-decoration: none;
      color: white;
    }
  }
  
`;


export const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.font_pink};
  width: 350px;
  padding-left: 2.6rem;
  padding-right: 2.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;

  @media ${({ theme }) => theme.device.tablet} {
    padding-left: 0;
    padding-right: 0;
    width: 250px;
  }

  span.label {
    margin: 0 1rem 0 0;
  }
`;