import styled from "styled-components";
import { SectionHeading } from "../Text";
import Button from "../Common/Button";
import BlockContent from "src/components/BlockContent";

export const Row = styled.div`
  margin-bottom: 5rem;

  @media ${({ theme }) => theme.device.tablet} {
    margin-bottom: 10.7rem;
  }
`;

export const Heading = styled(SectionHeading)`
  margin-bottom: 2.6rem;

  @media ${({ theme }) => theme.device.tablet} {
    line-height: 5.4rem;
    margin-bottom: 3rem;
  }
`;

export const StyledBlocks = styled(BlockContent)`
  color: ${({ theme }) => theme.colors.font_black};
  font-family: 'Effra-Regular';
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: 1.4rem;
  line-height: 2.2rem;

  @media ${({ theme }) => theme.device.tablet} {
    font-size: 1.6rem;
    line-height: 3rem;
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.geraldine};
  }
`;

export const StyledButton = styled(Button)`
  margin-top: 2.2rem;

  @media ${({ theme }) => theme.device.tablet} {
    margin-top: 3.6rem;
  }
`;
