import React, { FC, useState } from "react";
import { Container, Form, Input, SubmitButton } from "../ContactForm/styles";
import { StyledWrap, FormError, Heading, Description } from "./styles";
import { useForm } from "react-hook-form";

interface Props {
  data: {
    title?: string;
    description?: string;
    fields: [
      {
        type: string;
        name: string;
        placeholder?: string;
        required: boolean;
      }
    ];
    submitBtn?: string;
  };
}

const BlogForm: FC<Props> = ({ data }) => {
  const { title, description, fields, submitBtn } = data;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [sent, setSent] = useState<boolean>(false);
  const [formError, setFormError] = useState<string>("");

  const onSubmit = async (data: any) => {
    setFormError("");
    if (sent) return;

    const response = await fetch(`/.netlify/functions/send-email`, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: JSON.stringify(data),
    });

    if (response.status !== 200) {
      setFormError("Error. Please try again later!");
      return;
    }

    setSent(true);
  };

  return (
    <StyledWrap>
      <Container>
        {title && <Heading>{title}</Heading>}
        {description && <Description>{description}</Description>}
        <Form name="blog-form" method="POST" onSubmit={handleSubmit(onSubmit)}>
          {fields.map((field) => {
            const { name, placeholder, type, required } = field;
            const inputName = name.replace(/\s+/g, "");
            return (
              <Input
                placeholder={`${placeholder ?? name} ${required ? "*" : ""}`}
                {...register(inputName, {
                  required: required ? `${name} is required` : "",
                })}
                type={type}
                error={!!errors[inputName]}
              />
            );
          })}
          <SubmitButton type="submit" small={true} sent={sent}>
            {sent ? "Done!" : submitBtn ?? "Send it"}
          </SubmitButton>
          <FormError>{formError}</FormError>
        </Form>
      </Container>
    </StyledWrap>
  );
};

export default BlogForm;
