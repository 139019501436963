import React from "react";
import {
  StyledHeading,
  StyledWrap,
  SearchButton,
  Actions,
  ActionButton,
} from "./styles";
import { SectionSettings } from "../../interfaces/sanity";
import ReviewList from "src/components/ReviewList";
import BottomButton from "src/components/BottomButton";
import { SmallDesktop } from "src/components/Responsive";
import { useReviewDrawer } from "src/context/siteContext";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

interface Props {
  data: {
    sectionSettings: SectionSettings;
    title: string;
    list: any;
  };
  context: any;
}

const CustomerQuestions = ({ data, context }: Props) => {
  const { openReviews } = useReviewDrawer();
  const breakpoints = useBreakpoint();
  const { title } = data;

  const openReviewsDrawer = () => {
    openReviews({
      title: context.content.shopify.title,
      id: context.content.shopify.productId,
      sku: context.content.shopify.defaultVariant.sku,
      slug: context.main.slug.current,
      type: "questions",
    });
  };

  return (
    <StyledWrap>
      <StyledHeading>{title}</StyledHeading>
      <SearchButton
        buttonType="outline"
        iconType="search"
        small={true}
        onClick={openReviewsDrawer}
      >
        {/* Search {context.latestQuestions?.total} customer questions */}
      </SearchButton>
      {/* <ReviewList list={context.latestQuestions?.results} questions={true} /> */}
      <Actions>
        <ActionButton
          buttonType="outline"
          small={true}
          onClick={openReviewsDrawer}
        >
          Ask your question
        </ActionButton>

        {breakpoints.tablet ? (
          <ActionButton
            buttonType="outline"
            small={true}
            onClick={openReviewsDrawer}
          >
            {/* See all {context.latestQuestions?.total} customer questions */}
          </ActionButton>
        ) : (
          <BottomButton onClick={openReviewsDrawer}>
            {/* See all {context.latestQuestions?.total} customer questions */}
          </BottomButton>
        )}
      </Actions>
    </StyledWrap>
  );
};

export default CustomerQuestions;
