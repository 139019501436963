import styled from "styled-components";
import Wrap from "src/components/Wrap";
import { SectionHeading, Paragraph } from "src/components/Text";
import Link from "src/components/Link";
import Button from "src/components/Common/Button";

export const StyledWrap = styled(Wrap)`
  padding: 4rem 2rem;
  min-height: 75.1rem;

  @media ${({ theme }) => theme.device.tablet} {
    padding: 6.5rem 8.1rem 6rem;
    display: flex;
    align-items: center;
    min-height: 62.2rem;
  }
`;

export const Heading = styled(SectionHeading)`
  /* margin-bottom: 8.4rem; */
  margin-bottom: 2.5rem;

  @media ${({ theme }) => theme.device.tablet} {
    margin-bottom: 4.4rem;
  }
`;

export const Description = styled(Paragraph)`
  margin-bottom: 2.2rem;

  @media ${({ theme }) => theme.device.tablet} {
    margin-bottom: 3rem;
  }
`;

export const Author = styled(Paragraph)`
  margin-bottom: 0.3rem;

  @media ${({ theme }) => theme.device.tablet} {
    margin-bottom: 1.3rem;
  }
`;

export const StyledLink = styled(Link)`
  display: block;
  margin-top: 3.1rem;

  @media ${({ theme }) => theme.device.tablet} {
    margin-top: 6.4rem;
  }
`;

export const Container = styled.div`
  @media ${({ theme }) => theme.device.tablet} {
    max-width: 30.7vw;
    margin-left: auto;
    /* margin-right: 2.8rem; */
  }
`;

export const StyledButton = styled(Button)`
  margin-top: 7rem;

  @media ${({ theme }) => theme.device.tablet} {
    margin-top: 6.4rem;
  }
`;
