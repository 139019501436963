import styled from "styled-components";
import Wrap from "src/components/Wrap";
import Slider from "react-slick";
import VideoPopup from "src/components/VideoPopup";
import { SectionHeading, Paragraph } from "src/components/Text";
import ArrowIcon from "src/images/newArrow.svg";

export const SlideNumber = styled.button``;

interface sliderProps {
  slug: any;
}

export const StyledSlider = styled(Slider)<SliderProps>`
  .slick-track {
    display: flex;
    /* align-items: center; */
  }
  .slick-slide {
    height: 100% !important;
  }

  .slick-slide > div:first-child {
    /*  */
    /* display: flex;
    justify-content: center; */
  }
  @media ${({ theme }) => theme.device.laptop} {
  // .slick-slide {
  //   height: 633px !important;
  // }
  .slick-slide .gatsby-image-wrapper div:first-child {
    height: 593px !important;
  }
  .slick-slide .gatsby-image-wrapper img {
    height: 593px !important;
    border-radius: 4px;
  }
  // .slick-slide div div:first-child {
  //   width: 400px !important;
  // }
}

  @media ${({ theme }) => theme.device.phone} {
    .slick-slide {
      height: 100% !important;
    }
    .slick-slide .gatsby-image-wrapper div:first-child {
      height: 500px !important;
    }
    .slick-slide .gatsby-image-wrapper img {
      height: 500px !important;
      border-radius: 4px;
    }
    .slick-slide div div:first-child {
      width: 100% !important;
    }
  }

    // .slick-slide {
    //   height: 540px;
    // }
    .slick-slide .gatsby-image-wrapper div:first-child {
      height: 500px;
    }
    .slick-slide .gatsby-image-wrapper img {
      height: 500px;
      border-radius: 4px;
    }
    // .slick-slide div div:first-child {
    //   width: 345px;
    // } 
   
  .hOdFpH::before {
    background-color:white;
  }

  .slick-arrow,
  .slick-next {
    opacity: 1;
  }

  .slick-arrow {
    width: 3rem;
    height: 3rem;
    background-image: url(${ArrowIcon});
    background-size: cover;
    background-size: unset;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    z-index: 1;

    &:before {
      content: none;
    }

    &.slick-next {
      transform: translateY(0) rotate(180deg);
      right:  ${({slug}) => slug == "easy-sleeper" ? "24.5%" : "0%"};
      top: 100%;
      background-color: #F58E8A;
      width: 49px;
      height: 49px;
      border-radius: 4px;
      margin-top: ${({slug}) => slug == "easy-sleeper" ? "4%" : "-15%"};
    }

    &.slick-prev {
        left: 24%;
        top: 100%;
        transform: translateX(0);
        background-color: #F58E8A;
        width: 49px;
        height: 49px;
        border-radius: 4px;
        margin-top: 4%;
        display: ${({slug}) => slug == "easy-sleeper" ? "block !important" : "none !important"};
    }

    @media ${({ theme }) => theme.device.tablet} {
      &.slick-next {
        right:  ${({slug}) => slug == "easy-sleeper" ? "22.5%;" : "0%"};
        margin-top: ${({slug}) => slug == "easy-sleeper" ? "4%" : "-10%"};
      }
  
      &.slick-prev {
        left: 22%;
      }
    }

    @media ${({ theme }) => theme.device.phone} {
      &.slick-next {
        right: 5%;
        top: 100%;
        background-color: #F58E8A;
        width: 35px;
        height: 35px;
        border-radius: 4px;
        margin-top: ${({slug}) => slug == "easy-sleeper" ? "15%" : "-21%"};
      }
  
      &.slick-prev {
        // left: -6.5rem;
          left: 5%;
          top: 100%;
          transform: translateX(0);
          background-color: #F58E8A;
          width: 35px;
          height: 35px;
          border-radius: 4px;
          margin-top: 15%;
      }
    }
    @media ${({ theme }) => theme.device.smPhone} {
      &.slick-next {
        right: 0%;
        top: 100%;
        background-color: #F58E8A;
        width: 35px;
        height: 35px;
        border-radius: 4px;
        margin-top: ${({slug}) => slug == "easy-sleeper" ? "15%" : "-21%"};
      }
  
      &.slick-prev {
        // left: -6.5rem;
          left: 0%;
          top: 100%;
          transform: translateX(0);
          background-color: #F58E8A;
          width: 35px;
          height: 35px;
          border-radius: 4px;
          margin-top: 15%;
      }
    }
  }
   
  ul.slick-dots {
      top: 2rem;
      left: 2rem;
      bottom: unset;
      width: auto;
  
      @media ${({ theme }) => theme.device.tablet} {
        top: 3rem;
        left: 4.2rem;
      }
  
      li {
        margin: 0 2.5rem 0 0;
        width: auto;
        height: auto;
  
        &:last-child {
          margin: 0;
        }
  
        button {
          font-size: 0;
          width: 15px;
          height: 15px;
          border: 0;
          border-radius: 50%;

  
          &::before {
            content: none;
          }
        }

        @media ${({ theme }) => theme.device.phone} {
          margin: 0 0.7rem 0 0;
          button {
            width: 10px;
            height: 10px;
          }
          
        }
      }
  }

    .slick-slide {
      padding: 0;
    }
  
    ul.slick-dots {
      top: 100% !important;
      position: absolute;
      left: 37% !important;
      margin-top: 6.3% !important;

      @media ${({ theme }) => theme.device.laptop} {
        left: 45% !important;
        margin-top: 5.3% !important;
      }


      @media ${({ theme }) => theme.device.phone} {
        left: 25% !important;
        margin-top: 17.5% !important;
      }
      @media ${({ theme }) => theme.device.smPhone} {
        left: 20% !important;
        margin-top: 18.5% !important;
      }
      margin: 2rem 0;
    }
  
    .slick-active button {
      font-size: 0rem;
      height: 0.5rem;
      width: 0.5rem;
    }
  
    li button {
      background: #E5E5E5;
      border: 0 !important;
    }
  
    .slick-active button {
      background: #F58E8A;
      border: 0 !important;
    }

`;

export const Description = styled(Paragraph)`
  margin-bottom: 3.1rem;
  font-family: 'Effra-Regular';
  font-size: 1.8rem;
  line-height: 2.6rem;

  @media ${({ theme }) => theme.device.tablet} {
    margin-bottom: 4.6rem;
    font-family: 'Effra-Regular';
    font-size: 1.4rem;
    line-height: 2.2rem;
  }
`;

export const Slide = styled.div`
  /* height: 57vw; */
  /* object-fit: center; */
  /* max-width: 31.2rem; */
  /* width: 21.6vw; */
    width: 100%;
    display: inline-block;
    padding-right: 0%;

  @media ${({ theme }) => theme.device.phone} {
    /* padding-right: 2rem; */
    padding-right: 0%;
      
  }

  @media ${({ theme }) => theme.device.tablet} {
    // max-width: 31.2rem;
      padding-right: 5%;
  }
`;

export const StyledVideo = styled(VideoPopup)`
  margin-bottom: 1.5rem;
  width: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    @media ${({ theme }) => theme.device.tablet} {
      height: 21.7rem;
      object-fit: cover;
      object-position: center;
    }
  }
`;
