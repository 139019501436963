import { Link } from "gatsby";
import styled from "styled-components";
import Image from "src/components/Common/ImageFluid";
import { Paragraph, SectionHeading } from "src/components/Text";
import Slider from "react-slick";
import ArrowIcon from "src/images/main-arrow.svg";
import Button from 'src/components/Common/Button';
import pinkArrow from 'src/images/arrow-right-pink.svg';
import whiteArrow from 'src/images/arrow-right-white.svg';
import Rating from "src/components/Rating";

export const StyledContainer = styled.div`
  padding: 8rem 8.1rem 4.5rem 8.1rem;
  margin: 2rem auto;
  width: 100%;
  max-width: 144rem;
 
  @media ${({ theme }) => theme.device.smTablet} {
    padding: 8rem 5.1rem 12rem 5.1rem;
  }
  @media ${({ theme }) => theme.device.phone} {
    padding: 4rem 2.2rem 8rem 2.2rem;
  }
`
export const ContainerLinkSlider = styled(Link)`
  position: relative;
  overflow: hidden;
  display: flex !important;
  flex-direction: column;
  justify-content: end;
  overflow: visible;
  background: #fff;
  border: 1px solid rgba(202, 202, 202, 0.3);
  border-radius: 5px;
  height: 100%;

  &:focus {
    border: 1px solid #F58E8A;
    outline: unset;
  }
  &:active {
    border: 1px solid #F58E8A;
    outline: unset;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2.5rem 2rem 0rem!important;
  background: #F0F5FE;
  display: flex;
  justify-content: space-between;
  height: 60%;
  position: relative;
`;

export const UpperTextContainer = styled.div`
  margin-bottom: 4rem;  
  @media ${({ theme }) => theme.device.phone} {
    margin-bottom: 2rem;  
  }
`
export const Title = styled(SectionHeading)`
  font-family: 'Effra-Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 45px;
  text-align: center;
  color: #0F0821;

  @media ${({ theme }) => theme.device.phone} {
    font-weight: 700;
    font-size: 30px;
    line-height: 45px;
  }
`;

export const Description = styled.span`
  font-family: 'Effra-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  text-align: center;
  color: #0F0821;
  text-align: center;
  display: block;
  padding: 1rem 1rem;
  
  @media ${({ theme }) => theme.device.tablet} {
    padding: 1rem 0rem;
  }
  

  @media ${({ theme }) => theme.device.largeDesktop} {
    padding: 1rem 25rem;
  }

  @media ${({ theme }) => theme.device.phone} {
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    padding: 1rem;
  }
`;

export const ProductTitle = styled(SectionHeading)`
  font-family: 'Effra-Medium';
  font-weight: 500;
  font-size: 25px;
  line-height: 26px;
  text-align: center;
  color: #12142C;
`;

export const ProductPrice = styled.span`
  font-family: 'Effra-Regular';
  font-weight: 400;
  font-size: 25px;
  line-height: 26px;
  text-align: center;
  color: #F58E8A;
`
export const OkendoReviews = styled.div`
  padding: 0.55rem 0; 
  height: 78px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 0.6rem 0;
  .okeReviews .okeReviews-reviewsSummary {
    display: flex;    
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .okeReviews .okeReviews-reviewsSummary-ratingCount {
    margin: 0.3em 0 0;
  }
`;

export const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.font_pink} !important;
  width: 100% !important;
  height: 60px !important;
  padding-left: 2.6rem !important;
  padding-right: 2.6rem !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border: 1px solid #F58E8A;
  border-radius: 5px;     
  margin-top: 1.5rem;

  @media ${({ theme }) => theme.device.tablet} {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  span.label {
    margin: 0 1rem 0 0 !important;
    color: white !important;
    font-size: 16px;
  }
`;

export const StyledImg = styled(Image)`
  padding: 3.5rem 1rem !important;
`;

export const MainImageContainer = styled.div`
  width: 100%;
  height: 40% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px 10px 0px 0px;

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`;
export const CarouselContainer = styled.div`
  padding: 3rem 0rem;
  height: 100%;

  @media ${({ theme }) => theme.device.mobile} {
    padding: 0rem 0rem 10rem 0rem;
  }
  @media ${({ theme }) => theme.device.tablet} {
    padding: 3em 0rem 3rem 0rem;
  }
  @media ${({ theme }) => theme.device.laptop} {
    padding: 3em 0rem 3rem 0rem;
  }

  .slick-slider,  .slick-list, .slick-track, .slick-slide, .slick-slide :nth-child(1){
    height: 100%;
  }
`;

export const StyledSlider = styled(Slider)`
  .slick-track {
    display: flex;
    justify-content: center;
  }

  .slick-arrow,
  .slick-next {
    opacity: 1;
  }

  .slick-arrow {
    width: 50px;
    height: 50px;
    background-image: url(${ArrowIcon});
    background-repeat: none;
    background-size: cover;
    z-index: 1;

    &:before {
      content: none;
      display: none;
    }

    &.slick-next {
      right: -7.5rem;
    }

    &.slick-prev {
      transform: translateY(-1.6rem) rotate(180deg);
      left: -7.5rem;
    }

    width: 35px;
    height: 35px;

    &.slick-next  {
      top: 112%;
      right: 15%;
    }
    &.slick-prev  {
      top: 111.7%;
      left: 15%;
    }

    @media ${({ theme }) => theme.device.tablet} {

      &.slick-next  {
        top: 50%;
        right: -4%;
      }
      &.slick-prev  {
        top: 50%;
        left: -4%;
      }
    }
  }

  ul.slick-dots {
    top: 2rem;
    left: 2rem;
    bottom: unset;
    width: auto;

    @media ${({ theme }) => theme.device.tablet} {
      top: 3rem;
      left: 4.2rem;
    }

    li {
      margin: 0 0.9rem 0 0;
      width: 10px;
      height: 10px;

      &:last-child {
        margin: 0;
      }
    }
  }
  .slick-slide {
    padding: 0 1rem;
  }

  ul.slick-dots {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50% !important;
    margin: 2rem 0;

    top: 107.5% !important;

  }

  .slick-active button {
    font-size: 0rem;
    height: 0.5rem;
    width: 0.5rem;
  }

  li button {
    background: #E0E0E0;
    border: 0 !important;
    width: 10px;
    height: 10px;
    border-radius: 100px;
  }
  li button::before {
    display: none;
  }

  .slick-active button {
    background: #F58E8A;
    border: 0 !important;
  }
`;

import Product from '../ProductItem';
import { ContainerLink, CartLink, InfoContainer, TextContainer, ImageContainer } from "../ProductItem/styles";
import { RatingsList } from "../Rating/styles";


export const StyledProduct = styled(Product)`
  margin-bottom: 2rem;
  // background-color: ${({ theme }) => theme.colors.font_pink} !important;
  background-color: transparent;
  width: 100% !important;
  height: 60px !important;
  padding-left: 2.6rem !important;
  padding-right: 2.6rem !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border: 1px solid #F58E8A;
  border-radius: 5px;     
  margin-top: 1.5rem;

  &:focus {
    outline: unset;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.font_pink} !important;
  }

  ${ContainerLink} {
    height: 100% !important;
  }

  ${ImageContainer} {
    display:none !important;
  }

  ${InfoContainer} {
    padding: 0 !important;
    margin: 0 !important;
    width: 100%;
    height: 100% !important;
  }

  ${TextContainer} {
    display: none !important;
  }
  ${CartLink} {
    margin: 0 !important;
    height: 60px !important;
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #F58E8A;

    &:hover {
      color: #ffffff;

      &::after {
        content: url(${whiteArrow});
      }
    }
    &::after {
      content: url(${pinkArrow});
      padding-left: 0.5rem;
      padding-top: 0.5rem;
    }
  }

  @media ${({ theme }) => theme.device.tablet} {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
`;

export const StyledProductRating = styled(Product)`
  width: 100% !important;
  height: 15px !important;
  padding-left: 2.6rem !important;
  padding-right: 2.6rem !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;  
  background: #F0F5FE;
  margin-bottom: 1.5rem;
  margin-top: 2rem;

  &:focus {
    outline: unset;
  }



  ${ImageContainer} {
    display:none !important;
  }

  ${RatingsList} {
    width: 75px !important;

    svg {
      margin-right: unset !important;
      width: unset !important;
      height: unset !important;
    }
  }

  ${InfoContainer} {
    padding: 0 !important;
    margin: 0 !important;
  }

  ${TextContainer} {
    display: none !important;
  }
 
  @media ${({ theme }) => theme.device.tablet} {
    padding-left: 0 !important;
    padding-right: 0 !important;
    height: 60px !important;
  }
`;

export const StyledRating = styled(Rating)`
  @media ${({ theme }) => theme.device.tablet} {
    margin-bottom: auto;
  }
`;

export const NewProductMessage = styled.div`
  padding: 6px 11px;
  text-align: center;
  color: #FEF9F9;
  font-weight: 400;
  font-size: 9px;
  background: #4F6EA8;
  border-radius: 57px;
  width: fit-content;
  font-family: 'Effra-Regular';
  font-style: normal;
`

export const RatingsBox = styled.div`
  display: flex;    
  flex-direction: column;
  align-items: center;
  margin: 1.5rem 0;
`;


export const RatingParagraph = styled(Paragraph)`
  span {
    margin-right: .5rem;
    font-size: 15px;
  }
  @media ${({ theme }) => theme.device.phone} {
    span{
      font-size: 14px !important;
    }
  }
`;

export const NewProductMessageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 1.5rem 0;
`

export const BtnBox = styled.div`
  width: 300px;
  display: block;
  margin: auto;

`;

export const PriceHeading = styled.span`
  font-weight: 400;
  font-size: 25px;
  margin-right: 0.5rem;
  font-family: "Effra-Medium";
`;

export const Price = styled(PriceHeading)``;

export const ComparePrice = styled(PriceHeading)`
  opacity: 0.55;
  text-decoration: line-through;
  
  @media ${({ theme }) => theme.device.phone} {
    text-align: center;
    margin-right: 0.5rem;
  }
`;

export const ProductSubtitle = styled(Paragraph)`
display: none;

`;