import styled from "styled-components";
import Wrap from "src/components/Wrap";
import { Paragraph, SectionHeading } from "src/components/Text";

export const StyledWrap = styled(Wrap)`
  padding: 4rem 3rem;
  width: 53%;

  @media ${({ theme }) => theme.device.laptopDesktop} {
    width: 60%;
  }

  @media ${({ theme }) => theme.device.tablet} {
    padding: 1rem 8.1rem 5rem;
  }

  @media ${({ theme }) => theme.device.phone} {
    width: 100%;
  }
`;

export const FormError = styled.p`
  color: ${({ theme }) => theme.colors.error_red};
  margin-top: 10px;
  font-size: 11px;
`;

export const Description = styled(Paragraph)`
  line-height: 2.6rem;
  margin-bottom: 2rem;

  @media ${({ theme }) => theme.device.tablet} {
    line-height: 3rem;
    margin-bottom: 2rem;
  }
`;

export const Heading = styled(SectionHeading)`
  margin-bottom: 1rem;
`;