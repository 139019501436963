import React from 'react';
import {
  BgContainer,
  StyledWrap,
  StyledHeading,
  StyledBox,
  StyledDescription,
  StyledImage,
  StyledContent,
  StyledList,
  StyledListItem,
  StyledImageContainer

} from './styles';
import BlockContent from '@sanity/block-content-to-react';
import { Serializer } from 'src/utils/serializer';
import RedX from "src/images/red_x.svg";
import { useMediaQuery } from "react-responsive";
import { DesktopStyle } from '../NewHeroSection/styles';
import { MobileStyle } from '../NewImageRowSection/styles';


export const size = {
  tablet: 768,
};
interface Props {
  data: {
    description?: Array;
    mobileHeading?: Array;
    desktopHeading?: Array;
    listItems?: Array;
    image?: SanityImage;

  }

}

const NewTextImageBox = ({ data }: Props) => {
  const { description, mobileHeading, desktopHeading, listItems, image } = data;

  const isMobile = useMediaQuery({ maxWidth: size.tablet });
  return (
    <BgContainer>
      <StyledWrap>
        <DesktopStyle>
          <StyledHeading>
            <BlockContent blocks={desktopHeading} serializers={Serializer} />
          </StyledHeading></DesktopStyle>
        <MobileStyle>
          <StyledHeading>
            <BlockContent blocks={mobileHeading} serializers={Serializer} />
          </StyledHeading></MobileStyle>
        <MobileStyle>
          <StyledDescription>
            <BlockContent blocks={description} serializers={Serializer} />

          </StyledDescription></MobileStyle>
        <StyledBox>
          <StyledImageContainer>
            <StyledImage imageRef={image} alt="image" />
          </StyledImageContainer>
          <StyledContent>

            <DesktopStyle>
              <StyledDescription>
                <BlockContent blocks={description} serializers={Serializer} />

              </StyledDescription></DesktopStyle>
            <StyledList>
              {listItems?.map((item, i) => {
                return (
                  <StyledListItem key={i}>
                    <img src={RedX} alt="icon" />
                    <BlockContent blocks={item?.list} serializers={Serializer} />
                  </StyledListItem>
                )
              })}
            </StyledList>
          </StyledContent>

        </StyledBox>


      </StyledWrap>
    </BgContainer>
  )
}

export default NewTextImageBox;