import React, { Suspense, useState } from "react";
import { SanityImage, SanityColor } from "src/interfaces/sanity";
import {
  StyledWrap,
  StyledBlocks,
  Subheading,
  Name,
  StyledImage,
  StyledModel,
  StyledButton,
  ContainerNameSub,
  ActivateLink,
  TopIcon,
} from "./styles";
import { isSSR } from "src/utils/ssr";
import { SanityButton } from "src/interfaces/common";
import { VideoModal } from "src/components/VideoPopup/styles";
import { StyledVideo } from "src/components/Video/styles";

interface Props {
  data: {
    main: {
      heading: object[];
      image: SanityImage;
      modelFile: {
        url: string;
      };
      arModelFile?: {
        url: string;
      };
      title: string;
      subheading: string;
      video: any;
      leftTitle: boolean;
      leftHeading: boolean;
      addBottomPadding: boolean;
      activateLink: SanityButton;
      trustpilotReviews: SanityImage;
      button: SanityButton;
    };
    experiment: {
      variantA?: boolean;
      variantB?: boolean;
    };
    sectionSettings: {
      backgroundColor: SanityColor;
      backgroundImage: SanityImage;
    };
  };
}

const HeadingSection = ({ data }: Props) => {
  const [open, setOpen] = useState(false);
  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };
  const {
    main: {
      heading,
      modelFile,
      title,
      subheading,
      button,
      video,
      activateLink,
      leftTitle,
      addBottomPadding,
      leftHeading,
      trustpilotReviews,
      topIcon,
      arModelFile,
      videoShopify
    },
  } = data;

  return (
    <>
      <StyledWrap addBottomPadding={addBottomPadding} className={data?.experiment?.variantB ? 'variant_B' : data?.experiment?.variantA ? 'variant_A': ''}>
        {topIcon && <TopIcon imageRef={topIcon} />}
        <div className="content">
          <StyledBlocks blocks={heading} leftHeading={leftHeading} />
          {!isSSR && modelFile && (
            <Suspense fallback={<div />}>
              <StyledModel
                alt="A 3D model"
                src={modelFile.url}
                usdzSrc={arModelFile?.url}
              />
            </Suspense>
          )}

          <ContainerNameSub leftTitle={leftTitle}>
            {title && <Name leftTitle={leftTitle}>{title}</Name>}
            <div onClick={openModal}>
              {subheading && <Subheading>{subheading}</Subheading>}
            </div>
          </ContainerNameSub>

          {button && button.enabled && (
            <StyledButton buttonType="outline" to={button.link} small={true}>
              {button.text}
            </StyledButton>
          )}
        </div>
        <div className="trustpilot">
          {trustpilotReviews && <StyledImage imageRef={trustpilotReviews} />}
          {activateLink && <ActivateLink button={activateLink} />}
        </div>
      </StyledWrap>
      <VideoModal isOpen={open} onRequestClose={closeModal} className={data?.experiment?.variantB ? 'variant_B' : data?.experiment?.variantA ? 'variant_A': ''}>
        <StyledVideo isPopUp={true} src={videoShopify ? videoShopify : video?.url} controls={true} autoPlay={true} />
      </VideoModal>
    </>
  );
};

export default HeadingSection;
